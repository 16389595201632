import { Customer } from "./common";

export const enum AuthService {
    DatahubServices = "DatahubServices",
}

export const enum AuthFeature {
    ContactInfo = "ContactInfo",
    News = "News",
    ServiceAgreements = "ServiceAgreements",
}

// Authorization definition not bound to a businessId.
export interface AuthorizationDefinition<AuthService extends string, AuthFeature extends string> {
    // e.g. "Transmission" or "All" for all services
    service: AuthService;

    // e.g. "OutageNeeds" or "All" for all features
    feature: AuthFeature;

    // Note that write-only access is not supported.
    accessLevel: "Read" | "ReadWrite";
}

// Requirement when checking authorizations.
export interface AuthorizationRequirement<AuthService extends string, AuthFeature extends string>
    extends AuthorizationDefinition<AuthService, AuthFeature> {
    // An optional resource belonging to the business (of businessId).
    // Not defining a resourceId in the requirement is similar to requiring "All" for
    // service/feature/businessId: authorizations with a specific resourceId will not
    // satisfy the requirement.
    // In order to accept authorizations with resourceIds that otherwise satisfy the
    // requirement one can specify "Any" as the value of resourceId in the requirement.
    resourceId?: string;
    businessId: string;
    accountNumber?: string;
}

// A user has zero or more authorizations which describes access rights to different
// businesses, services and features with read or read-write access.
// The user can have a value of "All" for businessId, service or feature.
// Each endpoint of the application define a required authorization, which can
// contain "All" properties. It may also be a subset of the Authorization type, e.g. only requiring
// access to a specific business while using a "wildcard" for everything else.
export interface Authorization extends AuthorizationDefinition<string, string> {
    // An optional resource belonging to the business (of businessId).
    // This is e.g. a power plant project id.
    // The AD group display name business ID slot has "foo~bar" for
    // businessId of "foo" and resourceId of "bar".
    // e.g. "12345"
    resourceId?: string;

    // e.g. "1234567-8" or "All" for all businesses
    businessId: Customer["businessId"];

    accountNumber?: Customer["accountNumber"];
}

// User's role
export interface Role {
    // An optional resource belonging to the business (of businessId).
    // This is e.g. a power plant project id.
    // The AD group display name business ID slot has "foo~bar" for
    // businessId of "foo" and resourceId of "bar".
    // e.g. "12345"
    resourceId?: string;

    // e.g. "1234567-8" or "All" for all businesses
    businessId: Customer["businessId"];

    accountNumber?: Customer["accountNumber"];

    // e.g. "Transmission"
    service: string;

    // e.g. "Maintainer"
    name: string;
}

export interface RoleDefinition<AuthService extends string, AuthFeature extends string> {
    // e.g. "Transmission"
    service: AuthService;

    // e.g. "MapViewer"
    name: string;

    // e.g. "Map viewer"
    displayName: string;

    // "False" if the role cannot be assigned by anyone
    // "FingridUser" if the role can only be assigned by a Fingrid admin user
    // "True" if the role can be assigned by any admin user
    assignable: "False" | "FingridUser" | "True";

    authorizationDefinitions: AuthorizationDefinition<AuthService, AuthFeature>[];
}

export interface NewUser {
    email: string;
    givenName: string;
    surname: string;
    mobilePhone: string;
    displayName: string;
}

export interface User {
    // e.g. "my-fingrid test"
    // Full name
    name: string;

    // e.g. "my-fingrid.test@kantaverkko.fi"
    // User Principal Name
    // Stores the user name of the user principal.
    userName: string;

    // e.g. "test"
    // Provides the last name, surname, or family name of the user as defined
    // in the Azure AD user object.
    familyName: string;

    // e.g. "my-fingrid"
    // Provides the first or "given" name of the user, as set on the Azure AD
    // user object.
    givenName: string;

    // e.g. "my-fingrid.test@kantaverkko.fi"
    // Provides a human readable value that identifies the subject of the token.
    // This value is not guaranteed to be unique within a tenant and is designed
    // to be used only for display purposes.
    uniqueName: string;

    // e.g. "my-fingrid.test@kantaverkko.fi"
    email: string;
}

export interface AzureUser {
    // e.g. "18359cfc-3520-4cb6-b838-47709a7f78d5"
    id: string;

    // e.g. "John Doe"
    displayName: string;
}

export interface UserProfile extends AzureUser {
    businessPhones: string[];

    // e.g. "John"
    givenName: string | null;

    jobTitle: string | null;

    // e.g. "my-fingrid.test@kantaverkko.fi"
    mail: string;

    // e.g. "+358405835123"
    mobilePhone: string;

    officeLocation: string | null;
    preferredLanguage: string | null;

    // e.g. "Doe"
    surname: string | null;

    // e.g. "my-fingrid.test@kantaverkko.fi"
    userPrincipalName: string;
}

export interface UserAuthorizations extends UserProfile {
    authorizations: Authorization[];
    roles: Role[];
}

export interface UserDetails extends UserAuthorizations {
    // e.g. "2019-08-28T08:28:11.721Z"
    lastSignIn: string | null;
}

export interface AuthorizedUser {
    userId: string | null;
    user: User | null;
    authorizations: Authorization[];
    isFingridUser: boolean;
}
