import * as React from "react";
import TextArea from "../TextArea/TextArea";
import styled from "styled-components";

interface Props {
    value: string;
    disabled?: boolean;
    // Translated label
    label: string;
    onChange: (value: string) => void;
    required?: boolean;
    maxLength?: number;
    className?: string;
    name?: string;
}

const StyledContainer = styled.div`
    font-size: 18px;
`;

const StyledTextArea = styled(TextArea)`
    margin-left: 0;

    &:disabled {
        opacity: 0.5;
    }
`;

class LabeledTextAreaInput extends React.Component<Props> {
    public render() {
        return (
            <StyledContainer>
                <label>
                    {this.props.label}
                    {this.props.required ? "*" : ""}
                    <StyledTextArea
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength}
                    />
                </label>
            </StyledContainer>
        );
    }
}

export default LabeledTextAreaInput;
