import styles from "styles/styles";
import { css } from "styled-components";

const buttonCss = css`
    align-items: center;
    display: flex;
    font-size: 18px;
    height: 59px;
    justify-content: center;
    min-width: 120px;
    cursor: pointer;
    border: 0;
    padding: 0 10px;

    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }

    &:disabled {
        composes: button;
        background-color: ${styles.colors.grey3};
        cursor: default;
        color: ${styles.colors.grey5};

        &:hover,
        &:active {
            background-color: ${styles.colors.grey3};
        }
    }
`;

const primary = css`
    ${buttonCss}
    background-color: ${styles.colors.red};
    color: ${styles.colors.white};

    &:hover,
    &:active {
        background-color: ${styles.colors.darkRed};
    }
`;

const secondary = css`
    ${buttonCss}
    background-color: ${styles.colors.white};
    color: ${styles.colors.red};
    border: 2px solid ${styles.colors.red};

    &:hover,
    &:active {
        border-color: ${styles.colors.darkRed};
        color: ${styles.colors.darkRed};
    }
`;

const tertiary = css`
    ${buttonCss}
    background-color: initial;
    color: ${styles.colors.red};

    &:hover,
    &:active {
        color: ${styles.colors.darkRed};
    }
`;

const transparent = css`
    ${buttonCss}
    background-color: transparent;
    color: ${styles.colors.grey7};

    &:disabled {
        cursor: default;
        color: ${styles.colors.grey7};
    }
`;

const disabledButton = css`
    ${buttonCss}
    background-color: ${styles.colors.grey6};
    color: ${styles.colors.grey7};
    cursor: default;

    &:hover,
    &:active {
        background-color: ${styles.colors.grey6};
    }
`;

const buttonStyles = {
    primary,
    secondary,
    tertiary,
    transparent,
    disabledButton,
};

export { buttonStyles };
