import styles from "styles/styles";
import styled from "styled-components";

const Page = styled.div`
    max-width: ${styles.layout.pageMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: 0 ${styles.spacing[5]} ${styles.spacing[6]} ${styles.spacing[5]};
    min-height: 30vh;
`;

export default Page;
