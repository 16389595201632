import { combineEpics } from "redux-observable";

import { fetchSwitchMap } from "state/observable";
import { config } from "services/common/config/config";

import { fetchFaultNotifications } from "./actions";

export const fetchFaultNotificationsEpic = fetchSwitchMap(fetchFaultNotifications, (action) => ({
    url: `${config.content.news}`,
    queryParameters: {
        ...action.payload,
    },
}));

export default combineEpics(fetchFaultNotificationsEpic);
