import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { Tag } from "interfaces/content";
import FileSearchTag from "./FileSearchTag/FileSearchTag";
import styled from "styled-components";
import styles from "styles/styles";
import ContentContainer from "components/common/layout/ContentContainer";
import SearchInput from "../../common/ui/SearchInput/SearchInput";
import { Row } from "../../common/ui/FlexboxGrid";

export const TagsTitle = styled.div`
    font-size: ${styles.fontSize.normal};
`;

const TagsContainer = styled.div`
    max-width: ${styles.layout.pageMaxWidth};
    margin: 0 auto;
`;

const TagContainer = styled.div`
    margin: ${styles.spacing[3]};
    width: 130px;
`;

const SearchInputContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const Container = styled.div`
    width: 100%;
    background-color: ${styles.colors.lightGrey};
    display: inline-block;
    text-align: center;
    padding: ${styles.spacing[2]} 0;
`;

const TitleContainer = styled.h5`
    margin-bottom: 0;
`;

interface Props {
    allTags: Tag[];
    selectedTags: Tag[];
    searchTerm: string;
    onSearchTermChange: (searchTerm: string) => void;
    onTagChange: (tags: Tag[]) => void;
}

const FileSearchCard = ({
    allTags,
    searchTerm,
    onSearchTermChange,
    selectedTags,
    onTagChange,
}: Props) => {
    const translate = useTranslate();
    const onTagClick = useCallback(
        (clickedTag: Tag) => {
            const tagIsSelectedAlready = selectedTags.includes(clickedTag);

            const newTagFilters = tagIsSelectedAlready
                ? selectedTags.filter((selectedTag) => selectedTag.id !== clickedTag.id)
                : [...selectedTags, clickedTag];

            onTagChange(newTagFilters);
        },
        [selectedTags, onTagChange]
    );

    return (
        <Container>
            <ContentContainer>
                <SearchInputContainer>
                    <TitleContainer>
                        <FormattedMessage id="fileSearch.searchCard.title" />
                    </TitleContainer>
                    <SearchInput
                        value={searchTerm}
                        onChange={onSearchTermChange}
                        ariaLabel={translate("fileSearch.searchCard.title")}
                    />
                </SearchInputContainer>
                {allTags.length > 0 && (
                    <TagsContainer>
                        <TagsTitle>
                            <FormattedMessage id="fileSearch.searchCard.chooseTags"></FormattedMessage>
                        </TagsTitle>
                        <Row center="xs">
                            {allTags.map((tag) => (
                                <TagContainer key={tag.id}>
                                    <FileSearchTag
                                        tag={tag}
                                        isSelected={selectedTags.includes(tag)}
                                        onClick={onTagClick}
                                    />
                                </TagContainer>
                            ))}
                        </Row>
                    </TagsContainer>
                )}
            </ContentContainer>
        </Container>
    );
};

export default FileSearchCard;
