import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { NewsFeed } from "interfaces/content";
import { fetchFaultNotifications } from "./actions";

export interface FaultNotificationsState {
    faultNotifications: NewsFeed | null;
}

const initialState: FaultNotificationsState = {
    faultNotifications: null,
};

export default function reducer(state = initialState, action: AnyAction): FaultNotificationsState {
    if (isType(action, fetchFaultNotifications.done)) {
        return {
            ...state,
            faultNotifications: action.payload.result,
        };
    }

    return state;
}
