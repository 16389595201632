import { useEffect } from "react";

const useScrollToTop = () => {
    useEffect(() => {
        // Use timeout to achieve smoother expierience
        setTimeout(() => window.scrollTo(0, 0), 50);
    }, []);
};

export default useScrollToTop;
