import React, { FC, Fragment } from "react";
import useLocale from "hooks/common/useLocale/useLocale";
import { LOCALES } from "services/common/localization/localization";
import { Link, useLocation } from "react-router-dom";
import { getPathnameWithoutLocale } from "services/common/url/url";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    className?: string;
}

const Divider = styled.span`
    margin: 0 ${styles.spacing[1]};
`;

const LocaleLink = styled(Link)`
    color: ${styles.colors.red};
`;

const LocaleSelector: FC<Props> = ({ className }) => {
    const currentLocale = useLocale();
    const { pathname } = useLocation();
    const pathWithoutLocale = getPathnameWithoutLocale(pathname);

    return (
        <div className={className}>
            {LOCALES.map((locale, i) => (
                <Fragment key={locale}>
                    {i !== 0 ? <Divider>|</Divider> : null}
                    {locale === currentLocale ? (
                        <span>{locale.toUpperCase()}</span>
                    ) : (
                        <LocaleLink to={`/${locale}${pathWithoutLocale}`}>
                            {locale.toUpperCase()}
                        </LocaleLink>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default LocaleSelector;
