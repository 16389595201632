import React from "react";
import { User } from "oidc-client";
import { CallbackComponent } from "redux-oidc";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import useLocale from "hooks/common/useLocale/useLocale";
import userManager from "services/common/oidc/userManager";
import { LOGIN_FAILED_URL_SEGMENT } from "services/common/url/url";
import Page from "components/common/layout/Page";
import ContentContainer from "components/common/layout/ContentContainer";
import LoadingIndicator from "../../../components/common/ui/LoadingIndicator/LoadingIndicator";

const Auth = () => {
    const dispatch = useDispatch();
    const locale = useLocale();
    /**
     * Redirect user back to the originally requested URL after login.
     */
    const successCallback = (user: User) => {
        dispatch(push(user.state.redirectUrl));
    };

    const errorCallback = (_: Error) => {
        dispatch(push(`${locale}/${LOGIN_FAILED_URL_SEGMENT}`));
    };

    // added ignore, because the type def update requires large library update (or an ugly wrapper)
    return (
        <ContentContainer>
            <Page>
                {/* @ts-ignore */}
                <CallbackComponent
                    userManager={userManager}
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                >
                    <LoadingIndicator isLoading={true} />
                </CallbackComponent>
            </Page>
        </ContentContainer>
    );
};

export default Auth;
