import styles from "styles/styles";
import React from "react";
import styled from "styled-components";
import LoadingIndicator from "../../common/ui/LoadingIndicator/LoadingIndicator";

const LoadingStateContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${styles.colors.grey3};
    opacity: 0.3;
    z-index: 11;
`;

const LoadingState = ({ isLoading }: { isLoading: boolean }) =>
    isLoading ? (
        <LoadingStateContainer>
            <LoadingIndicator isLoading={isLoading} />
        </LoadingStateContainer>
    ) : null;

export default LoadingState;
