// @TODO move to @fingrid/react-ui-components
import * as React from "react";
import { useCallback, useState } from "react";

interface Props<FormFields> {
    FormComponent: React.ComponentType<FormContentProps<FormFields>>;
    initialValues: FormFields;
}

type FormContentProps<FormFields> = { fields: FormFields } & Handlers<FormFields>;

export type OnChangeHandler<FormFields> = <K extends keyof FormFields>(
    s: K,
    a: FormFields[K]
) => void;

interface Handlers<FormFields> {
    onChange: OnChangeHandler<FormFields>;
}

function Form<FormFields>({ FormComponent, initialValues }: Props<FormFields>) {
    const [state, setState] = useState({ fields: initialValues });

    const onChange: OnChangeHandler<FormFields> = useCallback(
        (field, value) => {
            setState((currentState) => ({
                ...currentState,
                fields: { ...state.fields, [field]: value },
            }));
        },
        [state.fields]
    );

    return <FormComponent onChange={onChange} fields={state.fields} />;
}

export default Form;
