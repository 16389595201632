import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import {
    ConnectingContact,
    ConnectingContactDetails,
    ConnectingContactInfo,
} from "interfaces/contact-info";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import { getConnectingOptions, getConnectionFeeValue } from "services/contact-info/connecting";
import { getStringFieldValue } from "services/contact-info/contact-info";
import Form from "components/common/ui/Form/Form";
import FormButtonRow from "components/common/ui/FormButtonRow/FormButtonRow";
import LabeledTextInput from "../../common/ui/LabeledTextInput/LabeledTextInput";
import LabeledSelect from "../../common/ui/LabeledSelect/LabeledSelect";
import LabeledTextAreaInput from "../../common/ui/LabeledTextAreaInput/LabeledTextAreaInput";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    id?: ConnectingContactInfo["id"];
    details: ConnectingContactDetails;
    isSaveInProgress: boolean;
    onSave: (contactDetails: ConnectingContactDetails, contactId?: ConnectingContact["id"]) => void;
    onCancel: () => void;
}

function ConnectingForm(props: Props) {
    const intl = useIntl();
    const translate = useTranslate();

    const onSaveForm = (contactDetails: ConnectingContactDetails) => (event: React.FormEvent) => {
        event.preventDefault();

        props.onSave(contactDetails, props.id);
    };

    const isSaveEnabled = useCallback(
        (contactDetails: ConnectingContactDetails) =>
            props.id ? true : contactDetails.participantId.length !== 0,
        [props.id]
    );

    return (
        <Form
            initialValues={props.details}
            FormComponent={({ fields: contactInfo, onChange }) => (
                <form onSubmit={onSaveForm(contactInfo)}>
                    {!props.id ? (
                        <Row>
                            <Col md={12}>
                                <LabeledTextInput
                                    label={translate("contactInfo.details.participantId")}
                                    required={true}
                                    value={contactInfo.participantId}
                                    onChange={(value) => onChange("participantId", value)}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col md={12}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.linkToPriceList")}
                                value={contactInfo.linkToPriceList || ""}
                                onChange={(value) => {
                                    onChange("linkToPriceList", value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrBefore13SameDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrBefore13SameDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrBefore13SameDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrAfter13SameDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrAfter13SameDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrAfter13SameDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrBefore13NextDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrBefore13NextDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrBefore13NextDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrAfter13NextDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrAfter13NextDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrAfter13NextDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ercBefore13SecondDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ercBefore13SecondDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ercBefore13SecondDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrAfter13SecondDay || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrAfter13SecondDay", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrAfter13SecondDay")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrWeekend || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrWeekend", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrWeekend")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.ecrAfterWorkingHours || ""}
                                allowDeselection={true}
                                options={getConnectingOptions(intl)}
                                onSelect={(value) => {
                                    onChange("ecrAfterWorkingHours", getConnectionFeeValue(value));
                                }}
                                label={translate("contactInfo.details.ecrAfterWorkingHours")}
                                unknownOption={translate(
                                    "contactInfo.details.connectingFee.notSelected"
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.workingTime")}
                                value={contactInfo.workingTime || ""}
                                onChange={(value) => {
                                    onChange("workingTime", getStringFieldValue(value));
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <LabeledTextAreaInput
                                label={translate("contactInfo.details.notes")}
                                value={contactInfo.notes || ""}
                                onChange={(value) => {
                                    onChange("notes", getStringFieldValue(value));
                                }}
                            />
                        </Col>
                    </Row>
                    <FormButtonRow
                        isSaveEnabled={isSaveEnabled(contactInfo)}
                        onCancel={props.onCancel}
                        isSaveInProgress={props.isSaveInProgress}
                    />
                </form>
            )}
        />
    );
}

export default ConnectingForm;
