import React, { MouseEvent, ReactNode } from "react";
import styled from "styled-components";
import styles from "../../../../styles/styles";

export interface ToggleSwitchButtonOption<T> {
    id: T;
    content: ReactNode;
}

export interface Props<T> {
    options: ToggleSwitchButtonOption<T>[];
    onSelectOption: (optionId: ToggleSwitchButtonOption<T>["id"]) => (event: MouseEvent) => void;
    selectedOptionId: ToggleSwitchButtonOption<T>["id"];
    ariaDescribedBy?: string;
    disabled?: boolean;
}

const StyledToggleSwitchButton = styled.button<{ selected?: boolean }>`
    background-color: transparent;
    color: ${styles.colors.grey4}
    border: none;
    border-bottom: 2px solid ${styles.colors.grey4}
    padding: 10px;
    font-size: inherit;
    
    ${(props) =>
        props.selected &&
        `
        composes: toggleSwitchButton;
        font-weight: bold;
        border-bottom-color: ${styles.colors.grey7};
    `}
`;

const ToggleSwitchButtons = <T extends string | undefined>({
    selectedOptionId,
    options,
    onSelectOption,
    ariaDescribedBy,
    disabled,
}: Props<T>) => (
    <>
        {options.map((option) => (
            <StyledToggleSwitchButton
                key={option.id}
                onClick={onSelectOption(option.id)}
                selected={option.id === selectedOptionId}
                aria-pressed={option.id === selectedOptionId}
                aria-describedby={ariaDescribedBy}
                disabled={disabled}
            >
                {option.content}
            </StyledToggleSwitchButton>
        ))}
    </>
);

export default ToggleSwitchButtons;
