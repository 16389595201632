import React, { ReactElement, ReactNode } from "react";
import {
    documentToReactComponents,
    Options as RawOptions,
} from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";

import {
    LocalizedDocument,
    ContentfulDocumentEmbeddedContent,
    DocumentContentBlock,
    AuthenticatedFeature as AuthenticatedFeatureType,
    Media,
    DocumentFileList,
    ColumnRow as ColumnRowType,
    DocumentSection as DocumentSectionType,
    ActionSteps as ActionStepsType,
    Section as SectionType,
    ContactList,
    IframeContent as IframeContentType,
    Table as TableType,
    RoleList as RoleListType,
    LinkButton as LinkButtonType,
    VersionHistoryBlock as VersionHistoryType,
    CtaBlock as CtaBlockType,
} from "interfaces/content";

import { Locale } from "services/common/localization/localization";
import { Block, Inline } from "interfaces/rich-text-types";
import useLocale from "hooks/common/useLocale/useLocale";
import AuthenticatedFeature from "../AuthenticatedFeature/AuthenticatedFeature";
import DocumentList from "../DocumentList/DocumentList";

import Section from "../Section/Section";
import RichTextContactList from "../RichTextContactList/RichTextContactList";
import IframeContent from "../IframeContent/IframeContent";
import Table from "../Table/Table";
import ColumnRow from "components/content/ColumnRow/ColumnRow";
import DocumentSection from "components/content/DocumentSection/DocumentSection";
import ActionSteps from "components/content/ActionSteps/ActionSteps";
import LinkButton from "components/content/LinkButton/LinkButton";
import RoleList from "components/content/RoleList/RoleList";
import styled from "styled-components";
import VersionHistoryBlock from "../VersionHistoryBlock/VersionHistoryBlock";
import CtaBlock from "../CtaBlock/CtaBlock";

export interface Props {
    richText: LocalizedDocument;
}

export const isAuthenticatedFeature = (
    entry: ContentfulDocumentEmbeddedContent
): entry is AuthenticatedFeatureType => entry.type === "authenticatedFeature";

export const isColumnRow = (entry: ContentfulDocumentEmbeddedContent): entry is ColumnRowType =>
    entry.type === "columnRow";

const isDocumentSection = (
    entry: ContentfulDocumentEmbeddedContent
): entry is DocumentSectionType => entry.type === "documentSection";

const isActionSteps = (entry: ContentfulDocumentEmbeddedContent): entry is ActionStepsType =>
    entry.type === "actionSteps";

const isLinkButton = (entry: ContentfulDocumentEmbeddedContent): entry is LinkButtonType =>
    entry.type === "linkButton";

export const isSection = (entry: ContentfulDocumentEmbeddedContent): entry is SectionType =>
    entry.type === "section";

const isAsset = (entry: ContentfulDocumentEmbeddedContent | Media): entry is Media =>
    entry.type === "media";

const isDocumentList = (entry: ContentfulDocumentEmbeddedContent): entry is DocumentFileList =>
    entry.type === "documentList";

const isContactList = (entry: ContentfulDocumentEmbeddedContent): entry is ContactList =>
    entry.type === "contactList";

const isIframeContent = (entry: ContentfulDocumentEmbeddedContent): entry is IframeContentType =>
    entry.type === "iframeContent";

const isTable = (entry: ContentfulDocumentEmbeddedContent): entry is TableType =>
    entry.type === "table";

const isRoleList = (entry: ContentfulDocumentEmbeddedContent): entry is RoleListType =>
    entry.type === "roleList";

const isVersionHistory = (entry: ContentfulDocumentEmbeddedContent): entry is VersionHistoryType =>
    entry.type === "versionHistory";

const isCtaBlock = (entry: ContentfulDocumentEmbeddedContent): entry is CtaBlockType =>
    entry.type === "ctaBlock";

const Container = styled.div`
    max-width: 100%;
    overflow: hidden;
`;

const EmbeddedImg = styled.img`
    max-width: 100%;
`;

interface Options {
    renderNode: {
        // Matches RawOptions's renderNode but includes our internal "node" definition.
        [k in BLOCKS]?: (node: Block | Inline, children: ReactNode) => ReactNode;
    };
}

export interface DocumentEmbeddedEntryContentBlock extends DocumentContentBlock {
    data: {
        target?: ContentfulDocumentEmbeddedContent;
    };
}

export interface DocumentEmbeddedAssetContentBlock extends DocumentContentBlock {
    data: {
        target?: Media;
    };
}

enum BLOCKS {
    EMBEDDED_ENTRY = "embedded-entry-block",
    EMBEDDED_ASSET = "embedded-asset-block",
}

const getOptions = (locale: Locale): Options => ({
    renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: DocumentEmbeddedEntryContentBlock) => {
            const embeddedEntry = node.data.target;

            if (!embeddedEntry) {
                return <div />;
            }

            if (isAuthenticatedFeature(embeddedEntry)) {
                return <AuthenticatedFeature authenticatedFeature={embeddedEntry} />;
            }

            if (isDocumentList(embeddedEntry)) {
                return <DocumentList documentList={embeddedEntry} />;
            }

            if (isColumnRow(embeddedEntry)) {
                return <ColumnRow columnRow={embeddedEntry} />;
            }

            if (isDocumentSection(embeddedEntry)) {
                return <DocumentSection documentSection={embeddedEntry} />;
            }

            if (isActionSteps(embeddedEntry)) {
                return <ActionSteps actionSteps={embeddedEntry} />;
            }

            if (isLinkButton(embeddedEntry)) {
                return <LinkButton linkButton={embeddedEntry} />;
            }

            if (isSection(embeddedEntry)) {
                return <Section section={embeddedEntry} />;
            }

            if (isContactList(embeddedEntry)) {
                return <RichTextContactList contactList={embeddedEntry} />;
            }

            if (isIframeContent(embeddedEntry)) {
                return <IframeContent iframe={embeddedEntry} />;
            }

            if (isTable(embeddedEntry)) {
                return <Table table={embeddedEntry} />;
            }

            if (isRoleList(embeddedEntry)) {
                return <RoleList roleList={embeddedEntry} />;
            }

            if (isVersionHistory(embeddedEntry)) {
                return <VersionHistoryBlock versionHistoryBlock={embeddedEntry} />;
            }

            if (isCtaBlock(embeddedEntry)) {
                return <CtaBlock ctaBlock={embeddedEntry} />;
            }

            ((_: never) => {
                throw new Error(`Unknown embedded entry ${embeddedEntry}`);
            })(embeddedEntry);
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: DocumentEmbeddedAssetContentBlock) => {
            const embeddedAsset = node.data.target;

            if (!embeddedAsset) {
                return <div />;
            }

            if (isAsset(embeddedAsset)) {
                return (
                    embeddedAsset.file[locale] && (
                        <EmbeddedImg
                            src={`${embeddedAsset.file[locale]!.url}?fm=jpg&fl=progressive`}
                            alt={embeddedAsset.description ? embeddedAsset.description[locale] : ""}
                        />
                    )
                );
            }

            ((_: never) => {
                throw new Error(`Unknown embedded asset ${embeddedAsset}`);
            })(embeddedAsset);
        },
    },
});

function RichText({ richText }: Props): ReactElement | null {
    const locale = useLocale();
    const options = getOptions(locale);

    return (
        <Container>
            {
                /* The library @contentful/rich-text-react-renderer uses internally the enums from @contentful/rich-text-types.
                    However, we are using our own cloned version of enums from @interfaces/rich-text-types in both the frontend & the backend.
                    In Typescript two different enums values are incompatible even if they are an identical string.
                    Therefore, the compiler doesn't allow using our own version of a document or options due to enums being incompatible.
                    Hence the "as" keyword is used here as a trade-off, since the @contentful/rich-text-react-renderer library is used only in this component (RichText). */
                documentToReactComponents(
                    richText[locale] as unknown as Document,
                    options as RawOptions
                )
            }
        </Container>
    );
}

export default RichText;
