import React from "react";
import { Authorization, AuthorizationRequirement } from "../../../interfaces/authorization";
import { isAuthorized } from "../../../services/auth/auth";

export interface Props<AuthService extends string, AuthFeature extends string> {
    userAuthorizations: Authorization[];
    isFingridUser: boolean;

    // A component which will be rendered if the user authorizations do not meet the given requirements
    unauthorizedPlaceholder?: React.ReactNode;

    requirements: {
        // The user must have these authorizations to see the children components
        required?: Partial<AuthorizationRequirement<AuthService, AuthFeature>>[];

        // If not empty, children components are displayed if at least one matching authorization is found
        some?: Partial<Authorization>[];

        // If true, additionally require that the user is a Fingrid user.
        requireFingridUser?: boolean;
    };
}

/**
 * This component is used to restrict the user to only display authorized content
 */
function Authorize<AuthService extends string, AuthFeature extends string>(
    props: React.PropsWithChildren<Props<AuthService, AuthFeature>>
) {
    if (!props.requirements.required && !props.requirements.some) {
        throw new Error("Missing properties for <Authorize>");
    }

    const authorizedContent = props.children || null;
    const unauthorizedPlaceholder = props.unauthorizedPlaceholder || null;

    return (
        <>
            {(!props.requirements.requireFingridUser || props.isFingridUser) &&
            ((props.requirements.required &&
                props.requirements.required.every(
                    (required) =>
                        Boolean(props.userAuthorizations) &&
                        isAuthorized(props.userAuthorizations || [], required)
                )) ||
                (props.requirements.some &&
                    props.requirements.some.some(
                        (required) =>
                            Boolean(props.userAuthorizations) &&
                            isAuthorized(props.userAuthorizations || [], required)
                    )))
                ? authorizedContent
                : unauthorizedPlaceholder}
        </>
    );
}

export default Authorize;
