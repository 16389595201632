import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import PageLoadingIndicator from "components/common/ui/PageLoadingIndicator/PageLoadingIndicator";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import PageNotFound from "components/common/ui/PageNotFound/PageNotFound";
import BlogPost from "components/blog/BlogPost/BlogPost";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import useLocale from "hooks/common/useLocale/useLocale";
import { extractPlainTextFromDocument } from "services/common/meta-data/meta-data";
import { BLOG_URL_SEGMENT } from "services/common/url/url";
import { loadingSelectorFactory } from "state/common/loading";
import { fetchBlogPost } from "state/content/contentful";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import styled from "styled-components";
import styles from "styles/styles";
import Page from "components/common/layout/Page";
import Arrow from "../../../components/common/ui/Arrow/Arrow";
import { Col, Row } from "../../../components/common/ui/FlexboxGrid";

const loadingSelector = loadingSelectorFactory([fetchBlogPost]);

const mapState = (state: RootState) => ({
    activeBlogPost: state.content.contentful.activeBlogPost,
    isLoading: loadingSelector(state),
});

const LinkContainer = styled.div`
    padding-bottom: ${styles.spacing[3]};
    padding-left: ${styles.spacing[1]};
    font-size: ${styles.fontSize.small};

    > a {
        color: ${styles.colors.grey6};
    }
`;

const ArrowLink = styled(Link)`
    border-color: ${styles.colors.grey6};
    border-width: 0 1px 1px 0;
    margin: 0 ${styles.spacing[1]} 0 0;
    padding: 4px;
`;

const BlogPostPage = () => {
    const { activeBlogPost, isLoading } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();
    const locale = useLocale();
    const params = useParams<{ id: string }>();
    const activeBlogPostId = params.id;

    useDocumentTitle(activeBlogPost ? activeBlogPost.title[locale] : undefined);

    useEffect(() => {
        dispatch(fetchBlogPost.started(activeBlogPostId));
    }, [dispatch, activeBlogPostId]);

    const activeBlogPostDescription = useMemo(() => {
        if (!activeBlogPost) {
            return "";
        }

        return extractPlainTextFromDocument(activeBlogPost.content[locale], locale);
    }, [locale, activeBlogPost]);

    if (isLoading) {
        return <PageLoadingIndicator isLoading={isLoading} />;
    }

    if (!activeBlogPost) {
        return (
            <PageNotFound
                message={<FormattedMessage id="navigation.pageNotFound" />}
                returnUrl="/"
            />
        );
    }

    return (
        <>
            <MetaDescription description={activeBlogPostDescription} />
            <Page>
                <LinkContainer>
                    <ArrowLink to={`/${locale}/uutiset-ja-tapahtumat/${BLOG_URL_SEGMENT}`}>
                        <Arrow direction="left" />
                        <FormattedMessage id={"blog.allBlogsLink"} />
                    </ArrowLink>
                </LinkContainer>
                <Row>
                    <Col md={8}>
                        <BlogPost blogPost={activeBlogPost} />
                    </Col>
                </Row>
            </Page>
        </>
    );
};

export default BlogPostPage;
