import React from "react";
import { FormattedMessage } from "react-intl";

import { formatDateWithHour } from "services/common/dates/dates";
import useLocale from "hooks/common/useLocale/useLocale";
import { ContactInfoWithType } from "interfaces/contact-info";
import styled from "styled-components";
import styles from "styles/styles";
import { Row } from "../../../common/ui/FlexboxGrid";

interface Props {
    contactUpdate: ContactInfoWithType;
}

const Container = styled.div`
    padding: ${styles.spacing[2]} 0;
    border-bottom: ${styles.colors.white} 1px solid;
`;

const StyledRow = styled(Row)`
    margin: 0;
    padding: ${styles.spacing[1]} 0;
`;

export const CompanyName = styled.div`
    font-weight: 500;
    color: ${styles.colors.red};
`;

export const UpdateDate = styled.div`
    font-weight: 500;
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.small};
    padding: 3px 0;

    ::before {
        content: "";
        width: 5px;
        height: 5px;
        margin: 0 ${styles.spacing[1]} 4px;
        border-radius: 50%;
        background: ${styles.colors.grey6};
        display: inline-block;
    }
`;

const ContactInfoSideItem = ({ contactUpdate }: Props) => {
    const locale = useLocale();

    return (
        <Container>
            <StyledRow>
                <CompanyName>{contactUpdate.companyName}</CompanyName>
                <UpdateDate>
                    {formatDateWithHour(String(contactUpdate.updatedAt), locale)}
                </UpdateDate>
            </StyledRow>
            {contactUpdate.contactType && (
                <FormattedMessage
                    id={`sideContent.contactInfo.update.${contactUpdate.contactType}`}
                />
            )}
        </Container>
    );
};

export default ContactInfoSideItem;
