import * as React from "react";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

import NotificationCloseButton from "./NotificationCloseButton/NotificationCloseButton";
import styles from "styles/styles";

/**
 * This function creates a toast notification with success style.
 * Returns the id of the created toast notification.
 * @param labelKey Localization key of the label
 */
export const successToast = (labelKey: string) =>
    toast.success(<FormattedMessage id={labelKey} />, {
        closeButton: <NotificationCloseButton />,
        style: { backgroundColor: styles.colors.green5 },
        autoClose: 5000,
        hideProgressBar: false,
    });
