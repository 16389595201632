import { AuthFeature, Authorization, AuthService } from "interfaces/authorization";

export const getRequirements = (
    businessId: string | null
): { required: Partial<Authorization>[] } => ({
    required: [
        {
            service: AuthService.DatahubServices,
            feature: AuthFeature.ServiceAgreements,
            accessLevel: "Read",
            businessId: businessId ?? undefined,
        },
    ],
});
