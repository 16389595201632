import styles from "styles/styles";
import * as React from "react";
import styled from "styled-components";
import Logo from "../../ui/Logo/Logo";
import HeaderNavigationBar from "../HeaderNavigationBar/HeaderNavigationBar";

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    min-height: 69px;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};

    > * {
        padding: 0;

        @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
            padding: ${styles.spacing[2]};
        }
    }
`;

const Header = () => (
    <header>
        <Container>
            <Logo />
            <HeaderNavigationBar />
        </Container>
    </header>
);

export default Header;
