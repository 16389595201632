import React, { memo } from "react";
import { ServiceAgreement } from "interfaces/service-agreements";
import FileListItem from "components/common/files/FileListItem/FileListItem";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import FileTags from "components/common/files/FileTags/FileTags";
import FileLink from "components/common/files/FileLink/FileLink";
import styled from "styled-components";
import styles from "styles/styles";

const getExtension = (fileName: string) => fileName.split(".").slice(1).join(".");

interface Props {
    serviceAgreement: ServiceAgreement;
    onDownload: (fileId: string, fileName: string) => unknown;
}

const Attachments = styled.div`
    grid-column: 2;
    grid-row: 1 / span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: ${styles.spacing[1]};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        align-items: flex-start;
        grid-column: 1;
        grid-row: 2;
    }
`;

const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
`;

const ServiceAgreementItem = ({ serviceAgreement, onDownload }: Props): JSX.Element => {
    const translate = useTranslate();

    const tags = [
        "serviceAgreements.serviceAgreement",
        `serviceAgreements.type.${serviceAgreement.type}`,
    ].map((tag) => translate(tag));

    const handleDownload =
        ({ id, fileName }: { id: string; fileName: string }) =>
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            onDownload(id, fileName);
        };

    return (
        <FileListItem>
            <FileLink
                title={serviceAgreement.fileName}
                onClick={handleDownload(serviceAgreement)}
                extension={getExtension(serviceAgreement.fileName)}
            >
                {serviceAgreement.title}
            </FileLink>
            <FileTags tags={tags} />
            {serviceAgreement.attachments?.length > 0 && (
                <Attachments>
                    {serviceAgreement.attachments.map((attachment) => (
                        <AttachmentItem key={attachment.id}>
                            <FileLink
                                title={attachment.fileName}
                                variant="small"
                                onClick={handleDownload(attachment)}
                                extension={getExtension(attachment.fileName)}
                            >
                                {attachment.title}
                            </FileLink>
                        </AttachmentItem>
                    ))}
                </Attachments>
            )}
        </FileListItem>
    );
};

export default memo(ServiceAgreementItem);
