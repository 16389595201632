import styles from "styles/styles";
import * as React from "react";
import { injectIntl, FormattedMessage, WrappedComponentProps } from "react-intl";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Col, Row } from "../../ui/FlexboxGrid";

const Container = styled.div`
    background-color: ${styles.colors.grey8};
`;

const Content = styled.div`
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: 0 ${styles.spacing[5]};
`;

const LinkCol = styled(Col)`
    margin: ${styles.spacing[4]} 0 !important;
`;

const LinkStyle = css`
    color: ${styles.colors.white};
    font-size: ${styles.fontSize.normal};
    text-decoration: none;
    margin-right: 60px;

    :hover {
        color: ${styles.colors.white};
        text-decoration: underline;
    }
`;

const NativeLink = styled.a`
    ${LinkStyle}
`;

const RouterLink = styled(Link)`
    ${LinkStyle}
`;

class BottomFooter extends React.Component<WrappedComponentProps> {
    renderLinkColumn(children: React.ReactNode) {
        return (
            <LinkCol xs={12} sm={2}>
                {children}
            </LinkCol>
        );
    }

    renderLink(url: string, key: string) {
        return (
            <NativeLink href={url} rel="noopener">
                <FormattedMessage id={key} />
            </NativeLink>
        );
    }

    public render() {
        return (
            <Container>
                <Content>
                    <Row>
                        {this.renderLinkColumn(
                            this.renderLink(
                                "https://www.fingrid.fi/tietosuojaselosteet/",
                                "navigation.legal"
                            )
                        )}
                        {this.renderLinkColumn(
                            this.renderLink(
                                "https://www.fingrid.fi/globalassets/dokumentit/fi/tietosuojaselosteet/saavutettavuusseloste3-palvelut.datahub.fi.pdf",
                                "navigation.accessibilityStatement"
                            )
                        )}
                        {this.renderLinkColumn(
                            this.renderLink(
                                "https://www.fingrid.fi/kayttoehdot/",
                                "navigation.termsAndConditions"
                            )
                        )}
                        {this.renderLinkColumn(
                            this.renderLink(
                                `/${this.props.intl.locale}/cookie-policy`,
                                "navigation.cookies"
                            )
                        )}
                        {this.renderLinkColumn(
                            <RouterLink
                                to={`/${this.props.intl.locale}/ota-yhteytta/ota-yhteytta`}
                                // Dynamic url that comes from Contentful.
                                // @TODO Instead of hardcoding the url, add it to common-content in Contentful.
                            >
                                <FormattedMessage id="navigation.feedback" />
                            </RouterLink>
                        )}
                    </Row>
                </Content>
            </Container>
        );
    }
}

export default injectIntl(BottomFooter);
