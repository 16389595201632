import { ContactDetails, ContactInfo, ContactWithCompanyName } from "interfaces/contact-info";
import { some, sortBy } from "lodash";

export interface Contact extends ContactDetails, ContactWithCompanyName {}

/**
 * Filter contacts by searching for a substring match from the business id, company name and participant id.
 */
function filterContactsBySubstring<T extends Contact>(contacts: T[], filter: string) {
    return contacts.filter((contact) =>
        some(
            [contact.participantId, contact.companyName, contact.businessId],
            (property) =>
                !filter ||
                (property && property.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        )
    );
}

export function getContacts<T extends Contact>(contacts: T[], filter: string) {
    return sortBy(filterContactsBySubstring(contacts, filter), "companyName", "participantId");
}

export const getContactItemHeader = <T extends Contact>(contact: T) =>
    `${contact.companyName} (${contact.participantId})`;

/**
 * Returns a contact object by ID. If not found, then returns undefined.
 */
export function getContactById<T extends ContactInfo>(contacts: T[] | null, id: ContactInfo["id"]) {
    return contacts ? contacts.find((contact) => contact.id === id) : undefined;
}

export const roleOptions = [
    {
        value: "M",
        label: "M",
    },
    {
        value: "V",
        label: "V",
    },
    {
        value: "T",
        label: "T",
    },
];

export const getStringFieldValue = (value: string) => (value.length > 0 ? value : null);
