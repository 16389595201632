import { DocumentFile, SingleDocument, Tag } from "interfaces/content";
import flatten from "lodash/flatten";

/**
 * @desc getDocumentTags Fn creates a list of all unique tags included in the provided documents
 */
export const getDocumentTags = (documents: SingleDocument[]): Tag[] => {
    const tags = flatten(
        documents.map((document) => (document.documentTags ? document.documentTags : []))
    );

    return tags.reduce((tags: Tag[], currentTag) => {
        const duplicateTag = tags.some((tag) => tag.id === currentTag.id);

        return duplicateTag ? tags : [...tags, currentTag];
    }, []);
};

/**
 * @desc filterDocumentsWithSearchTerm Fn filters documents if
 *        the given search term is part of the document's title
 */
export const filterDocumentsWithSearchTerm = (
    documents: SingleDocument[],
    searchTerm: string
): SingleDocument[] =>
    documents.filter((document) =>
        document.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

/**
 * @desc filterDocumentsWithTags Fn filters documents based on the given list of tags
 */
export const filterDocumentsWithTags = (
    documents: SingleDocument[],
    tagFilters: Tag[]
): SingleDocument[] => {
    if (tagFilters.length < 1) {
        return documents;
    }

    const documentsWithTags = documents.filter((document) => document.documentTags);

    return documentsWithTags.reduce((documentsWithTags: DocumentFile[], document) => {
        const documentWithTags = document.documentTags!.some((tag) =>
            tagFilters.some((tagFilter) => tagFilter.id === tag.id)
        );

        return documentWithTags ? [...documentsWithTags, document] : documentsWithTags;
    }, []);
};

/**
 *  @desc filterDocuments Fn filters documents based on given search term and tag-filters list
 */
export const filterDocuments = (
    allDocuments: SingleDocument[],
    searchTerm: string,
    tagFilters: Tag[]
) => {
    const documentsWithSearchTerm = filterDocumentsWithSearchTerm(allDocuments, searchTerm);

    return filterDocumentsWithTags(documentsWithSearchTerm, tagFilters);
};
