import styled from "styled-components";
import styles from "styles/styles";

const FileListItem = styled.div`
    background-color: ${styles.colors.white};
    padding: ${styles.spacing[3]};
    overflow: hidden;
    word-wrap: break-word;
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: ${styles.spacing[4]};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        grid-template-columns: 1fr;
    }

    @media only screen and (${styles.breakpoints.smGridBreakpoint}) {
        word-break: break-all;
    }
`;

export default FileListItem;
