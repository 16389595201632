import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled from "styled-components";
import styles from "styles/styles";

type TimeUnit = "month" | "day" | "hour" | "minute" | "second";

type CountdownPart = [unit: TimeUnit, value: number];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Header = styled.div`
    color: ${styles.colors.white};
    font-style: italic;
    margin-bottom: ${styles.spacing[1]};
    font-size: ${styles.fontSize.small};
`;

const Countdown = styled.div`
    color: ${styles.colors.white};
    background-color: ${styles.colors.red};
    display: flex;
    border-radius: 3px;
`;

const Part = styled.div`
    border-right: 1px solid rgb(255 255 255 / 40%);
    padding: ${styles.spacing[1]} ${styles.spacing[2]};
    display: flex;
    flex-direction: column;
    text-align: center;

    :last-child {
        border-right: none;
    }
`;

const Value = styled.div`
    font-size: ${styles.fontSize.small};
    font-weight: bold;
`;

const Unit = styled.div`
    font-size: 11px;
    font-style: italic;
`;

function getDuration(date: Date): CountdownPart[] {
    const now = new Date();
    const diff = moment.duration(moment(date).diff(now));

    if (now > date) {
        return [
            ["day", 0],
            ["hour", 0],
            ["minute", 0],
            ["second", 0],
        ];
    }

    return diff.asDays() > 5
        ? [
              ["month", diff.years() * 12 + diff.months()],
              ["day", diff.days()],
          ]
        : [
              ["day", diff.days()],
              ["hour", diff.hours()],
              ["minute", diff.minutes()],
              ["second", diff.seconds()],
          ];
}

interface Props {
    className?: string;
    date: Date;
}

function HeroCardCountdown({ className, date }: Props): ReactElement {
    const translate = useTranslate();
    const [parts, setParts] = useState<CountdownPart[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setParts(getDuration(date));
        }, 1000);

        return () => clearInterval(interval);
    }, [date]);

    return (
        <Container className={className}>
            <Header>
                <FormattedMessage id="hero.countdown.heading" />
            </Header>
            <Countdown>
                {parts.map(([unit, value]) => {
                    const label = translate(`common.${unit}`, { value });

                    return (
                        <Part key={`#cd_${unit}`}>
                            <Value role="timer" aria-label={label}>
                                {value}
                            </Value>
                            <Unit>{label}</Unit>
                        </Part>
                    );
                })}
            </Countdown>
        </Container>
    );
}

export default HeroCardCountdown;
