import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import ContentSection from "components/common/ui/ContentSection/ContentSection";
import UnauthorizedPlaceholder from "components/common/UnauthorizePlaceholder/UnauthorizePlaceholder";
import ContactInfo from "components/contact-info/ContactInfo/ContactInfo";
import { AuthenticatedFeature as AuthenticatedFeatureType } from "interfaces/content";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { getRequirements } from "components/service-agreements/authorization";
import ServiceAgreements from "components/service-agreements/ServiceAgreements/ServiceAgreements";

export interface Props {
    authenticatedFeature: AuthenticatedFeatureType;
}

const mapState = (state: RootState) => ({
    businessId: state.common.user.selectedOrganizationId,
});

function AuthenticatedFeature({ authenticatedFeature }: Props): ReactElement {
    const { businessId } = useSelector(mapState, shallowEqual);
    const serviceAgreementRequirements = React.useMemo(
        () => getRequirements(businessId),
        [businessId]
    );

    return (
        <>
            {authenticatedFeature.featureType === "ContactInfo" && <ContactInfo />}
            {authenticatedFeature.featureType === "ServiceAgreements" && (
                <AuthorizeContainer
                    requirements={serviceAgreementRequirements}
                    unauthorizedPlaceholder={
                        <ContentSection>
                            <UnauthorizedPlaceholder />
                        </ContentSection>
                    }
                >
                    {businessId && <ServiceAgreements />}
                </AuthorizeContainer>
            )}
        </>
    );
}

export default AuthenticatedFeature;
