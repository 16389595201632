import actionCreatorFactory from "../actionCreatorFactory";

const actionCreator = actionCreatorFactory();

/**
 * This action is dispatched if getting the auth token fails.
 * This can happen if the user is not signed in and the token in localStorage has expired.
 * dispatch with .failed method.
 * This action creator is not used for dispaching token refresh itself.
 * Example error type:
 * error: {
 *     // E.g. "AADSTS50058: A silent sign-in request was sent but no user is signed in. Trace ID: ..."
 *     message: string;
 *     // E.g. "login_required"
 *      msg: string;
 * };
 */
export const refreshToken = actionCreator.async("REFRESH_TOKEN");
