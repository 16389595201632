import styles from "styles/styles";
import * as React from "react";
import styled from "styled-components";

interface Props {
    className?: string;
}

const CircleContainer = styled.span`
    line-height: 43px;
    color: ${styles.colors.white};
    background-color: ${styles.colors.red};
    border-radius: 50%;
    display: inline-block;
    text-align: center;
`;

const Circle = ({ className, children }: React.PropsWithChildren<Props>) => (
    <CircleContainer className={className}>{children}</CircleContainer>
);

export default Circle;
