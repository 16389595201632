import * as React from "react";
import { FormattedMessage } from "react-intl";
import FaultNotificationsModal from "components/common/FaultNotifications/FaultNotificationsModal/FaultNotificationsModal";
import { useEffect, useState } from "react";
import { loadingSelectorFactory } from "state/common/loading";
import { fetchFaultNotifications } from "state/news/fault-notifications";
import { RootState } from "state/reducers";
import { getNewsTags } from "state/content/contentful/selectors";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "state/utilities";
import styled from "styled-components";
import styles from "styles/styles";

const loadingSelector = loadingSelectorFactory([fetchFaultNotifications]);

const mapState = (state: RootState) => ({
    tags: getNewsTags(state),
    faultNotifications: state.faultNotifications.faultNotifications,
    newsFeed: state.news.newsFeed,
    isLoading: loadingSelector(state),
});

const FaultNotificationsBtn = styled.button`
    border: 0;
    background: transparent;
    font-weight: 500;
    margin: 0 ${styles.spacing[1]};
    color: inherit;
    display: flex;
    align-items: center;
`;

const ActiveFaultNotificationsCount = styled.div`
    display: inline-block;
    background-color: ${styles.colors.red};
    border-radius: 50%;
    width: 43px;
    height: 43px;
    margin: 0 ${styles.spacing[1]};
    vertical-align: middle;
    color: ${styles.colors.white};
    line-height: 43px;
    overflow: hidden;
`;

const FaultNotifications = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { faultNotifications, isLoading, tags, newsFeed } = useSelector(mapState, shallowEqual);
    const activeFaultNotifications =
        faultNotifications &&
        faultNotifications.newsFeedItems.filter((notification) => notification.isActive);
    const tagId =
        tags.length > 0 && tags.filter((tag) => tag.label.en === "Fault notification")[0].id;

    const dispatch = useDispatch();
    useEffect(() => {
        if (tagId) {
            dispatch(fetchFaultNotifications.started({ tagId }));
        }
    }, [dispatch, tagId, newsFeed]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <FaultNotificationsBtn onClick={openModal}>
                <FormattedMessage id="common.faultNotifications" />
                {activeFaultNotifications && activeFaultNotifications.length > 0 && (
                    <ActiveFaultNotificationsCount>
                        {activeFaultNotifications.length}
                    </ActiveFaultNotificationsCount>
                )}
            </FaultNotificationsBtn>
            <FaultNotificationsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                faultNotifications={faultNotifications}
                isLoading={isLoading}
            />
        </div>
    );
};

export default FaultNotifications;
