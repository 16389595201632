import { Page, CommonContent, LandingPage, NewsFeedItem, BlogPost } from "interfaces/content";
import { BlogPageParameters, Blog } from "interfaces/blog";
import actionCreatorFactory from "../actionCreatorFactory";

const actionCreator = actionCreatorFactory();

export const fetchCommonContent = actionCreator.async<void, CommonContent>("FETCH_COMMON_CONTENT");
export const fetchActivePage = actionCreator.async<Page["urlSegment"], Page>("FETCH_ACTIVE_PAGE");
export const fetchLandingPage = actionCreator.async<void, LandingPage>("FETCH_LANDING_PAGE");
export const fetchBlogList = actionCreator.async<BlogPageParameters, Blog>("FETCH_BLOG_LIST");
export const fetchBlogPost = actionCreator.async<string, BlogPost>("FETCH_BLOG_POST");
export const updateBlogPageNumber = actionCreator<number>("UPDATE_BLOG_PAGE_NUMBER");
export const fetchNewsFeedItem = actionCreator.async<string, NewsFeedItem>("FETCH_NEWS_FEED_ITEM");
