import React, { ReactElement } from "react";
import styled from "styled-components";
import Page from "components/common/layout/Page";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

interface Props {
    className?: string;
    isLoading: boolean;
}

const Container = styled.div`
    position: relative;
    min-height: 400px;
`;

const PageLoadingIndicator = (props: Props): ReactElement => (
    <Page>
        <Container>
            <LoadingIndicator {...props} />
        </Container>
    </Page>
);

export default PageLoadingIndicator;
