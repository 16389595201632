import useLocale from "hooks/common/useLocale/useLocale";
import {
    MediaContent as MediaContentType,
    MediaContentImageItem,
    MediaContentTextItem,
    MediaContentYoutubeItem,
} from "interfaces/content";
import React from "react";
import MediaContentImage from "./MediaContentImage";
import MediaContentText from "./MediaContentText";
import MediaContentYoutube from "./MediaContentYoutube";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    mediaContent: MediaContentType;
}

const isMediaContentTextItem = (item: { itemType: string }): item is MediaContentTextItem =>
    item.itemType === "text";

const isMediaContentImageItem = (item: { itemType: string }): item is MediaContentImageItem =>
    item.itemType === "image";

const isMediaContentYoutubeItem = (item: { itemType: string }): item is MediaContentYoutubeItem =>
    item.itemType === "youtube";

const MediaContentItem = styled(Col)`
    padding: ${styles.spacing[3]};
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 0;
`;

const MediaContentRow = styled(Row)`
    width: 100%;
    padding: ${styles.spacing[6]};
    margin: 0;
`;

const Container = styled.div`
    background-color: ${styles.colors.grey3};
    padding-top: ${styles.spacing[3]};
`;

const MediaContent = ({ mediaContent }: Props) => {
    const locale = useLocale();

    return (
        <Container>
            <Title>{mediaContent.title[locale]}</Title>
            <MediaContentRow>
                {mediaContent.items.map((item, i) => {
                    if (isMediaContentTextItem(item)) {
                        return (
                            <MediaContentItem key={i} sm={12} md={6}>
                                <MediaContentText item={item} />
                            </MediaContentItem>
                        );
                    } else if (isMediaContentImageItem(item)) {
                        return (
                            <MediaContentItem key={i} sm={12} md={6}>
                                <MediaContentImage item={item} />
                            </MediaContentItem>
                        );
                    } else if (isMediaContentYoutubeItem(item)) {
                        return (
                            <MediaContentItem key={i} sm={12} md={6}>
                                <MediaContentYoutube item={item} />
                            </MediaContentItem>
                        );
                    }

                    return null;
                })}
            </MediaContentRow>
        </Container>
    );
};

export default MediaContent;
