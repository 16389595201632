import { InformationExchangeContactInfo } from "interfaces/contact-info";
import * as React from "react";
import { FormattedDate } from "react-intl";

export const getContactItems = (
    contacts: InformationExchangeContactInfo[],
    getContactItemHeader: (contactInfo: InformationExchangeContactInfo) => React.ReactNode
) =>
    contacts.map((contact) => ({
        id: contact.id,
        businessId: contact.businessId,
        header: getContactItemHeader(contact),
        detailsViewContent: {
            sections: [
                {
                    rows: [
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.contactPerson",
                                    value: contact.contactPerson,
                                },
                                {
                                    labelKey: "contactInfo.details.email",
                                    value: contact.email,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.phone",
                                    value: contact.phone,
                                },
                                {
                                    labelKey: "contactInfo.details.role",
                                    value: contact.role,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.validFrom",
                                    value: contact.validFrom && (
                                        <FormattedDate value={contact.validFrom} />
                                    ),
                                },
                                {
                                    labelKey: "contactInfo.details.validTo",
                                    value: contact.validTo && (
                                        <FormattedDate value={contact.validTo} />
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.crm",
                                    value: contact.crm,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.notes",
                                    value: contact.notes,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    }));
