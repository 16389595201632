import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Page from "../layout/Page";

const CookiePolicyPage = () => {
    useEffect(() => {
        const root = document.getElementById("cookie-declaration");
        const cookiePageScript = document.createElement("script");
        cookiePageScript.src =
            "https://consent.cookiebot.com/b02ba085-c121-42da-af26-e9f21ae61526/cd.js";
        cookiePageScript.type = "text/javascript";
        cookiePageScript.async = true;
        root!.appendChild(cookiePageScript);

        return () => {
            root!.removeChild(cookiePageScript);
        };
    }, []);

    return (
        <Page>
            <h3>
                <FormattedMessage id="navigation.cookies" />
            </h3>
            <div id="cookie-declaration"></div>
        </Page>
    );
};

export default CookiePolicyPage;
