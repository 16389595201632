import React, { ReactElement } from "react";
import { ActionSteps as ActionStepsType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import ActionStep from "components/content/ActionSteps/ActionStep";

export interface Props {
    actionSteps: ActionStepsType;
}

function ActionSteps({ actionSteps }: Props): ReactElement {
    const locale = useLocale();

    return (
        <div>
            <h5>{actionSteps.title[locale]}</h5>
            {actionSteps.steps &&
                actionSteps.steps.map((step, index) => (
                    <ActionStep key={index} step={step} index={index} />
                ))}
        </div>
    );
}

export default ActionSteps;
