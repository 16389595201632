import { LinkCard as LinkCardInt } from "interfaces/content";
import styles from "styles/styles";
import React, { ReactElement } from "react";
import styled from "styled-components";
import LinkCard from "../LinkCard/LinkCard";
import { Col, Row } from "../../common/ui/FlexboxGrid";

export interface Props {
    linkCards: LinkCardInt[];
}

const LinkCardListWrapper = styled.div`
    background: ${styles.colors.lightGrey};
    box-shadow: inset 0 2px 3px 0 rgb(0 0 0 / 16%);
`;

const LinkCardListContainer = styled.div`
    max-width: ${styles.layout.pageMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.spacing[5]} 0;
`;

const LinkCardRow = styled(Row)`
    width: 100%;
`;

const LinkCardCol = styled(Col)`
    min-inline-size: min-content;
    margin-bottom: ${styles.spacing[2]};
`;

function LinkCardList({ linkCards }: Props): ReactElement {
    return (
        <LinkCardListWrapper>
            <LinkCardListContainer>
                <LinkCardRow start="lg">
                    {linkCards.map((card, index) => (
                        <LinkCardCol xs={12} sm={6} md={3} lg={2} key={index}>
                            <LinkCard linkCard={card} contentType="frontpage" />
                        </LinkCardCol>
                    ))}
                </LinkCardRow>
            </LinkCardListContainer>
        </LinkCardListWrapper>
    );
}

export default LinkCardList;
