import React from "react";

import { CustomPage } from "interfaces/content";
import CustomNewsFeed from "./NewsFeed/NewsFeed";
import CustomEventsAndWebinars from "./EventsAndWebinars/EventsAndWebinars";
import CustomTestEnvironments from "./TestEnvironments/TestEnvironments";
import styled from "styled-components";
import styles from "styles/styles";
import CertificationQuestionnaire from "./CertificationQuestionnaire/CertificationQuestionnaire";
import BlogPage from "../../../screens/blog/BlogPage/BlogPage";

interface Props {
    customPage: CustomPage;
}

const Container = styled.div`
    background: ${styles.colors.lightGrey};
    background: linear-gradient(
        90deg,
        ${styles.colors.lightGrey} 0%,
        ${styles.colors.lightGrey} 50%,
        ${styles.colors.white} 51%,
        ${styles.colors.white} 100%
    );
`;

const getCustomPage = (customPage: CustomPage) => {
    if (customPage.contentTypeId === "newsFeedItem") {
        return { component: CustomNewsFeed, tabs: true };
    } else if (customPage.contentTypeId === "eventsAndWebinars") {
        return { component: CustomEventsAndWebinars, tabs: true };
    } else if (customPage.contentTypeId === "testEnvironments") {
        return { component: CustomTestEnvironments, tabs: false };
    } else if (customPage.contentTypeId === "certificationQuestionnaire") {
        return { component: CertificationQuestionnaire, tabs: false };
    } else if (customPage.contentTypeId === "blogPost") {
        return { component: BlogPage, tabs: false };
    }

    return undefined;
};

const CustomContent = ({ customPage }: Props) => {
    const customPageConfiguration = getCustomPage(customPage);

    if (!customPageConfiguration) {
        return null;
    } else if (customPageConfiguration.tabs) {
        return (
            <Container>
                <customPageConfiguration.component customPage={customPage} />
            </Container>
        );
    } else {
        return <customPageConfiguration.component customPage={customPage} />;
    }
};

export default CustomContent;
