import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import { Locale } from "interfaces/urls.d";
import { Document, DocumentContentBlock, Section } from "interfaces/content";

enum BLOCKS {
    DOCUMENT = "document",
}

/**
 * @desc extractPlainTextFromDocument Fn extracts all text from a document, including text from its linked sections.
 * @param document the rich-text document to extract text from.
 * @param locale document's locale, used to choose relevant linked section's documents.
 */
export const extractPlainTextFromDocument = (
    document: Document | undefined,
    documentLocale: Locale
): string => {
    if (!document) {
        return "";
    }

    // Coercion is used due to the Document type mismatch between documentToPlainTextString's library & our local Document type.
    const documentPlainText = documentToPlainTextString({
        nodeType: BLOCKS.DOCUMENT,
        content: document.content.map((content) => ({
            nodeType: content.nodeType,
            content: content.content,
            data: content.data,
        })),
        data: {},
    });

    const linkedEntriesPlainText: string = document.content
        .filter((contentItem) => contentItem.nodeType === "embedded-entry-block") //
        .reduce((plainText: string, contentItem) => {
            if (!isSection(contentItem.data.target)) {
                return plainText;
            }

            const sectionContent = contentItem.data.target.content;

            return plainText.length > 0
                ? `${plainText} ${extractPlainTextFromDocument(
                      sectionContent[documentLocale],
                      documentLocale
                  )}`
                : extractPlainTextFromDocument(sectionContent[documentLocale], documentLocale);
        }, "");

    return linkedEntriesPlainText.length > 0
        ? `${documentPlainText} ${linkedEntriesPlainText}`
        : documentPlainText;
};

const isSection = (entry: DocumentContentBlock["data"]["target"]): entry is Section =>
    !!entry && entry.type === "section";
