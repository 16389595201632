/**
 * Map specific error messages for the action to a different
 * localization key to show a different notification depending on the error message.
 */

interface IMapErrorMessages {
    [key: string]: {
        [key: string]: string;
    };
}
const mapErrorMessages: IMapErrorMessages = {};

export const getFailedActionNotificationLabel = (type: string, message?: string) => {
    if (message && mapErrorMessages[type] && mapErrorMessages[type][message]) {
        return `error.notification.${mapErrorMessages[type][message]}`;
    }

    return `error.notification.${type}`;
};
