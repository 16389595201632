import LoadingIndicator from "components/common/ui/LoadingIndicator/LoadingIndicator";
import * as React from "react";
import styled from "styled-components";

interface Props {
    isLoading: boolean;
}

const StyledLoadingIndicator = styled(LoadingIndicator)`
    background-color: #ffffffc2;
`;

function ContactListLoadingIndicator({ isLoading }: Props) {
    return <StyledLoadingIndicator isLoading={isLoading} />;
}

export default ContactListLoadingIndicator;
