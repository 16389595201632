import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { fetchRecentUpdates } from "state/contact-info";
import ContactInfoSideItem from "../ContactInfoSideItem/ContactInfoSideItem";
import RecentUpdates from "../RecentUpdates/RecentUpdates";

const mapState = (state: RootState) => ({
    recentContactUpdates: state.contactInfo.recentUpdates,
});

const ContactInfoSideContent = () => {
    const dispatch = useDispatch();
    const { recentContactUpdates } = useSelector(mapState, shallowEqual);

    useEffect(() => {
        dispatch(fetchRecentUpdates.started({ limit: 5 }));
    }, [dispatch]);

    if (!recentContactUpdates || recentContactUpdates.length === 0) {
        return null;
    }

    return (
        <RecentUpdates>
            {recentContactUpdates.map((contactUpdate) => (
                <ContactInfoSideItem
                    key={`${contactUpdate.contactType}-${contactUpdate.id}`}
                    contactUpdate={contactUpdate}
                />
            ))}
        </RecentUpdates>
    );
};

export default ContactInfoSideContent;
