import React, { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { contentfulActivePageLoadingSelector } from "state/common/loading";
import { getNavigationTabs } from "state/content/contentful/selectors";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import useLocale from "hooks/common/useLocale/useLocale";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import DynamicContentWithFileSearch from "components/file-search/DynamicContentWithFileSearch/DynamicContentWithFileSearch";
import PageLoadingIndicator from "components/common/ui/PageLoadingIndicator/PageLoadingIndicator";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import DynamicContent from "components/content/DynamicContent/DynamicContent";
import { extractPlainTextFromDocument } from "services/common/meta-data/meta-data";
import { getUrlSegment, UrlSegmentIndex } from "services/common/url/url";
import { findSubPage } from "services/common/navigation/navigation";
import { CustomPage, ContentPage, SubPage } from "interfaces/content";
import CustomContent from "components/content/CustomPageContent/CustomPageContent";

const loadingSelector = contentfulActivePageLoadingSelector;

const mapState = (state: RootState) => ({
    activePage: state.content.contentful.activePage,
    pageTabs: getNavigationTabs(state),
    isLoading: loadingSelector(state),
});

const isSubPage = (activeContentPage: ContentPage): activeContentPage is SubPage =>
    activeContentPage.type === "subPage";

const isCustomPage = (activeContentPage: ContentPage): activeContentPage is CustomPage =>
    activeContentPage.type === "customPage";

const DynamicContentPage = (): ReactElement => {
    const location = useLocation();
    const locale = useLocale();

    const { activePage, isLoading } = useSelector(mapState, shallowEqual);

    const activeContentPage: ContentPage | null | undefined = useMemo(() => {
        if (!activePage || !activePage.subPages) {
            return null;
        }
        const newSubPageUrlSegment = getUrlSegment(location.pathname, UrlSegmentIndex.SubPage);

        return findSubPage(activePage, newSubPageUrlSegment);
    }, [location.pathname, activePage]);

    useDocumentTitle(activeContentPage ? activeContentPage.title[locale] : undefined);

    const activeSubPageDescription = useMemo(() => {
        if (!activeContentPage || !activeContentPage.content) {
            return "";
        }

        return extractPlainTextFromDocument(activeContentPage.content[locale], locale);
    }, [locale, activeContentPage]);

    if (isLoading) {
        return <PageLoadingIndicator isLoading={isLoading} />;
    }

    if (activeContentPage && isSubPage(activeContentPage)) {
        return (
            <>
                <MetaDescription description={activeSubPageDescription} />
                {activeContentPage.enableFileSearch ? (
                    <DynamicContentWithFileSearch activeSubPage={activeContentPage} />
                ) : (
                    <DynamicContent activeSubPage={activeContentPage} />
                )}
            </>
        );
    } else if (activeContentPage && isCustomPage(activeContentPage)) {
        return (
            <>
                <MetaDescription description={activeSubPageDescription} />
                <CustomContent customPage={activeContentPage} />
            </>
        );
    } else {
        return <div />;
    }
};

export default DynamicContentPage;
