import React from "react";
import { IntlConfig, IntlProvider as ReactIntlProviver } from "react-intl";
import { useLocation } from "react-router";
import { defaultLocale, messages } from "services/common/localization/localization";
import { getLocale } from "services/common/url/url";

interface Props {
    intlConfig?: Omit<IntlConfig, "locale" | "messages">;
    children?: React.ReactNode;
}

const IntlProvider: React.FC<Props> = ({ children, intlConfig }) => {
    const { pathname } = useLocation();
    const locale = getLocale(pathname) || defaultLocale;

    return (
        <ReactIntlProviver locale={locale} messages={messages[locale]} {...intlConfig}>
            {children}
        </ReactIntlProviver>
    );
};

export default IntlProvider;
