import { v4 as uuid } from "uuid";
import actionCreatorFactory from "../actionCreatorFactory";

export interface ToastNotification {
    id: string;
    // TODO: Support "success" notifications
    type: "error";

    // Localization key
    label: string;
}

const actionCreator = actionCreatorFactory();

// This action will create a toast notification, which can be an error or a success notification
export const createToastNotification = actionCreator<ToastNotification>(
    "CREATE_TOAST_NOTIFICATION"
);

// This action will clear the toast notification of the given id
export const clearToastNotification = actionCreator<{
    id: string;
}>("CLEAR_TOAST_NOTIFICATION");

export const createErrorNotification = (label: string) =>
    createToastNotification({ id: uuid(), type: "error", label });
