import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncActionCreators } from "typescript-fsa";
import usePrevious from "hooks/common/usePrevious/usePrevious";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { ContactDetails, ContactInfo } from "interfaces/contact-info";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import { successToast } from "components/common/notification/success-notification";
import EditModal from "../EditModal/EditModal";
import { AuthService, AuthFeature, Authorization } from "interfaces/authorization";
import AddNew from "../../common/ui/AddNew/AddNew";

type Selector = (state: RootState) => boolean;

interface FormProps<T> {
    id?: ContactInfo["id"];
    details: T;
    onSave: (contactDetails: T) => void;
    onCancel: () => void;
    isSaveInProgress: boolean;
}

const mapState =
    (createLoadingSelector: Selector, createDoneSelector: Selector) => (state: RootState) => ({
        isCreateInProgress: createLoadingSelector(state),
        isCreateDone: createDoneSelector(state),
        selectedOrganizationId: state.common.user.selectedOrganizationId,
    });

const editRequirements = (businessId: string): { required: Partial<Authorization>[] } => ({
    required: [
        {
            businessId,
            service: AuthService.DatahubServices,
            feature: AuthFeature.ContactInfo,
            accessLevel: "ReadWrite",
        },
    ],
});

export interface Props<T> {
    createLoadingSelector: Selector;
    createDoneSelector: Selector;
    createAction: AsyncActionCreators<{ contactDetails: T }, void>;
    getDetails: (selectedOrganizationId: string) => T;
    formComponent: React.ComponentType<FormProps<T>>;
}

function AddNewContact<T extends ContactDetails>(props: Props<T>) {
    const dispatch = useDispatch();
    const { isCreateInProgress, isCreateDone, selectedOrganizationId } = useSelector(
        mapState(props.createLoadingSelector, props.createDoneSelector),
        shallowEqual
    );
    const translate = useTranslate();
    const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
    const prevIsUpdateDone = usePrevious(isCreateDone).current;

    const onEdit = useCallback(() => {
        setModalOpen(true);
    }, []);

    const closeEditForm = useCallback(() => {
        setModalOpen(false);
    }, []);

    const onEditSave = useCallback(
        (contactDetails: T) => {
            dispatch(props.createAction.started({ contactDetails }));
        },
        [dispatch, props]
    );

    useEffect(() => {
        if (prevIsUpdateDone === false && isCreateDone === true) {
            closeEditForm();
            successToast("contactInfo.create.success");
        }
    }, [prevIsUpdateDone, closeEditForm, isCreateDone]);

    const FormComponent = props.formComponent;

    return selectedOrganizationId ? (
        <AuthorizeContainer requirements={editRequirements(selectedOrganizationId)}>
            <div>
                <AddNew onClick={onEdit} label={translate("common.addNew")} />
                <EditModal isOpen={isModalOpen} createNew={true} onRequestClose={closeEditForm}>
                    <FormComponent
                        details={props.getDetails(selectedOrganizationId)}
                        isSaveInProgress={isCreateInProgress}
                        onSave={onEditSave}
                        onCancel={closeEditForm}
                    />
                </EditModal>
            </div>
        </AuthorizeContainer>
    ) : null;
}

export default AddNewContact;
