import { User } from "oidc-client";
import * as queryString from "query-string";
import { ajax } from "rxjs/ajax";

export interface RequestInfo {
    url: string;
    headers?: Record<string, string>;
    queryParameters?: Record<string, unknown>;
    requireAuthorizationHeader?: boolean;
}

export const defaultContentTypeHeader = {
    "Content-Type": "application/json",
};

export const getAuthorizationHeader = (token: string) => ({
    Authorization: `Bearer ${token}`,
});

type Token = User["id_token"] | null;

export const getMergedHeaders = (token: Token, headers: RequestInfo["headers"]) =>
    token
        ? {
              ...getAuthorizationHeader(token),
              ...(headers ? headers : defaultContentTypeHeader),
          }
        : {
              ...(headers ? headers : defaultContentTypeHeader),
          };

export const getJson = <ResponseType>(requestInfo: RequestInfo, token: string | null) => {
    const { url, queryParameters, headers } = requestInfo;
    const mergedHeaders = getMergedHeaders(token, headers);

    return ajax.getJSON<ResponseType>(
        queryParameters ? `${url}?${queryString.stringify(queryParameters)}` : url,
        mergedHeaders
    );
};

export const request = (
    method: "post" | "put",
    token: string | null,
    requestInfo: RequestInfo & { body?: any }
) => {
    const { url, queryParameters, headers, body } = requestInfo;
    const mergedHeaders = getMergedHeaders(token, headers);

    return ajax[method](
        queryParameters ? `${url}?${queryString.stringify(queryParameters)}` : url,
        body,
        mergedHeaders
    );
};

export const deleteRequest = (token: string | null, requestInfo: RequestInfo) => {
    const { url, queryParameters, headers } = requestInfo;
    const mergedHeaders = getMergedHeaders(token, headers);

    return ajax.delete(queryParameters ? `${url}?${queryString.stringify(queryParameters)}` : url, {
        ...mergedHeaders,
        // We use text/plain instead of application/json to avoid errors with IE11.
        // With application/json IE11 would send "undefined", which would not be valid JSON.
        // Note that DELETE requests don't have body in My Fingrid.
        "Content-Type": "text/plain",
    });
};
