import * as React from "react";
import styles from "../../../../styles/styles";
import styled from "styled-components";

interface Props {
    onClick: () => void;
    ariaExpanded?: boolean;
}

const StyledButton = styled.button`
    padding: 0;
    border: 0;
    background: none;
    font-size: 20px;
    color: ${styles.colors.grey7};

    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }

    &:active {
        color: ${styles.colors.grey8};
    }
`;

class DropdownToggle extends React.Component<React.PropsWithChildren<Props>> {
    public render() {
        return (
            <StyledButton
                type="button"
                onClick={this.props.onClick}
                aria-expanded={this.props.ariaExpanded}
            >
                {this.props.children}
            </StyledButton>
        );
    }
}

export default DropdownToggle;
