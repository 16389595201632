import React, { ReactElement, useMemo } from "react";

import { Document, AuthenticatedFeature, SubPage } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import SideContent from "../SideContent/SideContent";
import { DocumentEmbeddedEntryContentBlock, isAuthenticatedFeature } from "../RichText/RichText";
import ExternalLinksList from "../RichTextContainer/ExternalLinksList/ExternalLinksList";
import RichTextContainer from "../RichTextContainer/RichTextContainer";
import AuthenticatedFeatureSideContent, {
    AUTHENTICATED_FEATURES_WITH_SIDE_CONTENT,
} from "../AuthenticatedFeaturesSideContent/AuthenticatedFeatureSideContent";
import ExtendedTitle from "components/content/ExtendedTitle/ExtendedTitle";
import LinkCard from "components/content/LinkCard/LinkCard";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    subPage: SubPage;
}

const Container = styled.div`
    display: flex;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

const SideContentCol = styled(Col)`
    background-color: ${styles.colors.lightGrey};
    padding: 20px 0;
    display: inline-flex;
    flex: 1;
`;

const LinkCardContainer = styled.div`
    max-width: 81rem;
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

function getEmbeddedAuthenticatedFeatures(
    document: Document | undefined
): AuthenticatedFeature[] | null {
    if (!document) {
        return null;
    }

    return document.content.reduce(
        (
            authenticatedFeatures: AuthenticatedFeature[],
            contentBlock: DocumentEmbeddedEntryContentBlock
        ) => {
            const isEmbeddedEntry = contentBlock.nodeType === "embedded-entry-block";

            const embeddedEntry = contentBlock.data.target;

            if (isEmbeddedEntry && embeddedEntry && isAuthenticatedFeature(embeddedEntry)) {
                return authenticatedFeatures.concat(embeddedEntry);
            }

            return [...authenticatedFeatures];
        },
        []
    );
}

function DynamicContentColumns({ subPage }: Props): ReactElement {
    const locale = useLocale();

    const embeddedAuthenticatedFeaturesWithSideContent = useMemo(
        () =>
            (getEmbeddedAuthenticatedFeatures(subPage.content[locale]) || []).filter(
                (authenticatedFeature) =>
                    AUTHENTICATED_FEATURES_WITH_SIDE_CONTENT.includes(
                        authenticatedFeature.featureType
                    )
            ),
        [subPage.content, locale]
    );

    const hasPageSideContent = !!subPage.sideContent && subPage.sideContent.length > 0;

    const hasSideContent =
        hasPageSideContent || embeddedAuthenticatedFeaturesWithSideContent.length > 0;

    return (
        <>
            {subPage.extendedTitle && <ExtendedTitle extendedTitle={subPage.extendedTitle} />}
            {subPage.linkCards && subPage.linkCards.length > 0 && (
                <LinkCardContainer>
                    <Row>
                        {subPage.linkCards.map((linkCard, index) => (
                            <Col
                                xs={12}
                                sm={6}
                                md={12 / (subPage.linkCards ? subPage.linkCards.length : 1)}
                                key={index}
                            >
                                <LinkCard
                                    linkCard={linkCard}
                                    type="subPage"
                                    contentType="dynamic"
                                />
                            </Col>
                        ))}
                    </Row>
                </LinkCardContainer>
            )}
            <Container data-testid="dynamic-content-container">
                <RichTextContainer
                    subPage={subPage}
                    title={subPage.hideTitle || subPage.extendedTitle ? undefined : subPage.title}
                />

                {hasSideContent && (
                    <SideContentCol xs={12} md={4}>
                        {embeddedAuthenticatedFeaturesWithSideContent.map(
                            (authenticatedFeature, index) => (
                                <AuthenticatedFeatureSideContent
                                    key={index}
                                    authenticatedFeature={authenticatedFeature}
                                />
                            )
                        )}
                        {hasPageSideContent && (
                            <>
                                <SideContent sideContent={subPage.sideContent!} />
                                {subPage.externalLinks && (
                                    <ExternalLinksList externalLinks={subPage.externalLinks} />
                                )}
                            </>
                        )}
                    </SideContentCol>
                )}
            </Container>
        </>
    );
}

export default DynamicContentColumns;
