import {
    ConnectingContactInfo,
    InformationExchangeContactInfo,
    ContactInfoWithType,
} from "interfaces/contact-info";
import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import {
    deleteConnectingContact,
    deleteInformationExchangeContact,
    fetchConnectingContacts,
    fetchInformationExchangeContacts,
    fetchRecentUpdates,
} from "./actions";

export interface ContactInfoState {
    informationExchange: InformationExchangeContactInfo[] | null;
    connecting: ConnectingContactInfo[] | null;
    recentUpdates: ContactInfoWithType[] | null;
}

export const initialState: ContactInfoState = {
    informationExchange: null,
    connecting: null,
    recentUpdates: null,
};

export default function reducer(state = initialState, action: AnyAction): ContactInfoState {
    if (isType(action, fetchInformationExchangeContacts.done)) {
        return {
            ...state,
            informationExchange: action.payload.result,
        };
    }

    if (isType(action, deleteInformationExchangeContact.done)) {
        if (state.informationExchange) {
            const updatedContacts = state.informationExchange.filter(
                (contact) => contact.id !== action.payload.params.contactId
            );

            return {
                ...state,
                informationExchange: updatedContacts,
            };
        }
    }

    if (isType(action, fetchConnectingContacts.done)) {
        return {
            ...state,
            connecting: action.payload.result,
        };
    }

    if (isType(action, deleteConnectingContact.done)) {
        if (state.connecting) {
            const updatedContacts = state.connecting.filter(
                (contact) => contact.id !== action.payload.params.contactId
            );

            return {
                ...state,
                connecting: updatedContacts,
            };
        }
    }

    if (isType(action, fetchRecentUpdates.done)) {
        return { ...state, recentUpdates: action.payload.result };
    }

    return state;
}
