import { NavigationTab } from "interfaces/navigation";
import { sortNewsFeedItems } from "screens/common/FrontPage/FrontPage";
import { fetchNewsFeed } from "state/news";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { getNewsTags } from "state/content/contentful/selectors";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { uniq, max } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CustomPageContainer from "../CustomPageContainer";
import { useLocation } from "react-router-dom";
import { getUrlSegment } from "../EventsAndWebinars/EventsAndWebinars";
import useTranslate, { Translate } from "hooks/common/useTranslate/useTranslate";
import { CustomPage, NewsFeedItem } from "interfaces/content";
import { getLocalizedString } from "components/utilities";
import useLocale from "hooks/common/useLocale/useLocale";
import useQuery from "hooks/common/useQuery/useQuery";
import NewsFeedItemContainer from "components/news/NewsFeedItemContainer/NewsFeedItemContainer";
import NewsFeedFilters from "components/news/NewsFeed/NewsFeedFilters/NewsFeedFilters";
import useDebounce from "hooks/common/useDebounce/useDebounce";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import NewsFeedEditor from "components/news/NewsFeedEditor/NewsFeedEditor";
import { AuthService, AuthFeature, Authorization } from "interfaces/authorization";
import rss from "images/rss.svg";
import { apiBaseUrl } from "services/common/config/config";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../../common/ui/FlexboxGrid";

const requirements: { required: Partial<Authorization>[] } = {
    required: [
        {
            service: AuthService.DatahubServices,
            feature: AuthFeature.News,
            accessLevel: "ReadWrite",
        },
    ],
};

const mapState = (state: RootState) => ({
    newsFeedItems: sortNewsFeedItems(state.news.newsFeed?.newsFeedItems || []),
    tags: getNewsTags(state),
});

type MonthsByYears = Record<number, number[]>;

const getPath = (pathname: string, year: string): string =>
    moment().get("year").toString() === year ? pathname : `${pathname}?year=${year}`;

const getTabs = (
    pathname: string,
    monthsByYears: MonthsByYears,
    translate: Translate
): NavigationTab[] => [
    ...Object.entries(monthsByYears)
        .reverse()
        .reduce<NavigationTab[]>(
            (acc, [year, months]) => [
                ...acc,
                {
                    label: { fi: year, en: year },
                    path: getPath(pathname, year),
                    urlSegment: getUrlSegment(pathname),
                    subTabs: months.map((month) => ({
                        label: { fi: translate(`month.${month}`), en: translate(`month.${month}`) },
                        path: `${pathname}?year=${year}&month=${month}`,
                        urlSegment: getUrlSegment(pathname),
                        isHidden: false,
                    })),
                },
            ],
            []
        ),
    {
        label: { fi: "Kaikki", en: "All" },
        path: `${pathname}?showAll=true`,
        urlSegment: getUrlSegment(pathname),
        subTabs: [],
    },
];
const filterNewsFeed =
    (year: string, month: string | null, showAll: string | null) =>
    (item: NewsFeedItem): boolean => {
        const yearEquals =
            month === null && moment(item.publishingDate).get("year").toString() === year;

        const monthEquals =
            month !== null &&
            moment(item.publishingDate).get("year").toString() === year &&
            moment(item.publishingDate).get("month").toString() === month;

        return showAll ? true : yearEquals || monthEquals;
    };

const useGetFilters = (availableYears: number[]) => {
    const { year, month, showAll } = useQuery(["year", "month", "showAll"]);

    return {
        year: year || max(availableYears)?.toString() || moment().get("year").toString(),
        month,
        showAll,
    };
};

const EditorContainer = styled.div`
    padding-bottom: ${styles.spacing[4]};
`;

const RssIcon = styled.img`
    width: 20px;
    padding: 0 10px;
`;

const Header = styled(Row)`
    align-items: center;
`;

const NewsFeed: React.FC<{
    customPage: CustomPage;
}> = ({ customPage }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const translate = useTranslate();
    const locale = useLocale();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [tagId, setTagId] = useState<string>("");

    const searchTermQuery = useDebounce(searchTerm, 700);
    const tagIdQuery = useDebounce(tagId, 700);

    const { newsFeedItems, tags } = useSelector(mapState, shallowEqual);

    useEffect(() => {
        dispatch(fetchNewsFeed.started({ searchTerm: searchTermQuery, tagId: tagIdQuery }));
    }, [dispatch, searchTermQuery, tagIdQuery]);

    const availableYears = uniq(
        newsFeedItems.map((item) => moment(item.publishingDate).get("year"))
    );
    const availableMonths = availableYears.reduce<MonthsByYears>((acc, year) => {
        const months = newsFeedItems
            .filter((item) => moment(item.publishingDate).get("year") === year)
            .map((item) => moment(item.publishingDate).get("month"))
            .sort();

        return months.length ? { ...acc, [year]: uniq(months) } : acc;
    }, {});

    const { year, month, showAll } = useGetFilters(availableYears);

    const [tabs, setTabs] = useState<NavigationTab[]>([]);

    useEffect(() => {
        // Do not update tabs after filtering news feed
        if (Object.keys(availableMonths).length > tabs.length) {
            setTabs(getTabs(pathname, availableMonths, translate));
        }
    }, [availableMonths, tabs.length, pathname, translate]);

    return (
        <CustomPageContainer tabs={tabs}>
            <Header>
                <Col xs={6}>
                    <h3>
                        {getLocalizedString(locale, customPage.pageHeader)}
                        <a title="RSS" href={`${apiBaseUrl}/rss-feed/${locale}`} target="_blank">
                            <RssIcon src={`${rss}`} alt="RSS" />
                        </a>
                    </h3>
                </Col>
                <Col xs={6}>
                    <NewsFeedFilters
                        searchTerm={searchTerm}
                        onSearchTermChange={setSearchTerm}
                        allTags={tags}
                        selectedTagId={tagId}
                        onTagChange={setTagId}
                    />
                </Col>
            </Header>

            <AuthorizeContainer requirements={requirements}>
                <EditorContainer>
                    <NewsFeedEditor />
                </EditorContainer>
            </AuthorizeContainer>
            {newsFeedItems.filter(filterNewsFeed(year, month, showAll)).map((newsFeedItem) => (
                <NewsFeedItemContainer
                    key={newsFeedItem.id}
                    newsFeedItem={newsFeedItem}
                    previewMode={true}
                />
            ))}
        </CustomPageContainer>
    );
};

export default NewsFeed;
