import React, { useState, useEffect } from "react";

import arrowUp from "../../../../../images/arrow-up.svg";
import styled from "styled-components";
import styles from "../../../../../styles/styles";

interface Props {
    arrowIconAlt: string;
    scrollBreakingPoint?: number; // Defaults to 400 pixels
}

const StyledScrollToTopButton = styled.button<{ showScroll?: boolean }>`
    background-color: var(--bluish-grey-3);
    padding: 15px;
    position: fixed;
    right: 30px;
    bottom: 15px;
    border-radius: 50%;
    border: none;

    ${(props) => !props.showScroll && "display: none;"}
    :hover {
        cursor: pointer;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        right: 10px;
        bottom: 10px;
        padding: 10px;
    }
`;

const ArrowIcon = styled.img`
    width: 35px;
    height: 35px;
    display: block;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        width: 30px;
        height: 30px;
    }
`;

const ScrollToTopButton = ({ arrowIconAlt, scrollBreakingPoint = 400 }: Props) => {
    const [showScroll, setShowScroll] = useState(false);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        const checkScrollTop = () => {
            if (!showScroll && window.pageYOffset > scrollBreakingPoint) {
                setShowScroll(true);
            } else if (showScroll && window.pageYOffset <= scrollBreakingPoint) {
                setShowScroll(false);
            }
        };
        window.addEventListener("scroll", checkScrollTop);

        return () => {
            window.removeEventListener("scroll", checkScrollTop);
        };
    }, [showScroll, scrollBreakingPoint]);

    return (
        <StyledScrollToTopButton showScroll={showScroll} onClick={scrollTop}>
            <ArrowIcon src={arrowUp} alt={arrowIconAlt} />
        </StyledScrollToTopButton>
    );
};

export default ScrollToTopButton;
