import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserOrganization from "components/user/UserOrganization/UserOrganization";
import { setIsOrganizationSelectOpen } from "state/common/user";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import Modal from "../../common/modals/Modal/Modal";

const mapState = (state: RootState) => ({
    isSelectOrganizationOpen: state.common.user.isSelectOrganizationOpen,
});

const UserOrganizationModal = (): ReactElement => {
    const { isSelectOrganizationOpen } = useSelector(mapState, shallowEqual);

    const dispatch = useDispatch();

    const onCloseModal = useCallback(() => {
        dispatch(setIsOrganizationSelectOpen(false));
    }, [dispatch]);

    return (
        <Modal isOpen={isSelectOrganizationOpen} onRequestClose={onCloseModal}>
            <UserOrganization onCloseModal={onCloseModal} />
        </Modal>
    );
};

export default UserOrganizationModal;
