import { ServiceAgreement } from "interfaces/service-agreements";
import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { fetchServiceAgreements } from "./actions";

export interface ServiceAgreementState {
    serviceAgreements: {
        [businessId: string]: readonly ServiceAgreement[] | undefined;
    };
}

export const initialState: ServiceAgreementState = {
    serviceAgreements: {},
};

export default function reducer(state = initialState, action: AnyAction): ServiceAgreementState {
    if (isType(action, fetchServiceAgreements.done)) {
        return {
            ...state,
            serviceAgreements: {
                ...state.serviceAgreements,
                [action.payload.params.businessId]: action.payload.result,
            },
        };
    }

    return state;
}
