import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import ListItem, { ListItemData } from "./ListItem";
import Button from "../common/ui/buttons/Button/Button";
import LoadingIndicator from "../common/ui/LoadingIndicator/LoadingIndicator";

interface Props {
    title: string;
    showAllLabel: string;
    showAllUrl: string;
    isLoading: boolean;
    listItems: ListItemData[];
}

const Container = styled.div`
    margin-top: 24px;
`;

const Header = styled.h5`
    margin: 0;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    position: relative;
    display: flex;
    justify-content: center;
`;

const FrontPageList: React.FC<Props> = ({
    title,
    showAllLabel,
    showAllUrl,
    listItems,
    isLoading,
}) => {
    const dispatch = useDispatch();

    const handleButtonClick = useCallback(() => {
        window.scrollTo(0, 0);
        dispatch(push(showAllUrl));
    }, [dispatch, showAllUrl]);

    return (
        <Container>
            <Header>{title}</Header>
            {isLoading ? (
                <StyledLoadingIndicator isLoading={isLoading} />
            ) : (
                listItems.map((listItem, idx) => <ListItem key={idx} listItem={listItem} />)
            )}
            <Button onClick={handleButtonClick}>{showAllLabel}</Button>
        </Container>
    );
};
export default FrontPageList;
