import { IntlShape } from "react-intl";

import { ConnectingContactDetails, ConnectingContactInfo } from "interfaces/contact-info";
import { translate } from "components/utilities";
import { ConnectionFee } from "interfaces/common";
import { Option } from "../../components/common/ui/Select/Select";

export const getContactDetails = (
    contactInfo: ConnectingContactInfo
): ConnectingContactDetails => ({
    participantId: contactInfo.participantId,
    businessId: contactInfo.businessId,
    linkToPriceList: contactInfo.linkToPriceList,
    ecrBefore13SameDay: contactInfo.ecrBefore13SameDay,
    ecrBefore13NextDay: contactInfo.ecrBefore13NextDay,
    ercBefore13SecondDay: contactInfo.ercBefore13SecondDay,
    ecrAfter13SameDay: contactInfo.ecrAfter13SameDay,
    ecrAfter13NextDay: contactInfo.ecrAfter13NextDay,
    ecrAfter13SecondDay: contactInfo.ecrAfter13SecondDay,
    ecrWeekend: contactInfo.ecrWeekend,
    ecrAfterWorkingHours: contactInfo.ecrAfterWorkingHours,
    workingTime: contactInfo.workingTime,
    notes: contactInfo.notes,
});

export const getCreateContactDetails = (businessId: string): ConnectingContactDetails => ({
    businessId,
    participantId: "",
    linkToPriceList: "",
    ecrBefore13SameDay: null,
    ecrBefore13NextDay: null,
    ercBefore13SecondDay: null,
    ecrAfter13SameDay: null,
    ecrAfter13NextDay: null,
    ecrAfter13SecondDay: null,
    ecrWeekend: null,
    ecrAfterWorkingHours: null,
    workingTime: null,
    notes: null,
});

export const getConnectingOptions = (intl: IntlShape): Option[] => [
    getOption(intl, "ConnectingFree"),
    getOption(intl, "ConnectingCharged"),
    getOption(intl, "InstantConnectionCharged"),
    getOption(intl, "ConnectingNotPossible"),
];

const getOption = (intl: IntlShape, value: NonNullable<ConnectionFee>) => ({
    value,
    label: translate(intl, `contactInfo.details.connectingFee.${value}`),
});

export const CONNECTION_FEES: NonNullable<ConnectionFee>[] = [
    "ConnectingFree",
    "ConnectingCharged",
    "InstantConnectionCharged",
    "ConnectingNotPossible",
];

const isValidConnectionFee = (connectionFee: string): connectionFee is NonNullable<ConnectionFee> =>
    CONNECTION_FEES.includes(connectionFee as NonNullable<ConnectionFee>);

export const getConnectionFeeValue = (value: string): ConnectionFee =>
    isValidConnectionFee(value) ? value : null;
