import React, { ReactElement, useState, useMemo, useEffect } from "react";
import orderBy from "lodash/orderBy";

import { DocumentFileList } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import Document from "../Document/Document";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled from "styled-components";
import styles from "styles/styles";
import TextInput from "../../common/ui/TextInput/TextInput";
import { Option } from "../../common/ui/Select/Select";
import LabeledSelect from "../../common/ui/LabeledSelect/LabeledSelect";
import { Col, Row } from "../../common/ui/FlexboxGrid";

export interface Props {
    documentList: DocumentFileList;
    hideControls?: boolean;
}

const Title = styled.h5`
    margin: 0;
`;

const DocumentRow = styled(Row)`
    margin: 2em 0em 0em;
    background-color: ${styles.colors.lightGrey};
    border-top: 1px solid ${styles.colors.grey2};
    padding: 0.5em;
`;

const DocumentContainer = styled.div`
    margin: ${styles.spacing[3]} 0;
`;

const SearchContainer = styled(Row)`
    background-color: ${styles.colors.lightGrey};
    padding: 0.5em;
`;

const DocumentsContainer = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: ${styles.spacing[3]};
`;

type SortType = undefined | "default" | "updateDate" | "alphabetical";

function DocumentList({ documentList, hideControls }: Props): ReactElement {
    const [sortingType, setSortingType] = useState<SortType>("default");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [documents, setDocuments] = useState(documentList.documents);

    const locale = useLocale();
    const translate = useTranslate();

    const sortedDocumentList = useMemo(() => {
        if (sortingType === "alphabetical") {
            return orderBy(documents, ["title"], ["asc"]);
        }

        if (sortingType === "updateDate") {
            return orderBy(
                documents,
                ({ publishingDate }) => publishingDate || "", // publishingDate can be undefined. To avoid affecting the order, an empty string is returned instead.
                ["desc"]
            );
        }

        if (sortingType === "default") {
            return documents;
        }

        return documents;
    }, [sortingType, documents]);

    const selectOptions: Option[] = [
        {
            value: "default",
            label: translate("documentList.sortDefault"),
        },
        {
            value: "alphabetical",
            label: translate("documentList.sortByName"),
        },
        {
            value: "updateDate",
            label: translate("documentList.sortByPublishingDate"),
        },
    ];

    const onSelectSortChange = (newSortType: SortType) => {
        if (newSortType === sortingType) {
            setSortingType(undefined);

            return;
        }

        setSortingType(newSortType);
    };

    useEffect(() => {
        const visibleDocuments = documentList.documents.filter(
            (document) =>
                !searchTerm || document.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setDocuments(visibleDocuments);
    }, [documentList.documents, searchTerm]);

    return (
        <div>
            {!hideControls && (
                <DocumentRow middle="xs" between="xs">
                    <Col xs={6}>
                        {documentList.title && <Title>{documentList.title[locale]}</Title>}
                    </Col>

                    <Col xs={6}>
                        <Row end="xs" middle="xs">
                            <LabeledSelect
                                data-testid="select"
                                options={selectOptions}
                                selectedValue={sortingType!}
                                label=""
                                onSelect={(value) => onSelectSortChange(value as SortType)}
                                unknownOption=""
                            />
                        </Row>
                    </Col>
                </DocumentRow>
            )}

            <div>
                {documentList.subTitle && <h6>{documentList.subTitle[locale]}</h6>}
                {documentList.showSearch && (
                    <SearchContainer>
                        <Col xs={12} className="searchBar">
                            <TextInput
                                placeholder={translate("search.title")}
                                value={searchTerm}
                                onChange={(value) => setSearchTerm(value)}
                            />
                        </Col>
                    </SearchContainer>
                )}
            </div>

            <DocumentsContainer>
                {sortedDocumentList.map((document, index) => (
                    <DocumentContainer key={index}>
                        <Document data-testid="document" document={document} />
                    </DocumentContainer>
                ))}
            </DocumentsContainer>
        </div>
    );
}

export default DocumentList;
