import * as React from "react";
import Info from "../Info/Info";
import TextInput from "../TextInput/TextInput";
import styled from "styled-components";

interface Props {
    value: string;
    disabled?: boolean;

    // Translated label
    label: string;

    required?: boolean;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;

    onChange: (value: string) => void;
    onBlur?: () => void;

    tabIndex?: number;

    infoText?: string;
    infoIconAltText?: string;

    name?: string;
    placeholder?: string;
}

const StyledInfo = styled(Info)`
    img {
        width: 17px;
        height: 17px;
    }
`;

const StyledLabeledTextInput = styled.div`
    font-size: 18px;
`;

class LabeledTextInput extends React.Component<Props> {
    public render() {
        return (
            <StyledLabeledTextInput>
                <label>
                    <div>
                        {this.props.label}
                        {this.props.required ? "*" : ""}
                        {this.props.infoText && this.props.infoIconAltText && (
                            <StyledInfo
                                infoIconAltText={this.props.infoIconAltText}
                                infoText={this.props.infoText}
                            />
                        )}
                    </div>
                    <TextInput
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        invalid={this.props.invalid}
                        tabIndex={this.props.tabIndex}
                        onBlur={this.props.onBlur}
                        placeholder={this.props.placeholder}
                        validateBeforeFocus={this.props.validateBeforeFocus}
                    />
                </label>
            </StyledLabeledTextInput>
        );
    }
}

export default LabeledTextInput;
