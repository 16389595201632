// @TODO add tests.
import * as React from "react";
import whiteXIcon from "images/white-x-icon.svg";
import styled from "styled-components";

interface Props {
    // closeToast function is injected by react-toastify
    closeToast?: () => void;
}

const CloseButton = styled.input`
    width: 12px;
    height: 12px;
    padding: 3px;
`;

class NotificationCloseButton extends React.Component<Props> {
    closeToast = () => {
        if (this.props.closeToast) {
            this.props.closeToast();
        }
    };

    public render() {
        return <CloseButton type="image" src={whiteXIcon} onClick={this.closeToast} />;
    }
}

export default NotificationCloseButton;
