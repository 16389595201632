import { some } from "lodash";
import { AsyncActionCreators } from "typescript-fsa";
import { RootState } from "../../reducers";
import { fetchActivePage, fetchLandingPage, fetchCommonContent } from "../../content/contentful";
/*
 * Create a function that checks if the state has any ongoing fetches of the given types.
 */
export const loadingSelectorFactory =
    (actions: AsyncActionCreators<any, any>[]) => (state: RootState) =>
        some(actions, (action) => Object.keys(state.common.loading[action.type] || {}).length > 0);

/*
 * Create a function that checks if the state has any ongoing fetches of the given types with given id.
 */
export const idLoadingSelectorFactory =
    <Params, Result, Error>(action: AsyncActionCreators<Params, Result, Error>) =>
    (state: RootState, id: string) => {
        const actionState = state.common.loading[action.type];

        return Boolean(actionState && actionState[id]);
    };

export const contentfulActivePageLoadingSelector = loadingSelectorFactory([
    fetchActivePage,
    fetchCommonContent,
]);
export const contentfulLandingPageLoadingSelector = loadingSelectorFactory([fetchLandingPage]);
