import React from "react";

import { Blog } from "interfaces/blog";
import Pagination from "components/common/pagination/Pagination/Pagination";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    blog: Blog;
    onPaginationChange: (pageIndex: number) => void;
    currentPageNumber: string;
}
export const Container = styled.div`
    margin: 0 auto;
    padding: ${styles.spacing[3]} 0;
`;

const StyledPagination = styled(Pagination)`
    justify-content: center;
`;

const BlogPagination = ({ blog, onPaginationChange, currentPageNumber }: Props) => (
    <Container data-testid="blog-pagination-container">
        {/* Only displays pagination when there are more blog posts to show */}
        {blog.totalBlogPostsCount > blog.pageSize && (
            <StyledPagination
                totalResultsCount={blog.totalBlogPostsCount}
                resultsCountPerPage={blog.pageSize}
                onChange={onPaginationChange}
                currentPageNumber={+currentPageNumber}
            />
        )}
    </Container>
);

export default BlogPagination;
