import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Contact } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";
import { LocalizedString } from "interfaces/common";

interface Props {
    contact: Contact;
}

const Container = styled.ul`
    background-color: ${styles.colors.white};
    padding: ${styles.spacing[2]};
    list-style: none;
    margin: 0;
`;

function ContactCard({ contact }: Props): ReactElement {
    const { locale } = useIntl();

    return (
        <Container>
            <li>
                <strong>{contact.name}</strong>
            </li>
            <li>{contact.role[locale as keyof LocalizedString]}</li>
            <br />

            <li>
                <FormattedMessage
                    id="content.contact.tel"
                    values={{ telephoneNo: contact.telephoneNo }}
                />
            </li>
            <li>
                <FormattedMessage id="content.contact.email" values={{ email: contact.email }} />
            </li>
        </Container>
    );
}

export default ContactCard;
