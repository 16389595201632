import { Organization } from "interfaces/common";
import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import {
    fetchAuthorizations,
    userLogout,
    selectUserOrganization,
    setIsOrganizationSelectOpen,
} from "./actions";
import { Authorization } from "../../../interfaces/authorization";

export interface UserState {
    authorizations: Authorization[] | null;
    isFingridUser: boolean | null;
    organizations: Organization[] | null;
    selectedOrganizationId: Organization["businessId"] | null;
    isSelectOrganizationOpen: boolean;
    authorizationFetchRetryCount: number;
}

const initialState: UserState = {
    authorizations: null,
    isFingridUser: null,
    organizations: null,
    selectedOrganizationId: null,
    isSelectOrganizationOpen: false,
    authorizationFetchRetryCount: 0,
};

export default function reducer(state = initialState, action: AnyAction): UserState {
    if (isType(action, userLogout)) {
        return initialState;
    }

    if (isType(action, fetchAuthorizations.done)) {
        // Request gets cancelled randomly
        if (!action.payload.result) {
            return {
                ...state,
                authorizationFetchRetryCount: state.authorizationFetchRetryCount + 1,
            };
        }

        const { authorizations, organizations, isFingridUser } = action.payload.result;

        // If there is only one organization, select that one.
        // If there an organization is already selected, and it is found in the list of organizations,
        // keep the selection.
        // Otherwise clear the selection.
        const selectedOrganizationId =
            organizations.length === 1
                ? organizations[0].businessId
                : state.selectedOrganizationId &&
                  organizations.map((org) => org.businessId).includes(state.selectedOrganizationId)
                ? state.selectedOrganizationId
                : null;

        // Prompt the user to select an organization if none is selected.
        // isSelectOrganizationOpen will be true if the user has no organizations.
        // Do not close the prompt if it is already open.
        const isSelectOrganizationOpen = state.isSelectOrganizationOpen || !selectedOrganizationId;

        return {
            ...state,
            authorizations,
            organizations,
            selectedOrganizationId,
            isSelectOrganizationOpen,
            isFingridUser,
        };
    }

    if (isType(action, selectUserOrganization)) {
        return {
            ...state,
            selectedOrganizationId: action.payload,
        };
    }

    if (isType(action, setIsOrganizationSelectOpen)) {
        return {
            ...state,
            isSelectOrganizationOpen: action.payload,
        };
    }

    return state;
}
