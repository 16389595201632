type Environment = "local" | "test" | "production";

interface Config {
    adal: AdalConfig;
    content: ContentConfig;
    documents: DocumentsConfig;
    organizations: OrganizationsConfig;
    search: SearchConfig;
    news: NewsConfig;
    contactInfo: ContactInfoConfig;
    externalLinks: ExternalLinks;
    serviceAgreements: ServiceAgreementsConfig;
}

interface AdalConfig {
    tenant: string;
    clientId: string;
}

interface ContentConfig {
    commonContent: string;
    landingPage: string;
    blog: string;
    page: string;
    news: string;
    events: string;
}

interface SearchConfig {
    endpoints: {
        textSearch: string;
    };
}

interface NewsConfig {
    create: string;
}

interface DocumentsConfig {
    endpoints: {
        file: string;
    };
}
interface OrganizationsConfig {
    endpoints: {
        user: string;
        all: string;
    };
}

interface ContactInfoConfig {
    endpoints: {
        informationExchange: string;
        connecting: string;
        recentUpdates: string;
    };
}

interface ExternalLinks {
    myFingrid: string;
}

interface ServiceAgreementsConfig {
    endpoints: {
        get: (businessId: string) => string;
        attachment: (businessId: string, attachmentId: string) => string;
    };
}

export const apiBaseUrl = "/api";

const search: SearchConfig = {
    endpoints: {
        textSearch: `${apiBaseUrl}/search/query`,
    },
};

const organizations = {
    endpoints: {
        user: `${apiBaseUrl}/organizations/user`,
        all: `${apiBaseUrl}/organizations/all`,
    },
};

const contactInfo = {
    endpoints: {
        informationExchange: `${apiBaseUrl}/contact-info/info-exchange`,
        connecting: `${apiBaseUrl}/contact-info/connecting`,
        recentUpdates: `${apiBaseUrl}/contact-info/recent-updates`,
    },
};

const hostnames = {
    production: "palvelut.datahub.fi",
    test: "test-palvelut.datahub.fi",
};

const adal = {
    test: {
        clientId: "902ac8d9-e7e3-4f2c-baaf-a223a7cb973e",
        tenant: "4cb26dc9-e874-4af8-bba6-2dba2326b682",
    },
    prod: {
        tenant: "c0c8901f-430a-4c44-a3ac-11d14866905b",
        clientId: "d23e5317-0205-4d5d-bc29-69677d128947",
    },
};

export const environment = (
    window.location.hostname === hostnames.production
        ? "production"
        : window.location.hostname === hostnames.test
        ? "test"
        : "local"
) as Environment;

export const contentBaseUrl = `${apiBaseUrl}/content`;
const newsBaseUrl = `${apiBaseUrl}/news`;

const externalLinks = {
    myFingrid: "https://oma.fingrid.fi",
};

const serviceAgreements: ServiceAgreementsConfig = {
    endpoints: {
        get: (businessId) => `${apiBaseUrl}/service-agreements/${businessId}`,
        attachment: (businessId, attachmentId) =>
            `${apiBaseUrl}/service-agreements/${businessId}/attachments/${attachmentId}`,
    },
};

const configs: {
    [keys in Environment]: Config;
} = {
    local: {
        adal: adal.test,
        news: {
            create: newsBaseUrl,
        },
        content: {
            commonContent: `${contentBaseUrl}/common-content`,
            landingPage: `${contentBaseUrl}/landing-page`,
            page: `${contentBaseUrl}/page`,
            blog: `${contentBaseUrl}/blog`,
            news: `${contentBaseUrl}/news`,
            events: `${contentBaseUrl}/events`,
        },
        documents: {
            endpoints: {
                file: `${apiBaseUrl}/documents/file/`,
            },
        },
        organizations,
        contactInfo,
        search,
        externalLinks,
        serviceAgreements,
    },
    test: {
        adal: adal.test,
        news: {
            create: newsBaseUrl,
        },
        content: {
            commonContent: `${contentBaseUrl}/common-content`,
            landingPage: `${contentBaseUrl}/landing-page`,
            page: `${contentBaseUrl}/page`,
            blog: `${contentBaseUrl}/blog`,
            news: `${contentBaseUrl}/news`,
            events: `${contentBaseUrl}/events`,
        },
        documents: {
            endpoints: {
                file: `${apiBaseUrl}/documents/file/`,
            },
        },
        organizations,
        contactInfo,
        search,
        externalLinks,
        serviceAgreements,
    },
    production: {
        adal: adal.prod,
        news: {
            create: newsBaseUrl,
        },
        content: {
            commonContent: `${contentBaseUrl}/common-content`,
            landingPage: `${contentBaseUrl}/landing-page`,
            page: `${contentBaseUrl}/page`,
            blog: `${contentBaseUrl}/blog`,
            news: `${contentBaseUrl}/news`,
            events: `${contentBaseUrl}/events`,
        },
        documents: {
            endpoints: {
                file: `${apiBaseUrl}/documents/file/`,
            },
        },
        organizations,
        contactInfo,
        search,
        externalLinks,
        serviceAgreements,
    },
};

export const config = configs[environment];
