import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import styles from "styles/styles";
import Modal from "../Modal/Modal";
import Button from "../../ui/buttons/Button/Button";

interface Props {
    isOpen: boolean;

    // e.g. "newsfeed.delete"
    descriptionKey?: string;

    onConfirm: () => void;
    onCancel: () => void;
}

const Header = styled.h5`
    margin: 0 0 ${styles.spacing[4]} 0;
`;

const Description = styled.p`
    font-size: ${styles.fontSize.normal};
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: ${styles.spacing[4]};

    > button:not(:last-of-type) {
        margin-right: ${styles.spacing[3]};
    }
`;

/**
 * Display a modal to either confirm or cancel an action
 */
const ConfirmModal = (props: Props) => (
    <Modal
        size="small"
        isOpen={props.isOpen}
        disableWindowScrolling={true}
        onRequestClose={props.onCancel}
    >
        <Header>
            <FormattedMessage id="confirmModal.header" />
        </Header>
        {props.descriptionKey && (
            <Description>
                <FormattedMessage id={props.descriptionKey} />
            </Description>
        )}
        <ButtonContainer>
            <Button onClick={props.onConfirm}>
                <FormattedMessage id="common.yes" />
            </Button>
            <Button transparent onClick={props.onCancel}>
                <FormattedMessage id="common.cancel" />
            </Button>
        </ButtonContainer>
    </Modal>
);

export default ConfirmModal;
