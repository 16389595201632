import React, { memo } from "react";
import FileTag from "../FileTag/FileTag";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    tags: readonly string[];
}

const TagContainer = styled.div`
    grid-column: 1;
    display: flex;
    gap: ${styles.spacing[2]};
    align-items: flex-end;
`;

const FileTags = ({ tags }: Props): JSX.Element => (
    <TagContainer>
        {tags.map((tag) => (
            <FileTag key={tag} text={tag} />
        ))}
    </TagContainer>
);

export default memo(FileTags);
