import { successToast } from "components/common/notification/success-notification";
import {
    InformationExchangeContact,
    InformationExchangeContactDetails,
    InformationExchangeContactInfo,
} from "interfaces/contact-info";
import { getContactDetails } from "services/contact-info/info-exchange";
import {
    getContactById,
    getContactItemHeader,
    getContacts,
} from "services/contact-info/contact-info";
import { doneSelectorFactory } from "state/common/done";
import { loadingSelectorFactory } from "state/common/loading";
import {
    deleteInformationExchangeContact,
    fetchInformationExchangeContacts,
    fetchInformationExchangeIfNeeded,
    updateInformationExchangeContact,
} from "state/contact-info";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "hooks/common/usePrevious/usePrevious";
import InfoExchangeForm from "../InfoExchangeForm/InfoExchangeForm";
import ContactList from "../ContactList/ContactList";
import ContactListLoadingIndicator from "../ContactListLoadingIndicator/ContactListLoadingIndicator";
import EditModal from "../EditModal/EditModal";
import { getContactItems } from "./utilities";

const loadingSelector = loadingSelectorFactory([fetchInformationExchangeContacts]);
const updateLoadingSelector = loadingSelectorFactory([updateInformationExchangeContact]);
const updateDoneSelector = doneSelectorFactory(updateInformationExchangeContact);
const deleteLoadingSelector = loadingSelectorFactory([deleteInformationExchangeContact]);
const deleteDoneSelector = doneSelectorFactory(deleteInformationExchangeContact);

const mapState = (state: RootState) => ({
    informationExchangeContacts: state.contactInfo.informationExchange,
    isLoading: loadingSelector(state),
    isUpdateInProgress: updateLoadingSelector(state),
    isUpdateDone: updateDoneSelector(state),
    isDeleteInProgress: deleteLoadingSelector(state),
    isDeleteDone: deleteDoneSelector(state),
});

interface Props {
    filter: string;
}

interface State {
    isEditModalOpen: boolean;
    editedContact: InformationExchangeContactInfo | null;
}

const initialState: State = {
    isEditModalOpen: false,
    editedContact: null,
};

function InformationExchange({ filter }: Props) {
    const dispatch = useDispatch();
    const {
        isLoading,
        isUpdateInProgress,
        isUpdateDone,
        informationExchangeContacts,
        isDeleteInProgress,
        isDeleteDone,
    } = useSelector(mapState, shallowEqual);
    const [state, setState] = useState<State>(initialState);
    const prevIsUpdateDone = usePrevious(isUpdateDone).current;
    const prevIsDeleteDone = usePrevious(isDeleteDone).current;

    useEffect(() => {
        dispatch(fetchInformationExchangeIfNeeded());
    }, [dispatch]);

    const onEdit = useCallback(
        (contactId: InformationExchangeContactInfo["id"]) => {
            // Prevent editing while fetching/updating
            if (isLoading) {
                return;
            }

            const contactInfo = getContactById(informationExchangeContacts, contactId);

            if (contactInfo) {
                setState((state) => ({
                    ...state,
                    isEditModalOpen: true,
                    editedContact: contactInfo,
                }));
            }
        },
        [informationExchangeContacts, isLoading]
    );

    const closeEditForm = useCallback(() => {
        setState((state) => ({
            ...state,
            isEditModalOpen: false,
            editedContact: null,
        }));
    }, []);

    const onEditSave = useCallback(
        (
            contactDetails: InformationExchangeContactDetails,
            contactId: InformationExchangeContact["id"]
        ) => {
            dispatch(updateInformationExchangeContact.started({ contactDetails, contactId }));
        },
        [dispatch]
    );

    const onDelete = (
        contactId: InformationExchangeContactInfo["id"],
        businessId: InformationExchangeContactInfo["businessId"]
    ) => {
        dispatch(
            deleteInformationExchangeContact.started({
                contactId,
                businessId,
            })
        );
    };

    useEffect(() => {
        if (prevIsUpdateDone === false && isUpdateDone === true) {
            closeEditForm();
            successToast("contactInfo.update.success");
        }
    }, [isUpdateDone, prevIsUpdateDone, closeEditForm]);

    useEffect(() => {
        if (prevIsDeleteDone === false && isDeleteDone === true) {
            successToast("contactInfo.delete.success");
        }
    }, [isDeleteDone, prevIsDeleteDone]);

    const filteredContacts = useMemo(
        () => (informationExchangeContacts ? getContacts(informationExchangeContacts, filter) : []),
        [informationExchangeContacts, filter]
    );

    return (
        <>
            <ContactListLoadingIndicator isLoading={isLoading} />
            <ContactList
                contactItems={getContactItems(filteredContacts, getContactItemHeader)}
                onEdit={onEdit}
                onDelete={onDelete}
                isEditDisabled={isDeleteInProgress || isLoading || isUpdateInProgress}
            />
            <EditModal isOpen={state.isEditModalOpen} onRequestClose={closeEditForm}>
                {state.editedContact && (
                    <InfoExchangeForm
                        id={state.editedContact.id}
                        details={getContactDetails(state.editedContact)}
                        isSaveInProgress={isUpdateInProgress}
                        onSave={onEditSave}
                        onCancel={closeEditForm}
                    />
                )}
            </EditModal>
        </>
    );
}

export default InformationExchange;
