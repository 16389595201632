// @TODO add tests.
import { differenceWith, isEqual } from "lodash";
import * as React from "react";
import { toast } from "react-toastify";
import { injectIntl, FormattedMessage, WrappedComponentProps } from "react-intl";

import { ToastNotification } from "state/common/toast";
import { translate } from "components/utilities";
import NotificationCloseButton from "../NotificationCloseButton/NotificationCloseButton";

interface ComponentProps {
    errorToastNotifications: ToastNotification[];
    clearToastNotification: ({ id }: { id: string }) => void;
}

type Props = ComponentProps & WrappedComponentProps;

class ErrorNotifications extends React.PureComponent<Props> {
    createToast(id: string, label: string) {
        if (!toast.isActive(id)) {
            toast.error(
                <FormattedMessage
                    id={label || "error.notification.unknown.type"}
                    defaultMessage={translate(this.props.intl, "error.notification.unknown.type")}
                />,
                {
                    toastId: id,
                    closeButton: <NotificationCloseButton />,
                    autoClose: 10000,
                    hideProgressBar: false,
                    onClose: this.onToastClose(id),
                }
            );
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(this.props.errorToastNotifications, prevProps.errorToastNotifications)) {
            const newErrorNotifications = differenceWith(
                this.props.errorToastNotifications,
                prevProps.errorToastNotifications,
                (a: ToastNotification, b: ToastNotification) => a.id === b.id
            );

            newErrorNotifications.forEach((notification) => {
                this.createToast(notification.id, notification.label);
            });
        }
    }

    onToastClose = (id: string) => () => {
        this.props.clearToastNotification({ id });
    };

    render() {
        return null;
    }
}

export default injectIntl(ErrorNotifications);
