import React, { useCallback } from "react";

import {
    CustomerRole,
    InformationExchangeContact,
    InformationExchangeContactDetails,
    InformationExchangeContactInfo,
} from "interfaces/contact-info";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import { getStringFieldValue, roleOptions } from "services/contact-info/contact-info";
import Form from "components/common/ui/Form/Form";
import FormButtonRow from "components/common/ui/FormButtonRow/FormButtonRow";
import { Col, Row } from "../../common/ui/FlexboxGrid";
import LabeledTextInput from "../../common/ui/LabeledTextInput/LabeledTextInput";
import LabeledSelect from "../../common/ui/LabeledSelect/LabeledSelect";
import LabeledDatePicker from "../../common/ui/date-pickers/LabeledDatePicker/LabeledDatePicker";
import LabeledTextAreaInput from "../../common/ui/LabeledTextAreaInput/LabeledTextAreaInput";

export interface Props {
    id?: InformationExchangeContactInfo["id"];
    details: InformationExchangeContactDetails;
    onSave: (
        contactDetails: InformationExchangeContactDetails,
        contactId?: InformationExchangeContact["id"]
    ) => void;
    onCancel: () => void;
    isSaveInProgress: boolean;
}

function InformationExchangeForm(props: Props) {
    const translate = useTranslate();

    const onSaveForm =
        (contactDetails: InformationExchangeContactDetails) => (event: React.FormEvent) => {
            event.preventDefault();

            props.onSave(contactDetails, props.id);
        };

    const isSaveEnabled = useCallback(
        (contactDetails: InformationExchangeContactDetails) =>
            props.id ? true : contactDetails.participantId.length !== 0,
        [props.id]
    );

    return (
        <Form
            initialValues={props.details}
            FormComponent={({ fields: contactInfo, onChange }) => (
                <form onSubmit={onSaveForm(contactInfo)}>
                    {!props.id ? (
                        <Row>
                            <Col md={12}>
                                <LabeledTextInput
                                    label={translate("contactInfo.details.participantId")}
                                    required={true}
                                    value={contactInfo.participantId}
                                    onChange={(value) => onChange("participantId", value)}
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col md={6}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.contactPerson")}
                                value={contactInfo.contactPerson || ""}
                                onChange={(value) =>
                                    onChange("contactPerson", getStringFieldValue(value))
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.email")}
                                value={contactInfo.email || ""}
                                onChange={(value) => onChange("email", getStringFieldValue(value))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.phone")}
                                value={contactInfo.phone || ""}
                                onChange={(value) => onChange("phone", getStringFieldValue(value))}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledSelect
                                selectedValue={contactInfo.role ? contactInfo.role : ""}
                                options={roleOptions}
                                onSelect={(value) => {
                                    onChange("role", value as CustomerRole);
                                }}
                                label={translate("contactInfo.details.role")}
                                unknownOption=""
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledDatePicker
                                date={contactInfo.validFrom}
                                onDateChange={(value) => onChange("validFrom", value)}
                                label={translate("contactInfo.details.validFrom")}
                                ariaLabel={translate("contactInfo.details.validFrom")}
                                inputIconAltText={translate("datePicker.phrases.calendarLabel")}
                            />
                        </Col>
                        <Col md={6}>
                            <LabeledDatePicker
                                date={contactInfo.validTo}
                                onDateChange={(value) => onChange("validTo", value)}
                                label={translate("contactInfo.details.validTo")}
                                ariaLabel={translate("contactInfo.details.validTo")}
                                inputIconAltText={translate("datePicker.phrases.calendarLabel")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <LabeledTextInput
                                label={translate("contactInfo.details.crm")}
                                value={contactInfo.crm || ""}
                                onChange={(value) => {
                                    onChange("crm", getStringFieldValue(value));
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <LabeledTextAreaInput
                                label={translate("contactInfo.details.notes")}
                                value={contactInfo.notes || ""}
                                onChange={(value) => {
                                    onChange("notes", getStringFieldValue(value));
                                }}
                            />
                        </Col>
                    </Row>
                    <FormButtonRow
                        isSaveEnabled={isSaveEnabled(contactInfo)}
                        onCancel={props.onCancel}
                        isSaveInProgress={props.isSaveInProgress}
                    />
                </form>
            )}
        />
    );
}

export default InformationExchangeForm;
