import { Organization } from "interfaces/common";
import actionCreatorFactory from "../actionCreatorFactory";
import { Authorization } from "../../../interfaces/authorization";

const actionCreator = actionCreatorFactory();

export const userLogout = actionCreator("USER_LOGOUT");

export const fetchAuthorizations = actionCreator.async<
    void,
    {
        authorizations: Authorization[];
        organizations: Organization[];
        isFingridUser: boolean;
    }
>("FETCH_AUTHORIZATIONS");

export const selectUserOrganization = actionCreator<Organization["businessId"]>(
    "SELECT_USER_ORGANIZATION"
);

export const setIsOrganizationSelectOpen = actionCreator<boolean>(
    "SET_IS_ORGANIZATION_SELECT_OPEN"
);
