import { NavigationTab } from "interfaces/navigation";
import React from "react";
import SectionNav from "../SectionNav/SectionNav";
import styled from "styled-components";
import styles from "styles/styles";
import Page from "components/common/layout/Page";

interface Props {
    tabs: NavigationTab[];
    children?: React.ReactNode;
}

const Container = styled.div`
    display: flex;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

const Tabs = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: 20px 0;
    display: inline-flex;
    flex: 1;
`;

const Content = styled.div`
    background-color: white;
    padding: ${styles.spacing[2]} 0 ${styles.spacing[6]} ${styles.spacing[4]};
    flex: 4;
`;

const CustomPageContainer: React.FC<Props> = (props) =>
    !!props.tabs.length ? (
        <Container>
            <Tabs>
                <SectionNav tabs={props.tabs} />
            </Tabs>
            <Content>{props.children}</Content>
        </Container>
    ) : (
        <Page>{props.children}</Page>
    );

export default CustomPageContainer;
