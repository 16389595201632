import { connectRouter } from "connected-react-router";
import { reducer as oidc } from "redux-oidc";
import { History } from "history";
import { combineReducers } from "redux";
import common from "./common/reducer";
import contactInfo from "./contact-info/reducer";
import content from "./content/reducer";
import news from "./news/reducer";
import events from "./events/reducer";
import faultNotifications from "./news/fault-notifications/reducer";
import search from "./search/reducer";
import serviceAgreements from "./service-agreements";
import faultNotificationContacts from "./fault-notification-contacts";
import testEnvironments from "./test-environments/reducer";

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        common,
        oidc,
        content,
        news,
        events,
        testEnvironments,
        faultNotifications,
        contactInfo,
        search,
        serviceAgreements,
        faultNotificationContacts,
    });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
