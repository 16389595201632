const baseSpacing = 10;

const styles = {
    spacing: {
        0.5: `${baseSpacing * 0.25}px`,
        1: `${baseSpacing * 0.5}px`,
        1.5: `${baseSpacing * 0.75}px`,
        2: `${baseSpacing}px`,
        3: `${baseSpacing * 1.5}px`,
        4: `${baseSpacing * 2}px`,
        5: `${baseSpacing * 2.4}px`,
        6: `${baseSpacing * 3}px`,
    },
    colors: {
        green5: "#009a96",
        grey8: "#2a3a40",
        grey7: "#3e5660",
        grey6: "#6d838f",
        grey5: "#979797",
        grey4: "#d5dde3",
        grey3: "#e9eef2",
        grey2: "#e6e6e6",
        disabledGrey: "b8b8b8ff",
        red: "#d5121e",
        darkRed: "#c62631",
        lightGrey: "#f3f6f8",
        white: "#ffffff",
        focusBorderColor: "#618dfe",
    },
    fontSize: {
        smaller: "14px",
        small: "16px",
        normal: "18px",
        medium: "20px",
        large: "24px",
    },
    layout: {
        pageMaxWidth: "1400px",
        contentMargin: "0 auto",
        contentMaxWidth: "1660px",
        contentPadding: "0 24px",
        contentSectionMargin: "0 0 24px 0",
        headerMargin: "22px 0 42px 0",
    },
    breakpoints: {
        xlGridBreakpoint: "max-width: 1200px",
        mdGridBreakpoint: "max-width: 768px",
        smGridBreakpoint: "max-width: 400px",
    },
};

export default styles;
