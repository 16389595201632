import { LinkCard as LinkCardType } from "interfaces/content";
import React, { ReactElement } from "react";
import useLocale from "hooks/common/useLocale/useLocale";
import styled, { css } from "styled-components";
import styles from "styles/styles";

type ContentType = "frontpage" | "dynamic";

export interface Props {
    contentType: ContentType;
    linkCard: LinkCardType;
    type?: string;
}

const dynamicContentStyle = css`
    margin-bottom: 2rem;
`;

const Content = styled.div<{ contentType: ContentType }>`
    min-height: 9.375rem;
    ${(props) => props.contentType === "dynamic" && dynamicContentStyle}

    h5 {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        text-decoration: none;
    }
`;

const ContentPadding = styled.div`
    padding: ${styles.spacing[3]} ${styles.spacing[5]};
`;

const dynamicLinkContainerStyle = css`
    a {
        cursor: pointer;
        font-size: 1.125rem;
        padding: 1rem 1.625rem;
        color: ${styles.colors.white};
        background-color: ${styles.colors.red};
        border: 2px solid ${styles.colors.red};
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
        background-color: ${styles.colors.white};
        color: ${styles.colors.red};
    }
`;

const LinkContainer = styled.div<{ contentType: ContentType }>`
    font-size: ${styles.fontSize.normal};
    text-decoration: none;

    a {
        text-decoration: none;
    }

    ${(props) => props.contentType === "dynamic" && dynamicLinkContainerStyle}
`;

const PageContainer = styled.div`
    background-color: #fff;
    width: 100%;
    min-inline-size: min-content;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%);
`;

const SubPageContainer = styled(PageContainer)`
    background-color: ${styles.colors.lightGrey};
    box-shadow: none;
    text-align: left;
    margin-bottom: ${styles.spacing[3]};
`;

function LinkCard({ linkCard, type, contentType }: Props): ReactElement {
    const locale = useLocale();

    const Container = type === "subPage" ? SubPageContainer : PageContainer;

    return (
        <Container>
            <ContentPadding>
                <Content contentType={contentType}>
                    <h5>{linkCard.title[locale]}</h5>
                    {linkCard.content[locale]}
                </Content>
                <LinkContainer contentType={contentType}>
                    <a href={linkCard.url[locale]}>{linkCard.linkLabel[locale]}</a>
                </LinkContainer>
            </ContentPadding>
        </Container>
    );
}

export default LinkCard;
