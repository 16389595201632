import React, { ReactElement } from "react";

import {
    DocumentFile as DocumentFileType,
    DocumentLink as DocumentLinkType,
    SingleDocument,
    Tag,
} from "interfaces/content";
import DocumentFile from "../DocumentFile/DocumentFile";
import DocumentLink from "components/content/DocumentLink/DocumentLink";
import { LocalizedString } from "interfaces/common";

export interface Props {
    document: SingleDocument;
}

export const extractDocumentTags = (documentTags: Tag[], locale: string): string[] =>
    documentTags
        .map((tag) => tag.label[locale as keyof LocalizedString])
        .filter((tag): tag is string => !!tag);

const isDocumentFile = (document: SingleDocument): document is DocumentFileType =>
    document.type === "document";

const isDocumentLink = (document: SingleDocument): document is DocumentLinkType =>
    document.type === "documentLink";

function Document({ document }: Props): ReactElement {
    return (
        <>
            {isDocumentFile(document) && <DocumentFile document={document} />}
            {isDocumentLink(document) && <DocumentLink document={document} />}
        </>
    );
}

export default Document;
