import { RootState } from "./../../reducers";
import { Action, AnyAction } from "redux";
import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";
import { filter, map } from "rxjs/operators";

import { getFailedActionNotificationLabel } from "services/common/toast/toast";
import { createErrorNotification } from "../toast";

/**
 * An array of excluded action types
 */

const excludedActionTypes: string[] = [];

export const mapFailedActionToCreateErrorNotificationEpic = (action$: Observable<Action>) =>
    action$.pipe(
        filter(
            (action) =>
                /^(.*)_FAILED$/.test(action.type) &&
                !excludedActionTypes.includes(action.type.replace(/_FAILED$/, ""))
        ),
        map((action: AnyAction) => {
            const actionType = action.type.replace(/_FAILED$/, "");

            return createErrorNotification(
                getFailedActionNotificationLabel(actionType, action.payload.error.message)
            );
        })
    );

export default combineEpics<AnyAction, AnyAction, RootState>(
    mapFailedActionToCreateErrorNotificationEpic
);
