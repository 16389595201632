import * as React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

type Size = "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";

interface Props {
    className?: string;

    top?: Size;
    right?: Size;
    bottom?: Size;
    left?: Size;

    // Add vertical space between children
    vertical?: Size;
    // Add horizontal space between children
    horizontal?: Size;
}

const sizes = {
    xs: "2.5px",
    s: "5px",
    m: "10px",
    l: "15px",
    xl: "20px",
    xxl: "24px",
    xxxl: "30px",
};

const StyledSpacer = styled.div<Props>`
    padding-top: ${(props) => props.top && sizes[props.top]};
    padding-right: ${(props) => props.right && sizes[props.right]};
    padding-bottom: ${(props) => props.bottom && sizes[props.bottom]};
    padding-left: ${(props) => props.left && sizes[props.left]};

    & > *:not(:last-child) {
        margin-bottom: ${(props) => props.vertical && sizes[props.vertical]};
        margin-right: ${(props) => props.horizontal && sizes[props.horizontal]};
    }
`;

/**
 * A wrapper component that adds the given space around and/or between its children.
 */
const Spacer = ({
    top,
    right,
    bottom,
    left,
    vertical,
    horizontal,
    children,
}: PropsWithChildren<Props>) => (
    <StyledSpacer
        right={right}
        left={left}
        top={top}
        bottom={bottom}
        vertical={vertical}
        horizontal={horizontal}
        data-testid="spacer"
    >
        {children}
    </StyledSpacer>
);

export default Spacer;
