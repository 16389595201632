import { combineEpics } from "redux-observable";
import { postMergeMap, deleteMergeMap, fetchSwitchMap } from "state/observable";
import {
    deleteFaultNotificationContacts,
    fetchFaultNotificationContacts,
    updateFaultNotificationContacts,
} from "./actions";
import { apiBaseUrl } from "services/common/config/config";

export const updateFaultNotificationContactsEpic = postMergeMap(
    updateFaultNotificationContacts,
    (action) => ({
        url: `${apiBaseUrl}/fault-notification-contacts`,
        body: action.payload,
        requireAuthorizationHeader: true,
    })
);

export const deleteFaultNotificationContactsEpic = deleteMergeMap(
    deleteFaultNotificationContacts,
    (action) => ({
        url: `${apiBaseUrl}/fault-notification-contacts?ids=${action.payload.removedContactIds.join(
            ","
        )}`,
        requireAuthorizationHeader: true,
    })
);

export const fetchFaultNotificationContactsEpic = fetchSwitchMap(
    fetchFaultNotificationContacts,
    (action) => ({
        url: `${apiBaseUrl}/fault-notification-contacts/${action.payload.businessId}`,
        requireAuthorizationHeader: true,
    })
);

export default combineEpics(
    updateFaultNotificationContactsEpic,
    deleteFaultNotificationContactsEpic,
    fetchFaultNotificationContactsEpic
);
