import { combineEpics } from "redux-observable";
import { fetchSwitchMap } from "state/observable";
import { apiBaseUrl } from "services/common/config/config";
import { fetchTestEnvironments } from "./actions";

export const fetchTestEnvironmentsEpic = fetchSwitchMap(fetchTestEnvironments, ({ payload }) => ({
    url: payload.authenticated
        ? `${apiBaseUrl}/test-environments/environments-authenticated`
        : `${apiBaseUrl}/test-environments/environments`,
    requireAuthorizationHeader: payload.authenticated,
}));

export default combineEpics(fetchTestEnvironmentsEpic);
