import React, { ReactElement } from "react";

import { SideContentItem as SideContentItemType } from "interfaces/content";
import SideContentItem from "../SideContentItem/SideContentItem";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    sideContent: SideContentItemType[];
}

const Container = styled.div`
    padding: ${styles.spacing[3]} 0;
`;

function SideContent({ sideContent }: Props): ReactElement {
    return (
        <>
            {sideContent.map((sideContentItem, index) => (
                <Container key={index}>
                    <SideContentItem sideContentItem={sideContentItem} />
                </Container>
            ))}
        </>
    );
}

export default SideContent;
