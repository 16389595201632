import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

import { Notification } from "interfaces/content";
import RichText from "../RichText/RichText";
import LinkButton from "../LinkButton/LinkButton";
import { LocalizedString } from "interfaces/common";

interface Props {
    notification: Notification;
}

function NotificationCard({ notification }: Props): ReactElement {
    const { locale } = useIntl();

    return (
        <div>
            {notification.title && <h4> {notification.title[locale as keyof LocalizedString]}</h4>}
            {notification.content && <RichText richText={notification.content} />}
            {notification.linkButton && <LinkButton linkButton={notification.linkButton} />}
        </div>
    );
}

export default NotificationCard;
