import React, { ReactElement } from "react";
import { ExtendedTitle as ExtendedTitleType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import Link from "components/common/ui/Link/Link";
import LinkButton from "components/content/LinkButton/LinkButton";
import styled from "styled-components";
import styles from "styles/styles";
import RichText from "../RichText/RichText";
import CtaBlock from "../CtaBlock/CtaBlock";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    extendedTitle: ExtendedTitleType;
}

const ContentContainer = styled.div`
    max-width: 81rem;
    margin: ${styles.layout.contentMargin};
    padding: ${styles.spacing[5]} 0;
`;

const TitleContainer = styled.div`
    max-width: 40em;

    h3 {
        margin-bottom: 0.4rem;
    }

    p {
        font-size: 1.25rem;
    }
`;

const SideBoxContainer = styled.div`
    background: ${styles.colors.lightGrey};
    padding: ${styles.spacing[5]};
    text-align: center;
`;

const Button = styled(Link)`
    margin: 0 auto;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    height: 3.6875rem;
    justify-content: center;
    min-width: 7.5rem;
    padding: 0 0.625rem;
    color: ${styles.colors.red};
    background-color: ${styles.colors.white};
    border: 2px solid ${styles.colors.red};
    text-decoration: none;
    max-width: 20rem;

    :hover {
        text-decoration: none;
        background-color: ${styles.colors.red};
        color: ${styles.colors.white};
    }
`;

function ExtendedTitle({ extendedTitle }: Props): ReactElement {
    const locale = useLocale();

    return (
        <>
            <ContentContainer>
                <Row>
                    <Col xs={12} sm={6} md={8}>
                        <TitleContainer>
                            <h3>{extendedTitle.title[locale]}</h3>
                            {extendedTitle.subtitle && extendedTitle.subtitle[locale] && (
                                <RichText richText={extendedTitle.subtitle} />
                            )}
                            {extendedTitle.linkButton && (
                                <LinkButton linkButton={extendedTitle.linkButton} />
                            )}
                        </TitleContainer>
                    </Col>
                    {extendedTitle.sideBoxTitle && (
                        <Col xs={12} sm={6} md={4}>
                            <SideBoxContainer>
                                <p>{extendedTitle.sideBoxTitle[locale]}</p>
                                {extendedTitle.sideBoxLinkUrl && extendedTitle.sideBoxLinkLabel && (
                                    <Button
                                        localizedUrl={extendedTitle.sideBoxLinkUrl}
                                        openExternalLinkToNewTab={false}
                                    >
                                        {extendedTitle.sideBoxLinkLabel[locale]}
                                    </Button>
                                )}
                            </SideBoxContainer>
                        </Col>
                    )}
                </Row>
            </ContentContainer>
            {extendedTitle.ctaBlock && <CtaBlock ctaBlock={extendedTitle.ctaBlock} />}
        </>
    );
}

export default ExtendedTitle;
