import { AsyncActionCreators } from "typescript-fsa";
import { RootState } from "../../reducers";
import { loadingSelectorFactory, idLoadingSelectorFactory } from "../loading";
import { failedSelectorFactory, idFailedSelectorFactory } from "../failed";

/*
 * Create a function that checks if the state doesn't have loading or failed type
 */
export const doneSelectorFactory = (action: AsyncActionCreators<any, any>) => {
    const loadingSelector = loadingSelectorFactory([action]);
    const failedSelector = failedSelectorFactory([action]);

    return (state: RootState) => !(loadingSelector(state) || failedSelector(state));
};

/*
 * Create a function that checks if the state doesn't have loading or failed type with the given id.
 * Each request has an id, which is either defined when dispatching an action,
 * or alternatively generated automatically with a Redux middleware.
 */
export const idDoneSelectorFactory = <Params, Result, Error>(
    action: AsyncActionCreators<Params, Result, Error>
) => {
    const loadingSelector = idLoadingSelectorFactory(action);
    const failedSelector = idFailedSelectorFactory(action);

    return (state: RootState, id: string) =>
        !(loadingSelector(state, id) || failedSelector(state, id));
};
