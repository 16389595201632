import useDetectOutsideClick from "hooks/common/useDetectOutsideClick/useDetectOutsideClick";
import React, { useRef } from "react";
import serviceImage from "images/service-image.png";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../ui/FlexboxGrid";

interface Props {
    onClose: () => void;
}

const services = [
    {
        title_fi: "Datahub CMS",
        title_en: "Datahub CMS",
        description_fi: "Kirjaudu Datahubin käyttöliitymään",
        description_en: "Log in to Datahub GUI",
        url: "https://portaali.datahub.fi/login",
    },
    {
        title_fi: "Oma Datahub",
        title_en: "Oma Datahub",
        description_fi: "Datahub asiakasportaali",
        description_en: "Datahub Customer portal",
        url: "https://oma.datahub.fi/#/login?returnUrl=%2f",
    },
    {
        title_fi: "Tepa",
        title_en: "Tepa",
        description_fi: "Testaus- ja sertifiointipalvelu",
        description_en: "Test and Certification service",
        url: "https://sertifiointi.datahub.fi/#/etusivu",
    },
    {
        title_fi: "Datahub tukipalvelu",
        title_en: "Datahub support",
        description_fi: "Yhteydenotto datahubin tukeen",
        description_en: "Contact Datahub support",
        url: "https://support.datahub.fi/fingrid",
    },
];

const ServiceContainer = styled(Col)`
    display: flex;
    margin-bottom: ${styles.spacing[4]};
    font-size: ${styles.fontSize.normal};
`;

const ServiceImage = styled.img`
    width: 48px;
    height: 48px;
`;

const ServiceContainerContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ServiceLink = styled.a`
    color: ${styles.colors.grey7};
    margin: 0;
`;

const Container = styled.div`
    position: absolute;
    background-color: white;
    box-shadow: 0 0 1.9px rgb(0 0 0 / 2.8%), 0 0 4.6px rgb(0 0 0 / 4%), 0 0 8.6px rgb(0 0 0 / 5%),
        0 0 15.4px rgb(0 0 0 / 6%), 0 0 28.8px rgb(0 0 0 / 7.2%), 0 0 69px rgb(0 0 0 / 10%);
    z-index: 100;
    top: 80px;
    right: 150px;
    width: 500px;
    padding: ${styles.spacing[2]} ${styles.spacing[2]} 0 ${styles.spacing[2]};

    @media only screen and (${styles.breakpoints.xlGridBreakpoint}) {
        top: 90px;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        top: 135px;
        left: 50%;
        transform: translateX(-50%);
        width: 95%;
    }
`;
const ServicesModal = ({ onClose }: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useDetectOutsideClick(wrapperRef, onClose);

    const locale = useLocale();

    return (
        <Container ref={wrapperRef}>
            <Row>
                {services.map((service) => (
                    <ServiceContainer xs={6}>
                        <ServiceImage src={serviceImage} />
                        <ServiceContainerContent>
                            <ServiceLink href={service.url}>
                                {service[`title_${locale}`]}
                            </ServiceLink>
                            <span>{service[`description_${locale}`]}</span>
                        </ServiceContainerContent>
                    </ServiceContainer>
                ))}
            </Row>
        </Container>
    );
};

export default ServicesModal;
