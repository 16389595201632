import React, { ReactElement, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { loadingSelectorFactory } from "state/common/loading";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import { fetchNewsFeedItem } from "state/content/contentful";
import { extractPlainTextFromDocument } from "services/common/meta-data/meta-data";
import PageLoadingIndicator from "components/common/ui/PageLoadingIndicator/PageLoadingIndicator";
import NewsFeedItemContainer from "components/news/NewsFeedItemContainer/NewsFeedItemContainer";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import PageNotFound from "components/common/ui/PageNotFound/PageNotFound";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import useLocale from "hooks/common/useLocale/useLocale";
import useScrollToTop from "hooks/common/useScrollToTop/useScrollToTop";
import Page from "components/common/layout/Page";
import { Col, Row } from "../../../components/common/ui/FlexboxGrid";

const loadingSelector = loadingSelectorFactory([fetchNewsFeedItem]);

const mapState = (state: RootState) => ({
    isLoading: loadingSelector(state),
    newsFeedItem: state.content.contentful.activeNewsFeedItem,
});

function NewsFeedItemPage(): ReactElement {
    const { newsFeedItem, isLoading } = useSelector(mapState, shallowEqual);
    useScrollToTop();
    const params = useParams<{ id: string }>();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const activeNewsFeedItemId = params.id;
    const locale = useLocale();

    useDocumentTitle(translate("newsfeed.title"));

    useEffect(() => {
        dispatch(fetchNewsFeedItem.started(activeNewsFeedItemId));
    }, [dispatch, activeNewsFeedItemId]);

    const newsFeedItemDescription = useMemo(() => {
        if (!newsFeedItem) {
            return "";
        }

        return extractPlainTextFromDocument(newsFeedItem.content[locale], locale);
    }, [locale, newsFeedItem]);

    if (isLoading) {
        return <PageLoadingIndicator isLoading={true} />;
    }

    return (
        <>
            <MetaDescription description={newsFeedItemDescription} />
            <Page>
                {newsFeedItem ? (
                    <Row>
                        <Col md={8}>
                            <NewsFeedItemContainer newsFeedItem={newsFeedItem} />
                        </Col>
                    </Row>
                ) : (
                    <PageNotFound
                        message={<FormattedMessage id="navigation.pageNotFound" />}
                        returnUrl="/"
                    />
                )}
            </Page>
        </>
    );
}

export default NewsFeedItemPage;
