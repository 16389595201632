import styles from "styles/styles";
import React, { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Container = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: ${styles.spacing[3]};
`;

const Title = styled.h4`
    margin: 0;
`;

const RecentUpdates = ({ children }: PropsWithChildren<any>) => (
    <Container>
        <Title>
            <FormattedMessage id={"sideContent.authenticatedFeatureTitle"} />
        </Title>
        {children}
    </Container>
);

export default RecentUpdates;
