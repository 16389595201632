import { UserManagerSettings } from "oidc-client";
import { createUserManager } from "redux-oidc";
import { config } from "services/common/config/config";

const baseUrl = `${window.location.protocol}//${window.location.hostname}`;

const userManagerConfig: UserManagerSettings = {
    authority: `https://login.microsoftonline.com/${config.adal.tenant}`,
    client_id: config.adal.clientId,
    scope: "openid profile email",
    metadataUrl: `https://login.microsoftonline.com/${config.adal.tenant}/v2.0/.well-known/openid-configuration`,
    loadUserInfo: true,
    redirect_uri: `${baseUrl}/auth`,
    automaticSilentRenew: false,
    post_logout_redirect_uri: baseUrl,
    response_type: "code",
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
