import fi from "../../../locales/fi.json";
import en from "../../../locales/en.json";

import { Locale as LocaleType } from "interfaces/urls.d";

export const LOCALES: LocaleType[] = ["fi", "en"];

export type Locale = LocaleType;

export const messages = {
    fi,
    en,
};

export const defaultLocale: Locale = "fi";

export const isValidLocale = (locale: string): locale is Locale =>
    LOCALES.includes(locale as Locale);
