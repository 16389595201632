import React, { ReactElement } from "react";
import { RoleList as RoleListType } from "interfaces/content";
import Role from "components/content/RoleList/Role";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    roleList: RoleListType;
}

const Container = styled.div`
    background-color: ${styles.colors.lightGrey};
    display: grid;
    column-gap: 10px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    margin: 30px 0;

    @media only screen and (${styles.breakpoints.xlGridBreakpoint}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        grid-template-columns: 1fr 1fr;
    }
`;

function RoleList({ roleList }: Props): ReactElement {
    return (
        <Container>
            {roleList.roles.map((role, index) => (
                <Role key={index} index={index} role={role} />
            ))}
        </Container>
    );
}

export default RoleList;
