import { fetchAuthorizations } from "state/common/user";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const mapState = (state: RootState) => ({
    authorizationFetchRetryCount: state.common.user.authorizationFetchRetryCount,
});

/**
 * fetchAuthorizations request gets cancelled randomly every now and then.
 *
 * This hook retries the authorization fetch.
 */
const useRetryAuthorizationsFetch = () => {
    const dispatch = useDispatch();
    const { authorizationFetchRetryCount } = useSelector(mapState, shallowEqual);

    useEffect(() => {
        if (authorizationFetchRetryCount === 1) {
            dispatch(fetchAuthorizations.started());
        }
    }, [dispatch, authorizationFetchRetryCount]);
};

export default useRetryAuthorizationsFetch;
