import React, { ReactElement, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { NavigationTab as NavigationTabType, NavigationTabBase } from "interfaces/navigation";
import { getUrlSegment, UrlSegmentIndex } from "services/common/url/url";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import NavigationTab from "../NavigationTab/NavigationTab";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    tabs: NavigationTabType[] | null;
}

const SubBar = styled.div`
    background-color: ${styles.colors.grey4};
    margin-top: -2px;
    padding: ${styles.spacing[2]} 0;
`;

const SubBarContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
    align-items: center;
    justify-content: start;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        padding: ${styles.spacing[3]};
        justify-content: start;
    }
`;

const SubNavBar = ({ tab }: { tab: NavigationTabType }) => (
    <SubBar>
        <SubBarContent>
            {tab.subTabs.map((subTab: NavigationTabBase) => (
                <NavigationTab key={subTab.path} tab={subTab} isSubTab={true} />
            ))}
        </SubBarContent>
    </SubBar>
);

const Bar = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
    justify-content: space-between;
    align-items: center;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        padding: ${styles.spacing[3]};
        flex-direction: column;
    }
`;

const TabsStyles = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media only screen and (${styles.breakpoints.xlGridBreakpoint}) {
        align-self: center;
        justify-content: flex-start;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        flex-direction: column;
        align-self: auto;
        width: 100%;
    }
`;

const Name = styled.h6`
    color: ${styles.colors.red};
    margin: ${styles.spacing[2]} 0;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        display: none;
    }
`;

const NavBar = ({ tabs }: { tabs: NavigationTabType[] }) => (
    <Bar>
        <Name>
            <FormattedMessage id="app.name" />
        </Name>
        <TabsStyles>
            <NavigationTab
                tab={{
                    path: "",
                    label: {
                        fi: "Koti",
                        en: "Home",
                    },
                    urlSegment: "",
                }}
                exact={true}
            />

            {tabs.map((tab: NavigationTabType) => (
                <NavigationTab key={tab.path} tab={tab} />
            ))}
        </TabsStyles>
    </Bar>
);

function NavigationBar({ tabs }: Props): ReactElement {
    const location = useLocation();
    const translate = useTranslate();

    const activeTab = useMemo(() => {
        if (!tabs) {
            return undefined;
        }

        const currentPageUrlSegment = getUrlSegment(location.pathname, UrlSegmentIndex.Page);

        return tabs.find((tab) => tab.urlSegment === currentPageUrlSegment);
    }, [location, tabs]);

    if (!tabs) {
        return <nav />;
    }

    return (
        <nav aria-label={translate("navigation.navbar")}>
            <NavBar tabs={tabs} />
            {activeTab && activeTab.subTabs.length > 1 ? <SubNavBar tab={activeTab} /> : null}
        </nav>
    );
}

export default NavigationBar;
