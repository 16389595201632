import useTranslate from "hooks/common/useTranslate/useTranslate";
import { loadingSelectorFactory } from "state/common/loading";
import { downloadAttachment, fetchServiceAgreements } from "state/service-agreements";
import { RootState } from "state/reducers";
import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ServiceAgreementList from "../ServiceAgreementList/ServiceAgreementList";
import styled from "styled-components";
import styles from "styles/styles";
import LoadingIndicator from "../../common/ui/LoadingIndicator/LoadingIndicator";
import ErrorMessage from "../../common/ui/ErrorMessage/ErrorMessage";

const Container = styled.div`
    padding: ${styles.spacing[2]};
    background-color: ${styles.colors.lightGrey};
    margin-top: ${styles.spacing[4]};
`;

const Title = styled.h5`
    margin: ${styles.spacing[2]};
`;

const LoadingContainer = styled.div`
    position: relative;
    padding: 100px ${styles.spacing[4]};
`;

const ErrorContainer = styled.div`
    padding: ${styles.spacing[2]};
`;

const Empty = styled.p`
    margin: ${styles.spacing[4]} ${styles.spacing[2]} ${styles.spacing[2]};
`;

const ServiceAgreements = (): JSX.Element => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const { businessId, serviceAgreements, loading, error } = useSelector(mapState);

    useEffect(() => {
        if (businessId) {
            dispatch(fetchServiceAgreements.started({ businessId }));
        }
    }, [dispatch, businessId]);

    const handleDownload = useCallback(
        (fileId: string, fileName: string) => {
            if (businessId) {
                dispatch(downloadAttachment.started({ businessId, fileId, fileName }));
            }
        },
        [dispatch, businessId]
    );

    return (
        <Container>
            <Title>
                <FormattedMessage id="serviceAgreements.heading" />
            </Title>

            {loading && (
                <LoadingContainer>
                    <LoadingIndicator isLoading />
                </LoadingContainer>
            )}

            {error && (
                <ErrorContainer>
                    <ErrorMessage
                        id="service-agreements-fetch-failed"
                        message={translate(`error.notification.${fetchServiceAgreements.type}`)}
                    />
                </ErrorContainer>
            )}

            {serviceAgreements &&
                (serviceAgreements.length ? (
                    <ServiceAgreementList
                        serviceAgreements={serviceAgreements}
                        onDownload={handleDownload}
                    />
                ) : (
                    <Empty>
                        <FormattedMessage id="serviceAgreements.empty" />
                    </Empty>
                ))}
        </Container>
    );
};

const loadingSelector = loadingSelectorFactory([fetchServiceAgreements]);

const mapState = (state: RootState) => {
    const businessId = state.common.user.selectedOrganizationId;

    return {
        businessId,
        serviceAgreements: businessId
            ? state.serviceAgreements.serviceAgreements[businessId]
            : undefined,
        loading: loadingSelector(state),
        error: !!state.common.failed[fetchServiceAgreements.type]?.all,
    };
};

export default ServiceAgreements;
