import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import useLocale from "hooks/common/useLocale/useLocale";
import { config } from "services/common/config/config";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {}

const Divider = styled.div`
    height: 100%;
    width: 1px;
    background-color: ${styles.colors.grey7};
    margin: 0 1.3vw;
`;

const LogoImage = styled.img`
    height: 22px;
`;

const Wrapper = styled.div`
    display: flex;
    height: 22px;
`;

const Name = styled.h6`
    margin: 0;

    > a {
        text-decoration: none;
        color: ${styles.colors.grey6};
    }
`;

function Logo({}: Props): ReactElement {
    const locale = useLocale();

    return (
        <Wrapper>
            <div>
                <Link to="/">
                    <LogoImage src={`${logo}`} alt="Fingrid" />
                </Link>
            </div>
            <Divider />
            <div>
                <Name>
                    <a href={`${config.externalLinks.myFingrid}/${locale}`}>Oma Fingrid</a>
                </Name>
            </div>
        </Wrapper>
    );
}

export default Logo;
