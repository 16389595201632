import styles from "styles/styles";
import React from "react";
import styled from "styled-components";

interface Props {
    text: string;
}

const FileTagContainer = styled.div`
    background-color: ${styles.colors.lightGrey};
    border-radius: ${styles.spacing[4]};
    padding: ${styles.spacing[1]} ${styles.spacing[3]};
    font-size: 14px;
    color: ${styles.colors.grey7};
`;

const FileTag = ({ text }: Props): JSX.Element => <FileTagContainer>{text}</FileTagContainer>;

export default FileTag;
