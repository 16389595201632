import styles from "styles/styles";
import { isNil } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Col, Row } from "../FlexboxGrid";

export interface DetailsViewContent {
    sections: DetailsSection[];
}

interface DetailsSection {
    headerKey?: string;
    rows: DetailsRow[];
}

export interface DetailsRow {
    columns: DetailsColumn[];
}

export interface DetailsColumn {
    labelKey: string;
    value: React.ReactNode;
    updatedValue?: React.ReactNode;
}

const columnCount = (row: DetailsRow) =>
    row.columns.filter((column) => column !== undefined).length;

const getColumnWidth = (row: DetailsRow) => {
    switch (columnCount(row)) {
        case 1:
            return 12;
        case 2:
            return 6;
        default:
            return 4;
    }
};

interface Props {
    content: DetailsViewContent;
}

const DetailsViewChanges = styled.span`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
`;

const Divider = styled.div`
    border-top: 2px solid ${styles.colors.lightGrey};
    padding-top: 10px;
`;

const SectionHeader = styled(Row)`
    font-weight: bold !important;
    margin-bottom: 5px !important;
`;

const PropertyRowWrapper = styled(Row)`
    margin-bottom: 30px !important;

    :last-of-type {
        margin-bottom: 0 !important;
    }
`;

const PropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > span:first-of-type {
        margin: 10px 0 !important;
    }

    > span:last-of-type {
        font-weight: bold !important;
    }
`;

const renderValue = (value: React.ReactNode, updatedValue?: React.ReactNode) => {
    const renderedValue = isNil(value) ? "-" : value;

    if (updatedValue === undefined || updatedValue === value) {
        return <span>{renderedValue}</span>;
    }

    return (
        <DetailsViewChanges>
            <del>{renderedValue}</del>
            &rarr; {updatedValue}
        </DetailsViewChanges>
    );
};

const Container = styled.div`
    font-size: 18px;
`;

class DetailsView extends React.Component<Props> {
    getSection(section: DetailsSection, sectionIndex: number) {
        return (
            <div key={sectionIndex}>
                {sectionIndex !== 0 ? <Divider /> : null}
                {section.headerKey && (
                    <SectionHeader>
                        <Col xs={12} md={12}>
                            <FormattedMessage id={section.headerKey} />
                        </Col>
                    </SectionHeader>
                )}
                {section.rows.map((row, rowIndex) => (
                    <PropertyRowWrapper key={`${sectionIndex}-${rowIndex}`}>
                        {row.columns.map((column, columnIndex) =>
                            column !== undefined ? (
                                <Col
                                    key={`${sectionIndex}-${rowIndex}-${columnIndex}`}
                                    xs={12}
                                    md={getColumnWidth(row)}
                                >
                                    <PropertyWrapper>
                                        <FormattedMessage id={column.labelKey} />
                                        {renderValue(column.value, column.updatedValue)}
                                    </PropertyWrapper>
                                </Col>
                            ) : null
                        )}
                    </PropertyRowWrapper>
                ))}
            </div>
        );
    }
    public render() {
        return (
            <Container>
                {this.props.content.sections.map((section, sectionIndex) =>
                    this.getSection(section, sectionIndex)
                )}
            </Container>
        );
    }
}

export default DetailsView;
