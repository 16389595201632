import React, { useEffect } from "react";
import { CustomPage } from "../../../../interfaces/content";
import { getLocalizedString } from "../../../utilities";
import useLocale from "../../../../hooks/common/useLocale/useLocale";

import data from "../../../../files/certification-questionnaire.json";
import { Question as QuestionType, QuestionnaireData } from "../../../../interfaces/common";
import Question from "./Question";
import { useHistory } from "react-router";
import styled from "styled-components";
import Page from "../../../common/layout/Page";
import bgImage from "../../../../images/questionnaire-bg.png";
import styles from "../../../../styles/styles";
import RichText from "../../RichText/RichText";
import Button from "../../../common/ui/buttons/Button/Button";
import useTranslate from "../../../../hooks/common/useTranslate/useTranslate";

const CertificationQuestionnaire: React.FC<{
    customPage: CustomPage;
}> = ({ customPage }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const history = useHistory();
    const questionnaireData: QuestionnaireData = data;
    const [questionPath, setQuestionPath] = React.useState<string[]>([]);

    const [currentQuestion, setCurrentQuestion] = React.useState<QuestionType>(
        questionnaireData.questions[0]
    );

    useEffect(() => {
        const q = questionnaireData.questions.find(
            (q) => q.id === questionPath[questionPath.length - 1]
        );

        if (q) {
            setCurrentQuestion(q);
        }
    }, [questionPath, questionnaireData.questions]);

    function goToPreviousQuestion() {
        if (questionPath.length > 1) {
            setQuestionPath(questionPath.slice(0, questionPath.length - 1));
        }
    }

    function goToNextQuestion(id: string) {
        if (Object.keys(questionnaireData.results).includes(id)) {
            history.push(questionnaireData.results[id].url);

            return;
        }
        setQuestionPath([...questionPath, id]);
    }

    function resetQuestionnaire() {
        setQuestionPath([]);
    }

    const Container = styled.div`
        padding: 20px;
        background-color: ${styles.colors.lightGrey};
        background-image: url(${bgImage});
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 60%;
        min-height: 400px;
    `;

    const QuestionContainer = styled.div`
        max-width: 800px;
    `;

    const Title = styled.h1`
        font-size: 2rem;
        margin-bottom: 20px;
        line-height: 2rem;
        max-width: 800px;
    `;

    return (
        <Page>
            <Container>
                <Title>{getLocalizedString(locale, customPage.pageHeader)}</Title>
                <QuestionContainer>
                    {questionPath.length > 0 ? (
                        <Question
                            question={currentQuestion}
                            goToPreviousQuestion={goToPreviousQuestion}
                            goToNextQuestion={goToNextQuestion}
                            resetQuestionnaire={resetQuestionnaire}
                            showPreviousBtn={questionPath.length > 1}
                            showResetBtn={questionPath.length > 0}
                        />
                    ) : (
                        <div>
                            {customPage.pageDesc && <RichText richText={customPage.pageDesc} />}
                            <Button onClick={() => goToNextQuestion("q1")}>
                                {translate("questionnaire.start")}
                            </Button>
                        </div>
                    )}
                </QuestionContainer>
            </Container>
        </Page>
    );
};

export default CertificationQuestionnaire;
