import * as React from "react";

const StatusComplete = () => (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={9} cy={9} r={9} fill="#277158" />
        <g clipPath="url(#a)">
            <path
                d="M7.312 11.346 4.967 9l-.799.793 3.144 3.145 6.75-6.75-.793-.793-5.957 5.95Z"
                fill="#fff"
            />
        </g>
    </svg>
);

export default StatusComplete;
