import { ContentSearchParameters, QueryResult, IndexDataType } from "interfaces/search";

import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory("CONTENT_SEARCH");

export const textSearch = actionCreator.async<ContentSearchParameters, QueryResult>("TEXT_SEARCH");
export const updateSearchTerm = actionCreator<string>("UPDATE_SEARCH_TERM");
export const updatePageNumber = actionCreator<number>("UPDATE_PAGE_NUMBER");
export const updateFilters = actionCreator<Record<IndexDataType, boolean>>("UPDATE_FILTERS");
