export interface DatePickerAriaPropsType {
    previousMonthAriaLabel?: string;
    nextYearAriaLabel?: string;
    chooseDayAriaLabelPrefix?: string;
    nextMonthAriaLabel?: string;
    disabledDayAriaLabelPrefix?: string;
    weekAriaLabelPrefix?: string;
    previousYearAriaLabel?: string;
}

export const defaultDatePickerAriaLabels: DatePickerAriaPropsType = {
    nextMonthAriaLabel: "Seuraava kuukausi",
    previousMonthAriaLabel: "Edellinen kuukausi",
    nextYearAriaLabel: "Seuraava vuosi",
    previousYearAriaLabel: "Edellinen vuosi",
    chooseDayAriaLabelPrefix: "Valitse",
    disabledDayAriaLabelPrefix: "Ei valitettavissa",
    weekAriaLabelPrefix: "Viikko",
};
