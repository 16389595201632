import * as React from "react";
import { FormattedMessage } from "react-intl";
import NewsFeedItemContainer from "components/news/NewsFeedItemContainer/NewsFeedItemContainer";
import { NewsFeed } from "interfaces/content";
import { sortNewsFeedItems } from "screens/common/FrontPage/FrontPage";
import styled from "styled-components";
import LoadingIndicator from "../../ui/LoadingIndicator/LoadingIndicator";

export interface Props {
    faultNotifications: NewsFeed | null;
    isLoading: boolean;
    closeModal?: () => void;
}

const Container = styled.div`
    position: relative;
`;

const FaultNotificationsFeed = (props: Props) => {
    const { faultNotifications, isLoading, closeModal } = props;

    if (isLoading) {
        return <Container>{isLoading && <LoadingIndicator isLoading={isLoading} />}</Container>;
    }

    if (!faultNotifications) {
        return null;
    }

    if (faultNotifications.newsFeedItems.length === 0) {
        return <FormattedMessage id="newsfeed.newsNotFound" />;
    }

    return (
        <Container>
            {sortNewsFeedItems(faultNotifications.newsFeedItems)
                .slice(0, 5)
                .map((newsFeedItem, index) => (
                    <NewsFeedItemContainer
                        key={index}
                        newsFeedItem={newsFeedItem}
                        previewMode={true}
                        closeModal={closeModal}
                    />
                ))}
        </Container>
    );
};

export default FaultNotificationsFeed;
