import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { IndexDataType, QueryResult } from "interfaces/search";
import { textSearch, updateFilters } from "./actions";

export interface SearchState {
    searchResults: QueryResult | null;
    filters: Record<IndexDataType, boolean>;
}

const initialState: SearchState = {
    searchResults: null,
    filters: { news: false, page: false, document: false, blog: false },
};

export default function reducer(state = initialState, action: AnyAction): SearchState {
    if (isType(action, textSearch.done)) {
        return {
            ...state,
            searchResults: action.payload.result,
        };
    }

    if (isType(action, updateFilters)) {
        return {
            ...state,
            filters: { ...action.payload },
        };
    }

    return state;
}
