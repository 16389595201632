import { MutableRefObject, useCallback, useLayoutEffect, useState } from "react";

const useElementDimensions = (ref: MutableRefObject<any>) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const handleResize = useCallback(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth);
            setHeight(ref.current.offsetHeight);
        }
    }, [ref]);

    useLayoutEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return { width, height };
};

export default useElementDimensions;
