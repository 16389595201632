import createRootReducer, { RootState } from "state/reducers";

const organizationIdKey = "selectedOrganizationId";

export const loadState = (reducer: ReturnType<typeof createRootReducer>) => {
    try {
        const emptyState = reducer(undefined, { type: "NOOP" });
        const selectedOrganizationId = localStorage.getItem(organizationIdKey);

        const state: RootState = {
            ...emptyState,
            common: {
                ...emptyState.common,
                user: {
                    ...emptyState.common.user,
                    selectedOrganizationId,
                },
            },
        };

        return state;
    } catch {
        return undefined;
    }
};

export const saveState = (state: RootState) => {
    try {
        const { selectedOrganizationId } = state.common.user;

        if (selectedOrganizationId) {
            localStorage.setItem(organizationIdKey, selectedOrganizationId);
        } else {
            localStorage.removeItem(organizationIdKey);
        }
    } catch {
        // Ignore write errors
    }
};
