import React from "react";

import arrowRight from "images/arrow-right.svg";
import arrowLeft from "images/arrow-left.svg";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled, { css } from "styled-components";
import styles from "styles/styles";

export interface Props {
    onClick: (event: React.MouseEvent) => void;
    isDisabled: boolean;
    direction: "right" | "left";
}

const disabledStyle = css`
    opacity: 0.35;
    border-color: ${styles.colors.grey7};
`;

const ArrowButton = styled.button`
    background-color: transparent;
    border: 1px solid ${styles.colors.grey4};
    border-radius: 50%;
    min-height: 44px;
    min-width: 44px;
    margin: ${styles.spacing[2]};
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.normal};
    font-weight: bold;
    padding: 0;

    > img {
        display: block;
        margin: 0 auto;
    }

    ${(props) => props.disabled && disabledStyle}
`;

const PaginationButton = ({ onClick, isDisabled, direction }: Props) => {
    const translate = useTranslate();

    return (
        <ArrowButton onClick={onClick} disabled={isDisabled}>
            <img
                src={direction === "right" ? arrowRight : arrowLeft}
                alt={translate(`common.${direction === "right" ? "next" : "previous"}`)}
            />
        </ArrowButton>
    );
};

export default PaginationButton;
