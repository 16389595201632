import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { Event } from "interfaces/content";
import { fetchEvents } from "./actions";

export interface EventsState {
    events: Event[];
}

const initialState: EventsState = {
    events: [],
};

export default function reducer(state = initialState, action: AnyAction): EventsState {
    if (isType(action, fetchEvents.done)) {
        return {
            ...state,
            events: action.payload.result,
        };
    }

    return state;
}
