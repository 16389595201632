import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import styles from "styles/styles";
import Page from "components/common/layout/Page";

interface Props {
    page?: React.ReactNode;
    message?: React.ReactNode;
    returnUrl?: string;
}

const Divider = styled.span`
    display: inline-block;
    text-align: center;
    font-weight: lighter;
    width: 30px;
`;

const HeaderContainer = styled.div`
    font-weight: lighter;
    display: flex;

    h4 {
        flex-direction: row;
        margin-right: ${styles.spacing[1]};
    }
`;

class PageNotFound extends React.Component<Props> {
    // @TODO add title here
    private getPath = () => {
        let breadcrumb: React.ReactNode[] = [];

        if (this.props.hasOwnProperty("page")) {
            breadcrumb = this.pushToPath(
                <FormattedMessage id={this.props.page as string} />,
                breadcrumb
            );
        }

        if (this.props.hasOwnProperty("message")) {
            breadcrumb = this.pushToPath(this.props.message, breadcrumb);
        } else {
            breadcrumb = this.pushToPath(
                <FormattedMessage id="navigation.pageNotFound" />,
                breadcrumb
            );
        }
        breadcrumb.pop();

        return breadcrumb;
    };

    private pushToPath(part: React.ReactNode, breadcrumb: React.ReactNode[]) {
        breadcrumb.push(<span key={breadcrumb.length}>{part}</span>);
        breadcrumb.push(<Divider key={breadcrumb.length}>/</Divider>);

        return breadcrumb;
    }

    public render() {
        return (
            <Page>
                <HeaderContainer>
                    <h4>{this.getPath()}</h4>
                </HeaderContainer>
                {this.props.returnUrl ? (
                    <Link
                        to={{
                            pathname: this.props.returnUrl,
                        }}
                    >
                        <FormattedMessage id="navigation.returnTo" />
                    </Link>
                ) : null}
            </Page>
        );
    }
}

export default PageNotFound;
