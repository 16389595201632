import React from "react";
import expand from "images/expand.svg";
import { isExternalUrl } from "services/common/link/link";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import styles from "styles/styles";

type BackgroundColor = "dark" | "light";

export interface ListItemData {
    details: string;
    description: string;
    backgroundColor: BackgroundColor;
    url: string;
}

const ListItemDescription = styled.h6`
    color: ${styles.colors.grey7};
    margin: 0;
    text-align: left;
`;

const ListItemDetails = styled.p`
    color: ${styles.colors.grey6};
    margin: 0 0 ${styles.spacing[1]} 0;
    text-align: left;
    font-size: ${styles.fontSize.small};
`;

interface ButtonProps {
    disabled: boolean;
    backgroundColor: BackgroundColor;
}

const ListItemButton = styled.button<ButtonProps>`
    padding: ${styles.spacing[4]};
    margin: ${styles.spacing[4]} 0;
    border: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor === "dark" ? styles.colors.grey4 : styles.colors.lightGrey};
    ${(props) => (props.disabled ? `opacity: 0.75; cursor: initial;` : ``)};
`;
const ListItem: React.FC<{ listItem: ListItemData }> = ({ listItem }) => {
    const disabled = !listItem.url;
    const dispatch = useDispatch();

    const handleOnClick = () =>
        isExternalUrl(listItem.url)
            ? window.open(listItem.url, "_blank")
            : dispatch(push(listItem.url));

    return (
        <ListItemButton
            backgroundColor={listItem.backgroundColor}
            disabled={disabled}
            onClick={handleOnClick}
        >
            <div>
                <ListItemDetails>{listItem.details}</ListItemDetails>
                <ListItemDescription>{listItem.description}</ListItemDescription>
            </div>
            {disabled ? null : <img src={expand} />}
        </ListItemButton>
    );
};

export default ListItem;
