import React, { ReactElement } from "react";
import { Tag as TagType } from "interfaces/content";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import Tag from "components/content/Tag/Tag";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../../common/ui/FlexboxGrid";

interface Props {
    tags: TagType[];
    selectedTagIds: string[];
    onTagSelect: (selectedTagId: string) => void;
}

const mapState = (state: RootState) => ({
    isFingridUser: state.common.user.isFingridUser,
});

const NewsTag = styled(Tag)<{ selected: boolean }>`
    background-color: ${(props) => (props.selected ? styles.colors.red : styles.colors.white)};
    color: ${(props) => (props.selected ? styles.colors.white : styles.colors.grey7)};
`;

const TagBtn = styled.button`
    background: transparent;
    border: none;
    padding: 0;
`;

const TagRow = styled(Row)`
    margin: ${styles.spacing[2]} 0;
    padding-left: ${styles.spacing[2]};
`;

const TagCol = styled(Col)`
    padding: 0 ${styles.spacing[2]};
    cursor: pointer;
`;

function NewsFeedEditorTags({ tags, onTagSelect, selectedTagIds }: Props): ReactElement {
    const isSelectedTag = (tagId: string) => selectedTagIds.some((id) => id === tagId);
    const { isFingridUser } = useSelector(mapState, shallowEqual);

    const filteredTags = isFingridUser ? tags : tags.filter((tag) => !tag.forFingridUsers);

    if (filteredTags.length < 1) {
        return <div />;
    }

    return (
        <TagRow>
            <FormattedMessage id="newsfeed.addTags" />:
            {filteredTags.map((tag) => (
                <TagCol key={tag.id}>
                    <TagBtn onClick={() => onTagSelect(tag.id)}>
                        <NewsTag selected={isSelectedTag(tag.id)} tag={tag} />
                    </TagBtn>
                </TagCol>
            ))}
        </TagRow>
    );
}

export default NewsFeedEditorTags;
