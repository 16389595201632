import React from "react";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${styles.spacing[2]};
`;

const FileList = ({ children, className }: Props) => (
    <Container className={className}>{children}</Container>
);

export default FileList;
