import "core-js";
import "formdata-polyfill";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/fi";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fi";
import "@formatjs/intl-pluralrules/locale-data/en";

import "moment-timezone";

import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { AnyAction, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";

import React from "react";
import ReactDOM from "react-dom";

import "./styles/fonts.css";

import App from "./components/common/navigation/App/App";
import IntlProvider from "components/common/IntlProvider/IntlProvider";

import epics from "./state/epics";
import { loadState, saveState } from "state/localStorage";
import { asyncActionMiddleware } from "./state/middleware/asyncActionMiddleware";
import createRootReducer, { RootState } from "./state/reducers";
import { loadUser, OidcProvider } from "redux-oidc";
import userManager from "services/common/oidc/userManager";
import GlobalStyles from "styles/GlobalStyles";

const history = createBrowserHistory();

// redux-observable middleware
const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState, unknown>();

const reducer = createRootReducer(history);

const store = createStore(
    reducer,
    loadState(reducer),
    composeWithDevTools(
        applyMiddleware(thunk, epicMiddleware, routerMiddleware(history), asyncActionMiddleware)
    )
);

store.subscribe(() => {
    saveState(store.getState());
});

// Load potentially existing user data into the redux store
loadUser(store, userManager);

epicMiddleware.run(epics);

// added ignore, because the type def update requires large library update (or an ugly wrapper)
ReactDOM.render(
    <Provider store={store}>
        {/* @ts-ignore */}
        <OidcProvider userManager={userManager} store={store}>
            <ConnectedRouter history={history}>
                <IntlProvider>
                    <GlobalStyles />
                    <App />
                </IntlProvider>
            </ConnectedRouter>
        </OidcProvider>
    </Provider>,
    document.getElementById("root")
);
