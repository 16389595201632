import useTranslate from "hooks/common/useTranslate/useTranslate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FaultNotificationContact as FaultNotificationContactType,
    Organization,
} from "interfaces/common";
import ContactRow from "./ContactRow";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import { validateEmail } from "../../utils/validate";
import { setIsOrganizationSelectOpen } from "state/common/user";
import {
    fetchFaultNotificationContacts,
    updateFaultNotificationContacts,
    updateRequestStatus,
} from "state/fault-notification-contacts";
import { replaceArrayIndex } from "../../utils/array";
import { successToast } from "components/common/notification/success-notification";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../components/common/ui/buttons/Button/Button";
import { Col, Row } from "../../components/common/ui/FlexboxGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

const ButtonContainer = styled(Row)`
    margin-top: ${styles.spacing[4]};
    margin-bottom: ${styles.spacing[4]};
`;

const ActionButtonContainer = styled(Col)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const SaveButton = styled(Button)`
    margin-left: ${styles.spacing[2]};
`;

const mapState = (state: RootState) => ({
    selectedOrganizationId: state.common.user.selectedOrganizationId,
    organizations: state.common.user.organizations,
    requestStatus: state.faultNotificationContacts.requestStatus,
    contacts: state.faultNotificationContacts.contacts,
});

const validate = (contacts: FaultNotificationContactType[]): boolean =>
    contacts.reduce<boolean>(
        (validContact, contact) =>
            !validContact
                ? validContact
                : validateEmail(contact.email) &&
                  !!contact.businessId.length &&
                  !!contact.name.length &&
                  (!contact.contactSms || !!contact.phone.length),
        true
    );

const getInitialContact = (selectedOrganizationId: string): FaultNotificationContactType => ({
    id: null,
    name: "",
    phone: "",
    email: "",
    businessId: selectedOrganizationId,
    contactEmail: true,
    contactSms: true,
});

interface Props {
    selectedOrganizationId: string;
    organizations: Organization[];
}

const FaultNotificationContact = ({ selectedOrganizationId, organizations }: Props) => {
    const t = useTranslate();
    const dispatch = useDispatch();

    const [contactList, setContactList] = useState<FaultNotificationContactType[]>([]);
    const [removedContactIds, setRemovedContactIds] = useState<number[]>([]);
    const { requestStatus, contacts } = useSelector(mapState, shallowEqual);

    useEffect(() => {
        setContactList(contacts);
    }, [contacts]);

    useEffect(() => {
        if (requestStatus === "contacts-updated") {
            dispatch(updateRequestStatus("contacts-refresh"));
        }
    }, [dispatch, requestStatus, removedContactIds]);

    useEffect(() => {
        if (requestStatus === "contacts-fetch" || requestStatus === "contacts-refresh") {
            dispatch(
                fetchFaultNotificationContacts.started({ businessId: selectedOrganizationId })
            );
        }

        if (requestStatus === "contacts-refresh") {
            successToast("faultNotificationContacts.update.success");
        }
    }, [dispatch, selectedOrganizationId, requestStatus]);

    const handleRemove = (contact: FaultNotificationContactType, idx: number) => {
        if (typeof contact.id === "number") {
            setRemovedContactIds([...removedContactIds, contact.id]);
        }

        setContactList(contactList.filter((_, contactIdx) => contactIdx !== idx));
    };

    return (
        <Container>
            <h3>{t("faultNotificationContacts.title")}</h3>
            <p>{t("faultNotificationContacts.description")}</p>
            {contactList.map((contact, idx) => (
                <ContactRow
                    key={idx}
                    contact={contact}
                    organizations={organizations}
                    onChange={(newContact) =>
                        setContactList(replaceArrayIndex(contactList, idx, newContact))
                    }
                    onRemove={() => handleRemove(contact, idx)}
                />
            ))}
            <ButtonContainer>
                <Col xs={6}>
                    <Button
                        tertiary
                        onClick={() =>
                            setContactList([
                                ...contactList,
                                getInitialContact(selectedOrganizationId),
                            ])
                        }
                    >
                        {t("faultNotificationContacts.buttons.addNewContact")}
                    </Button>
                </Col>
                <ActionButtonContainer xs={5}>
                    <SaveButton
                        primary
                        disabled={!validate(contactList)}
                        onClick={() =>
                            dispatch(
                                updateFaultNotificationContacts.started({
                                    faultNotificationContacts: contactList,
                                    businessId: selectedOrganizationId,
                                })
                            )
                        }
                    >
                        {t("faultNotificationContacts.buttons.save")}
                    </SaveButton>
                </ActionButtonContainer>
            </ButtonContainer>
            <p>
                <small>{t("faultNotificationContacts.disclaimer")}</small>
            </p>
        </Container>
    );
};

const FaultNotificationContactContainer = () => {
    const dispatch = useDispatch();
    const { selectedOrganizationId, organizations } = useSelector(mapState, shallowEqual);

    if (!selectedOrganizationId || !organizations) {
        dispatch(setIsOrganizationSelectOpen(true));

        return null;
    }

    return (
        <FaultNotificationContact
            selectedOrganizationId={selectedOrganizationId}
            organizations={organizations}
        />
    );
};

export default FaultNotificationContactContainer;
