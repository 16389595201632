import React, { ReactElement } from "react";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { NavigationTab } from "interfaces/navigation";
import SectionNavTab from "../SectionNavTab/SectionNavTab";
import styled from "styled-components";

interface Props {
    tabs: NavigationTab[];
}

const SectionNavContainer = styled.nav`
    background-color: transparent;
    box-shadow: none;
    top: 0;
    position: sticky;
    max-height: 700px;
`;

function SectionNav({ tabs }: Props): ReactElement {
    const translate = useTranslate();

    return (
        <SectionNavContainer aria-label={translate("navigation.pageNavbar")}>
            {tabs.map((tab, index) => (
                <SectionNavTab key={`${tab.path}`} tab={tab} isDefaultTab={index === 0} />
            ))}
        </SectionNavContainer>
    );
}

export default SectionNav;
