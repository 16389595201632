import Link from "components/common/ui/Link/Link";
import useLocale from "hooks/common/useLocale/useLocale";
import circleRightIcon from "images/circle_right.svg";
import { LinkButton as LinkButtonType } from "interfaces/content";
import styles from "styles/styles";
import React from "react";
import styled, { css } from "styled-components";

interface Props {
    linkButton: LinkButtonType;
    isEven: boolean;
}

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 12px;
`;

const Label = styled.span`
    font-size: 16px;
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${styles.colors.white};
    padding: ${styles.spacing[2]} ${styles.spacing[2]};
    font-weight: 500;

    :hover {
        text-decoration: underline;
        color: ${styles.colors.white};
    }
`;

const EvenButton = css`
    color: ${styles.colors.red};
    background-color: ${styles.colors.white};
    border: 2px solid ${styles.colors.red};

    :hover {
        background-color: ${styles.colors.red};
        color: ${styles.colors.white};
    }
`;

const UnevenButton = css`
    background-color: ${styles.colors.red};
    color: ${styles.colors.white};
    border: 2px solid ${styles.colors.red};

    :hover {
        color: ${styles.colors.red};
        background-color: ${styles.colors.white};
    }
`;

const LinkButton = styled(StyledLink)<{ isEven: boolean }>`
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    height: 3.6875rem;
    justify-content: center;
    min-width: 7.5rem;
    padding: 0 0.625rem;

    img {
        display: none;
    }

    :hover {
        text-decoration: none;
    }

    ${(props) => (props.isEven ? EvenButton : UnevenButton)}
`;

function HeroCardLink({ linkButton, isEven }: Props) {
    const locale = useLocale();

    return (
        <LinkButton isEven={isEven} localizedUrl={linkButton.url} openExternalLinkToNewTab={false}>
            <Icon src={circleRightIcon} alt="" />
            <Label>{linkButton.label[locale]}</Label>
        </LinkButton>
    );
}

export default HeroCardLink;
