import React, { ReactElement } from "react";

import {
    SideContentItem as SideContentItemType,
    SideContent as SideContentContentfulType,
    Contact,
    Event,
    Notification,
    BlogPost as BlogPostType,
} from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import BlogPost from "components/blog/BlogPost/BlogPost";
import ContactCard from "../ContactCard/ContactCard";
import EventCard from "../EventCard/EventCard";
import NotificationCard from "../NotificationCard/NotificationCard";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    sideContentItem: SideContentItemType;
}

const isContact = (content: SideContentContentfulType): content is Contact =>
    content.type === "contact";

const isEvent = (content: SideContentContentfulType): content is Event => content.type === "event";

const isNotification = (content: SideContentContentfulType): content is Notification =>
    content.type === "notification";

const isBlogPost = (content: SideContentContentfulType): content is BlogPostType =>
    content.type === "blogPost";

const ContentItem = ({ content }: { content: SideContentContentfulType }): ReactElement | null => {
    if (isContact(content)) {
        return <ContactCard contact={content} />;
    }

    if (isEvent(content)) {
        return <EventCard event={content} />;
    }

    if (isNotification(content)) {
        return <NotificationCard notification={content} />;
    }

    if (isBlogPost(content)) {
        return <BlogPost blogPost={content} previewMode={true} />;
    }

    // This function is here to make the compiler notice if we've missed a side content type.
    ((_: never) => {
        throw new Error(`Unknown side content: ${content}`);
    })(content);
};

const Container = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: ${styles.spacing[3]};
`;

const Title = styled.h4`
    margin: 0;
    margin-bottom: ${styles.spacing[3]};
    padding: 0 ${styles.spacing[2]};
`;

const Content = styled.div`
    padding: ${styles.spacing[2]};
`;

function SideContentItem({ sideContentItem }: Props): ReactElement | null {
    const locale = useLocale();

    if (!sideContentItem.contentList || sideContentItem.contentList.length < 1) {
        // Only render a template if actual content-items are added to the content-list.
        return null;
    }

    return (
        <Container>
            {sideContentItem.title && <Title>{sideContentItem.title[locale]}</Title>}
            {sideContentItem.contentList.map((content, index) => (
                <Content key={index}>
                    <ContentItem content={content} />
                </Content>
            ))}
        </Container>
    );
}

export default SideContentItem;
