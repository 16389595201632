import * as React from "react";
import { FormattedMessage } from "react-intl";
import FaultNotificationsFeed from "components/common/FaultNotifications/FaultNotificationsFeed/FaultNotificationsFeed";
import { NewsFeed } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";
import Modal from "../../modals/Modal/Modal";
import Button from "../../ui/buttons/Button/Button";

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    faultNotifications: NewsFeed | null;
    descriptionKey?: string;
    onClose: () => void;
}

const Header = styled.h5`
    margin: 0 0 ${styles.spacing[4]} 0;
    display: flex;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: ${styles.spacing[4]};
`;

const CloseButton = styled.button`
    margin-left: auto;
    border: 0;
    background: transparent;
    font-size: 2.5rem;
    margin-top: -1rem;
`;

const FaultNotificationsModal = (props: Props) => (
    <Modal
        size="medium"
        isOpen={props.isOpen}
        disableWindowScrolling={true}
        onRequestClose={props.onClose}
        shouldCloseOnOverlayClick={true}
    >
        <Header>
            <FormattedMessage id="common.faultNotifications" />
            <CloseButton type="button" onClick={props.onClose}>
                ×
            </CloseButton>
        </Header>
        <FaultNotificationsFeed
            faultNotifications={props.faultNotifications}
            isLoading={props.isLoading}
            closeModal={props.onClose}
        />
        <ButtonContainer>
            <Button onClick={props.onClose}>
                <FormattedMessage id="common.close" />
            </Button>
        </ButtonContainer>
    </Modal>
);

export default FaultNotificationsModal;
