import React, { ReactElement } from "react";
import useLocale from "hooks/common/useLocale/useLocale";
import { Role as RoleType } from "interfaces/content";
import styled from "styled-components";

interface Props {
    role: RoleType;
    index: number;
}

const RoleTitle = styled.div`
    text-transform: uppercase;
    font-size: 0.6em;
`;

const PersonName = styled.div`
    font-size: 0.8em;
    font-weight: bold;
`;

function Role({ role, index }: Props): ReactElement {
    const locale = useLocale();

    return (
        <div key={index}>
            <RoleTitle>{role.title[locale]}</RoleTitle>
            <PersonName>{role.name}</PersonName>
        </div>
    );
}

export default Role;
