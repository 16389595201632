import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { NewsFeed } from "interfaces/content";
import {
    createNewsFeedItem,
    deleteNewsFeedItem,
    fetchNewsFeed,
    updateNewsFeedItem,
} from "./actions";

export interface NewsState {
    newsFeed: NewsFeed | null;
}

const initialState: NewsState = {
    newsFeed: null,
};

export default function reducer(state = initialState, action: AnyAction): NewsState {
    if (isType(action, createNewsFeedItem.done)) {
        if (!state.newsFeed) {
            return state;
        }

        return {
            ...state,
            newsFeed: {
                ...state.newsFeed,
                totalNewsFeedItemsCount: state.newsFeed.totalNewsFeedItemsCount + 1,
                newsFeedItems: [action.payload.result, ...state.newsFeed.newsFeedItems],
            },
        };
    }

    if (isType(action, updateNewsFeedItem.done)) {
        if (!state.newsFeed) {
            return state;
        }

        return {
            ...state,
            newsFeed: {
                ...state.newsFeed,
                newsFeedItems: [
                    action.payload.result,
                    ...state.newsFeed.newsFeedItems.filter(
                        (item) => item.id !== action.payload.result.id
                    ),
                ],
            },
        };
    }

    if (isType(action, deleteNewsFeedItem.done)) {
        if (!state.newsFeed) {
            return state;
        }

        return {
            ...state,
            newsFeed: {
                ...state.newsFeed,
                totalNewsFeedItemsCount:
                    state.newsFeed.totalNewsFeedItemsCount > 0
                        ? state.newsFeed.totalNewsFeedItemsCount - 1
                        : 0,
                newsFeedItems: state.newsFeed.newsFeedItems.filter(
                    (newsFeedItem) => newsFeedItem.id !== action.payload.params
                ),
            },
        };
    }

    if (isType(action, fetchNewsFeed.done)) {
        return {
            ...state,
            newsFeed: action.payload.result,
        };
    }

    return state;
}
