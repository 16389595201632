import actionCreatorFactory from "typescript-fsa";
import { ServiceAgreement } from "interfaces/service-agreements";

const actionCreator = actionCreatorFactory("SERVICE_AGREEMENTS");

export const fetchServiceAgreements = actionCreator.async<
    { businessId: string },
    ServiceAgreement[]
>("FETCH_SERVICE_AGREEMENTS");

export const downloadAttachment = actionCreator.async<
    { businessId: string; fileId: string; fileName: string },
    Blob
>("FETCH_ATTACHMENT");
