import React, { useMemo } from "react";

import { Tag, DocumentFileList, SingleDocument } from "interfaces/content";
import DocumentList from "components/content/DocumentList/DocumentList";
import { FormattedMessage } from "react-intl";
import { filterDocuments } from "services/file-search/file-search";
import Page from "components/common/layout/Page";

export interface Props {
    tagFilters: Tag[];
    searchTerm: string;
    allDocuments: SingleDocument[];
}

const FileSearchResults = ({ tagFilters, searchTerm, allDocuments }: Props) => {
    const filteredDocumentList: DocumentFileList = useMemo(
        () => ({
            type: "documentList",
            documents: filterDocuments(allDocuments, searchTerm, tagFilters),
            showSearch: false,
        }),
        [searchTerm, allDocuments, tagFilters]
    );

    return (
        <Page>
            <h4>
                <FormattedMessage
                    id="fileSearch.results.title"
                    values={{ resultsTotalCount: filteredDocumentList.documents.length }}
                />
            </h4>
            {filteredDocumentList.documents.length > 0 && (
                <DocumentList documentList={filteredDocumentList}></DocumentList>
            )}
        </Page>
    );
};

export default FileSearchResults;
