import { useEffect, useRef } from "react";

const usePrevious = <T>(value: T): React.MutableRefObject<T | undefined> => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref;
};

export default usePrevious;
