import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import ErrorNotificationsContainer from "components/common/notification/ErrorNotificationsContainer/ErrorNotificationsContainer";
import AnnouncementContainer from "components/common/AnnouncementContainer/AnnouncementContainer";
import { fetchCommonContent } from "state/content/contentful";
import useLocale from "hooks/common/useLocale/useLocale";
import ErrorBoundary from "../../ui/ErrorBoundary/ErrorBoundary";
import Header from "../Header/Header";
import MainContainer from "../MainContainer/MainContainer";
import useRetryAuthorizationsFetch from "hooks/common/useRetryAuthorizationsFetch/useRetryAuthorizationsFetch";
import TopFooter from "../TopFooter/TopFooter";
import BottomFooter from "../BottomFooter/BottomFooter";
import styled from "styled-components";
import styles from "styles/styles";

const StyledToastContainer = styled(ToastContainer)`
    > div {
        cursor: default;
        font-family: "Lab Grotesque";
        font-size: ${styles.fontSize.small};
        margin-bottom: ${styles.spacing[2]};
    }

    > div:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 480px) {
        top: ${styles.spacing[2]};
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
    }
`;

const App: React.FC = () => {
    const dispatch = useDispatch();
    const locale = useLocale();
    useRetryAuthorizationsFetch();

    // Initialize moment locale
    useEffect(() => {
        moment.locale(locale);
        // Set <html lang="locale"> attribute
        document.documentElement.lang = locale;
    }, [locale]);

    useEffect(() => {
        dispatch(fetchCommonContent.started());
    }, [dispatch]);

    return (
        <>
            <AnnouncementContainer />
            <Header />
            <ErrorBoundary>
                <MainContainer />
                <ErrorNotificationsContainer />
                <StyledToastContainer
                    theme="colored"
                    position="bottom-right"
                    hideProgressBar={true}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </ErrorBoundary>
            <footer>
                <TopFooter />
                <BottomFooter />
            </footer>
        </>
    );
};

export default App;
