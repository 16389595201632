import React from "react";
import { FormattedMessage } from "react-intl";
import StatusComplete from "./StatusComplete";
import StatusInProgress from "./StatusInProgress";
import styled from "styled-components";
import { ActionStatus } from "../../../../interfaces/content";
import styles from "../../../../styles/styles";

interface Props {
    status: ActionStatus;
    showLabel?: boolean;
}

const StatusBadgeContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Fill = styled.div`
    width: 16px;
    height: 16px;
`;

const Incomplete = styled(Fill)`
    border: 1px solid #000;
    border-radius: 50%;
`;

const StatusLabel = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    color: ${styles.colors.grey5};
`;

const StatusMap: Record<ActionStatus, { component?: JSX.Element }> = {
    complete: {
        component: <StatusComplete />,
    },
    in_progress: { component: <StatusInProgress /> },
    incomplete: {
        component: <Incomplete />,
    },
    not_started: {
        component: <Incomplete />,
    },
} as const;

export const StatusBadge = ({ status, showLabel }: Props) => (
    <StatusBadgeContainer>
        {showLabel && (
            <StatusLabel>
                <FormattedMessage id={`common.status.${status}`} />
            </StatusLabel>
        )}
        {StatusMap[status].component}
    </StatusBadgeContainer>
);
