import { useEffect } from "react";
import { useTranslate } from "../useTranslate/useTranslate";

/**
 * Update document title hook.
 * Only update if title changes.
 */
export const useDocumentTitle = (pageTitle?: string) => {
    const translate = useTranslate();
    const appName = translate("app.name");

    const title = pageTitle && pageTitle.length ? `${pageTitle} | ${appName}` : appName;

    useEffect(() => {
        document.title = title;
    }, [title, appName]);
};

export default useDocumentTitle;
