import { css, FlattenInterpolation, ThemedStyledProps } from "styled-components";

interface BaseConfig {
    media?: any;
    gridSize: number;
    gutterWidth: number;
    outerMargin: number;
    mediaQuery: string;
    container: {
        sm: number;
        md: number;
        lg: number;
    };
    breakpoints: {
        xs: number;
        sm: number;
        md: number;
        lg: number;
    };
}

const THEME_CONF = "flexboxgrid";
export const BASE_CONF: BaseConfig = {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    mediaQuery: "only screen",
    container: {
        sm: 46,
        md: 61,
        lg: 76,
    },
    breakpoints: {
        xs: 0,
        sm: 48,
        md: 64,
        lg: 75,
    },
};

const configCache: [string?, BaseConfig?] = [];
const makeCacheId = (props: any): string =>
    JSON.stringify((props.theme && props.theme[THEME_CONF]) || {});
const resolveConfig = (props: any): BaseConfig => {
    const themeConf = (props.theme && props.theme[THEME_CONF]) || {};

    const conf: BaseConfig = {
        ...BASE_CONF,
        ...themeConf,
        container: {
            ...BASE_CONF.container,
            ...themeConf.container,
        },
        breakpoints: {
            ...BASE_CONF.breakpoints,
            ...themeConf.breakpoints,
        },
    };

    conf.media = Object.keys(conf.breakpoints).reduce((media: any, breakpoint: string) => {
        const breakpointWidth = conf.breakpoints[breakpoint as keyof BaseConfig["breakpoints"]];
        media[breakpoint] = makeMedia(
            [conf.mediaQuery, breakpoint !== "xs" && `(min-width: ${breakpointWidth}em)`]
                .filter(Boolean)
                .join(" and ")
        );

        return media;
    }, {});

    return conf;
};

export const DIMENSION_NAMES = ["xs", "sm", "md", "lg"];

export default function config(props: any): BaseConfig {
    const cacheId = makeCacheId(props);

    if (configCache[0] === cacheId) {
        return configCache[1]!;
    }

    const conf = resolveConfig(props);

    configCache[0] = cacheId;
    configCache[1] = conf;

    return conf;
}

function makeMedia(
    media: string
): (...args: Parameters<typeof css>) => FlattenInterpolation<ThemedStyledProps<any, any>> {
    return (...args) => css`
        @media ${media} {
            ${css(...args)}
        }
    `;
}
