import ExpandButton from "components/common/ui/ExpandButton/ExpandButton";
import { Event } from "interfaces/content";

import React, { useState } from "react";
import { formatDate } from "services/common/dates/dates";
import useLocale from "hooks/common/useLocale/useLocale";
import download from "images/download.svg";
import IconLink from "components/common/ui/IconLink/IconLink";
import { config } from "services/common/config/config";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../../common/ui/buttons/Button/Button";

interface Props {
    event: Event;
}

const Container = styled.div`
    padding-bottom: 10px;
    margin-bottom: 20px;
    background-color: ${styles.colors.lightGrey};
`;

const LinkContainer = styled.div`
    padding-right: 10px;
`;

const EventBlockContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px 0;
    justify-content: space-between;
    min-height: 59px;
`;

const EventDescription = styled.span`
    display: flex;
    align-items: center;
`;

const EventDescriptionTitle = styled.p`
    font-weight: bold;
    margin: 0 8px 0 17px;
`;

const EventDescriptionLocation = styled.p`
    margin: 0 0 0 8px;
`;

const ExpandedEventBlock = styled.div`
    padding: 10px 20px 10px 45px;
    white-space: pre-wrap;
`;

const FileList = styled.div`
    display: flex;

    button:not(:first-child) {
        margin-left: 20px;
    }
`;

const EventBlock = ({ event }: Props) => {
    const translate = useTranslate();
    const [isExpanded, setIsExpanded] = useState(false);
    const locale = useLocale();

    const showExpandButton =
        !!event.details?.fi || !!event.recordingUrl || !!event.documents.length;

    return (
        <Container>
            <EventBlockContainer>
                <div>
                    <EventDescription>
                        {showExpandButton && (
                            <ExpandButton isExpanded={isExpanded} onClick={setIsExpanded} />
                        )}
                        <EventDescriptionTitle>{event.description.fi}</EventDescriptionTitle>
                        <p>{formatDate(event.date, locale)}</p>
                        {event.location && (
                            <EventDescriptionLocation>{event.location}</EventDescriptionLocation>
                        )}
                    </EventDescription>
                </div>
                <div>
                    {event.isPastEvent || !event.weblink ? null : (
                        <Button onClick={() => window.open(event.weblink || "", "_blank")}>
                            {translate("customPage.eventsAndWebinars.participate")}
                        </Button>
                    )}
                </div>
            </EventBlockContainer>
            {isExpanded && (
                <ExpandedEventBlock>
                    {event.details?.fi || ""}
                    <FileList>
                        {event.recordingUrl && (
                            <LinkContainer>
                                <IconLink
                                    label={translate("customPage.eventsAndWebinars.recording")}
                                    src={download}
                                    href={event.recordingUrl}
                                    openInNewTab={true}
                                />
                            </LinkContainer>
                        )}
                        {event.documents.map((document) => (
                            <LinkContainer>
                                <IconLink
                                    label={document.title}
                                    src={download}
                                    href={`${config.documents.endpoints.file}${document.mFilesDocument.file.id}`}
                                    download={true}
                                />
                            </LinkContainer>
                        ))}
                    </FileList>
                </ExpandedEventBlock>
            )}
        </Container>
    );
};

export default EventBlock;
