import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";
import { buttonStyles } from "../index";
import styled from "styled-components";

interface Props {
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    className?: string;
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    transparent?: boolean;
    // The class to apply when the button is disabled.
    // If not set, then the default disabled style is used.
    disabledClassName?: string;
    title?: string;
    submit?: boolean;
    ariaExpanded?: boolean;
    ariaPressed?: boolean;
    ariaLabel?: string;
    ariaDescribedBy?: string;
}

const StyledButton = styled.button<{
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    transparent?: boolean;
    disabled?: boolean;
}>`
    ${(props) =>
        (props.primary && buttonStyles.primary) ||
        (props.secondary && buttonStyles.secondary) ||
        (props.tertiary && buttonStyles.tertiary) ||
        (props.transparent && buttonStyles.transparent) ||
        buttonStyles.primary}
    ${(props) => props.disabled && buttonStyles.disabledButton}
`;

function Button({
    onClick,
    disabled,
    title,
    primary,
    secondary,
    tertiary,
    transparent,
    submit,
    ariaExpanded,
    ariaPressed,
    ariaLabel,
    ariaDescribedBy,
    children,
}: PropsWithChildren<Props>): ReactElement {
    function click(event: React.MouseEvent) {
        if (!disabled && onClick) {
            onClick(event);
        }
    }

    return (
        <StyledButton
            onClick={click}
            primary={primary}
            secondary={secondary}
            tertiary={tertiary}
            transparent={transparent}
            title={title}
            type={submit ? "submit" : "button"}
            disabled={disabled}
            aria-expanded={ariaExpanded}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedBy}
            aria-pressed={ariaPressed}
        >
            {children}
        </StyledButton>
    );
}

export default Button;
