import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import without from "lodash/without";
import usePrevious from "hooks/common/usePrevious/usePrevious";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import { NewsFeedItem } from "interfaces/content";
import { loadingSelectorFactory } from "state/common/loading";
import { createNewsFeedItem, updateNewsFeedItem } from "state/news";
import { doneSelectorFactory } from "state/common/done";
import NewsFeedEditorTags from "./NewsFeedEditorTags/NewsFeedEditorTags";
import { successToast } from "components/common/notification/success-notification";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { getNewsTags } from "state/content/contentful/selectors";
import LoadingState from "../common/LoadingStateContainer";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../common/ui/buttons/Button/Button";
import Checkbox from "../../common/ui/Checkbox/Checkbox";
import { Col, Row } from "../../common/ui/FlexboxGrid";

const FINGRID_DATAHUB_ID = "2745543-5";
const FAULT_NOTIFICATION_TAG_NAME = "Fault notification";

const loadingSelector = loadingSelectorFactory([createNewsFeedItem, updateNewsFeedItem]);

const createNewsFeedItemDone = doneSelectorFactory(createNewsFeedItem);
const updateNewsFeedItemDone = doneSelectorFactory(updateNewsFeedItem);

const mapState = (state: RootState) => ({
    selectedOrganizationId: state.common.user.selectedOrganizationId,
    isLoading: loadingSelector(state),
    isCreateNewsFeedItemDone: createNewsFeedItemDone(state),
    isUpdateNewsFeedItemDone: updateNewsFeedItemDone(state),
    tags: getNewsTags(state),
});

interface Props {
    newsFeedItem?: NewsFeedItem;
    onSave?: () => void;
}

const toolbarConfig: any = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [{ label: "Bold", style: "BOLD" }],
    BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
    ],
    LINK_BUTTONS: [
        { label: "LINK", style: "link" },
        { label: "REMOVE_LINK", style: "remove-link" },
    ],
};

const StyledButton = styled(Button)`
    max-height: 36px;
    padding-top: ${styles.spacing[2]};
    padding-bottom: ${styles.spacing[2]};
    line-height: 1;
`;

const SubmitRow = styled(Row)`
    max-width: 100%;
    background-color: ${styles.colors.grey3};
    margin: 0;
    border: 1px solid ${styles.colors.grey4};
`;

const EditorContainer = styled.div`
    position: relative;

    > * {
        font-family: "Lab Grotesque" !important;
        color: ${styles.colors.grey7};
    }
`;

const Editor = styled(RichTextEditor)`
    max-width: 100%;
    border-top: 1px solid ${styles.colors.grey4} !important;
    border-right: 1px solid ${styles.colors.grey4} !important;
    border-left: 1px solid ${styles.colors.grey4} !important;
    border-bottom: none !important;

    .public-DraftEditor-content {
        min-height: 52px; /* height of two text lines */
    }

    button {
        border: 1px solid ${styles.colors.grey4} !important;
    }
`;

function NewsFeedEditor({ newsFeedItem, onSave }: Props): ReactElement {
    const dispatch = useDispatch();
    const {
        isLoading,
        selectedOrganizationId,
        isCreateNewsFeedItemDone,
        isUpdateNewsFeedItemDone,
        tags,
    } = useSelector(mapState, shallowEqual);

    const canPostFaultNotifications = selectedOrganizationId === FINGRID_DATAHUB_ID;

    const filteredTags =
        tags &&
        tags.filter(
            (tag) => !(!canPostFaultNotifications && tag.label.en === FAULT_NOTIFICATION_TAG_NAME)
        );
    const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
    const [isActive, setIsActive] = useState(false);

    const prevIsCreateNewsFeedItemDone = usePrevious(isCreateNewsFeedItemDone).current;
    const prevIsUpdateNewsFeedItemDone = usePrevious(isUpdateNewsFeedItemDone).current;

    const onSetActive = () => {
        setIsActive(!isActive);
    };

    const onTagSelect = useCallback(
        (selectedTagId: string) => {
            const isSelectedTag = (tagId: string) => selectedTagIds.some((id) => id === tagId);

            setSelectedTagIds((tagIds) => {
                if (isSelectedTag(selectedTagId)) {
                    return without(tagIds, selectedTagId);
                }

                return [...tagIds, selectedTagId];
            });
        },
        [selectedTagIds]
    );

    useEffect(() => {
        if (prevIsCreateNewsFeedItemDone === false && isCreateNewsFeedItemDone) {
            setEditorState(RichTextEditor.createEmptyValue());
            successToast("info.newsfeed.create.success");
        }
    }, [setEditorState, isCreateNewsFeedItemDone, prevIsCreateNewsFeedItemDone]);

    useEffect(() => {
        if (prevIsUpdateNewsFeedItemDone === false && isUpdateNewsFeedItemDone) {
            setEditorState(RichTextEditor.createEmptyValue());

            if (onSave) {
                onSave();
                successToast("info.newsfeed.update.success");
            }
        }
    }, [setEditorState, isUpdateNewsFeedItemDone, prevIsUpdateNewsFeedItemDone, onSave]);

    useEffect(() => {
        if (newsFeedItem) {
            setEditorState(
                RichTextEditor.createValueFromString(
                    documentToHtmlString(newsFeedItem?.content.fi),
                    "html"
                )
            );
            setIsActive(newsFeedItem.isActive || false);

            if (newsFeedItem.newsFeedTags && newsFeedItem.newsFeedTags.length > 0) {
                setSelectedTagIds(newsFeedItem?.newsFeedTags.map((tag) => tag.id));
            }
        }
    }, [newsFeedItem]);

    const onClick = (event: MouseEvent) => {
        event.preventDefault();

        if (!isLoading && selectedOrganizationId) {
            const markdown = editorState.toString("markdown");
            dispatch(
                createNewsFeedItem.started({
                    newsFeedItem: {
                        content: { fi: markdown, en: markdown },
                        tagIds: selectedTagIds,
                        isActive,
                    },
                    customerId: selectedOrganizationId,
                })
            );
        }
    };

    const onClickUpdate = (event: MouseEvent) => {
        event.preventDefault();

        if (!isLoading && selectedOrganizationId) {
            const markdown = editorState.toString("markdown");
            dispatch(
                updateNewsFeedItem.started(
                    {
                        newsFeedItem: {
                            content: { fi: markdown, en: markdown },
                            tagIds: selectedTagIds,
                            isActive,
                        },
                        customerId: selectedOrganizationId,
                    },
                    { id: newsFeedItem!.id }
                )
            );
        }
    };

    const submitButton =
        newsFeedItem !== undefined ? (
            <StyledButton primary disabled={isLoading} onClick={onClickUpdate}>
                <FormattedMessage id="common.save" />
            </StyledButton>
        ) : (
            <StyledButton primary disabled={isLoading} onClick={onClick}>
                <FormattedMessage id="newsfeed.submit" />
            </StyledButton>
        );

    return (
        <EditorContainer>
            <Editor toolbarConfig={toolbarConfig} value={editorState} onChange={setEditorState} />

            <SubmitRow between={"xs"} middle={"xs"}>
                {filteredTags && (
                    <NewsFeedEditorTags
                        tags={filteredTags}
                        onTagSelect={onTagSelect}
                        selectedTagIds={selectedTagIds}
                    />
                )}
                <Col>
                    {canPostFaultNotifications && (
                        <Checkbox
                            checked={isActive}
                            label={<FormattedMessage id="common.setActive" />}
                            onClick={onSetActive}
                        />
                    )}
                    {submitButton}
                </Col>
            </SubmitRow>
            <LoadingState isLoading={!!isLoading} />
        </EditorContainer>
    );
}

export default NewsFeedEditor;
