import React from "react";
import { MediaContentImageItem } from "interfaces/content";
import styled from "styled-components";

interface Props {
    item: MediaContentImageItem;
}

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
`;

const MediaContentImage = ({ item }: Props) => (
    <Container>
        <Image src={item.image} />
    </Container>
);

export default MediaContentImage;
