import React from "react";
import expand from "images/expand.svg";
import styled from "styled-components";

interface Props {
    onClick: (isExpanded: boolean) => void;
    isExpanded: boolean;
}

const Button = styled.input<{ isExpanded: boolean }>`
    transition: all 0.2s;
    transform: ${(props) => (props.isExpanded ? "rotate(90deg)" : "rotate(0)")};
`;

const ExpandButton = ({ onClick, isExpanded }: Props) => (
    <Button
        isExpanded={isExpanded}
        type="image"
        src={expand}
        onClick={() => onClick(!isExpanded)}
    />
);

export default ExpandButton;
