import styles from "styles/styles";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Button from "../buttons/Button/Button";
import { Col, Row } from "../FlexboxGrid";

interface Props {
    isSaveEnabled: boolean;
    isSaveInProgress: boolean;
    onCancel: () => void;
}

const ButtonRow = styled.div`
    display: flex;
    padding: ${styles.spacing[3]} 0;
`;

function FormButtonRow({ isSaveEnabled, isSaveInProgress, onCancel }: Props) {
    return (
        <Row>
            <Col md={12}>
                <ButtonRow>
                    <Button submit={true} disabled={!isSaveEnabled || isSaveInProgress}>
                        {isSaveInProgress ? (
                            <FormattedMessage id="common.saving" />
                        ) : (
                            <FormattedMessage id="common.save" />
                        )}
                    </Button>
                    <Button transparent onClick={onCancel} submit={false}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </ButtonRow>
            </Col>
        </Row>
    );
}

export default FormButtonRow;
