import * as React from "react";
import styled, { css } from "styled-components";
import styles from "../../../../styles/styles";
import DropdownToggle from "./DropdownToggle";

interface Props {
    renderButtonContent: () => React.ReactElement<unknown>;
    position?: "left" | "right";
    renderContent: () => React.ReactElement<unknown>;
    isOpen: boolean;
    toggle: (isOpen: boolean) => void;
    contentClassName?: string;
    toggleButtonClassName?: string;
}

const dropdown = css`
    position: relative;
    @media only screen and (max-width: 500px) {
        position: unset;
    }
`;

const menu = css`
    position: absolute;
    background-color: ${styles.colors.white};
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
    padding: 10px 0;
    min-width: 400px;
    margin-top: 10px;
    z-index: 2000;
    animation: slide-up 0.1s ease-out;
    max-height: 280px;
    overflow: auto;

    @media only screen and (max-width: 500px) {
        min-width: unset;
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
    }
    @keyframes slide-up {
        0% {
            opacity: 0;
            transform: translateY(25px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const StyledDropdown = styled.div`
    ${dropdown}
`;

const StyledMenu = styled.div<{ position: "left" | "right" }>`
    ${menu}
    ${(props) => (props.position === "left" ? `left: 0` : `right: 0`)};
`;

class Dropdown extends React.Component<Props> {
    private wrapper: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.wrapper = React.createRef();
    }

    componentDidMount() {
        this.handlePropsChanged();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.handlePropsChanged();
        }
    }

    componentWillUnmount() {
        this.removeEvents();
    }

    handlePropsChanged() {
        if (this.props.isOpen) {
            this.addEvents();
        } else {
            this.removeEvents();
        }
    }

    addEvents() {
        ["click", "touchstart", "keyup"].forEach((event) => {
            document.addEventListener(event, this.handleOutsideClick, true);
        });
    }

    removeEvents() {
        ["click", "touchstart", "keyup"].forEach((event) => {
            document.removeEventListener(event, this.handleOutsideClick, true);
        });
    }

    handleOutsideClick = (event: Event) => {
        if (
            event.target &&
            this.wrapper.current &&
            !this.wrapper.current.contains(event.target as Node)
        ) {
            this.props.toggle(false);
        }
    };

    toggle = () => {
        this.props.toggle(!this.props.isOpen);
    };

    public render() {
        return (
            <StyledDropdown ref={this.wrapper}>
                <DropdownToggle onClick={this.toggle} ariaExpanded={this.props.isOpen}>
                    {this.props.renderButtonContent()}
                </DropdownToggle>
                {this.props.isOpen && (
                    <StyledMenu position={this.props.position || "right"}>
                        {this.props.renderContent()}
                    </StyledMenu>
                )}
            </StyledDropdown>
        );
    }
}

export default Dropdown;
