import React, { useState, useMemo } from "react";

import DynamicContent from "components/content/DynamicContent/DynamicContent";
import { getDocumentTags } from "services/file-search/file-search";
import { SubPage, Tag } from "interfaces/content";
import FileSearchCard from "../FileSearchCard/FileSearchCard";
import FileSearchResults from "../FileSearchResults/FileSearchResults";

interface Props {
    activeSubPage: SubPage;
}

const DynamicContentWithFileSearch = ({ activeSubPage }: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [tagFilters, setTagFilters] = useState<Tag[]>([]);

    const allDocuments = activeSubPage.subPageDocuments;

    const documentTags = useMemo(
        () => (allDocuments ? getDocumentTags(allDocuments) : []),
        [allDocuments]
    );

    const showFileSearchResults = tagFilters.length || searchTerm.length;

    if (!allDocuments) {
        return <DynamicContent activeSubPage={activeSubPage} />;
    }

    return (
        <div>
            <FileSearchCard
                onTagChange={setTagFilters}
                onSearchTermChange={setSearchTerm}
                allTags={documentTags}
                selectedTags={tagFilters}
                searchTerm={searchTerm}
            />
            {showFileSearchResults ? (
                <FileSearchResults
                    tagFilters={tagFilters}
                    searchTerm={searchTerm}
                    allDocuments={allDocuments}
                />
            ) : (
                // shows dynamic content when no file search filters are applied.
                <DynamicContent activeSubPage={activeSubPage} />
            )}
        </div>
    );
};

export default DynamicContentWithFileSearch;
