import { getLocation } from "connected-react-router";

import { RootState } from "state/reducers";
import { BLOG_PAGE_NUM_QUERY_PARAM } from "services/common/url/url";

export const getNavigationTabs = (state: RootState) =>
    state.content.contentful.commonContent
        ? state.content.contentful.commonContent.navigationTabs
        : null;

export const getTags = (state: RootState) =>
    state.content.contentful.commonContent ? state.content.contentful.commonContent.tags : null;

export const getAnnouncement = (state: RootState) =>
    state.content.contentful.commonContent && state.content.contentful.commonContent.announcement;

export const getNewsTags = (state: RootState) =>
    state.content.contentful.commonContent
        ? state.content.contentful.commonContent.tags.filter((tag) => tag.tagType === "NewsTag")
        : [];

export const getCurrentPageNumber = (state: RootState) =>
    new URLSearchParams(getLocation(state).search).get(BLOG_PAGE_NUM_QUERY_PARAM) || "1";
