import React, { ReactElement, useState } from "react";
import { ActionStep as ActionStepType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import { FormattedTime, FormattedMessage, FormattedDate } from "react-intl";
import ExpandButton from "components/common/ui/ExpandButton/ExpandButton";
import styled from "styled-components";
import styles from "styles/styles";
import RichText from "../RichText/RichText";
import { StatusBadge } from "../../common/ui/StatusBadge/StatusBadge";

export interface Props {
    step: ActionStepType;
    index: number;
}

const Container = styled.div`
    margin-bottom: ${styles.spacing[3]};
    display: grid;
    grid-template-columns: 90px 50px auto;
    align-items: center;
    column-gap: ${styles.spacing[1]};
`;

const Title = styled.h6`
    margin: 0;
`;

const TitleContent = styled.div`
    width: 100%;
    margin-left: 10px;
`;

const UserStepDate = styled.div`
    font-size: 0.8em;
    color: ${styles.colors.grey5};
    grid-column-start: 1;
    display: flex;
    align-items: center;
    justify-self: stretch;
    justify-content: center;
    align-self: stretch;
    padding: ${styles.spacing[3]} 0;
    background-color: #fff;
`;

const UserStepTime = styled(UserStepDate)`
    grid-column-start: 2;
`;

const DatahubStepDate = styled(UserStepDate)`
    background-color: ${styles.colors.grey3};
`;

const DatahubStepTime = styled(UserStepTime)`
    background-color: ${styles.colors.grey3};
`;

const ActionStatusContainer = styled.div``;

const Status = styled.div`
    font-size: 0.8em;
    display: flex;
    justify-content: right;
`;

const UserStepTitleContainer = styled.div`
    cursor: pointer;
    vertical-align: top;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column-start: 3;
    background-color: #fff;
    padding: ${styles.spacing[3]};
`;

const DatahubStepTitleContainer = styled(UserStepTitleContainer)`
    background-color: ${styles.colors.grey3};
`;

const UserStepTitleContainerWithoutDate = styled(UserStepTitleContainer)`
    grid-column-start: 1;
    grid-column-end: 4;
`;

const DatahubStepTitleContainerWithoutDate = styled(DatahubStepTitleContainer)`
    grid-column-start: 1;
    grid-column-end: 4;
`;

const TitleAndAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserAction = styled.div`
    text-transform: uppercase;
    color: ${styles.colors.red};
    font-size: 0.6em;
    min-width: 130px;
    text-align: right;
`;

const DatahubAction = styled(UserAction)`
    color: ${styles.colors.grey6};
`;

const UserStepContent = styled.div`
    grid-column-start: 3;
    background-color: #fff;
    padding: 0 ${styles.spacing[3]};
`;

const DatahubStepContent = styled(UserStepContent)`
    background-color: ${styles.colors.grey3};
`;

const UserStepContentWithoutDate = styled(UserStepContent)`
    grid-column-start: 1;
    grid-column-end: 4;
`;

const DatahubStepContentWithoutDate = styled(DatahubStepContent)`
    grid-column-start: 1;
    grid-column-end: 4;
`;

function ActionStep({ step, index }: Props): ReactElement {
    const locale = useLocale();
    const [isExpanded, setIsExpanded] = useState(false);

    const TitleContainer =
        step.action && step.action.fi === "datahub"
            ? step.datetime
                ? DatahubStepTitleContainer
                : DatahubStepTitleContainerWithoutDate
            : step.datetime
            ? UserStepTitleContainer
            : UserStepTitleContainerWithoutDate;
    const Content =
        step.action && step.action.fi === "datahub"
            ? step.datetime
                ? DatahubStepContent
                : DatahubStepContentWithoutDate
            : step.datetime
            ? UserStepContent
            : UserStepContentWithoutDate;

    const Date = step.action && step.action.fi === "datahub" ? DatahubStepDate : UserStepDate;

    const Time = step.action && step.action.fi === "datahub" ? DatahubStepTime : UserStepTime;

    const Action = step.action && step.action.fi === "datahub" ? DatahubAction : UserAction;

    return (
        <Container key={index}>
            {step.datetime && (
                <>
                    <Date>
                        <FormattedDate value={step.datetime} />
                    </Date>
                    <Time>
                        <FormattedTime value={step.datetime} />
                    </Time>
                </>
            )}
            <TitleContainer onClick={() => setIsExpanded(!isExpanded)}>
                <ExpandButton isExpanded={isExpanded} onClick={setIsExpanded} />
                <TitleContent>
                    <TitleAndAction>
                        <Title>
                            {index + 1}. {step.title[locale]}
                        </Title>
                        <ActionStatusContainer>
                            {step.status && (
                                <Status>
                                    <StatusBadge status={step.status} showLabel={true} />
                                </Status>
                            )}
                            {step.action && (
                                <Action>
                                    {step.action.fi === "client" ? (
                                        <FormattedMessage id="common.yourAction" />
                                    ) : (
                                        <FormattedMessage id="common.datahubAction" />
                                    )}
                                </Action>
                            )}
                        </ActionStatusContainer>
                    </TitleAndAction>
                </TitleContent>
            </TitleContainer>
            {isExpanded && (
                <Content>
                    <RichText richText={step.description} />
                </Content>
            )}
        </Container>
    );
}

export default ActionStep;
