import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import UserOrganizationModal from "components/user/UserOrganizationModal/UserOrganizationModal";
import { getUrlSegment, UrlSegmentIndex, isValidPageUrl } from "services/common/url/url";
import { contentfulActivePageLoadingSelector } from "state/common/loading";
import { getNavigationTabs } from "state/content/contentful/selectors";
import { fetchActivePage } from "state/content/contentful";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import Main from "../Main/Main";

const loadingSelector = contentfulActivePageLoadingSelector;

const mapState = (state: RootState) => ({
    pageTabs: getNavigationTabs(state),
    activePage: state.content.contentful.activePage,
    isLoading: loadingSelector(state),
});

const MainContainer = (): ReactElement => {
    const { pageTabs, activePage, isLoading } = useSelector(mapState, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const pageUrlSegment = getUrlSegment(location.pathname, UrlSegmentIndex.Page);

        const isValidUrlSegment = isValidPageUrl(pageUrlSegment, pageTabs);

        if (isValidUrlSegment) {
            // Fetches a page only when the url-segment of a page is changed.
            const pageChanged = activePage ? activePage.urlSegment !== pageUrlSegment : true;

            if (pageChanged) {
                dispatch(fetchActivePage.started(pageUrlSegment));
            }
        }
    }, [activePage, dispatch, location.pathname, pageTabs, isLoading]);

    return (
        <>
            <UserOrganizationModal />
            <Main isLoading={isLoading} pageTabs={pageTabs}></Main>
        </>
    );
};

export default MainContainer;
