import { ContactDetails, ContactType } from "interfaces/contact-info";
import { getCreateContactDetails as getCreateConnectingContactDetails } from "services/contact-info/connecting";
import { getCreateContactDetails as getCreateInformationExchangeContactDetails } from "services/contact-info/info-exchange";
import { doneSelectorFactory } from "state/common/done";
import { loadingSelectorFactory } from "state/common/loading";
import { createConnectingContact, createInformationExchangeContact } from "state/contact-info";
import ConnectingForm from "../ConnectingForm/ConnectingForm";
import InfoExchangeForm from "../InfoExchangeForm/InfoExchangeForm";
import { Props } from "./AddNewContact";

// Information exchange selectors
const createInformationExchangeLoadingSelector = loadingSelectorFactory([
    createInformationExchangeContact,
]);
const createInformationExchangeDoneSelector = doneSelectorFactory(createInformationExchangeContact);

// Connecting selectors
const createConnectingLoadingSelector = loadingSelectorFactory([createConnectingContact]);
const createConnetingDoneSelector = doneSelectorFactory(createConnectingContact);

type AddNewProps = { [K in ContactType]: Props<ContactDetails> };

export const addNewProps: AddNewProps = {
    informationExchange: {
        createLoadingSelector: createInformationExchangeLoadingSelector,
        createDoneSelector: createInformationExchangeDoneSelector,
        createAction: createInformationExchangeContact,
        getDetails: getCreateInformationExchangeContactDetails,
        formComponent: InfoExchangeForm,
    },
    connecting: {
        createLoadingSelector: createConnectingLoadingSelector,
        createDoneSelector: createConnetingDoneSelector,
        createAction: createConnectingContact,
        getDetails: getCreateConnectingContactDetails,
        formComponent: ConnectingForm,
    },
};
