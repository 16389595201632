import React from "react";
import errorIcon from "../../../../images/attention-red.svg";
import styled from "styled-components";
import styles from "../../../../styles/styles";

const StyledErrorMessage = styled.div`
    color: ${styles.colors.red};
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
`;

const StyledErrorImage = styled.img`
    padding-right: 4px;
`;

const ErrorMessage = ({ message, id }: { message: string; id: string }) => (
    <StyledErrorMessage id={id}>
        <StyledErrorImage src={errorIcon} alt="" />
        <span>{message}</span>
    </StyledErrorMessage>
);

export default ErrorMessage;
