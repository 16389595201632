import React, { useEffect } from "react";
import CustomPageContainer from "../CustomPageContainer";
import { CustomPage } from "interfaces/content";
import { getLocalizedString } from "components/utilities";
import useLocale from "hooks/common/useLocale/useLocale";
import Environment from "./Environment";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestEnvironments } from "state/test-environments/actions";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import styled from "styled-components";
import styles from "styles/styles";
import RichText from "../../RichText/RichText";
import { Col, Row } from "components/common/ui/FlexboxGrid";

const mapState = (state: RootState) => ({
    testEnvironments: state.testEnvironments.testEnvironments,
    user: state.oidc.user,
});

const EnvironmentCol = styled(Col)`
    margin-bottom: ${styles.spacing[4]};
`;

const TestEnvironments: React.FC<{
    customPage: CustomPage;
}> = ({ customPage }) => {
    const locale = useLocale();
    const { testEnvironments, user } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();
    const authenticated = !!user;

    useEffect(() => {
        dispatch(fetchTestEnvironments.started({ authenticated }));
    }, [dispatch, authenticated]);

    return (
        <CustomPageContainer tabs={[]}>
            <h3>{getLocalizedString(locale, customPage.pageHeader)}</h3>
            <Row>
                <Col md={12} lg={9}>
                    {customPage.pageDesc && customPage.pageDesc[locale] && (
                        <RichText richText={customPage.pageDesc} />
                    )}
                </Col>
            </Row>
            <Row>
                {testEnvironments.map((environment, idx) => (
                    <EnvironmentCol key={idx} sm={12} md={6}>
                        <Environment environment={environment} />
                    </EnvironmentCol>
                ))}
            </Row>
        </CustomPageContainer>
    );
};

export default TestEnvironments;
