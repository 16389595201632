import useTranslate, { Translate } from "hooks/common/useTranslate/useTranslate";
import React from "react";
import remove from "../../images/remove.png";
import { FaultNotificationContact, Organization } from "interfaces/common";
import Select from "react-select";
import { validateEmail } from "../../utils/validate";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../components/common/ui/buttons/Button/Button";
import TextInput from "../../components/common/ui/TextInput/TextInput";
import { Col, Row } from "../../components/common/ui/FlexboxGrid";

interface Option {
    value: string;
    label: string;
}

const Label = styled.span`
    font-size: ${styles.fontSize.small};
    white-space: nowrap;
`;

const RemoveButtonContainer = styled(Col)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`;

const RemoveButton = styled.img`
    width: 28px;
    height: 28px;
`;

const getContactMethodOptions = (t: Translate): Option[] => [
    { value: "email", label: t("faultNotificationContacts.contactType.email") },
    { value: "sms", label: t("faultNotificationContacts.contactType.sms") },
    { value: "email,sms", label: t("faultNotificationContacts.contactType.email,sms") },
];

const getContactMethodValue = (contact: FaultNotificationContact, t: Translate) => {
    const value = [
        contact.contactEmail ? "email" : undefined,
        contact.contactSms ? "sms" : undefined,
    ]
        .filter(Boolean)
        .join(",");

    return getContactMethodOptions(t).find((option) => option.value === value);
};

const mapContactMethodOption = (
    contact: FaultNotificationContact,
    option: Option
): FaultNotificationContact => ({
    ...contact,
    contactEmail: option.value.includes("email"),
    contactSms: option.value.includes("sms"),
});

interface Props {
    contact: FaultNotificationContact;
    onChange: (newContact: FaultNotificationContact) => void;
    onRemove: () => void;
    organizations: Organization[];
}

const ContactRow = ({ contact, onChange, onRemove, organizations }: Props) => {
    const t = useTranslate();

    const organizationName = organizations.find(
        (organization) => organization.businessId === contact.businessId
    )?.name;

    return (
        <Row>
            <Col sm={4} md={3}>
                <Label>{t("faultNotificationContacts.fields.name")}</Label>
                <TextInput
                    required={true}
                    value={contact.name}
                    onChange={(value) => onChange({ ...contact, name: value })}
                />
            </Col>
            <Col sm={4} md={2}>
                <Label>{t("faultNotificationContacts.fields.phone")}</Label>
                <TextInput
                    required={contact.contactSms}
                    value={contact.phone}
                    onChange={(value) => onChange({ ...contact, phone: value })}
                />
            </Col>
            <Col sm={4} md={2}>
                <Label>{t("faultNotificationContacts.fields.email")}</Label>
                <TextInput
                    required={true}
                    value={contact.email}
                    onChange={(value) => onChange({ ...contact, email: value })}
                    invalid={!validateEmail(contact.email)}
                />
            </Col>
            <Col sm={4} md={2}>
                <Label>{t("faultNotificationContacts.fields.company")}</Label>
                <TextInput
                    required={true}
                    value={organizationName || contact.businessId}
                    onChange={() => undefined}
                    disabled
                />
            </Col>
            <Col sm={4} md={2}>
                <Label>{t("faultNotificationContacts.fields.contactType")}</Label>
                <Select
                    value={getContactMethodValue(contact, t)}
                    onChange={(newValue) =>
                        newValue && onChange(mapContactMethodOption(contact, newValue))
                    }
                    options={getContactMethodOptions(t)}
                />
            </Col>
            <RemoveButtonContainer sm={4} md={1}>
                <Button tertiary onClick={onRemove}>
                    <RemoveButton src={remove} />
                </Button>
            </RemoveButtonContainer>
        </Row>
    );
};

export default ContactRow;
