import { isType } from "typescript-fsa";
import { AnyAction } from "redux";
import { dismissAnnouncement } from "./actions";
import { Announcement } from "interfaces/content";

export interface AnnouncementsState {
    dismissedAnnouncement: Announcement["id"] | null;
}

const DISMISSED_ANNOUNCEMENT_LOCAL_STORAGE_KEY = "dismissedAnnouncement";

const initialState: AnnouncementsState = {
    dismissedAnnouncement: localStorage.getItem(DISMISSED_ANNOUNCEMENT_LOCAL_STORAGE_KEY),
};

export default function reducer(state = initialState, action: AnyAction): AnnouncementsState {
    if (isType(action, dismissAnnouncement)) {
        localStorage.setItem(DISMISSED_ANNOUNCEMENT_LOCAL_STORAGE_KEY, action.payload);

        return {
            ...state,
            dismissedAnnouncement: action.payload,
        };
    }

    return state;
}
