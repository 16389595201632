import React, { ReactElement, useCallback } from "react";
import { match, useRouteMatch, useLocation } from "react-router-dom";
import { NavigationTabBase, SubNavigationTab } from "interfaces/navigation";
import { Location } from "history";
import { getUrlSegment, UrlSegmentIndex } from "services/common/url/url";
import useLocale from "hooks/common/useLocale/useLocale";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import NavLinkAdapter from "components/content/NavLinkAdapter/NavLinkAdapter";

interface Props {
    tab: NavigationTabBase;
    isSubTab?: boolean;
    exact?: boolean;
}

const getAriaCurrent = (isActive: boolean, isSubTab?: boolean) =>
    isActive ? (isSubTab ? "page" : "true") : undefined;

const isHiddenTab = (tab: NavigationTabBase): boolean => (tab as SubNavigationTab).isHidden;

const tabCss = css`
    align-items: center;
    display: flex;
    font-size: ${styles.fontSize.medium};
    text-align: center;
`;

const Tab = styled.div`
    ${tabCss}
    font-weight: 500;
    padding: ${styles.spacing[4]};
    margin-bottom: -2px;

    ::after {
        content: "";
        min-height: inherit;
        font-size: 0;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        text-align: left;
        padding: ${styles.spacing[2]};
        min-height: auto;
    }
`;

const SubTab = styled.div`
    ${tabCss}
    color: ${styles.colors.grey7};
    padding: ${styles.spacing[3]} ${styles.spacing[4]};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        text-align: left;
        padding: ${styles.spacing[2]};
        margin: 0;
    }
`;

const navLinkStyle = css`
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.medium};
    text-decoration: none;
`;

const StyledSubNavLink = styled(NavLinkAdapter)`
    &__className {
        ${navLinkStyle}
        :hover {
            ${navLinkStyle}
        }
    }
    &__activeClassName {
        color: ${styles.colors.grey7};

        .${SubTab.styledComponentId} {
            background-color: ${styles.colors.grey7};
            color: ${styles.colors.white};
        }

        .${Tab.styledComponentId} {
            background-color: ${styles.colors.grey4};
            font-weight: bold;
        }
    }
`;

function NavigationTab({ tab, isSubTab, exact }: Props): ReactElement | null {
    const locale = useLocale();
    const match = useRouteMatch();
    const location = useLocation();
    const Component = isSubTab ? SubTab : Tab;

    const isActive = useCallback(
        (match: match | null, location: Location) =>
            exact
                ? Boolean(match)
                : getUrlSegment(
                      location.pathname,
                      isSubTab ? UrlSegmentIndex.SubPage : UrlSegmentIndex.Page
                  ) === tab.urlSegment,
        [exact, isSubTab, tab.urlSegment]
    );

    if (isHiddenTab(tab)) {
        return null;
    }

    return (
        <StyledSubNavLink
            key={tab.path}
            exact={exact}
            isActive={isActive}
            to={`/${locale}${tab.path}`}
            aria-current={getAriaCurrent(isActive(match, location), isSubTab)}
        >
            <Component>{tab.label[locale]}</Component>
        </StyledSubNavLink>
    );
}

export default NavigationTab;
