import { getLocation } from "connected-react-router";
import { RootState } from "state/reducers";
import {
    SEARCH_SEARCH_TERM_QUERY_PARAM,
    SEARCH_PAGE_NUM_QUERY_PARAM,
} from "services/common/url/url";
import { DEFAULT_PAGE_QUERY_PARAM } from "./epics";

export const getCurrentSearchTerm = (state: RootState) =>
    new URLSearchParams(getLocation(state).search).get(SEARCH_SEARCH_TERM_QUERY_PARAM) || "";

export const getCurrentPageNumber = (state: RootState) =>
    new URLSearchParams(getLocation(state).search).get(SEARCH_PAGE_NUM_QUERY_PARAM) ||
    DEFAULT_PAGE_QUERY_PARAM;
