import styles from "styles/styles";
import styled from "styled-components";

const ContentContainer = styled.div`
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.layout.contentPadding};
`;

export default ContentContainer;
