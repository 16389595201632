import React, { ReactElement, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Organization } from "interfaces/common";
import { logout } from "services/auth/auth";
import { selectUserOrganization } from "state/common/user";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import SelectAutoFill from "components/common/ui/SelectAutoFill/SelectAutoFill";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import { push } from "connected-react-router";
import useLocale from "hooks/common/useLocale/useLocale";
import { FAULT_NOTIFICATIONS_CONTACT_URL_SEGMENT } from "services/common/url/url";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../common/ui/buttons/Button/Button";

const mapState = (state: RootState) => ({
    user: state.oidc.user,
    organizations: state.common.user.organizations,
    selectedOrganizationId: state.common.user.selectedOrganizationId,
});

const getOrganizationOptions = (organizations: Organization[] | null) =>
    organizations
        ? organizations.map((organization) => ({
              value: organization.businessId,
              label: organization.name,
          }))
        : [];

interface Props {
    onCloseModal: () => void;
}

const Header = styled.h3`
    padding-left: ${styles.spacing[2]};
`;

const LogoutWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${styles.spacing[2]};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0;
`;

const SingleOrganization = styled.span`
    margin: 0 ${styles.spacing[2]} ${styles.spacing[4]} ${styles.spacing[2]};
    font-size: ${styles.fontSize.large};
`;

const UserOrganizationButton = styled(Button)`
    margin: ${styles.spacing[4]} 0 0 0;
    padding: 0;
`;

const UserOrganization = ({ onCloseModal }: Props): ReactElement => {
    const { user, organizations, selectedOrganizationId } = useSelector(mapState, shallowEqual);
    const translate = useTranslate();
    const dispatch = useDispatch();
    const locale = useLocale();

    const onOrganizationSelect = useCallback(
        (id: string) => {
            dispatch(selectUserOrganization(id));
        },
        [dispatch]
    );
    const isCloseButtonDisabled = !selectedOrganizationId;

    const options = useMemo(() => getOrganizationOptions(organizations), [organizations]);

    const onFaultNotificationContactsClick = useCallback(() => {
        dispatch(push(`/${locale}/${FAULT_NOTIFICATIONS_CONTACT_URL_SEGMENT}`));
        onCloseModal();
    }, [dispatch, locale, onCloseModal]);

    return (
        <>
            <Header>{user && user.profile.name}</Header>
            <Wrapper>
                {options.length > 1 ? (
                    <SelectAutoFill
                        placeholder={translate("user.chooseOrganization")}
                        options={options}
                        selectedValue={selectedOrganizationId}
                        onSelect={onOrganizationSelect}
                    />
                ) : options.length === 1 ? (
                    <SingleOrganization data-testid="single-organization">
                        {options[0].label}
                    </SingleOrganization>
                ) : (
                    <FormattedMessage data-id="no-organizations" id="user.noRights.message" />
                )}
            </Wrapper>
            <UserOrganizationButton tertiary onClick={onFaultNotificationContactsClick}>
                {translate("navigation.faultNotificationContacts")}
            </UserOrganizationButton>

            <LogoutWrapper>
                <UserOrganizationButton
                    primary
                    onClick={onCloseModal}
                    disabled={isCloseButtonDisabled}
                >
                    <FormattedMessage id="common.ok" />
                </UserOrganizationButton>
                <UserOrganizationButton tertiary onClick={logout}>
                    <FormattedMessage id="navigation.logout" />
                </UserOrganizationButton>
            </LogoutWrapper>
        </>
    );
};

export default UserOrganization;
