import * as React from "react";
import styled from "styled-components";
import styles from "../../../../styles/styles";

interface Props extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    placeholder?: string;
    ariaLabel?: string;
}

const StyledTextArea = styled.textarea`
    background-color: ${styles.colors.white};
    border: 1px solid ${styles.colors.grey6};
    box-sizing: border-box;
    color: ${styles.colors.grey7};
    font-family: "Lab Grotesque";
    font-size: 20px;
    height: 80px;
    padding: 11px 20px;
    outline: none;
    resize: none;
    margin: 10px 0;
    width: 100%;

    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }
`;

class TextArea extends React.Component<Props> {
    onChange = (event: React.SyntheticEvent) => {
        this.props.onChange((event.target as HTMLTextAreaElement).value);
    };

    public render() {
        const { value, onChange, className, placeholder, ariaLabel, ...standardProps } = this.props;

        return (
            <StyledTextArea
                {...standardProps}
                value={value}
                onChange={this.onChange}
                placeholder={placeholder || ""}
                aria-label={ariaLabel}
            />
        );
    }
}

export default TextArea;
