import userManager from "services/common/oidc/userManager";
import { RootState } from "state/reducers";
import { Authorization, AuthorizationRequirement } from "../../interfaces/authorization";

export const getIdToken = (state: RootState): string | null =>
    state.oidc.user ? state.oidc.user.id_token : null;

export const login = (redirectUrl = window.location.pathname) =>
    userManager.signinRedirect({
        data: {
            redirectUrl,
        },
    });

export const logout = () => userManager.signoutRedirect();

export const authorizationWildcard = "All";
const businessIdKey = "businessId";
const resourceIdKey = "resourceId";
const accessLevelKey = "accessLevel";

/**
 * Ensure that the authorizations contain at least one authorization matching the
 * required object. The required object can contain a subset of properties of the Authorization type.
 * If the required authorization is for a resource, a matching business id authorization will satisfy
 * the condition.
 * If the required authorization is not for a resource for a businessId, and the checked authorization
 * is for a resource, authorization is not granted.
 */
export const isAuthorized = <AuthService extends string, AuthFeature extends string>(
    authorizations: Authorization[],
    required: Partial<AuthorizationRequirement<AuthService, AuthFeature>>
) =>
    // If no valid authorization is found from user's all authorization, access is unauthorized
    Boolean(
        authorizations.find((authorization) => {
            const requiredKeys = Object.keys(required) as (keyof Authorization)[];
            const authorizedKeys = Object.keys(authorization);

            if (
                authorizedKeys.includes(resourceIdKey) &&
                requiredKeys.includes(businessIdKey) &&
                !requiredKeys.includes(resourceIdKey)
            ) {
                // The authorization is for a resource but the requirement is for business level.
                return false;
            }

            // Check every required property against the authorization
            return requiredKeys.every(
                (key) =>
                    // "ReadWrite" satisfies "Read".
                    (key === accessLevelKey && authorization.accessLevel === "ReadWrite") ||
                    // If the user's authorization for a given key is "All" (authorizationWildcard),
                    // we satisfy the condition. Otherwise the value must match exactly the required value
                    // (unless the required key is "resourceId" in which case matching "businessId" keys
                    // will also satisfy the condition, if the user's authorization is not for a resource).
                    // Note: if the _required_ value is "All" (authorizationWildcard),
                    // then the authorization value must be "All" (authorizationWildcard) also.
                    // Note: if the _required_ resourceId is "Any", then the user must have an authorization
                    // that contain the permission for any resource of the specified businessId.
                    [authorizationWildcard, required[key]].includes(authorization[key]) ||
                    (key === resourceIdKey &&
                        (required.resourceId === "Any" ||
                            (!authorization.resourceId &&
                                [authorizationWildcard, required.businessId].includes(
                                    authorization.businessId
                                ))))
            );
        })
    );
