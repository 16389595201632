import useTranslate from "hooks/common/useTranslate/useTranslate";
import { EnvironmentStatus } from "interfaces/test-environments";
import React from "react";
import { Text } from "./Environment";
import styled, { css } from "styled-components";
import styles from "styles/styles";

const Started = css`
    background-color: #008480;
`;

const Stopped = css`
    background-color: ${styles.colors.red};
`;

const Restarting = css`
    background-color: #f8f43f;
    color: #023;
`;

const getStyleByStatus = (status: EnvironmentStatus) => {
    if (status === "started") {
        return Started;
    } else if (status === "stopped") {
        return Stopped;
    } else if (status === "restarting") {
        return Restarting;
    }

    return ``;
};

const Container = styled.div<{ status: EnvironmentStatus }>`
    color: ${styles.colors.white};
    display: inline-block;
    white-space: nowrap;
    border-radius: 4px;
    height: 25px;
    padding: ${styles.spacing[1]};

    ${(props) => getStyleByStatus(props.status)}
`;
const Status = ({ status }: { status: EnvironmentStatus }) => {
    const translate = useTranslate();
    const translationKey = `testEnvironments.status.${status}`;

    const label = translate(translationKey);
    const translationIsFound = label !== translationKey;

    return translationIsFound ? (
        <Container status={status}>
            <Text>{label}</Text>
        </Container>
    ) : null;
};

export default Status;
