import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { QueryResult } from "interfaces/search";
import SearchResultItem from "../SearchResultItem/SearchResultItem";
import SearchFilters from "../SearchFilters/SearchFilters";
import styled from "styled-components";
import LoadingIndicator from "../../common/ui/LoadingIndicator/LoadingIndicator";
import { Col, Row } from "../../common/ui/FlexboxGrid";

export interface Props {
    searchTerm: string | null;
    searchResults: QueryResult | null;
    isLoading: boolean;
}

const Title = styled.h5`
    font-size: 20px;
    margin: 0;
    font-weight: inherit;
    line-height: inherit;
`;

function SearchResults({ searchTerm, searchResults, isLoading }: Props): ReactElement {
    return (
        <Row>
            <Col md={3} xs={12}>
                <Title>
                    <FormattedMessage id="common.filter" />
                </Title>
                <SearchFilters />
            </Col>
            <Col mdOffset={1} md={8} xs={12}>
                {isLoading ? (
                    <LoadingIndicator isLoading={isLoading} />
                ) : (
                    !!searchResults &&
                    !!searchTerm &&
                    searchTerm.length > 0 && (
                        <div>
                            <h2 className="h4">
                                <FormattedMessage
                                    id="search.result.title"
                                    values={{
                                        resultsTotalCount: searchResults.totalResultsCount,
                                        searchTerm,
                                    }}
                                />
                            </h2>
                            {searchResults.results.map((searchResultItem) => (
                                <SearchResultItem
                                    key={searchResultItem.id}
                                    searchResultItem={searchResultItem}
                                />
                            ))}
                        </div>
                    )
                )}
            </Col>
        </Row>
    );
}

export default SearchResults;
