import { combineReducers } from "redux";
import failed from "./failed";
import loading from "./loading";
import progress from "./progress";
import toast from "./toast";
import user from "./user";

const reducers = combineReducers({ loading, user, failed, toast, progress });

export default reducers;
