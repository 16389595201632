import React, { ReactElement, Suspense } from "react";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { NavigationTab } from "interfaces/navigation";
import NavigationBar from "../NavigationBar/NavigationBar";
import RouteContent from "../RouteContent/RouteContent";
import styled from "styled-components";
import LoadingIndicator from "../../ui/LoadingIndicator/LoadingIndicator";
import ScrollToTopButton from "../../ui/buttons/ScrollToTopButton/ScrollToTopButton";

interface Props {
    pageTabs: NavigationTab[] | null;
    isLoading: boolean;
}

const Container = styled.div`
    word-wrap: break-word;
`;

function Main({ isLoading, pageTabs }: Props): ReactElement {
    const translate = useTranslate();

    return (
        <Container>
            <NavigationBar tabs={pageTabs}></NavigationBar>
            <main>
                <Suspense fallback={<LoadingIndicator isLoading={isLoading} />}>
                    <RouteContent pageTabs={pageTabs} isLoading={isLoading} />
                </Suspense>
                <ScrollToTopButton arrowIconAlt={translate("common.up")} />
            </main>
        </Container>
    );
}

export default Main;
