import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import PageLoadingIndicator from "components/common/ui/PageLoadingIndicator/PageLoadingIndicator";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import BlogPagination from "components/blog/BlogPagination/BlogPagination";
import PageNotFound from "components/common/ui/PageNotFound/PageNotFound";
import BlogPost from "components/blog/BlogPost/BlogPost";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import { loadingSelectorFactory } from "state/common/loading";
import { fetchBlogList, updateBlogPageNumber } from "state/content/contentful";
import { getCurrentPageNumber } from "state/content/contentful/selectors";
import { shallowEqual } from "state/utilities";
import { RootState } from "state/reducers";
import Page from "components/common/layout/Page";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../../components/common/ui/FlexboxGrid";
import CustomPageContainer from "../../../components/content/CustomPageContent/CustomPageContainer";

const loadingSelector = loadingSelectorFactory([fetchBlogList]);

const mapState = (state: RootState) => ({
    blog: state.content.contentful.blog,
    pageNumber: getCurrentPageNumber(state),
    isLoading: loadingSelector(state),
});

const Title = styled.h3``;

const BlogPostContainer = styled.div`
    padding-top: ${styles.spacing[4]};
`;

export const Circle = styled.div`
    width: 5px;
    height: 5px;
    margin: 0;
    border-radius: 50%;
    background: ${styles.colors.grey6};
    margin: 0 ${styles.spacing[1]} 4px;
    display: inline-block;
`;

const BlogPage = () => {
    const { blog, isLoading, pageNumber } = useSelector(mapState, shallowEqual);
    const translate = useTranslate();
    const dispatch = useDispatch();

    useDocumentTitle(translate("blog.title"));

    useEffect(() => {
        dispatch(
            fetchBlogList.started({
                pageNumber: +pageNumber,
            })
        );
    }, [dispatch, pageNumber]);

    const onPaginationChange = useCallback(
        (pageNumber: number) => {
            dispatch(updateBlogPageNumber(pageNumber));
        },
        [dispatch]
    );

    if (isLoading) {
        return <PageLoadingIndicator isLoading={isLoading} />;
    }

    if (!blog) {
        return <PageNotFound message={<FormattedMessage id="blog.emptyList" />} returnUrl="/" />;
    }

    const blogPageDescription = translate("blog.description");

    return (
        <CustomPageContainer tabs={[]}>
            <MetaDescription description={blogPageDescription} />
            <Page>
                <Title>
                    <FormattedMessage id="blog.title" />
                </Title>
                <Row>
                    <Col md={8}>
                        {blog.blogPosts.map((blogPost) => (
                            <BlogPostContainer key={blogPost.id}>
                                <BlogPost blogPost={blogPost} previewMode={true} />
                            </BlogPostContainer>
                        ))}
                    </Col>
                </Row>

                <BlogPagination
                    blog={blog}
                    onPaginationChange={onPaginationChange}
                    currentPageNumber={pageNumber}
                />
            </Page>
        </CustomPageContainer>
    );
};

export default BlogPage;
