import styled from "styled-components";
import styles from "../../../../styles/styles";
import React from "react";

interface Props {
    className?: string;
    direction: "up" | "right" | "down" | "left";
}

const StyledArrow = styled.i<{
    direction: "up" | "right" | "down" | "left";
}>`
    border: solid ${styles.colors.grey7};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    ${(props) => props.direction === "up" && `transform: rotate(225deg);`}
    ${(props) => props.direction === "right" && `transform: rotate(315deg);`}
    ${(props) => props.direction === "down" && `transform: rotate(45deg);`}
    ${(props) => props.direction === "left" && `transform: rotate(135deg);`}
`;

/**
 * Arrow icon component
 */
class Arrow extends React.Component<Props> {
    public render() {
        return <StyledArrow direction={this.props.direction} />;
    }
}

export default Arrow;
