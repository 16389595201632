import styles from "styles/styles";
import React from "react";
import styled from "styled-components";

interface Props {
    title: string;
    children: React.ReactNode;
}

const Header = styled.h6`
    font-weight: 500;
    font-size: ${styles.fontSize.smaller};
    margin: 0;
`;

const Container = styled.div`
    margin-top: ${styles.spacing[4]};
`;

const Content = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: ${styles.spacing[4]};
`;

const Section = ({ title, children }: Props) => (
    <Container>
        <Header>{title}</Header>
        <Content>{children}</Content>
    </Container>
);

export default Section;
