import ExcelExport from "components/common/ui/ExcelExport/ExcelExport";
import { ContactType } from "interfaces/contact-info";
import { loadingSelectorFactory } from "state/common/loading";
import { downloadConnecting, downloadInformationExchange } from "state/contact-info/actions";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import styles from "styles/styles";

const loadingSelector = loadingSelectorFactory([downloadInformationExchange, downloadConnecting]);

const mapState = (state: RootState) => ({
    isLoading: loadingSelector(state),
});

interface Props {
    contactType: ContactType;
}

const ExportButton = styled.div`
    margin-right: ${styles.spacing[3]};
`;

function ExportContacts({ contactType }: Props) {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(mapState, shallowEqual);
    const locale = useLocale();

    const onClick = useCallback(() => {
        switch (contactType) {
            case "informationExchange":
                dispatch(downloadInformationExchange.started({ locale }));

                return;
            case "connecting":
                dispatch(downloadConnecting.started({ locale }));

                return;
            default:
                ((_: never) => {})(contactType);
        }
    }, [contactType, dispatch, locale]);

    return (
        <ExportButton>
            <ExcelExport onClick={onClick} isLoading={isLoading} />
        </ExportButton>
    );
}

export default ExportContacts;
