import { useIntl } from "react-intl";
import { defaultLocale, isValidLocale } from "services/common/localization/localization";

export const useLocale = () => {
    const { locale } = useIntl();

    return isValidLocale(locale) ? locale : defaultLocale;
};

export default useLocale;
