import {
    InformationExchangeContactDetails,
    InformationExchangeContactInfo,
} from "interfaces/contact-info";

export const getContactDetails = (
    contactInfo: InformationExchangeContactInfo
): InformationExchangeContactDetails => ({
    participantId: contactInfo.participantId,
    businessId: contactInfo.businessId,
    role: contactInfo.role,
    validFrom: contactInfo.validFrom,
    validTo: contactInfo.validTo,
    contactPerson: contactInfo.contactPerson,
    email: contactInfo.email,
    phone: contactInfo.phone,
    crm: contactInfo.crm,
    notes: contactInfo.notes,
});

export const getCreateContactDetails = (businessId: string): InformationExchangeContactDetails => ({
    businessId,
    participantId: "",
    role: null,
    validFrom: null,
    validTo: null,
    contactPerson: null,
    email: null,
    phone: null,
    crm: null,
    notes: null,
});
