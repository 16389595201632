import React from "react";

import { SubPage } from "interfaces/content";
import HeroCard from "../HeroCard/HeroCard";
import DynamicContentColumns from "../DynamicContentColumns/DynamicContentColumns";
import Page from "components/common/layout/Page";

interface Props {
    activeSubPage: SubPage;
}

const DynamicContent = ({ activeSubPage }: Props) => (
    <>
        {activeSubPage.heroCard && <HeroCard heroCard={activeSubPage.heroCard} type="dynamic" />}
        <Page>
            <DynamicContentColumns subPage={activeSubPage} />
        </Page>
    </>
);

export default DynamicContent;
