import styles from "styles/styles";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import styled from "styled-components";

export interface Option {
    value: string;
    label: string;
}

interface Props {
    options: Option[];
    onSelect: (value: string | null) => void;
    selectedValue: string | null;
    tabIndex?: string;
    disabled?: boolean;
    isLoading?: boolean;
    required?: boolean;
    // Localization key of the empty option label
    placeholder?: string;
}

const { useCallback } = React;

const StyledSelect = styled(Select)`
    width: 100%;
    text-indent: 1px;
    padding: 7px ${styles.spacing[2]};
    background: #fff;
    color: #6d848f;
    font-size: ${styles.fontSize.medium};
    flex: 1;
`;

const SelectAutoFill = ({ options, onSelect, selectedValue, tabIndex, ...otherProps }: Props) => {
    const onChange = useCallback(
        (option: Option | null) => {
            onSelect(option && option.value);
        },
        [onSelect]
    );

    const selected = options.find((option) => option.value === selectedValue);

    return (
        <StyledSelect
            tabIndex={tabIndex ? Number(tabIndex) : undefined}
            value={selected}
            onChange={onChange}
            options={options}
            placeholder={<FormattedMessage id="user.chooseOrganization" />}
            {...otherProps}
        />
    );
};

export default SelectAutoFill;
