import React, { ReactElement, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { IndexDataType } from "interfaces/search";
import { RootState } from "state/reducers";
import { updateFilters } from "state/search";
import styled from "styled-components";
import styles from "styles/styles";
import Checkbox from "../../common/ui/Checkbox/Checkbox";

const Container = styled.div`
    > * {
        margin: ${styles.spacing[2]} 0;
    }
`;

const mapState = (state: RootState) => ({
    filters: state.search.filters,
});

function SearchFilters(): ReactElement {
    const { filters } = useSelector(mapState);
    const dispatch = useDispatch();

    const setFilters = useCallback(
        (newFilters: Record<IndexDataType, boolean>) => dispatch(updateFilters(newFilters)),
        [dispatch]
    );

    const onNewsFilterChange = useCallback(
        (news: boolean) => {
            setFilters({ ...filters, news });
        },
        [setFilters, filters]
    );

    const onPageFilterChange = useCallback(
        (page: boolean) => {
            setFilters({ ...filters, page });
        },
        [setFilters, filters]
    );

    const onDocumentFilterChange = useCallback(
        (document: boolean) => {
            setFilters({ ...filters, document });
        },
        [setFilters, filters]
    );

    const onBlogFilterChange = useCallback(
        (blog: boolean) => {
            setFilters({ ...filters, blog });
        },
        [setFilters, filters]
    );

    return (
        <Container>
            <Checkbox
                checked={filters.news}
                label={<FormattedMessage id="search.filter.news" />}
                onClick={onNewsFilterChange}
            />
            <Checkbox
                checked={filters.page}
                label={<FormattedMessage id="search.filter.page" />}
                onClick={onPageFilterChange}
            />
            <Checkbox
                checked={filters.document}
                label={<FormattedMessage id="search.filter.document" />}
                onClick={onDocumentFilterChange}
            />
            <Checkbox
                checked={filters.blog}
                label={<FormattedMessage id="search.filter.blog" />}
                onClick={onBlogFilterChange}
            />
        </Container>
    );
}

export default SearchFilters;
