export interface TableData {
    columns: string[];
    body: string[][];
}

type Row = string[];

function parseRows(data: string): Row[] {
    return data
        .split("\n")
        .map((row) => row.trim())
        .filter((row) => row)
        .map((row, index) => {
            if (!row.startsWith("|")) {
                throw new Error(`Missing leading pipe character on line ${index + 1}.`);
            }

            if (!row.endsWith("|")) {
                throw new Error(`Missing trailing pipe character on line ${index + 1}.`);
            }

            return row
                .split("|")
                .slice(1, -1)
                .map((cell) => cell.trim());
        });
}

function parseHeader(rows: Row[]): Row {
    const [columns, delimiters] = rows;
    const validDelimiters =
        delimiters?.filter((delimiter) => /^-{3,}$/.test(delimiter)).length ?? 0;

    if (!columns?.length) {
        return [];
    }

    if (!validDelimiters) {
        throw new Error(`Missing delimiter row on line 2.`);
    }

    if (validDelimiters !== columns.length) {
        throw new Error(
            `Delimiter count (${validDelimiters}) does not match column count (${columns.length}) on line 2.`
        );
    }

    return columns;
}

function parseBody(header: Row, rows: Row[]): Row[] {
    const bodyOffset = 2;
    const body = rows.slice(bodyOffset).map((row, index) => {
        if (row.length > header.length) {
            throw new Error(
                `Cell count (${row.length}) exceeds the number of columns (${
                    header.length
                }) on line ${bodyOffset + index + 1}.`
            );
        }

        return row;
    });

    return body;
}

export function parseTableData(data: string): TableData {
    const rows = parseRows(data);
    const columns = parseHeader(rows);
    const body = parseBody(columns, rows);

    return {
        columns,
        body,
    };
}
