import styles from "styles/styles";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface Props {
    id?: string;
    children?: React.ReactNode;
    header?: {
        // Localization key of the section header
        headerKey: string;

        // Localization key of the info
        infoKey?: string;

        // The right-side content of the header bar, e.g. an Excel export button
        actions?: React.ReactNode;
    };
}

const Container = styled.div`
    margin: ${styles.layout.contentSectionMargin};
    padding: ${styles.spacing[2]} ${styles.spacing[4]} ${styles.spacing[4]} ${styles.spacing[4]};
    background-color: ${styles.colors.lightGrey};
`;

const Header = styled.h5`
    margin: ${styles.spacing[4]} 0;
    flex-grow: 1;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const SectionContentWrapper = styled.div`
    margin-top: ${styles.spacing[2]};
`;

class ContentSection extends React.Component<Props> {
    public render() {
        return (
            <Container {...(this.props.id ? { id: this.props.id } : {})}>
                {this.props.header && (
                    <HeaderWrapper>
                        <Header>
                            <FormattedMessage id={this.props.header.headerKey} />
                        </Header>
                        {this.props.header.actions}
                    </HeaderWrapper>
                )}
                <SectionContentWrapper>{this.props.children}</SectionContentWrapper>
            </Container>
        );
    }
}

export default ContentSection;
