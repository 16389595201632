import React from "react";
import { ServiceAgreement } from "interfaces/service-agreements";
import ServiceAgreementItem from "../ServiceAgreementItem/ServiceAgreementItem";
import FileList from "components/common/files/FileList/FileList";
import styles from "styles/styles";
import styled from "styled-components";

const StyledFileList = styled(FileList)`
    margin-top: ${styles.spacing[4]};
`;

interface Props {
    serviceAgreements: readonly ServiceAgreement[];
    onDownload: (fileId: string, fileName: string) => unknown;
}

const ServiceAgreementList = ({ serviceAgreements, onDownload }: Props): JSX.Element => (
    <StyledFileList>
        {serviceAgreements.map((serviceAgreement) => (
            <ServiceAgreementItem
                key={serviceAgreement.id}
                serviceAgreement={serviceAgreement}
                onDownload={onDownload}
            />
        ))}
    </StyledFileList>
);

export default ServiceAgreementList;
