import React, { ReactElement } from "react";

import { Section as SectionType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import RichText from "../RichText/RichText";

interface Props {
    section: SectionType;
}

function Section({ section }: Props): ReactElement {
    const locale = useLocale();

    return (
        <>
            <h3 id={section.urlSegment}>{section.title[locale]}</h3>
            <RichText richText={section.content}></RichText>
        </>
    );
}

export default Section;
