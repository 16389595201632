import React, { useMemo } from "react";
import { Table as TableType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import { parseTableData } from "./utilities";
import { environment } from "services/common/config/config";
import { Table as TableComponent, Column } from "../../common/ui/Table/Table";
import ErrorMessage from "../../common/ui/ErrorMessage/ErrorMessage";

type Data =
    | {
          valid: true;
          columns: Column<string[]>[];
          body: string[][];
      }
    | {
          valid: false;
          error: string;
      };

interface Props {
    table: TableType;
}

const Table = ({ table }: Props) => {
    const locale = useLocale();

    const data = useMemo<Data>(() => {
        try {
            const parsed = parseTableData(table.data[locale] ?? "");
            const columns = parsed.columns.map<Column<string[]>>((column, i) => ({
                Header: column,
                accessor: (row) => row[i],
            }));

            return {
                valid: true,
                columns,
                body: parsed.body,
            };
        } catch (error) {
            const message = `Table could not be rendered: ${error.message}`;
            console.warn(message);

            return {
                valid: false,
                error: message,
            };
        }
    }, [table.data, locale]);

    if (data.valid && !data.columns.length) {
        return null;
    }

    return data.valid ? (
        <TableComponent columns={data.columns} data={data.body} />
    ) : environment !== "production" ? (
        <ErrorMessage id="table-render-error" message={data.error} />
    ) : null;
};

export default Table;
