import * as React from "react";
import editIcon from "../../../../../images/edit.svg";
import { buttonStyles } from "../index";
import styled from "styled-components";

interface Props {
    onClick: (event: React.MouseEvent) => void;
    className?: string;
    disabled?: boolean;
    disabledClassName?: string;
    editIconAltText?: string;
}

const StyledButton = styled.button`
    ${buttonStyles.transparent}
    height: 24px;
    width: 24px;
    min-width: 24px;
    padding: 0;
    margin: 20px;
`;

const StyledIcon = styled.img``;

export default class EditButton extends React.PureComponent<Props> {
    render() {
        return (
            <StyledButton
                className={this.props.className}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                <StyledIcon src={editIcon} alt={this.props.editIconAltText || ""} />
            </StyledButton>
        );
    }
}
