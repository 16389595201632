import { config } from "services/common/config/config";
import { fetchSwitchMap } from "state/observable";
import { RootState } from "state/reducers";
import { getLocation, push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { debounceTime, filter, map, withLatestFrom } from "rxjs/operators";
import {
    SEARCH_SEARCH_TERM_QUERY_PARAM,
    SEARCH_PAGE_NUM_QUERY_PARAM,
} from "services/common/url/url";
import { textSearch, updateSearchTerm, updatePageNumber, updateFilters } from "./actions";
import { getCurrentSearchTerm, getCurrentPageNumber } from "./selectors";

export const DEFAULT_PAGE_QUERY_PARAM = "1";

export const textSearchEpic = fetchSwitchMap(textSearch, (action) => ({
    url: config.search.endpoints.textSearch,
    queryParameters: {
        ...action.payload,
    },
}));

export const mapUpdateFiltersToUpdatePageNumberQueryEpic = (
    action$: Observable<Action>,
    state$: Observable<RootState>
) =>
    action$.pipe(
        filter(updateFilters.match),
        withLatestFrom(state$),
        map(([_, state]) =>
            push(
                `${getLocation(state).pathname}?${new URLSearchParams({
                    [SEARCH_SEARCH_TERM_QUERY_PARAM]: getCurrentSearchTerm(state),
                    [SEARCH_PAGE_NUM_QUERY_PARAM]: DEFAULT_PAGE_QUERY_PARAM, // resets page number to default whenever a filter changes.
                }).toString()}`
            )
        )
    );

export const updateSearchQueryEpic = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(updateSearchTerm.match),
        debounceTime(700),
        withLatestFrom(state$),
        filter(([action, state]) => getCurrentSearchTerm(state) !== action.payload),
        map(([action, state]) =>
            push(
                action.payload
                    ? `${getLocation(state).pathname}?${new URLSearchParams({
                          [SEARCH_SEARCH_TERM_QUERY_PARAM]: action.payload,
                          [SEARCH_PAGE_NUM_QUERY_PARAM]: DEFAULT_PAGE_QUERY_PARAM,
                      }).toString()}`
                    : getLocation(state).pathname // Removes query paramter when no search query is added
            )
        )
    );

export const updatePageNumberQueryEpic = (
    action$: Observable<Action>,
    state$: Observable<RootState>
) =>
    action$.pipe(
        filter(updatePageNumber.match),
        withLatestFrom(state$),
        filter(([action, state]) => getCurrentPageNumber(state) !== String(action.payload)),
        map(([action, state]) =>
            push(
                action.payload
                    ? `${getLocation(state).pathname}?${new URLSearchParams({
                          [SEARCH_SEARCH_TERM_QUERY_PARAM]: getCurrentSearchTerm(state),
                          [SEARCH_PAGE_NUM_QUERY_PARAM]: String(action.payload),
                      }).toString()}`
                    : getLocation(state).pathname // Removes query paramter when no search query is added
            )
        )
    );

export default combineEpics(
    textSearchEpic,
    updateSearchQueryEpic,
    updatePageNumberQueryEpic,
    mapUpdateFiltersToUpdatePageNumberQueryEpic
);
