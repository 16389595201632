import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBefore, isAfter } from "date-fns";

import { Announcement as AnnouncementType } from "interfaces/content";
import { getAnnouncement } from "state/content/contentful/selectors";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { Announcement } from "./Announcement/Announcement";
import { dismissAnnouncement } from "state/content/announcements";

const mapState = (state: RootState) => ({
    announcement: getAnnouncement(state),
    userDismissedAnnouncement: state.content.announcements.dismissedAnnouncement,
});

const AnnouncementContainer = () => {
    const { announcement, userDismissedAnnouncement } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();

    const onDismiss = (announcementId: AnnouncementType["id"]) => {
        dispatch(dismissAnnouncement(announcementId));
    };

    if (!announcement) {
        return null;
    }

    const now = new Date();
    const isValidAnnouncement =
        isAfter(now, new Date(announcement.relevantFrom)) &&
        isBefore(now, new Date(announcement.relevantUntil));

    const userAlreadyDismissedAnnouncement = announcement.id === userDismissedAnnouncement;

    if (!isValidAnnouncement || userAlreadyDismissedAnnouncement) {
        return null;
    }

    return <Announcement announcement={announcement} onDismiss={onDismiss} />;
};

export default AnnouncementContainer;
