import styles from "styles/styles";
import React from "react";
import styled, { css } from "styled-components";

type Variant = "normal" | "small";

export interface Props {
    extension: string;
    variant?: Variant;
}

const SmallVariant = css`
    font-size: 12px;
    margin-right: 0;
`;

const FileTypeContainer = styled.div<{ variant?: Variant }>`
    padding: ${styles.spacing[0.5]} ${styles.spacing[1]};
    margin: 0 ${styles.spacing[2]};
    display: inline-block;
    font-size: ${styles.fontSize.small};
    font-weight: bold;
    vertical-align: top;
    border: 2px solid ${styles.colors.grey7};
    text-transform: uppercase;
    color: ${styles.colors.grey7};
    border-radius: 2px;
    align-self: flex-start;
    word-break: normal;

    ${(props) => (props.variant === "small" ? SmallVariant : "")}
`;

const FileType = ({ extension, variant }: Props): JSX.Element => (
    <FileTypeContainer variant={variant}>{extension}</FileTypeContainer>
);

export default FileType;
