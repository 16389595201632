import styles from "styles/styles";
import styled from "styled-components";

const DocumentDate = styled.div`
    font-weight: 300;
    font-size: ${styles.fontSize.small};
    color: ${styles.colors.grey7};
    grid-column: 2;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        grid-column: auto;
    }
`;

export default DocumentDate;
