import React, { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Document as ContentDocument, Section as SectionType, SubPage } from "interfaces/content";
import { LocalizedString } from "interfaces/common";
import { NavigationTab } from "interfaces/navigation";
import useLocale from "hooks/common/useLocale/useLocale";
import SectionNav from "../SectionNav/SectionNav";
import RichText, { DocumentEmbeddedEntryContentBlock, isSection } from "../RichText/RichText";
import ExternalLinksList from "./ExternalLinksList/ExternalLinksList";
import styled from "styled-components";
import styles from "styles/styles";

export interface Props {
    subPage: SubPage;
    title?: LocalizedString;
}

function getEmbeddedSections(document: ContentDocument): SectionType[] {
    return document.content.reduce(
        (sections: SectionType[], contentBlock: DocumentEmbeddedEntryContentBlock) => {
            const isEmbeddedEntry = contentBlock.nodeType === "embedded-entry-block";

            const embeddedEntry = contentBlock.data.target;

            if (isEmbeddedEntry && embeddedEntry && isSection(embeddedEntry)) {
                return sections.concat(embeddedEntry);
            }

            return [...sections];
        },
        []
    );
}

function getSectionsTabs(sections: SectionType[], pathname: string): NavigationTab[] {
    return sections.map((section) => ({
        path: `${pathname}#${section.urlSegment}`,
        label: section.title,
        urlSegment: section.urlSegment,
        subTabs: [],
    }));
}

const Tabs = styled.div`
    background-color: ${styles.colors.lightGrey};
    padding: 20px;
    display: inline-flex;
    flex: 1;
`;

const Content = styled.div`
    background-color: white;
    padding: ${styles.spacing[2]} 0 ${styles.spacing[6]} ${styles.spacing[4]};
    flex: 4;
`;

const StickyContainer = styled.div`
    position: sticky;
    width: 100%;
    top: ${styles.spacing[3]};
    max-height: 700px;
`;

const Divider = styled.div`
    background-color: #d5dde3;
    height: 1px;
    margin: ${styles.spacing[4]} 0;
    width: 100%;
`;

function RichTextContainer({ subPage, title }: Props): ReactElement | null {
    const locale = useLocale();
    const location = useLocation();

    const sectionsTabs = useMemo(() => {
        const document = subPage.content[locale];

        if (!document) {
            return undefined;
        }

        const sections = getEmbeddedSections(document);

        return getSectionsTabs(sections, location.pathname);
    }, [subPage, locale, location]);

    const hasSectionTabs = useMemo(() => !!sectionsTabs && sectionsTabs.length > 0, [sectionsTabs]);

    const hasSideCol = useMemo(
        () => hasSectionTabs || !!subPage.externalLinks,
        [hasSectionTabs, subPage.externalLinks]
    );

    return (
        <>
            {hasSideCol && (
                <Tabs>
                    <StickyContainer>
                        {hasSectionTabs && <SectionNav tabs={sectionsTabs!} />}
                        {subPage.externalLinks && hasSectionTabs && <Divider />}
                        {subPage.externalLinks && (
                            <ExternalLinksList externalLinks={subPage.externalLinks} />
                        )}
                    </StickyContainer>
                </Tabs>
            )}
            <Content>
                {title && <h3>{title[locale]}</h3>}
                <RichText richText={subPage.content} />
            </Content>
        </>
    );
}

export default RichTextContainer;
