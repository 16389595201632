import React from "react";
import { Question as QuestionType } from "../../../../interfaces/common";
import RadioButton from "../../../common/ui/RadioButton/RadioButton";
import styled from "styled-components";
import Button from "../../../common/ui/buttons/Button/Button";
import useLocale from "../../../../hooks/common/useLocale/useLocale";
import useTranslate from "../../../../hooks/common/useTranslate/useTranslate";
import Info from "../../../common/ui/Info/Info";

const Question: React.FC<{
    question: QuestionType;
    goToPreviousQuestion: () => void;
    goToNextQuestion: (id: string) => void;
    resetQuestionnaire: () => void;
    showPreviousBtn?: boolean;
    showResetBtn?: boolean;
}> = ({
    question,
    goToPreviousQuestion,
    goToNextQuestion,
    resetQuestionnaire,
    showPreviousBtn,
    showResetBtn,
}) => {
    const locale = useLocale();
    const translate = useTranslate();
    const Title = styled.h5`
        font-size: 1.5rem;
        font-weight: normal;
        margin-bottom: 15px;
    `;
    const Container = styled.div`
        padding: 20px 0;
    `;

    const DescriptionContainer = styled.div`
        display: flex;
        margin-bottom: 20px;
    `;

    const Description = styled.div`
        font-size: 1rem;
        margin-left: 10px;
    `;

    const RadioContainer = styled.div`
        margin-bottom: 20px;
        min-height: 250px;
    `;

    const ButtonContainer = styled.div`
        display: flex;
        margin-top: 20px;
    `;

    return (
        <Container>
            <Title>{question.question[locale]}</Title>
            {question.description && (
                <DescriptionContainer>
                    <Info infoText="Info" infoIconAltText="Info" />
                    <Description>{question.description[locale]}</Description>
                </DescriptionContainer>
            )}
            <RadioContainer>
                <RadioButton
                    name={"name"}
                    options={question.options.map((option) => ({
                        value: option.next,
                        label: option.text[locale],
                        key: option.id,
                        description: option.description && option.description[locale],
                    }))}
                    onChange={(_event, value) => goToNextQuestion(value)}
                />
            </RadioContainer>
            <ButtonContainer>
                <Button secondary disabled={!showPreviousBtn} onClick={goToPreviousQuestion}>
                    &larr; {translate("common.previous")}
                </Button>
                {showResetBtn && (
                    <Button transparent onClick={resetQuestionnaire}>
                        {translate("questionnaire.reset")}
                    </Button>
                )}
            </ButtonContainer>
        </Container>
    );
};

export default Question;
