import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { updateProgress } from "./actions";

export interface ProgressState {
    [id: string]: {
        loaded: number;
        total: number;
    };
}

const initialState: ProgressState = {};

// TODO: Make the logic type-safe
const getKey = (action: AnyAction, state: ProgressState) => {
    const id =
        action.payload &&
        action.payload.params &&
        (action.payload.params.id || action.payload.params.fileId);
    const key = state[id] ? id : action.type;

    return state[key] ? key : null;
};

export default function reducer(state = initialState, action: AnyAction): ProgressState {
    if (isType(action, updateProgress)) {
        const { id, loaded, total } = action.payload;

        return {
            ...state,
            [id]: { loaded, total },
        };
    }

    if (action.type.endsWith("_DONE")) {
        // Make sure the progress is 100% when done.

        const key = getKey(action, state);

        if (key) {
            const stateTotal = state[key].total;

            if (stateTotal !== state[key].loaded) {
                const total = stateTotal || 1;

                return {
                    ...state,
                    [key]: { loaded: total, total },
                };
            }
        }
    }

    if (action.type.endsWith("_FAILED")) {
        // Remove progress from state if failed.

        const key = getKey(action, state);

        if (key) {
            const { [key]: _, ...rest } = state;

            return rest;
        }
    }

    return state;
}
