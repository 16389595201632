import styles from "styles/styles";
import styled from "styled-components";

export const Table = styled.div`
    display: table;
`;
export const Row = styled.div`
    display: table-row;
`;

export const Cell = styled.div`
    display: table-cell;
    vertical-align: middle;

    :not(:first-child) {
        padding-left: ${styles.spacing[2]};
    }
`;
