import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { clearToastNotification } from "state/common/toast";
import ErrorNotifications from "../ErrorNotifications/ErrorNotifications";

const mapState = (state: RootState) => ({
    errorToastNotifications: state.common.toast.notifications.filter(
        (notification) => notification.type === "error"
    ),
});

const ErrorNotificationsContainer = () => {
    const { errorToastNotifications } = useSelector(mapState, shallowEqual);

    return (
        <ErrorNotifications
            errorToastNotifications={errorToastNotifications}
            clearToastNotification={clearToastNotification}
        ></ErrorNotifications>
    );
};

export default ErrorNotificationsContainer;
