import React, { ReactElement, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { QueryResultValue } from "interfaces/search";
import { formatDateWithHour } from "services/common/dates/dates";
import { config } from "services/common/config/config";
import { Locale } from "services/common/localization/localization";
import { BLOG_URL_SEGMENT, NEWS_URL_SEGMENT } from "services/common/url/url";
import useLocale from "hooks/common/useLocale/useLocale";
import styled, { css } from "styled-components";
import styles from "styles/styles";

interface Props {
    searchResultItem: QueryResultValue;
}

const getUrl = (searchResultItem: QueryResultValue, locale: Locale) => {
    if (searchResultItem.type === "page") {
        return `/${locale}/${searchResultItem.link}`;
    }

    if (searchResultItem.type === "news") {
        return `/${locale}/${NEWS_URL_SEGMENT}/${searchResultItem.link}`;
    }

    if (searchResultItem.type === "document") {
        return `${config.documents.endpoints.file}${searchResultItem.link}`;
    }

    if (searchResultItem.type === "blog") {
        return `/${locale}/uutiset-ja-tapahtumat/${BLOG_URL_SEGMENT}/${searchResultItem.link}`;
    }

    ((type: never) => {
        throw new Error(`Unknown search result type ${type}`);
    })(searchResultItem.type);
};

const Container = styled.div`
    padding: ${styles.spacing[4]} 0;
    border-bottom: 1px solid ${styles.colors.grey4};
`;

const itemUrlStyle = css`
    color: ${styles.colors.grey7};
    display: block;
    text-decoration: none;
`;

const ItemUrl = styled.a`
    ${itemUrlStyle}

    .itemUrl:hover {
        ${itemUrlStyle}
    }

    :hover h4 {
        color: ${styles.colors.red};
    }

    > * {
        padding-bottom: ${styles.spacing[2]};
    }
`;

const Dates = styled.div`
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.normal};
`;

const Type = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${styles.fontSize.small};
`;

const Content = styled.p`
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Title = styled.h4`
    margin: 0;
    font-size: ${styles.fontSize.medium};
    text-decoration: underline;
`;

function SearchResultItem({ searchResultItem }: Props): ReactElement {
    const locale = useLocale();

    const created = useMemo(
        () => formatDateWithHour(searchResultItem.created, locale),
        [searchResultItem.created, locale]
    );
    const updated = useMemo(
        () => formatDateWithHour(searchResultItem.updated, locale),
        [searchResultItem.updated, locale]
    );
    const url = getUrl(searchResultItem, locale);

    return (
        <Container>
            <ItemUrl href={url}>
                <Type>
                    <FormattedMessage id={`search.type.${searchResultItem.type}`} />
                </Type>
                <Title>{searchResultItem.title[locale]}</Title>
                {searchResultItem.content[locale] && (
                    <Content>{searchResultItem.content[locale]}</Content>
                )}
                <Dates>
                    <FormattedMessage
                        id="search.result.date"
                        values={{
                            created,
                            updated,
                        }}
                    />
                </Dates>
            </ItemUrl>
        </Container>
    );
}

export default SearchResultItem;
