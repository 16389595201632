import fsaActionCreatorFactory from "typescript-fsa";
import { FaultNotificationContact } from "interfaces/common";
import { RequestStatus } from "./reducer";

const actionCreator = fsaActionCreatorFactory("FAULT_NOTIFICATION_CONTACTS");

export const updateRequestStatus = actionCreator<RequestStatus>("UPDATE_REQUEST_STATUS");

export const updateFaultNotificationContacts = actionCreator.async<
    {
        faultNotificationContacts: FaultNotificationContact[];
        businessId: string;
    },
    FaultNotificationContact[]
>("UPDATE_CONTACTS");

export const deleteFaultNotificationContacts = actionCreator.async<
    { removedContactIds: number[] },
    void
>("DELETE_CONTACTS");

export const fetchFaultNotificationContacts = actionCreator.async<
    { businessId: string },
    FaultNotificationContact[]
>("FETCH_CONTACTS");
