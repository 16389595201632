import React, { ReactElement } from "react";
import { Tag as TagType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import styles from "styles/styles";

export interface Props {
    tag: TagType;
    className?: string;
}

const TagContainer = styled.div`
    background-color: ${styles.colors.lightGrey};
    border-radius: ${styles.spacing[4]};
    padding: ${styles.spacing[1]} ${styles.spacing[3]};
    font-size: 14px;
    color: ${styles.colors.grey7};
`;

function Tag({ tag, ...props }: Props): ReactElement {
    const locale = useLocale();

    return <TagContainer {...props}>{tag.label[locale]}</TagContainer>;
}

export default Tag;
