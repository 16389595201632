import React, { ReactElement, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Profile } from "oidc-client";

import { setIsOrganizationSelectOpen } from "state/common/user";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { login } from "services/auth/auth";
import Circle from "components/common/ui/Circle/Circle";
import LocaleSelector from "components/common/navigation/LocaleSelector/LocaleSelector";
import verticalLine from "images/vertical-line.svg";
import FaultNotifications from "components/common/FaultNotifications/FaultNotifications";
import searchIcon from "../../../../images/search.svg";
import useLocale from "hooks/common/useLocale/useLocale";
import IconLink from "components/common/ui/IconLink/IconLink";
import { SEARCH_URL_SEGMENT } from "services/common/url/url";
import Services from "components/common/Services/Services";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../ui/buttons/Button/Button";

const matchesDotName = /^([^\.]+)\.([^\.]+)$/;

const formatInitials = (first: string, last: string) =>
    (first.charAt(0) + last.charAt(0)).toLocaleUpperCase();

const nameInitials = (user: Profile) => {
    const { given_name, family_name, name } = user;

    // given_name and family_name might be undefined, fallback to name
    if (!given_name || !family_name) {
        const fallbackName = String(name);

        // If fallbackName is in the form of "first.last"
        const nameParts = fallbackName.match(matchesDotName);

        if (nameParts !== null) {
            return formatInitials(nameParts[1], nameParts[2]);
        } else {
            // Otherwise just return the first two letters
            return String(name).slice(0, 2);
        }
    }

    return formatInitials(given_name, family_name);
};

const mapState = (state: RootState) => ({
    user: state.oidc.user,
    selectedOrganizationId: state.common.user.selectedOrganizationId,
    organizations: state.common.user.organizations,
});

const ToggleCircle = styled(Circle)`
    width: 43px;
    height: 43px;
    font-size: ${styles.fontSize.medium};
`;

const Bar = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    max-width: 100%;
`;

const Divider = styled.img`
    margin: 0 ${styles.spacing[6]};

    @media only screen and (${styles.breakpoints.xlGridBreakpoint}) {
        margin: 0 ${styles.spacing[3]};
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin: 0 ${styles.spacing[1.5]};
    }
`;

const StyledLocaleSelector = styled(LocaleSelector)`
    display: flex;
    align-items: center;
`;

const ToggleLabel = styled.span`
    text-transform: capitalize;
    margin: 0 ${styles.spacing[2]};
    font-weight: 500;

    @media only screen and (${styles.breakpoints.xlGridBreakpoint}) {
        display: none;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        display: none;
    }
`;

function HeaderNavigationBar(): ReactElement {
    const locale = useLocale();
    const { user, selectedOrganizationId, organizations } = useSelector(mapState, shallowEqual);

    const organization = useMemo(
        () =>
            (organizations &&
                organizations.find((org) => org.businessId === selectedOrganizationId)) ||
            null,
        [organizations, selectedOrganizationId]
    );

    const dispatch = useDispatch();

    const onClick = () => dispatch(setIsOrganizationSelectOpen(true));

    return (
        <Bar>
            <IconLink href={`/${locale}/${SEARCH_URL_SEGMENT}`} src={searchIcon} />
            <Divider src={verticalLine} alt="" />
            <StyledLocaleSelector />
            <Divider src={verticalLine} alt="" />
            <FaultNotifications />
            <Divider src={verticalLine} alt="" />
            <Services />
            <Divider src={verticalLine} alt="" />
            {user ? (
                <Button transparent onClick={onClick}>
                    <span>
                        <ToggleCircle>{nameInitials(user.profile)}</ToggleCircle>
                        {organization && <ToggleLabel>{organization.name}</ToggleLabel>}
                    </span>
                </Button>
            ) : (
                <Button data-id="login" onClick={() => login()}>
                    <FormattedMessage id="navigation.login" />
                </Button>
            )}
        </Bar>
    );
}

export default HeaderNavigationBar;
