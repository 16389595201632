import styles from "styles/styles";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import ServicesModal from "./ServicesModal";

const Svg = styled.svg`
    padding-left: ${styles.spacing[1]};
    fill: ${styles.colors.grey7};
`;

const ServicesBtn = styled.button`
    border: 0;
    background: transparent;
    font-weight: 500;
    margin: 0 ${styles.spacing[1]};
    color: inherit;
    display: flex;
    align-items: center;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        span {
            display: none;
            background-color: hotpink;
        }

        .svg {
            padding-left: 0;
        }
    }
`;

const ServicesIcon = () => (
    <Svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4h4V0H0v4zm6 12h4v-4H6v4zm-6 0h4v-4H0v4zm0-6h4V6H0v4zm6 0h4V6H6v4zm6-10v4h4V0h-4zM6 4h4V0H6v4zm6 6h4V6h-4v4zm0 6h4v-4h-4v4z"></path>
    </Svg>
);

const Services = () => {
    const [servicesVisible, setServicesVisible] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const servicesUpdated = useMemo(() => new Date().getTime(), [servicesVisible]);

    const onClose = useCallback(() => setServicesVisible(false), []);

    return (
        <div>
            <ServicesBtn
                onClick={() => {
                    const isDuplicateEvent = new Date().getTime() - servicesUpdated < 200;

                    if (!isDuplicateEvent) {
                        setServicesVisible(!servicesVisible);
                    }
                }}
            >
                <span>
                    <FormattedMessage id="common.otherServices" />
                </span>
                <ServicesIcon />
            </ServicesBtn>
            {servicesVisible && <ServicesModal onClose={onClose} />}
        </div>
    );
};

export default Services;
