import uniqueId from "lodash/uniqueId";
import { useState } from "react";

/**
 * Returns a string ID for use with html elements (such as form inputs).
 */
function useId(prefix?: string): string {
    const [id] = useState(() => uniqueId(prefix));

    return id;
}

export default useId;
