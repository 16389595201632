import { ConnectingContactInfo } from "interfaces/contact-info";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const PriceListLink = styled.a`
    word-break: break-word;
`;

export const getContactItems = (
    contacts: ConnectingContactInfo[],
    getContactItemHeader: (contactInfo: ConnectingContactInfo) => React.ReactNode
) =>
    contacts.map((contact) => ({
        id: contact.id,
        businessId: contact.businessId,
        header: getContactItemHeader(contact),
        detailsViewContent: {
            sections: [
                {
                    rows: [
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.linkToPriceList",
                                    value: (
                                        <PriceListLink
                                            href={contact.linkToPriceList}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {contact.linkToPriceList}
                                        </PriceListLink>
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.ecrBefore13SameDay",
                                    value: contact.ecrBefore13SameDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrBefore13SameDay}`}
                                        />
                                    ),
                                },
                                {
                                    labelKey: "contactInfo.details.ecrAfter13SameDay",
                                    value: contact.ecrAfter13SameDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrAfter13SameDay}`}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.ecrBefore13NextDay",
                                    value: contact.ecrBefore13NextDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrBefore13NextDay}`}
                                        />
                                    ),
                                },
                                {
                                    labelKey: "contactInfo.details.ecrAfter13NextDay",
                                    value: contact.ecrAfter13NextDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrAfter13NextDay}`}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.ercBefore13SecondDay",
                                    value: contact.ercBefore13SecondDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ercBefore13SecondDay}`}
                                        />
                                    ),
                                },
                                {
                                    labelKey: "contactInfo.details.ecrAfter13SecondDay",
                                    value: contact.ecrAfter13SecondDay && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrAfter13SecondDay}`}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.ecrWeekend",
                                    value: contact.ecrWeekend && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrWeekend}`}
                                        />
                                    ),
                                },
                                {
                                    labelKey: "contactInfo.details.ecrAfterWorkingHours",
                                    value: contact.ecrAfterWorkingHours && (
                                        <FormattedMessage
                                            id={`contactInfo.details.connectingFee.${contact.ecrAfterWorkingHours}`}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.workingTime",
                                    value: contact.workingTime,
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    labelKey: "contactInfo.details.notes",
                                    value: contact.notes,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    }));
