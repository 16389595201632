import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import excel from "images/excel.svg";
import { translate } from "../../../utilities";
import styled, { css } from "styled-components";
import Button from "../buttons/Button/Button";

interface Props extends WrappedComponentProps {
    onClick: () => void;
    title?: string;
    isLoading: boolean;
}

const ExportButton = styled(Button)`
    min-width: auto;
`;

const loadingIcon = css`
    opacity: 0.4;
    animation: loading 0.8s infinite;

    @keyframes loading {
        50% {
            transform: scale(0.85);
        }

        100% {
            transform: scale(1);
        }
    }
`;

const Icon = styled.img<{ isLoading: boolean }>`
    ${(props) => props.isLoading && loadingIcon}
`;

class ExcelExport extends React.PureComponent<Props> {
    render() {
        return (
            <ExportButton
                transparent
                onClick={this.props.onClick}
                title={this.props.title || translate(this.props.intl, "common.exportToExcel")}
                disabled={this.props.isLoading}
            >
                <Icon src={excel} alt="" isLoading={this.props.isLoading} />
            </ExportButton>
        );
    }
}

export default injectIntl(ExcelExport);
