import { FaultNotificationContact } from "interfaces/common";
import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import {
    deleteFaultNotificationContacts,
    fetchFaultNotificationContacts,
    updateFaultNotificationContacts,
    updateRequestStatus,
} from "./actions";

export type RequestStatus =
    | "contacts-fetch"
    | "contacts-ready"
    | "contacts-updated"
    | "contacts-refresh";

export interface NewsState {
    contacts: FaultNotificationContact[];
    requestStatus: RequestStatus;
}

const initialState: NewsState = {
    contacts: [],
    requestStatus: "contacts-fetch",
};

export default function reducer(state = initialState, action: AnyAction): NewsState {
    if (isType(action, fetchFaultNotificationContacts.done)) {
        return {
            ...state,
            contacts: action.payload.result,
            requestStatus: "contacts-ready",
        };
    }

    if (
        isType(action, deleteFaultNotificationContacts.done) ||
        isType(action, deleteFaultNotificationContacts.failed) ||
        isType(action, updateFaultNotificationContacts.failed)
    ) {
        return {
            ...state,
            requestStatus: "contacts-refresh",
        };
    }

    if (isType(action, updateRequestStatus)) {
        return {
            ...state,
            requestStatus: action.payload,
        };
    }

    if (isType(action, updateFaultNotificationContacts.done)) {
        return {
            ...state,
            requestStatus: "contacts-updated",
        };
    }

    return state;
}
