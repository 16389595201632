import Authorize, { Props as AuthorizeProps } from "../Authorize/Authorize";
import { loadingSelectorFactory } from "state/common/loading";
import { fetchAuthorizations } from "state/common/user";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator";

const loadingSelector = loadingSelectorFactory([fetchAuthorizations]);

const mapState = (state: RootState) => ({
    userAuthorizations: state.common.user.authorizations,
    isFingridUser: state.common.user.isFingridUser,
    isLoading: loadingSelector(state),
});

const Container = styled.div`
    position: relative;
`;

function AuthorizeContainer<AuthService extends string, AuthFeature extends string>(
    props: React.PropsWithChildren<
        Omit<AuthorizeProps<AuthService, AuthFeature>, "userAuthorizations" | "isFingridUser">
    >
) {
    const { isLoading, userAuthorizations, isFingridUser } = useSelector(mapState, shallowEqual);

    return (
        <Container>
            <LoadingIndicator isLoading={isLoading} />
            {!isLoading && (
                <Authorize
                    {...props}
                    userAuthorizations={userAuthorizations ? userAuthorizations : []}
                    isFingridUser={isFingridUser || false}
                >
                    {props.children}
                </Authorize>
            )}
        </Container>
    );
}

export default AuthorizeContainer;
