import React from "react";
import { FormattedMessage } from "react-intl";

import RichText from "components/content/RichText/RichText";
import { Announcement as AnnouncementType } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";
import Button from "../../ui/buttons/Button/Button";

interface Props {
    announcement: AnnouncementType;
    onDismiss: (id: AnnouncementType["id"]) => void;
}

const Container = styled.div`
    width: 100%;
    background-color: rgb(40 58 66 / 85%);
    color: ${styles.colors.white};
    text-align: center;
    padding: ${styles.spacing[4]} 0;
    z-index: 1001;
`;

const AnnouncementText = styled.div`
    font-size: ${styles.fontSize.normal};
    padding: 0 ${styles.spacing[4]};
`;

const DismissButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${styles.spacing[4]};

    > button {
        height: 48px;
    }
`;

export const Announcement = ({ announcement, onDismiss }: Props) => {
    const dismiss = () => {
        onDismiss(announcement.id);
    };

    return (
        <Container>
            <AnnouncementText>
                <RichText richText={announcement.content} />
            </AnnouncementText>
            <DismissButtonContainer>
                <Button onClick={dismiss}>
                    <FormattedMessage id="common.ok" />
                </Button>
            </DismissButtonContainer>
        </Container>
    );
};
