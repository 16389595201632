import * as React from "react";
import deleteIcon from "../../../../../images/remove.svg";
import styled from "styled-components";
import { buttonStyles } from "../index";

interface Props {
    onClick: () => void;
    className?: string;
    deleteIconAltText?: string;
    disabledClassName?: string;
    disabled?: boolean;
}

const StyledDeleteButton = styled.button`
    ${buttonStyles.transparent}
    height: 24px;
    width: 24px;
    min-width: 24px;
    padding: 0;
    margin: 20px;
`;

const Icon = styled.img`
    width: 100%;
`;

/**
 * DeleteButton displays a clickable delete icon.
 */
const DeleteButton = (props: Props) => (
    <StyledDeleteButton onClick={props.onClick} disabled={props.disabled}>
        <Icon src={deleteIcon} alt={props.deleteIconAltText} />
    </StyledDeleteButton>
);

export default DeleteButton;
