import { combineEpics } from "redux-observable";

import { fetchSwitchMap } from "state/observable";
import { config } from "services/common/config/config";

import { fetchEvents } from "./actions";

export const fetchEventsEpic = fetchSwitchMap(fetchEvents, () => ({
    url: config.content.events,
}));

export default combineEpics(fetchEventsEpic);
