// Based on https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
import { omit } from "lodash";
import { AnyAction } from "redux";

export interface LoadingState {
    [actionType: string]: {
        // Each request has an id, which is either defined when dispatching an action,
        // or alternatively generated automatically with a Redux middleware.
        [metaId: string]: true;
    };
}

const initialState: LoadingState = {};

export default function reducer(state = initialState, action: AnyAction): LoadingState {
    const matches = /^(.*)_(STARTED|DONE|FAILED|CANCELLED)$/.exec(action.type);

    if (!matches) {
        return state;
    }

    if (!action.meta || !action.meta.id) {
        throw new Error(`Async action ID not found (${action.type})`);
    }

    const [, requestName, requestState] = matches;

    if (requestState === "STARTED") {
        return {
            ...state,
            [requestName]: {
                ...state[requestName],
                [action.meta.id]: true,
            },
        };
    } else {
        const updatedState = omit(state[requestName], [action.meta.id]);

        return Object.keys(updatedState).length > 0
            ? { ...state, [requestName]: updatedState }
            : omit(state, [requestName]);
    }
}
