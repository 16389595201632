import { config } from "services/common/config/config";
import {
    deleteMergeMap,
    downloadMergeMap,
    fetchSwitchMap,
    postMergeMap,
    putMergeMap,
} from "state/observable";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
    createConnectingContact,
    createInformationExchangeContact,
    downloadConnecting,
    downloadInformationExchange,
    fetchConnectingContacts,
    fetchInformationExchangeContacts,
    updateConnectingContact,
    updateInformationExchangeContact,
    fetchRecentUpdates,
    deleteInformationExchangeContact,
    deleteConnectingContact,
} from "./actions";

export const fetchInformationExchangeContactsEpic = fetchSwitchMap(
    fetchInformationExchangeContacts,
    () => ({
        url: config.contactInfo.endpoints.informationExchange,
        requireAuthorizationHeader: true,
    })
);

export const updateInformationExchangeContactEpic = putMergeMap(
    updateInformationExchangeContact,
    (action) => ({
        url: `${config.contactInfo.endpoints.informationExchange}/${action.payload.contactId}`,
        body: action.payload.contactDetails,
        requireAuthorizationHeader: true,
    })
);

export const createInformationExchangeContactEpic = postMergeMap(
    createInformationExchangeContact,
    (action) => ({
        url: config.contactInfo.endpoints.informationExchange,
        body: action.payload.contactDetails,
        requireAuthorizationHeader: true,
    })
);

export const deleteInformationExchangeContactEpic = deleteMergeMap(
    deleteInformationExchangeContact,
    (action) => ({
        url: `${config.contactInfo.endpoints.informationExchange}/${action.payload.businessId}/${action.payload.contactId}`,
        requireAuthorizationHeader: true,
    })
);

export const mapCreateUpdateInformationExchangeToFetch = (action$: Observable<Action>) =>
    action$.pipe(
        filter(
            (action) =>
                updateInformationExchangeContact.done.match(action) ||
                createInformationExchangeContact.done.match(action)
        ),
        map(() => fetchInformationExchangeContacts.started())
    );

export const fetchConnectingContactsEpic = fetchSwitchMap(fetchConnectingContacts, () => ({
    url: config.contactInfo.endpoints.connecting,
    requireAuthorizationHeader: true,
}));

export const updateConnectingContactEpic = putMergeMap(updateConnectingContact, (action) => ({
    url: `${config.contactInfo.endpoints.connecting}/${action.payload.contactId}`,
    body: action.payload.contactDetails,
    requireAuthorizationHeader: true,
}));

export const createConnectingContactEpic = postMergeMap(createConnectingContact, (action) => ({
    url: config.contactInfo.endpoints.connecting,
    body: action.payload.contactDetails,
    requireAuthorizationHeader: true,
}));

export const deleteConnectingContactEpic = deleteMergeMap(deleteConnectingContact, (action) => ({
    url: `${config.contactInfo.endpoints.connecting}/${action.payload.businessId}/${action.payload.contactId}`,
    requireAuthorizationHeader: true,
}));

export const mapCreateUpdateConnectingToFetch = (action$: Observable<Action>) =>
    action$.pipe(
        filter(
            (action) =>
                updateConnectingContact.done.match(action) ||
                createConnectingContact.done.match(action)
        ),
        map(() => fetchConnectingContacts.started())
    );

export const downloadInformationExchangeEpic = downloadMergeMap(
    downloadInformationExchange,
    (action) => ({
        url: `${config.contactInfo.endpoints.informationExchange}/export/${action.payload.locale}`,
        requireAuthorizationHeader: true,
    })
);

export const downloadConnectingEpic = downloadMergeMap(downloadConnecting, (action) => ({
    url: `${config.contactInfo.endpoints.connecting}/export/${action.payload.locale}`,
    requireAuthorizationHeader: true,
}));

export const fetchRecentUpdatesEpic = fetchSwitchMap(fetchRecentUpdates, (action) => ({
    url: config.contactInfo.endpoints.recentUpdates,
    requireAuthorizationHeader: true,
    queryParameters: {
        ...action.payload,
    },
}));

export default combineEpics(
    fetchInformationExchangeContactsEpic,
    updateInformationExchangeContactEpic,
    createInformationExchangeContactEpic,
    mapCreateUpdateInformationExchangeToFetch,
    fetchConnectingContactsEpic,
    updateConnectingContactEpic,
    createConnectingContactEpic,
    mapCreateUpdateConnectingToFetch,
    downloadInformationExchangeEpic,
    downloadConnectingEpic,
    fetchRecentUpdatesEpic,
    deleteConnectingContactEpic,
    deleteInformationExchangeContactEpic
);
