import { includes } from "lodash";

// Based on https://stackoverflow.com/a/23797348/4321262

const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

const parseFileName = (dispositionHeader: string | null) => {
    if (dispositionHeader && includes(dispositionHeader, "attachment")) {
        const matches = fileNameRegex.exec(dispositionHeader);

        if (matches !== null && matches[1]) {
            return matches[1].replace(/['"]/g, "");
        }
    }

    return "";
};

/**
 * Download file with Blob, bloblUrl and then <a>-element.
 * downloadLink is preferrable for downloading files.
 */
export const downloadBlob = (
    { response, xhr }: { response: any; xhr: XMLHttpRequest },
    fileNameOverride?: string
) => {
    const fileName =
        fileNameOverride || parseFileName(xhr.getResponseHeader("Content-Disposition"));
    const type = xhr.getResponseHeader("Content-Type") || undefined;

    // We are using Blob instead of File because the file constructor isn't supported in MS Edge
    const blob = new Blob([response], { type });

    const blobUrl = window.URL.createObjectURL(blob);

    // Construct an a element and use it to download the file.
    // Note: does not work with Safari < 10.1.
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    return blobUrl;
};

export const cleanUp = (blobUrl: string | null) => blobUrl && window.URL.revokeObjectURL(blobUrl);
