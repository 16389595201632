import React, { ReactElement } from "react";

import {
    ActionSteps as ActionStepsType,
    Column as ColumnType,
    DocumentSection as DocumentSectionType,
} from "interfaces/content";
import RichText from "../RichText/RichText";
import DocumentSection from "../DocumentSection/DocumentSection";
import ActionSteps from "components/content/ActionSteps/ActionSteps";
import styled from "styled-components";
import styles from "styles/styles";

export interface Props {
    column: ColumnType | ActionStepsType | DocumentSectionType;
    columnStyle?: string;
}

const ColumnContainer = styled.div<Pick<Props, "columnStyle">>`
    padding: ${styles.spacing[3]} ${styles.spacing[5]};
    margin: ${styles.spacing[1]};
    background-color: ${styles.colors.lightGrey};
    ${(props) => props.columnStyle}
`;

function Column({ column, columnStyle }: Props): ReactElement {
    return (
        <ColumnContainer columnStyle={columnStyle}>
            {column.type === "column" && <RichText richText={column.content}></RichText>}
            {column.type === "actionSteps" && <ActionSteps actionSteps={column} />}
            {column.type === "documentSection" && <DocumentSection documentSection={column} />}
        </ColumnContainer>
    );
}

export default Column;
