import { Action } from "redux";
import { v4 as uuid } from "uuid";
import { from, Observable, of } from "rxjs";
import { combineEpics } from "redux-observable";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { SESSION_TERMINATED, USER_EXPIRING, USER_FOUND, USER_SIGNED_OUT } from "redux-oidc";

import userManager from "services/common/oidc/userManager";
import { fetchSwitchMap } from "../../observable";
import { refreshToken } from "../failed/actions";
import { fetchAuthorizations, userLogout } from "./actions";
import { apiBaseUrl } from "services/common/config/config";

export const fetchAuthorizationsEpic = fetchSwitchMap(fetchAuthorizations, () => ({
    url: `${apiBaseUrl}/user/authorizations`,
    requireAuthorizationHeader: true,
}));

export const mapUserLoginToFetchAuthorizationsEpic = (action$: Observable<Action>) =>
    action$.pipe(
        filter((action) => action.type === USER_FOUND),
        map(() => fetchAuthorizations.started())
    );

export const mapOidcLogoutToLogoutEpic = (action$: Observable<Action>) =>
    action$.pipe(
        filter((action) => action.type === SESSION_TERMINATED || action.type === USER_SIGNED_OUT),
        map(() => userLogout())
    );

export const mapUserExpiringToRefreshTokenEpic = (action$: Observable<Action>) =>
    action$.pipe(
        filter((action) => action.type === USER_EXPIRING),
        map(() => refreshToken.started({}, { id: uuid() }))
    );

export const refreshTokenEpic = (action$: Observable<Action>) =>
    action$.pipe(
        filter(refreshToken.started.match),
        mergeMap((action) =>
            from(userManager.signinSilent()).pipe(
                map((user) => refreshToken.done({ params: {}, result: user }, action.meta)),
                catchError((error) => of(refreshToken.failed({ params: {}, error }, action.meta)))
            )
        )
    );

export default combineEpics(
    fetchAuthorizationsEpic,
    refreshTokenEpic,
    mapUserExpiringToRefreshTokenEpic,
    mapOidcLogoutToLogoutEpic,
    mapUserLoginToFetchAuthorizationsEpic
);
