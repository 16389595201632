import React, { ReactElement } from "react";
import { DocumentSection as DocumentSectionType } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import Document from "components/content/Document/Document";
import Link from "components/common/ui/Link/Link";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import styles from "styles/styles";
export interface Props {
    documentSection: DocumentSectionType;
}

const DocumentContainer = styled.div`
    margin-bottom: ${styles.spacing[3]};
`;

const DocumentLink = styled(Link)`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    height: 3.6875rem;
    justify-content: center;
    min-width: 3.5rem;
    padding: 0 0.625rem;
    color: ${styles.colors.red};
    background-color: ${styles.colors.white};
    border: 2px solid ${styles.colors.red};
    text-decoration: none;
    max-width: 20rem;

    :hover {
        text-decoration: none;
        background-color: ${styles.colors.red};
        color: ${styles.colors.white};
    }
`;

function DocumentSection({ documentSection }: Props): ReactElement {
    const locale = useLocale();

    return (
        <div>
            <h5>{documentSection.title[locale]}</h5>
            {documentSection.documents.map((doc, index) => (
                <DocumentContainer key={index}>
                    <Document document={doc} />
                </DocumentContainer>
            ))}
            <DocumentLink
                localizedUrl={{
                    fi: "dokumentaatio/dokumentaatio-ja-materiaalit",
                    en: "dokumentaatio/dokumentaatio-ja-materiaalit",
                }}
                openExternalLinkToNewTab={false}
            >
                <FormattedMessage id="common.showAllDocuments" />
            </DocumentLink>
        </div>
    );
}

export default DocumentSection;
