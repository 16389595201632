import React, { useEffect } from "react";
import moment from "moment";
import { CustomPage, Event } from "interfaces/content";
import { RootState } from "state/reducers";
import { uniq, max } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "state/utilities";
import { fetchEvents } from "state/events/actions";
import useQuery from "hooks/common/useQuery/useQuery";
import { NavigationTab } from "interfaces/navigation";
import CustomPageContainer from "../CustomPageContainer";
import EventBlock from "./EventBlock";
import { useLocation } from "react-router-dom";
import useLocale from "hooks/common/useLocale/useLocale";
import { getLocalizedString } from "components/utilities";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import RichText from "../../RichText/RichText";

const mapState = (state: RootState) => ({
    events: state.events.events,
});

const getEventYear = (event: Event): number => moment(event.date).get("year");

export const getUrlSegment = (pathname: string): string => pathname.split("/").pop() || "";

const getTabs = (pathname: string, years: number[]): NavigationTab[] => [
    ...years.map<NavigationTab>((year) => ({
        label: { fi: year.toString(), en: year.toString() },
        path: year === moment().get("year") ? pathname : `${pathname}?year=${year}`,
        urlSegment: getUrlSegment(pathname),
        subTabs: [],
    })),
    {
        label: { fi: "Kaikki", en: "All" },
        path: `${pathname}?showAll=true`,
        urlSegment: getUrlSegment(pathname),
        subTabs: [],
    },
    {
        label: { fi: "Vanha tapahtumalistaus", en: "Older events" },
        path: "/fi/datahub/tilaisuudet-ja-webinaarit",
        urlSegment: "tilaisuudet-ja-webinaarit",
        subTabs: [],
    },
];

export const sortAscending = (a: Event, b: Event) =>
    new Date(a.date).getTime() - new Date(b.date).getTime();

export const sortDescending = (a: Event, b: Event) =>
    new Date(b.date).getTime() - new Date(a.date).getTime();

const getVisibleEvents = (events: Event[], yearFilter: number | null) => {
    const visibleEvents =
        yearFilter === null ? events : events.filter((event) => getEventYear(event) === yearFilter);

    const futureEvents = visibleEvents.filter((event) => !event.isPastEvent).sort(sortAscending);
    const pastEvents = visibleEvents.filter((event) => event.isPastEvent).sort(sortDescending);

    return { futureEvents, pastEvents };
};

const EventList = ({ events, title }: { events: Event[]; title: string }) =>
    events.length ? (
        <div>
            <h5>{title}</h5>
            {events.map((event, idx) => (
                <EventBlock event={event} key={idx} />
            ))}
        </div>
    ) : null;

const useGetYearFilter = (availableYears: number[]): number | null => {
    const { year, showAll } = useQuery(["year", "showAll"]);

    if (showAll === "true") {
        return null;
    } else if (year === null) {
        return max(availableYears) || moment().get("year");
    }

    return parseInt(year, 10);
};

const EventsAndWebinars: React.FC<{ customPage: CustomPage }> = ({ customPage }) => {
    const { events } = useSelector(mapState, shallowEqual);
    const { pathname } = useLocation();
    const locale = useLocale();
    const translate = useTranslate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchEvents.started());
    }, [dispatch]);

    const availableYears = uniq(events.map(getEventYear));
    const yearFilter = useGetYearFilter(availableYears);

    const { futureEvents, pastEvents } = getVisibleEvents(events, yearFilter);

    return (
        <CustomPageContainer tabs={getTabs(pathname, availableYears)}>
            <h3>{getLocalizedString(locale, customPage.pageHeader)}</h3>
            {customPage.pageDesc && customPage.pageDesc[locale] && (
                <RichText richText={customPage.pageDesc} />
            )}
            <div>
                <EventList
                    events={futureEvents}
                    title={translate("customPage.eventsAndWebinars.futureEvents")}
                />
                <EventList
                    events={pastEvents}
                    title={translate("customPage.eventsAndWebinars.pastEvents")}
                />
            </div>
        </CustomPageContainer>
    );
};

export default EventsAndWebinars;
