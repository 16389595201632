import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import usePrevious from "hooks/common/usePrevious/usePrevious";

import { NewsFeedItem as NewsFeedItemType } from "interfaces/content";
import { deleteNewsFeedItem, updateNewsFeedItem } from "state/news";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { idLoadingSelectorFactory } from "state/common/loading";
import { idDoneSelectorFactory } from "state/common/done";
import { successToast } from "components/common/notification/success-notification";
import NewsFeedItem from "./NewsFeedItem/NewsFeedItem";
import NewsFeedEditor from "components/news/NewsFeedEditor/NewsFeedEditor";
import styled from "styled-components";
import { documentToMarkdown } from "../../../utils/markdown";
import Button from "../../common/ui/buttons/Button/Button";
import { Row } from "../../common/ui/FlexboxGrid";

interface Props {
    newsFeedItem: NewsFeedItemType;
    previewMode?: boolean;
    closeModal?: () => void;
}

const EditNewsFeedItemEditorContainer = styled.div`
    max-width: 85%;
`;

const deleteNewsFeedItemDone = idDoneSelectorFactory(deleteNewsFeedItem);
const loadingSelector = idLoadingSelectorFactory(deleteNewsFeedItem);

const mapState = (newsFeedItemId: NewsFeedItemType["id"]) => (state: RootState) => ({
    selectedOrganizationId: state.common.user.selectedOrganizationId,
    isFingridUser: state.common.user.isFingridUser,
    isDeleteNewsFeedItemDone: deleteNewsFeedItemDone(state, newsFeedItemId),
    isLoading: loadingSelector(state, newsFeedItemId),
});

function NewsFeedItemContainer({ newsFeedItem, previewMode, closeModal }: Props): ReactElement {
    const { selectedOrganizationId, isFingridUser, isDeleteNewsFeedItemDone, isLoading } =
        useSelector(mapState(newsFeedItem.id), shallowEqual);

    const dispatch = useDispatch();
    const [showEditor, setShowEditor] = useState(false);
    const onNewsFeedItemDelete = useCallback(
        (newsFeedItemId: string) => {
            dispatch(deleteNewsFeedItem.started(newsFeedItemId, { id: newsFeedItemId })); // 2nd arg is a meta id to identify the action dispatched
        },
        [dispatch]
    );

    const onNewsFeedItemDeactivate = useCallback(
        (newsFeedItem: NewsFeedItemType) => {
            dispatch(
                updateNewsFeedItem.started(
                    {
                        customerId: newsFeedItem.companyId ? newsFeedItem.companyId : "",
                        newsFeedItem: {
                            isActive: false,
                            tagIds:
                                newsFeedItem.newsFeedTags &&
                                newsFeedItem.newsFeedTags.map((tag) => tag.id),
                            content: {
                                fi: documentToMarkdown(newsFeedItem.content.fi),
                                en: newsFeedItem.content.en
                                    ? documentToMarkdown(newsFeedItem.content.en)
                                    : "",
                            },
                        },
                    },
                    { id: newsFeedItem.id }
                )
            );
        },
        [dispatch]
    );

    const toggleEditorShow = () => {
        setShowEditor(!showEditor);
    };

    const prevIsDeleteNewsFeedItemDone = usePrevious(isDeleteNewsFeedItemDone).current;

    const actionsAllowed =
        // NewsFeed items published by content editors from Contentful app don't have a companyId
        (isFingridUser && !newsFeedItem.companyId) ||
        (!!selectedOrganizationId && selectedOrganizationId === newsFeedItem.companyId);

    useEffect(() => {
        if (prevIsDeleteNewsFeedItemDone === false && isDeleteNewsFeedItemDone) {
            successToast("info.newsfeed.delete.success");
        }
    }, [isDeleteNewsFeedItemDone, prevIsDeleteNewsFeedItemDone]);

    if (showEditor) {
        return (
            <Row between={"xs"} middle={"xs"}>
                <EditNewsFeedItemEditorContainer>
                    <NewsFeedEditor
                        newsFeedItem={newsFeedItem}
                        onSave={() => setShowEditor(false)}
                    />
                </EditNewsFeedItemEditorContainer>
                <div>
                    <Button onClick={() => toggleEditorShow()}>sulje</Button>
                </div>
            </Row>
        );
    } else {
        return (
            <NewsFeedItem
                newsFeedItem={newsFeedItem}
                previewMode={previewMode}
                actionsAllowed={actionsAllowed}
                onNewsFeedItemDelete={onNewsFeedItemDelete}
                onNewsFeedItemDeactivate={onNewsFeedItemDeactivate}
                onEditorShow={toggleEditorShow}
                isLoading={isLoading}
                closeModal={closeModal}
            />
        );
    }
}

export default NewsFeedItemContainer;
