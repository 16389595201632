import * as React from "react";
import { FormattedMessage } from "react-intl";

import ContentSection from "../ContentSection/ContentSection";
import styled from "styled-components";
import styles from "styles/styles";
import Content from "../Content/Content";

interface Props {
    children?: React.ReactNode;
}

interface State {
    error?: Error;
}

const ErrorMessage = styled.span`
    color: red;
`;

const StackTrace = styled.p`
    padding: 5px 10px;
    font-size: 10px;
    background-color: ${styles.colors.grey3};
`;

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    public render() {
        return this.state.error ? (
            <ContentSection>
                <Content>
                    <h5>
                        <FormattedMessage id="error.errorBoundaryHeader" />
                    </h5>
                    <p>
                        <FormattedMessage id="error.errorBoundaryMessage" />
                    </p>
                    <p>
                        <ErrorMessage>{this.state.error.message}</ErrorMessage>
                    </p>
                    <StackTrace>
                        <pre>{this.state.error.stack}</pre>
                    </StackTrace>
                </Content>
            </ContentSection>
        ) : (
            this.props.children
        );
    }
}
