import {
    ConnectingContact,
    ConnectingContactDetails,
    ConnectingContactInfo,
    ContactType,
    InformationExchangeContact,
    InformationExchangeContactDetails,
    InformationExchangeContactInfo,
    ContactRecentUpdatesParameters,
    ContactInfoWithType,
} from "interfaces/contact-info";
import { Locale } from "services/common/localization/localization";
import { RootState } from "state/reducers";
import { Dispatch } from "redux";
import actionCreatorFactory, { AsyncActionCreators } from "typescript-fsa";

const actionCreator = actionCreatorFactory("CONTACT_INFO");

export const fetchInformationExchangeContacts = actionCreator.async<
    void,
    InformationExchangeContactInfo[]
>("FETCH_INFORMATION_EXCHANGE_CONTACTS");

export const updateInformationExchangeContact = actionCreator.async<
    {
        contactDetails: InformationExchangeContactDetails;
        contactId: InformationExchangeContact["id"];
    },
    InformationExchangeContactInfo
>("UPDATE_INFORMATION_EXCHANGE_CONTACT");

export const createInformationExchangeContact = actionCreator.async<
    { contactDetails: InformationExchangeContactDetails },
    void
>("CREATE_INFORMATION_EXCHANGE_CONTACT");

export const deleteInformationExchangeContact = actionCreator.async<
    {
        contactId: InformationExchangeContactInfo["id"];
        businessId: InformationExchangeContactInfo["businessId"];
    },
    void
>("DELETE_INFORMATION_EXCHANGE_CONTACT");

export const fetchInformationExchangeIfNeeded = fetchIfNeeded<InformationExchangeContactInfo[]>(
    "informationExchange",
    fetchInformationExchangeContacts
);

export const fetchConnectingContacts = actionCreator.async<void, ConnectingContactInfo[]>(
    "FETCH_CONNECTING_CONTACTS"
);

export const updateConnectingContact = actionCreator.async<
    { contactDetails: ConnectingContactDetails; contactId: ConnectingContact["id"] },
    ConnectingContactInfo
>("UPDATE_CONNECTING_CONTACT");

export const createConnectingContact = actionCreator.async<
    { contactDetails: ConnectingContactDetails },
    void
>("CREATE_CONNECTING_CONTACT");

export const deleteConnectingContact = actionCreator.async<
    { contactId: ConnectingContactInfo["id"]; businessId: ConnectingContactInfo["businessId"] },
    void
>("DELETE_CONNECTING_CONTACT");

export const fetchConnectingIfNeeded = fetchIfNeeded<ConnectingContactInfo[]>(
    "connecting",
    fetchConnectingContacts
);

export const downloadInformationExchange = actionCreator.async<ExportLocale, void>(
    "DOWNLOAD_INFORMATION_EXCHANGE"
);
export const downloadConnecting = actionCreator.async<ExportLocale, void>("DOWNLOAD_CONNECTING");

export const fetchRecentUpdates = actionCreator.async<
    ContactRecentUpdatesParameters,
    ContactInfoWithType[]
>("FETCH_RECENT_UPDATES");

interface ExportLocale {
    locale: Locale;
}

function fetchIfNeeded<T>(contactType: ContactType, actionCreator: AsyncActionCreators<void, T>) {
    return () => (dispatch: Dispatch, getState: () => RootState) => {
        const { contactInfo } = getState();

        if (contactInfo[contactType] === null) {
            dispatch(actionCreator.started());
        }
    };
}
