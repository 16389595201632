import React, { FocusEvent, useCallback, useMemo, useRef, useState } from "react";
import "../../../../../styles/datepicker.css";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { shouldVisualizeInvalid } from "../../utilities";
import fi from "date-fns/locale/fi";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { format, isValid, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput, { DATE_STRING_FORMAT } from "../DatePickerInput";
import { DatePickerAriaPropsType, defaultDatePickerAriaLabels } from "../datePickerAriaLabels";
import { pickBy } from "lodash";
import useId from "../../../../../hooks/common/useId/useId";
import styled from "styled-components";
import styles from "../../../../../styles/styles";

registerLocale("fi", fi);

export type DatePickerProps = {
    // A string in the ISO date format, e.g. "2021-03-31"
    date: string | Date | null;
    onDateChange: (startDate: string | null, endDate?: string | null) => void;
    ariaLabel?: string;
    // id of the text input.
    // Used to associate a label to the correct input.
    id?: string;
    popperPlacement?: "bottom-start" | "top-start";
    disabled?: boolean;
    focused?: boolean;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;
    // If true and the input has been focused and is empty, visualize the input as invalid.
    required?: boolean;
    inputIconAltText?: string;
    inputClassName?: string;
    selectsStart?: boolean;
    selectsEnd?: boolean;
    selectsRange?: boolean;
    dateFormat?: string;
    minDate?: Date;
    startDate?: Date;
    endDate?: Date;
    className?: string;
    inline?: boolean;
    highlightDates?: Date[];
    hideIcon?: boolean;
} & DatePickerAriaPropsType;

const StyledWrapper = styled.div<{ isInvalid?: boolean }>`
    width: 100%;
    display: inline-block;

    &:global(.SingleDatePicker) {
        margin: 0;
        width: 100%;
    }

    &:global(.SingleDatePickerInput) {
        height: 44px;
        width: 100%;
    }

    &:global(.DateInput) {
        padding: 4px;
        width: calc(100% - 55px);
    }

    ${(props) =>
        props.isInvalid &&
        `
        &:global(.SingleDatePickerInput__withBorder) {
            border-color: ${styles.colors.red};
        } 
        `};
`;

/**
 * Component to pick a single date
 */
const DatePicker = (props: DatePickerProps) => {
    const [visited, setVisited] = useState<boolean>(false);
    const pickerContainerRef = useRef<HTMLDivElement>(null);
    const defaultId = useId();

    const date = useMemo(() => {
        const dateVal = props.date
            ? typeof props.date === "string"
                ? parseISO(props.date)
                : props.date
            : null;

        return dateVal && isValid(dateVal) ? dateVal : null;
    }, [props.date]);

    const onFocusChange = useCallback(({ focused }: { focused: boolean }) => {
        setVisited((visited) => (focused ? visited : true));
    }, []);

    const onBlur = useCallback(
        (e: FocusEvent) => {
            if (!pickerContainerRef.current) {
                return;
            }

            // If the focus moves to a non-null element that is not inside this component (pickerContainerRef)
            // close the calendar popup by calling onFocusChange
            const isFocusLeavingContainer =
                e.relatedTarget !== null &&
                !pickerContainerRef.current.contains(e.relatedTarget as Node);

            if (isFocusLeavingContainer) {
                onFocusChange({ focused: false });
            }
        },
        [onFocusChange]
    );

    const onDateChange = useCallback(
        (date: Date | (Date | null)[] | null) => {
            const [startDate, endDate] = !!date && Array.isArray(date) ? date : [date];

            if (!startDate) {
                props.onDateChange(null, null);
            } else {
                const formattedStartDate = format(startDate, DATE_STRING_FORMAT);

                if (endDate) {
                    const formattedEndDate = format(endDate, DATE_STRING_FORMAT);
                    props.onDateChange(formattedStartDate, formattedEndDate);
                } else {
                    props.onDateChange(formattedStartDate);
                }
            }
        },
        [props]
    );

    const isInvalid = shouldVisualizeInvalid(
        date,
        visited,
        props.invalid,
        props.required,
        props.validateBeforeFocus
    );

    const errorElementId = useId("errorMessage_");

    const ariaLabels = {
        ...defaultDatePickerAriaLabels,
        ...pickBy(props, (_, key) => key.includes("AriaLabel")),
    } as DatePickerAriaPropsType;

    return (
        <StyledWrapper
            isInvalid={isInvalid}
            className={`${props.className || ""}`}
            ref={pickerContainerRef}
            onBlur={onBlur}
            aria-invalid={isInvalid}
            aria-describedby={errorElementId}
        >
            <ReactDatePicker
                id={props.id || defaultId}
                locale={fi}
                selected={date || null}
                onChange={onDateChange}
                onFocus={() => onFocusChange({ focused: true })}
                onBlur={() => onFocusChange({ focused: false })}
                monthsShown={1}
                open={typeof props.focused !== "undefined" ? props.focused : undefined}
                showPopperArrow={false}
                popperPlacement={props.popperPlacement || "bottom-start"}
                calendarStartDay={1}
                disabled={props.disabled}
                required={props.required}
                dateFormat={props.dateFormat || "d.M.yyyy"}
                selectsStart={props.selectsStart}
                selectsEnd={props.selectsEnd}
                selectsRange={props.selectsRange}
                startDate={props.startDate}
                endDate={props.endDate}
                minDate={props.minDate}
                inline={props.inline}
                highlightDates={props.highlightDates}
                customInput={
                    // Value and onChange will be set correctly by React-DatePicker.
                    <DatePickerInput
                        required={props.required}
                        disabled={props.disabled}
                        onChange={() => console.log("CHANGE EVENT NOT IMPLEMENTED")}
                        value={date || ""}
                        inputIconAltText={props.inputIconAltText || "Kalenteri"}
                        ariaLabel={props.ariaLabel}
                        inputClassName={props.inputClassName}
                        hideIcon={props.hideIcon}
                    />
                }
                {...ariaLabels}
            />
            {isInvalid && typeof props.invalid === "string" && (
                <ErrorMessage id={errorElementId} message={props.invalid} />
            )}
        </StyledWrapper>
    );
};

export default DatePicker;
