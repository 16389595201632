import { createGlobalStyle } from "styled-components";
import styles from "./styles";

/**
 * Fix issue with prettier and createGlobalStyle: https://github.com/prettier/prettier/issues/11196
 */
const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }

    body {
        color: ${styles.colors.grey7};
        margin: 0;
        padding: 0;
        font-family: "Lab Grotesque";
        font-size: 20px;
        line-height: 1.5;
    }

    nav {
        background-color: ${styles.colors.grey3};
        box-shadow: inset 0 2px 2px 0 rgb(62 86 96 / 10%);
        width: 100%;
    }

    main {
        display: block;
    }

    footer {
        background-color: ${styles.colors.grey7};
    }

    button {
        font-family: "Lab Grotesque";
        font-size: 18px;
        cursor: pointer;
    }

    input {
        font-family: "Lab Grotesque";
        color: ${styles.colors.grey7};
    }

    a {
        color: ${styles.colors.red};
        display: inline-block;
        margin: 2px;
    }

    h1 {
        font-family: "Lab Grotesque";
        font-size: 70px;
        font-weight: bold;
        line-height: 80px;
        margin: ${styles.layout.headerMargin};
    }

    h2 {
        font-family: "Lab Grotesque";
        font-size: 46px;
        font-weight: bold;
        line-height: 55px;
        margin: ${styles.layout.headerMargin};
    }

    h3 {
        font-family: "Lab Grotesque";
        font-size: 38px;
        font-weight: bold;
        line-height: 48px;
        margin: ${styles.layout.headerMargin};
    }

    h4 {
        font-family: "Lab Grotesque";
        font-size: 30px;
        font-weight: bold;
        line-height: 40px;
        margin: ${styles.layout.headerMargin};
    }

    h5 {
        font-family: "Lab Grotesque";
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        margin: ${styles.layout.headerMargin};
    }

    h6 {
        font-family: "Lab Grotesque";
        font-size: 20px;
        font-weight: bold;
        line-height: 27px;
        margin: ${styles.layout.headerMargin};
    }

    p {
        font-family: "Lab Grotesque";
        font-size: 18px;
        line-height: 26px;
    }

    a:hover {
        text-decoration: underline;
        color: ${styles.colors.darkRed};
    }

    /* Highlight elements on focused element */
    button:focus,
    a:focus,
    input:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }
`;

export default GlobalStyles;
