import React, { ReactElement } from "react";
import { VersionHistoryBlock as VersionHistoryBlockType } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";
import DocumentList from "../DocumentList/DocumentList";
import useLocale from "../../../hooks/common/useLocale/useLocale";

interface Props {
    versionHistoryBlock: VersionHistoryBlockType;
}

const Container = styled.div`
    padding: 20px;
    margin-bottom: 20px;
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const Date = styled.div`
    font-size: 1.2rem;
    color: ${styles.colors.grey5};

    :after {
        content: " | ";
        margin-right: 10px;
    }
`;

const Title = styled.div`
    font-size: 1.5rem;
    text-decoration: underline;
    font-weight: bold;
`;

const Content = styled.div``;

const Documents = styled.div`
    margin-top: 20px;
`;

function VersionHistoryBlock({ versionHistoryBlock }: Props): ReactElement {
    const locale = useLocale();
    const { date, title, content, documentList } = versionHistoryBlock;

    return (
        <Container>
            <TitleRow>
                <Date>{date.fi}</Date>
                <Title>{title[locale]}</Title>
            </TitleRow>
            <Content>{content[locale]}</Content>
            {documentList && (
                <Documents>
                    <DocumentList hideControls={true} documentList={documentList} />
                </Documents>
            )}
        </Container>
    );
}

export default VersionHistoryBlock;
