import React from "react";

import { AuthenticatedFeature } from "interfaces/content";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import ContactInfoSideContent from "./ContactInfoSideContent/ContactInfoSideContent";
import { AuthService, AuthFeature, Authorization } from "interfaces/authorization";

interface Props {
    authenticatedFeature: AuthenticatedFeature;
}

export const AUTHENTICATED_FEATURES_WITH_SIDE_CONTENT: AuthenticatedFeature["featureType"][] = [
    "ContactInfo",
];

const contactInfoReadRequirements: { required: Partial<Authorization>[] } = {
    required: [
        {
            service: AuthService.DatahubServices,
            feature: AuthFeature.ContactInfo,
            accessLevel: "Read",
        },
    ],
};

const AuthenticatedFeatureSideContent = ({ authenticatedFeature }: Props) => (
    <>
        {authenticatedFeature.featureType === "ContactInfo" && (
            <AuthorizeContainer requirements={contactInfoReadRequirements}>
                <ContactInfoSideContent />
            </AuthorizeContainer>
        )}
    </>
);

export default AuthenticatedFeatureSideContent;
