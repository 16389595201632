import { combineEpics } from "redux-observable";
import userEpics from "./common/user/epics";
import toastEpics from "./common/toast/epics";
import contactInfoEpics from "./contact-info/epics";
import contentfulEpics from "./content/contentful/epics";
import newsEpics from "./news/epics";
import faultNotificationsEpics from "./news/fault-notifications/epics";
import eventsEpics from "./events/epics";
import testEnvironmentEpics from "./test-environments/epics";
import searchEpics from "./search/epics";
import serviceAgreementsEpics from "./service-agreements/epics";
import faultNotificationContactsEpics from "./fault-notification-contacts/epics";

const epics = combineEpics(
    contentfulEpics,
    userEpics,
    newsEpics,
    faultNotificationsEpics,
    eventsEpics,
    testEnvironmentEpics,
    toastEpics,
    contactInfoEpics,
    searchEpics,
    serviceAgreementsEpics,
    faultNotificationContactsEpics
);

export default epics;
