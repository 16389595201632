import React, { ReactElement, useMemo } from "react";

import useLocale from "hooks/common/useLocale/useLocale";
import { DocumentLink as DocumentLinkType } from "interfaces/content";
import FileTags from "components/common/files/FileTags/FileTags";
import FileLink from "components/common/files/FileLink/FileLink";
import FileListItem from "components/common/files/FileListItem/FileListItem";
import { extractDocumentTags } from "components/content/Document/Document";
import { formatDate } from "services/common/dates/dates";
import DocumentDate from "../Document/DocumentDate";

export interface Props {
    document: DocumentLinkType;
}

function DocumentLink({ document }: Props): ReactElement {
    const locale = useLocale();

    const formattedDate = useMemo(
        () => formatDate(document.publishingDate, locale),
        [document.publishingDate, locale]
    );

    const tags = useMemo(
        () => extractDocumentTags(document?.documentTags || [], locale),
        [document.documentTags, locale]
    );

    return (
        <FileListItem>
            <FileLink data-testid="document-title" href={document.url} extension="link">
                {document.title}
            </FileLink>
            <DocumentDate data-testid="document-date">{formattedDate}</DocumentDate>
            {!!tags?.length && <FileTags tags={tags} />}
        </FileListItem>
    );
}

export default DocumentLink;
