import * as React from "react";
import { shouldVisualizeInvalid } from "../utilities";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import uniqueId from "lodash/uniqueId";
import styled from "styled-components";
import styles from "../../../../styles/styles";

export interface TextInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value: string;
    onChange: (value: string) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<unknown> | React.KeyboardEvent<unknown>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    className?: string;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;
    ariaLabel?: string;
}

interface State {
    // Whether the user has focused and lost focus on this input.
    visited: boolean;
}

const StyledTextInput = styled.input<{ invalid: boolean }>`
    background-color: ${styles.colors.white};
    border: 1px solid ${styles.colors.grey6};
    box-sizing: border-box;
    color: ${styles.colors.grey7};
    font-family: "Lab Grotesque";
    font-size: 20px;
    padding: 11px 20px;
    margin: 0;
    outline: none;
    width: 100%;

    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }

    &:hover {
        outline: 1px solid ${styles.colors.focusBorderColor};
    }

    &:disabled {
        opacity: 0.5;
    }

    ${({ invalid }) => invalid && `border-color: ${styles.colors.red};`}
`;

class TextInput extends React.Component<TextInputProps, State> {
    state = { visited: false };
    errorElementId = uniqueId("errorMessage_");

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event?.target?.value);
    };

    onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({
            visited: true,
        });

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    isInvalid() {
        return shouldVisualizeInvalid(
            this.props.value,
            this.state.visited,
            this.props.invalid,
            this.props.required,
            this.props.validateBeforeFocus
        );
    }

    public render() {
        const isInvalid = this.isInvalid();

        const {
            value,
            onChange,
            onBlur,
            className,
            invalid: invalidProp,
            validateBeforeFocus,
            ariaLabel,
            ...standardProps
        } = this.props;

        return (
            <>
                <StyledTextInput
                    {...standardProps}
                    type="text"
                    invalid={isInvalid}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    aria-invalid={Boolean(isInvalid)}
                    aria-label={ariaLabel}
                    aria-describedby={this.errorElementId}
                />
                {isInvalid && typeof invalidProp === "string" && (
                    <ErrorMessage id={this.errorElementId} message={invalidProp} />
                )}
            </>
        );
    }
}

export default TextInput;
