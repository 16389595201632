import { default as React, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import styled from "styled-components";
import styles from "styles/styles";
import SearchInput from "../../common/ui/SearchInput/SearchInput";

export interface Props {
    searchTerm: string;
    onSearchTermChange: (searchTerm: string) => void;
}

const SearchPageSection = styled.div`
    background-color: ${styles.colors.lightGrey};
`;

const SearchInputContainer = styled.div`
    padding: ${styles.spacing[3]} 0;
    margin: 0 auto;
    max-width: calc(${styles.layout.pageMaxWidth} / 1.5);

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        > *:last-child {
            margin-left: ${styles.spacing[2]};
        }
    }
`;

const Title = styled.h5`
    margin-bottom: 0;
    text-align: center;
`;

function SearchSection({ searchTerm: currentSearchTerm, onSearchTermChange }: Props): ReactElement {
    const translate = useTranslate();
    const [searchTerm, setSearchTerm] = useState("");

    // If the search term given as prop changes, set the local search term to that one.
    // This also inits the local state.
    useEffect(() => {
        setSearchTerm(currentSearchTerm);
    }, [currentSearchTerm]);

    const onUserInput = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        onSearchTermChange(searchTerm);
    };

    return (
        <SearchPageSection>
            <SearchInputContainer>
                <Title>
                    <FormattedMessage id="search.searchInput.title" />
                </Title>

                <SearchInput
                    value={searchTerm}
                    onChange={onUserInput}
                    ariaLabel={translate("search.searchInput.title")}
                />
            </SearchInputContainer>
        </SearchPageSection>
    );
}

export default SearchSection;
