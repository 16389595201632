import { AnyAction } from "redux";
import { isType } from "typescript-fsa";

import { clearToastNotification, createToastNotification, ToastNotification } from "./actions";

export interface ToastState {
    // Toast notifications internal to the application
    notifications: ToastNotification[];
}

const initialState: ToastState = {
    notifications: [],
};

export default function reducer(state = initialState, action: AnyAction): ToastState {
    if (isType(action, createToastNotification)) {
        return {
            ...state,
            notifications: [...state.notifications, action.payload],
        };
    }

    if (isType(action, clearToastNotification)) {
        return {
            ...state,
            notifications: state.notifications.filter(
                (notification) => notification.id !== action.payload.id
            ),
        };
    }

    return state;
}
