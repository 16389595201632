import React from "react";
import PaginationButton from "../PaginationButton/PaginationButton";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import { Row } from "../../ui/FlexboxGrid";

interface Props {
    resultsCountPerPage: number;
    totalResultsCount: number;
    currentPageNumber: number;
    onChange: (pageIndex: number) => void;
}

const activeButtonStyle = css`
    border-color: ${styles.colors.red};
    background-color: ${styles.colors.red};
    color: ${styles.colors.white};
`;

export const Button = styled.button<{ isActive: boolean }>`
    background-color: transparent;
    border: 1px solid ${styles.colors.grey4};
    border-radius: 50%;
    min-height: 44px;
    min-width: 44px;
    margin: ${styles.spacing[2]};
    color: ${styles.colors.grey7};
    font-size: ${styles.fontSize.normal};
    font-weight: bold;

    :hover {
        ${activeButtonStyle}
    }

    ${(props) => props.isActive && activeButtonStyle}
`;

const Pagination = ({
    totalResultsCount,
    resultsCountPerPage,
    onChange,
    currentPageNumber,
}: Props) => {
    // Rounds up the division's results to include the last page.
    const pageTotalCount = Math.ceil(totalResultsCount / resultsCountPerPage);
    const pageIndexes = [...Array(pageTotalCount).keys()].map((i) => i + 1);

    const incrementIsDisabled = currentPageNumber === pageTotalCount;
    const decrementIsDisabled = currentPageNumber === 1;

    const onIncrement = () => {
        if (currentPageNumber < pageTotalCount) {
            onChange(currentPageNumber + 1);
        }
    };

    const onDecrement = () => {
        if (currentPageNumber > 1) {
            onChange(currentPageNumber - 1);
        }
    };

    const onPageIndexClick = (pageIndex: number) => () => {
        if (pageIndex >= 1 && pageIndex <= pageTotalCount) {
            onChange(pageIndex);
        }
    };

    return (
        <Row>
            <PaginationButton
                onClick={onDecrement}
                isDisabled={decrementIsDisabled}
                direction="left"
            />
            {pageIndexes.map((pageIndex) => (
                <Button
                    key={pageIndex}
                    onClick={onPageIndexClick(pageIndex)}
                    isActive={pageIndex === currentPageNumber}
                    {...(pageIndex === currentPageNumber && { "aria-current": "page" })}
                >
                    {pageIndex}
                </Button>
            ))}
            <PaginationButton
                onClick={onIncrement}
                isDisabled={incrementIsDisabled}
                direction="right"
            />
        </Row>
    );
};

export default Pagination;
