import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Modal from "../../common/modals/Modal/Modal";

interface Props {
    isOpen: boolean;
    onRequestClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
    createNew?: boolean;
}

const Header = styled.h5`
    margin: 0;
    margin-bottom: 20px;
`;

function EditModal({
    isOpen,
    createNew,
    onRequestClose,
    children,
}: React.PropsWithChildren<Props>) {
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <Header>
                <FormattedMessage
                    id={createNew ? "contactInfo.new.title" : "contactInfo.edit.title"}
                />
            </Header>
            {children}
        </Modal>
    );
}

export default EditModal;
