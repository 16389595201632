import minus from "../../../../images/minus-square.svg";
import plus from "../../../../images/plus-square.svg";
import React, { PropsWithChildren, useState } from "react";
import Content from "../Content/Content";
import useId from "../../../../hooks/common/useId/useId";
import styled from "styled-components";
import { buttonStyles } from "../buttons";
import styles from "../../../../styles/styles";

interface Props {
    header: React.ReactNode;
    actions?: React.ReactNode;
    className?: string;
    iconClassName?: string;
    contentClassName?: string;
    openByDefault?: boolean;
    // Show a different header depending on whether the content is open or closed
    openHeader?: React.ReactNode;
    expandedAriaLabel: string;
    collapsedAriaLabel: string;
    headerId?: string;
}

const StyledButton = styled.button`
    ${buttonStyles.transparent}
    margin: 0 15px;
    color: ${styles.colors.grey7};
    padding: 0;
    text-align: left;
    height: auto;
    width: 100%;
`;

const StyledHeaderWrapper = styled.div<{ open?: boolean }>`
    background-color: ${styles.colors.white};
    box-sizing: border-box;
    display: flex;
    height: 70px;
    padding: 20px 0;
    align-items: center;

    ${(props) =>
        props.open &&
        `
        margin-bottom: 2px;
       `};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        min-height: 70px;
        height: auto;
    }
`;

const StyledHeader = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

const StyledHeaderLabel = styled.div`
    font-size: 18px;
    line-height: 30px;
    flex-grow: 1;
    padding-left: 20px;
`;

const StyledIcon = styled.img`
    cursor: pointer;
    height: 30px;
    width: 30px;
`;

/**
 * Expandable item
 */
const Expandable = (props: PropsWithChildren<Props>) => {
    const [open, setOpen] = useState<boolean>(props.openByDefault || false);

    const onClick = () => {
        setOpen(!open);
    };

    const randomId = useId();
    const headerId = props.headerId || randomId;

    return (
        <div className={props.className}>
            <StyledHeaderWrapper>
                <StyledHeader>
                    <StyledButton
                        onClick={onClick}
                        role="button"
                        name={open ? props.expandedAriaLabel : props.collapsedAriaLabel}
                        aria-expanded={open}
                        aria-label={open ? props.expandedAriaLabel : props.collapsedAriaLabel}
                    >
                        <StyledIcon src={open ? minus : plus} alt="" />
                        <StyledHeaderLabel id={headerId}>
                            <span>
                                {open && props.openHeader ? props.openHeader : props.header}
                            </span>
                        </StyledHeaderLabel>
                    </StyledButton>
                    {props.actions}
                </StyledHeader>
            </StyledHeaderWrapper>
            {open ? <Content className={props.contentClassName}>{props.children}</Content> : null}
        </div>
    );
};

export default Expandable;
