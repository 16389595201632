import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";
import { getLocation, push } from "connected-react-router";
import { Action } from "redux";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { RootState } from "state/reducers";
import { fetchSwitchMap } from "state/observable";
import { config } from "services/common/config/config";
import { BLOG_PAGE_NUM_QUERY_PARAM } from "services/common/url/url";

import {
    fetchCommonContent,
    fetchActivePage,
    fetchLandingPage,
    fetchNewsFeedItem,
    fetchBlogList,
    fetchBlogPost,
    updateBlogPageNumber,
} from "./actions";

export const fetchCommonContentEpic = fetchSwitchMap(fetchCommonContent, () => ({
    url: `${config.content.commonContent}`,
}));

export const fetchActivePageEpic = fetchSwitchMap(fetchActivePage, (action) => ({
    url: `${config.content.page}/${action.payload}`,
}));

export const fetchLandingPageEpic = fetchSwitchMap(fetchLandingPage, () => ({
    url: `${config.content.landingPage}`,
}));

export const fetchBlogEpic = fetchSwitchMap(fetchBlogList, (action) => ({
    url: `${config.content.blog}`,
    queryParameters: {
        ...action.payload,
    },
}));

export const fetchBlogPostEpic = fetchSwitchMap(fetchBlogPost, (action) => ({
    url: `${config.content.blog}/${action.payload}`,
}));

export const fetchNewsFeedItemEpic = fetchSwitchMap(fetchNewsFeedItem, (action) => ({
    url: `${config.content.news}/${action.payload}`,
}));

export const updateBlogPageEpic = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(updateBlogPageNumber.match),
        withLatestFrom(state$),
        map(([action, state]) =>
            push(
                action.payload
                    ? `${getLocation(state).pathname}?${new URLSearchParams({
                          [BLOG_PAGE_NUM_QUERY_PARAM]: String(action.payload),
                      }).toString()}`
                    : getLocation(state).pathname // Removes query paramter when no search query is added
            )
        )
    );

export default combineEpics(
    fetchCommonContentEpic,
    fetchActivePageEpic,
    fetchLandingPageEpic,
    fetchNewsFeedItemEpic,
    fetchBlogEpic,
    fetchBlogPostEpic,
    updateBlogPageEpic
);
