import React from "react";
import DatePicker, { DatePickerProps } from "../DatePicker/DatePicker";
import Info from "../../Info/Info";
import useId from "../../../../../hooks/common/useId/useId";
import styled from "styled-components";
import useTranslate from "../../../../../hooks/common/useTranslate/useTranslate";

export type LabeledDatePickerProps = {
    label: string;
    infoIntlId?: string;
} & DatePickerProps;

/**
 * Component to pick a single date
 */

const StyledInfo = styled(Info)`
    img {
        width: 17px;
        height: 17px;
    }
`;

const StyledLabeledDatePicker = styled.div`
    font-size: 18px;
`;

const LabeledDatePicker = ({ label, infoIntlId, ...datePickerProps }: LabeledDatePickerProps) => {
    const id = useId();
    const translate = useTranslate();

    return (
        <StyledLabeledDatePicker>
            <label htmlFor={datePickerProps.id || id}>
                <div>
                    {label}
                    {datePickerProps.required ? "*" : ""}
                    {infoIntlId ? (
                        <StyledInfo infoIconAltText={label} infoText={translate(infoIntlId)} />
                    ) : null}
                </div>
            </label>
            <DatePicker id={datePickerProps.id || id} {...datePickerProps} />
        </StyledLabeledDatePicker>
    );
};

export default LabeledDatePicker;
