import React from "react";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Document } from "interfaces/rich-text-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clip from "text-clipper";
import dompurify from "dompurify";

import { LocalizedDocument } from "interfaces/content";
import useLocale from "hooks/common/useLocale/useLocale";
import RichText from "../RichText/RichText";

export interface Props {
    richText: LocalizedDocument;
    readMoreLink: string;
    maxCharLength: number;
    readMoreIntlKey: string;
    closeModal?: () => void;
}

const getDocumentPreviewHtmlString = (document: Document, maxCharLength: number) => {
    const documentHtmlString = documentToHtmlString(document);

    const documentPreviewHtmlString = clip(documentHtmlString, maxCharLength, {
        html: true,
        indicator: "...",
    });

    // Sanitizes HTML and prevents XSS attacks. Strips images from the preview.
    return dompurify.sanitize(documentPreviewHtmlString, { FORBID_TAGS: ["img"] });
};

enum BLOCKS {
    DOCUMENT = "document",
}

const RichTextPreview = ({
    richText,
    readMoreLink,
    maxCharLength,
    readMoreIntlKey,
    closeModal,
}: Props) => {
    const locale = useLocale();

    const document = richText[locale];

    if (!document) {
        return null;
    }

    // Coercion is used due to the Document type mismatch between documentToPlainTextString's library & our local Document type.
    const richTextDocument: Document = {
        nodeType: BLOCKS.DOCUMENT,
        content: document.content.map((content) => ({
            nodeType: content.nodeType,
            content: content.content,
            data: content.data,
        })),
        data: {},
    };

    const noImages = richTextDocument.content.every(
        (content) => content.nodeType !== "embedded-asset-block"
    );

    const documentPlainText = documentToPlainTextString(richTextDocument);
    const textWithinCharLimits = documentPlainText.length <= maxCharLength;

    if (textWithinCharLimits && noImages) {
        // No need to truncate the content if it is within the characters limit defined and there are no embedded assets (images).
        return <RichText richText={richText} />;
    }

    const documentPreviewHtml = getDocumentPreviewHtmlString(richTextDocument, maxCharLength);

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: documentPreviewHtml,
                }}
            />
            <Link to={readMoreLink} onClick={closeModal}>
                <FormattedMessage id={readMoreIntlKey} />
            </Link>
        </>
    );
};

export default RichTextPreview;
