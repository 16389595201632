import { AnyAction } from "redux";
import { isType } from "typescript-fsa";

import { Page, CommonContent, LandingPage, NewsFeedItem, BlogPost } from "interfaces/content";
import { Blog } from "interfaces/blog";
import {
    fetchCommonContent,
    fetchActivePage,
    fetchLandingPage,
    fetchNewsFeedItem,
    fetchBlogList,
    fetchBlogPost,
} from "./actions";

export interface ContentfulState {
    commonContent: CommonContent | null;
    activePage: Page | null;
    landingPage: LandingPage | null;
    activeNewsFeedItem: NewsFeedItem | null;
    blog: Blog | null;
    activeBlogPost: BlogPost | null;
}

const initialState: ContentfulState = {
    activePage: null,
    landingPage: null,
    commonContent: null,
    activeNewsFeedItem: null,
    blog: null,
    activeBlogPost: null,
};

export default function reducer(state = initialState, action: AnyAction): ContentfulState {
    if (isType(action, fetchCommonContent.started)) {
        return { ...state, commonContent: null };
    }

    if (isType(action, fetchCommonContent.done)) {
        return { ...state, commonContent: action.payload.result };
    }

    if (isType(action, fetchActivePage.started)) {
        return { ...state, activePage: null };
    }

    if (isType(action, fetchActivePage.done)) {
        const activePage = action.payload.result;

        return { ...state, activePage };
    }

    if (isType(action, fetchLandingPage.started)) {
        return { ...state, landingPage: null };
    }

    if (isType(action, fetchBlogList.started)) {
        return { ...state, blog: null };
    }

    if (isType(action, fetchBlogList.done)) {
        return { ...state, blog: action.payload.result };
    }

    if (isType(action, fetchBlogPost.started)) {
        return { ...state, activeBlogPost: null };
    }

    if (isType(action, fetchBlogPost.done)) {
        return { ...state, activeBlogPost: action.payload.result };
    }

    if (isType(action, fetchLandingPage.done)) {
        return {
            ...state,
            landingPage: action.payload.result,
        };
    }

    if (isType(action, fetchNewsFeedItem.started)) {
        return {
            ...state,
            activeNewsFeedItem: null,
        };
    }

    if (isType(action, fetchNewsFeedItem.done)) {
        return {
            ...state,
            activeNewsFeedItem: action.payload.result,
        };
    }

    return state;
}
