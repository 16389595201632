// Based on https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
import { omit, merge } from "lodash";
import { AnyAction } from "redux";

export interface FailedAction {
    statusCode: number;
    error: string;
    message?: string;
    payload: any;
}

export interface TypedFailedAction extends FailedAction {
    type: string;
}

export interface FailedState {
    [actionType: string]: {
        // "all" is always set regardless of action.meta.id to the latest value.
        // The value is null if a started action of type actionType has been dispatched and has not failed.
        all: FailedAction | null;
        // Each request has an id, which is either defined when dispatching an action,
        // or alternatively generated automatically with a Redux middleware.
        id: { [metaId: string]: FailedAction };
    };
}

const initialState: FailedState = {};

export default function reducer(state = initialState, action: AnyAction): FailedState {
    const matches = /^(.*)_(STARTED|FAILED)$/.exec(action.type);

    if (!matches) {
        return state;
    }

    if (!action.meta || !action.meta.id) {
        throw new Error(`Async action ID not found (${action.type})`);
    }

    const [, requestName, requestState] = matches;

    if (requestState === "FAILED") {
        const failedAction = { ...action.payload.error, payload: action.payload.params };

        return {
            ...state,
            [requestName]: {
                ...state[requestName],
                all: failedAction,
                id: {
                    ...(state[requestName] ? state[requestName].id : {}),
                    [action.meta.id]: failedAction,
                },
            },
        };
    } else {
        // Handle requestState === "STARTED".
        // This clears the "all" state, and also any matching action.meta.id

        // Omit matching ids, which will remove any FailedAction from the state with
        // matching request name and action.meta.id if a new STARTED action is dispatched.
        const nonMatching = Object.entries(state[requestName] ? state[requestName].id : {})
            .filter(([metaId]) => action.meta.id !== metaId)
            .map(([metaId]) => ({
                [metaId]: state[requestName].id[metaId],
            }));

        // If there would be no failed actions in the state for the given requestName,
        // remove the object completely to avoid assigning e.g. '"FETCH_X": {}' to the state.
        if (nonMatching.length === 0) {
            return omit(state, [requestName]);
        } else {
            return {
                ...state,
                [requestName]: {
                    // On STARTED, always clear the "all" state
                    all: null,
                    // Merge [{id1: ...}, {id2: ...}] to {id1: ..., id2: ...}
                    id: merge({}, ...nonMatching),
                },
            };
        }
    }
}
