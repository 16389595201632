import React, { ReactElement } from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";

interface Props {
    className?: string;
    isLoading: boolean;
}

const StyledSpinner = styled(Spinner)`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top: 0;
    left: 0;
    pointer-events: none;
`;

const LoadingIndicator = ({ isLoading, className }: Props): ReactElement | null =>
    isLoading ? (
        <StyledSpinner className={className} name="ball-beat" color="red" fadeIn="none" />
    ) : null;

export default LoadingIndicator;
