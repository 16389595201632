import * as React from "react";
import useId from "../../../../hooks/common/useId/useId";
import styled, { css } from "styled-components";
import styles from "../../../../styles/styles";

interface Props {
    checked: boolean;
    label: React.ReactNode;
    onClick: (checked: boolean) => void;
    disabled?: boolean;
    // The font size in pixels. The checkbox will be scaled based on this.
    // Default is 18.
    fontSize?: number;
    id?: string;
    name?: string;
}

const CheckboxWrapper = styled.span`
    display: flex;
    position: relative;

    input[type="checkbox"]:checked + div::after {
        content: "✓";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: inherit;
        width: inherit;
        text-align: center;
    }

    input[type="checkbox"]:disabled + div {
        border-color: ${styles.colors.disabledGrey};
    }

    input[type="checkbox"]:focus + div {
        outline: ${styles.colors.grey7} solid 1px;
    }
`;

const StyledCheckbox = styled.div`
    border: 1px solid ${styles.colors.grey6};
    box-sizing: border-box;
    margin-right: 10px;
    position: relative;
`;

const StyledCheckboxInput = styled.input`
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
`;

const disabledLabel = css`
    color: ${styles.colors.disabledGrey};
`;

const StyledCheckboxLabel = styled.label<{ disabled?: boolean }>`
    display: flex;
    user-select: none;
    ${({ disabled }) => disabled && disabledLabel}
`;

const Checkbox = (props: Props) => {
    const fontSize = props.fontSize || 18;
    const checkboxSize = Math.round(fontSize / 0.75);

    const randomId = useId();
    const id = props.id || randomId; // Id is necessary to make whole label text clickable

    const onChange = () => {
        props.onClick(!props.checked);
    };

    return (
        <CheckboxWrapper>
            <StyledCheckboxLabel disabled={props.disabled} style={{ fontSize }} htmlFor={id}>
                <StyledCheckboxInput
                    id={id}
                    name={props.name}
                    type="checkbox"
                    onChange={onChange}
                    checked={props.checked}
                    disabled={props.disabled}
                />
                <StyledCheckbox
                    style={{
                        height: checkboxSize,
                        width: checkboxSize,
                        minHeight: checkboxSize,
                        minWidth: checkboxSize,
                    }}
                />
                {props.label}
            </StyledCheckboxLabel>
        </CheckboxWrapper>
    );
};

export default Checkbox;
