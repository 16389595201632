import React, { memo } from "react";
import expand from "images/expand.svg";
import FileType from "../FileType/FileType";
import styled, { css } from "styled-components";
import styles from "styles/styles";

type Variant = "normal" | "small";

export interface Props extends React.HTMLProps<HTMLAnchorElement> {
    variant?: Variant;
    extension: string;
    children: React.ReactNode;
}

const Image = styled.img`
    margin-left: 4px;
    margin-right: 4px;
`;

const Container = styled.div`
    align-self: flex-start;
    display: flex;
    align-items: center;
`;

const SmallLink = css`
    font-size: ${styles.fontSize.small};
    font-weight: 500;
`;

const LinkContainer = styled.div<{ variant?: Variant }>`
    a {
        color: ${styles.colors.grey7};
        font-weight: bold;
        text-decoration: underline;
        font-size: ${styles.fontSize.small};
        cursor: pointer;

        &:hover {
            color: ${styles.colors.grey7};
            text-decoration: underline;
        }

        ${(props) => (props.variant === "small" ? SmallLink : "")}
    }
`;
const FileLink = ({
    children,
    extension,
    variant,
    className,
    ...anchorProps
}: Props): JSX.Element => (
    <Container className={className}>
        <Image src={expand} />
        <LinkContainer variant={variant}>
            <a {...anchorProps}>{children}</a>
        </LinkContainer>
        <FileType extension={extension} variant={variant} />
    </Container>
);

export default memo(FileLink);
