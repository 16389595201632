import React from "react";
import { FormattedMessage } from "react-intl";

import useLocale from "hooks/common/useLocale/useLocale";
import { Contact, ContactList } from "interfaces/content";
import { Locale } from "services/common/localization/localization";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    contactList: ContactList;
}

const getContactImgSrc = (contact: Contact, locale: Locale) => {
    if (!contact.image) {
        return "";
    }

    const localizedImgFile = contact.image.file[locale];

    return localizedImgFile ? localizedImgFile.url : "";
};

const Container = styled(Row)`
    max-width: 670px;
    text-align: center;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        text-align: left;
    }
`;

const ContactImage = styled.img`
    height: 150px;
    width: 140px;
    margin: ${styles.spacing[3]} auto;
    object-fit: cover;
    display: block;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin: ${styles.spacing[3]} 0;
    }
`;

const ContactInfo = styled.ul`
    list-style: none;
    padding: 0;
`;

const RichTextContactList = ({ contactList }: Props) => {
    const locale = useLocale();

    return (
        <Container>
            {contactList.contacts.map((contact) => (
                <Col xs={6} md={4} key={contact.name}>
                    {contact.image && contact.image.file[locale] && (
                        <ContactImage
                            src={getContactImgSrc(contact, locale)}
                            alt={`${
                                contact.image.description ? contact.image.description[locale] : ""
                            }`}
                        />
                    )}
                    <ContactInfo>
                        <li>{contact.name}</li>
                        <li>{contact.role[locale]}</li>
                        <li>{contact.email}</li>
                        <li>
                            <FormattedMessage
                                id="content.contact.tel"
                                values={{ telephoneNo: contact.telephoneNo }}
                            />
                        </li>
                        <li>{contact.extraInfo && contact.extraInfo[locale]}</li>
                    </ContactInfo>
                </Col>
            ))}
        </Container>
    );
};

export default RichTextContactList;
