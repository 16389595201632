import React, { ReactElement } from "react";

import { Event } from "interfaces/content";
import {
    formatDateWithMonthAndDay,
    getDateYear,
    formatDistanceFromNow,
} from "services/common/dates/dates";
import useLocale from "hooks/common/useLocale/useLocale";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    event: Event;
}

const EventRow = styled(Row)`
    background-color: ${styles.colors.white};
    margin: 0;

    :hover {
        color: ${styles.colors.red};
    }
`;

const ContentCol = styled(Col)`
    padding: ${styles.spacing[2]};
`;

const Description = styled.p`
    margin: 0;
    text-decoration: underline;
    color: ${styles.colors.red};
`;

const LinkStyle = css`
    text-decoration: none;
    color: ${styles.colors.grey7};
    width: 100%;
`;

const Link = styled.a`
    ${LinkStyle}

    :hover {
        ${LinkStyle}
    }
`;

const Date = styled.div`
    color: ${styles.colors.white};
    font-size: ${styles.fontSize.normal};
    font-weight: bold;
`;

const DateWrapper = styled(Col)`
    padding: ${styles.spacing[2]};
    background-color: ${styles.colors.grey7};
    text-align: center;
    overflow: hidden;
`;

const DateCount = styled.div`
    color: ${styles.colors.white};
    font-size: 14px;
    word-break: break-word;
`;

const Location = styled.div`
    color: ${styles.colors.grey5};
`;

function EventCard({ event }: Props): ReactElement {
    const locale = useLocale();

    return (
        <Link href={event.weblink} target="_blank" rel="noreferrer">
            <EventRow>
                <DateWrapper lg={3} xs={4}>
                    <Date>
                        {formatDateWithMonthAndDay(event.date, locale)}
                        <br />
                        {getDateYear(event.date)}
                    </Date>
                    <DateCount>{formatDistanceFromNow(event.date, locale)}</DateCount>
                </DateWrapper>
                <ContentCol lg={9} xs={8}>
                    <Description>{event.description[locale]}</Description>
                    <Location>{event.location}</Location>
                </ContentCol>
            </EventRow>
        </Link>
    );
}

export default EventCard;
