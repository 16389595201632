import * as React from "react";
import { shouldVisualizeInvalid } from "../utilities";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import uniqueId from "lodash/uniqueId";
import styled from "styled-components";
import styles from "../../../../styles/styles";

export interface Option<T extends string = string> {
    value: T;
    label: string;
}

interface Props<T extends string>
    extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onSelect"> {
    options: readonly Option<T>[];
    // Empty string means no value is selected by default.
    // Once a value has been selected, the selection cannot be cleared.
    selectedValue: T | "";
    // Translated label for unknown (or empty) option
    unknownOption: string;
    onSelect?: (value: T) => void;
    containerClassName?: string;
    className?: string;
    // If true, the selection can also be deselected
    allowDeselection?: boolean;
    // If true, visualize loading indicator for options
    isLoading?: boolean;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;
    ariaLabelledBy?: string;
    ariaLabel?: string;
}

interface State {
    // Whether or not the user has focused and lost focus on this input.
    visited: boolean;
}

const Container = styled.div<{
    disabled?: boolean;
}>`
    width: 100%;
    font-size: 20px;
    position: relative;
    display: inline-block;
    ${(props) =>
        props.disabled &&
        `
    opacity: 0.5;
    pointer-events: none;
    & > select {
    opacity: 0.5;
    `}
`;

const StyledSelect = styled.select<{
    invalid?: boolean;
}>`
    text-indent: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    outline: none;
    appearance: none !important;
    display: block;
    width: 100%;
    /* Add extra padding to the right side to make room for the arrow */
    padding: 13px 40px 13px 20px !important;
    margin: 0;
    border: 1px solid #6d848f;
    border-radius: 0;
    background: #fff;
    color: #6d848f;
    font-size: 20px;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }

    &:hover {
        outline: 1px solid ${styles.colors.focusBorderColor};
    }

    ${(props) =>
        props.invalid &&
        `
    border-color: ${styles.colors.red};
    `}
`;

/**
 * Select drop-down component
 */
class Select<T extends string = string> extends React.Component<Props<T>, State> {
    state = { visited: false };
    errorElementId = uniqueId("errorMessage_");

    onChange: React.ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        if (this.props.onSelect) {
            this.props.onSelect(value as T);
        }
    };

    onBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
        this.setState({
            visited: true,
        });

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    isInvalid() {
        return shouldVisualizeInvalid(
            this.props.selectedValue,
            this.state.visited,
            this.props.invalid,
            this.props.required,
            this.props.validateBeforeFocus
        );
    }

    /**
     * Renders an option for unknown (or empty) value (if required)
     */
    renderUnknownOption() {
        const values = this.props.options.map((option) => option.value);
        const isUnknownOptionSelected = !values.some((value) => value === this.props.selectedValue);

        if (this.props.isLoading) {
            return null;
        } else if (isUnknownOptionSelected) {
            return <option value={this.props.selectedValue}>{this.props.unknownOption}</option>;
        } else if (this.props.allowDeselection) {
            return <option value="">{this.props.unknownOption}</option>;
        } else {
            return null;
        }
    }

    public render() {
        const isInvalid = this.isInvalid();

        const {
            options,
            selectedValue,
            unknownOption,
            onSelect,
            containerClassName,
            className,
            allowDeselection,
            isLoading,
            invalid,
            validateBeforeFocus,
            ariaLabel,
            ariaLabelledBy,
            onBlur,
            ...standardProps
        } = this.props;

        return (
            <>
                <Container disabled={standardProps.disabled}>
                    <StyledSelect
                        {...standardProps}
                        invalid={isInvalid}
                        value={selectedValue}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        aria-invalid={Boolean(isInvalid)}
                        aria-describedby={this.errorElementId}
                        aria-labelledby={ariaLabelledBy}
                        aria-label={ariaLabel}
                    >
                        {this.renderUnknownOption()}
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </StyledSelect>
                    <LoadingIndicator isLoading={Boolean(isLoading)} />
                </Container>
                {isInvalid && typeof invalid === "string" && (
                    <ErrorMessage id={this.errorElementId} message={invalid} />
                )}
            </>
        );
    }
}

export default Select;
