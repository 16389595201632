import { config } from "services/common/config/config";
import { downloadMergeMap, fetchSwitchMap } from "state/observable";
import { combineEpics } from "redux-observable";
import { downloadAttachment, fetchServiceAgreements } from "./actions";

export const fetchServiceAgreementsEpic = fetchSwitchMap(fetchServiceAgreements, (action) => ({
    url: config.serviceAgreements.endpoints.get(action.payload.businessId),
    requireAuthorizationHeader: true,
}));

export const downloadAttachmentEpic = downloadMergeMap(downloadAttachment, (action) => ({
    url: config.serviceAgreements.endpoints.attachment(
        action.payload.businessId,
        action.payload.fileId
    ),
    requireAuthorizationHeader: true,
}));

export default combineEpics(fetchServiceAgreementsEpic, downloadAttachmentEpic);
