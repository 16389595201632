import React, { ReactElement, useMemo, useState, useCallback } from "react";

import ConfirmModal from "components/common/modals/ConfirmModal/ConfirmModal";
import RichTextPreview from "components/content/RichTextPreview/RichTextPreview";
import { NewsFeedItem as NewsFeedItemType } from "interfaces/content";
import { NEWS_URL_SEGMENT } from "services/common/url/url";
import useLocale from "hooks/common/useLocale/useLocale";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import { formatDateWithHour } from "services/common/dates/dates";
import RichText from "components/content/RichText/RichText";
import Tag from "components/content/Tag/Tag";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import styles from "styles/styles";
import LoadingState from "components/news/common/LoadingStateContainer";
import DeleteButton from "../../../common/ui/buttons/DeleteButton/DeleteButton";
import Button from "../../../common/ui/buttons/Button/Button";
import { Col, Row } from "../../../common/ui/FlexboxGrid";

interface Props {
    newsFeedItem: NewsFeedItemType;
    previewMode?: boolean;
    onNewsFeedItemDelete: (id: NewsFeedItemType["id"]) => void;
    onNewsFeedItemDeactivate: (newsFeedItem: NewsFeedItemType) => void;
    onEditorShow: () => void;
    actionsAllowed: boolean;
    isLoading?: boolean;
    closeModal?: () => void;
}

interface DeleteState {
    isDeleteConfirmOpen: boolean;

    // The ID of the news-feed item that the user is confirming to delete
    deleteConfirmId: NewsFeedItemType["id"] | null;
}

const initialDeleteState: DeleteState = {
    isDeleteConfirmOpen: false,
    deleteConfirmId: null,
};

const NewsFeedTag = styled(Tag)<{ isActive: boolean }>`
    background-color: ${(props) =>
        props.isActive ? styles.colors.grey3 : styles.colors.lightGrey};
`;
const TagContainer = styled.div`
    padding-right: ${styles.spacing[2]};
    padding-bottom: ${styles.spacing[2]};
`;

const TagsContainer = styled(Row)`
    margin: 0;
    padding-top: ${styles.spacing[3]};
`;

const CompanyName = styled.h5`
    color: ${styles.colors.red};
    font-size: ${styles.fontSize.normal};
    margin: 0;
    display: inline-block;
`;

const Circle = styled.div`
    width: 5px;
    height: 5px;
    margin: 0;
    border-radius: 50%;
    background: ${styles.colors.grey6};
    margin: 0 ${styles.spacing[1]} 4px;
    display: inline-block;
`;

const PublishingDate = styled.div`
    font-weight: 300;
    color: ${styles.colors.grey7};
    display: inline-block;
    font-size: ${styles.fontSize.normal};
`;

const DeactivateBtn = styled(Button)`
    border: 0;
    background: transparent;
    font-weight: 500;
    margin: 0 ${styles.spacing[1]};
    color: inherit;
`;

const ActionsContainer = styled.div`
    display: flex;
`;

const ItemHeader = styled(Row)`
    margin: 0;
`;

const activeStyle = css`
    padding: ${styles.spacing[3]} ${styles.spacing[3]} ${styles.spacing[4]} ${styles.spacing[3]};
    border: 2px solid ${styles.colors.grey3};
    background-color: ${styles.colors.lightGrey};
`;

const borderStyle = css`
    border-bottom: 1px solid ${styles.colors.lightGrey};
`;

const Container = styled(Row)<{ previewMode?: boolean; isActive?: boolean }>`
    padding: ${styles.spacing[3]} 0 ${styles.spacing[4]} 0;
    position: relative;

    ${(props) => props.previewMode && borderStyle};
    ${(props) => props.isActive && activeStyle};
`;

function NewsFeedItem({
    newsFeedItem,
    previewMode,
    onNewsFeedItemDelete,
    onNewsFeedItemDeactivate,
    onEditorShow,
    actionsAllowed,
    isLoading,
    closeModal,
}: Props): ReactElement {
    const [deleteState, setDeleteState] = useState(initialDeleteState);
    const locale = useLocale();
    const translate = useTranslate();

    const formattedDate = useMemo(
        () => formatDateWithHour(newsFeedItem.publishingDate, locale),
        [newsFeedItem.publishingDate, locale]
    );

    const onDeleteConfirm = useCallback(() => {
        if (deleteState.deleteConfirmId && !isLoading) {
            onNewsFeedItemDelete(deleteState.deleteConfirmId);
        }

        setDeleteState(initialDeleteState);
    }, [deleteState.deleteConfirmId, onNewsFeedItemDelete, isLoading]);

    const onDeleteCancel = useCallback(() => {
        setDeleteState(initialDeleteState);
    }, []);

    const defaultCompanyName = "Fingrid Datahub Oy"; // Used when no companyName is defined.

    return (
        <Container previewMode={previewMode} isActive={newsFeedItem.isActive}>
            <Col xs={12}>
                <ItemHeader between={"xs"} middle={"xs"}>
                    <div>
                        <CompanyName>
                            {/* All news-feed items created in Contentful's app by Fingrid content editors have no companyName. */}
                            {newsFeedItem.companyName || defaultCompanyName}
                        </CompanyName>
                        <Circle />
                        <PublishingDate>{formattedDate}</PublishingDate>
                    </div>
                    {actionsAllowed && (
                        <ActionsContainer>
                            <DeactivateBtn transparent onClick={() => onEditorShow()}>
                                <FormattedMessage id="common.edit" />
                            </DeactivateBtn>
                            {newsFeedItem.isActive && (
                                <DeactivateBtn
                                    onClick={() => onNewsFeedItemDeactivate(newsFeedItem)}
                                >
                                    <FormattedMessage id="newsfeed.deactivate" />
                                </DeactivateBtn>
                            )}
                            <DeleteButton
                                onClick={() => {
                                    setDeleteState({
                                        isDeleteConfirmOpen: true,
                                        deleteConfirmId: newsFeedItem.id,
                                    });
                                }}
                                deleteIconAltText={translate("common.delete")}
                            />
                        </ActionsContainer>
                    )}
                </ItemHeader>
                <div>
                    {previewMode ? (
                        <RichTextPreview
                            richText={newsFeedItem.content}
                            readMoreLink={`/${locale}/${NEWS_URL_SEGMENT}/${newsFeedItem.id}`}
                            maxCharLength={225}
                            readMoreIntlKey="newsfeed.readMore"
                            closeModal={closeModal}
                        />
                    ) : (
                        <RichText richText={newsFeedItem.content} />
                    )}
                </div>
                {newsFeedItem.newsFeedTags && (
                    <TagsContainer>
                        {newsFeedItem.newsFeedTags.map((tag, index) => (
                            <TagContainer key={index}>
                                <NewsFeedTag isActive={!!newsFeedItem.isActive} tag={tag} />
                            </TagContainer>
                        ))}
                    </TagsContainer>
                )}
            </Col>
            <ConfirmModal
                isOpen={deleteState.isDeleteConfirmOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
                descriptionKey="newsfeed.delete"
            />
            <LoadingState isLoading={!!isLoading} />
        </Container>
    );
}

export default NewsFeedItem;
