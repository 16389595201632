import { format, formatDistanceToNow, Locale as DateFnsLocale } from "date-fns";
import { fi, enUS as en } from "date-fns/locale";

import { Locale as EdielLocale } from "../localization/localization";

/**
 * @desc localeMapper maps ediel's locale to date-fns locale object.
 */
const localeMapper: Record<EdielLocale, DateFnsLocale> = {
    fi,
    en,
};

/**
 * @desc formatDateWithMonthAndDay Fn return a formatted date of the given date in days and written month.
 *       e.g. 27 Jan or 07 joulu
 * @param date
 */
export const formatDateWithMonthAndDay = (date: string | undefined, locale: EdielLocale) =>
    date && format(new Date(date), "dd MMM", { locale: localeMapper[locale] });

/**
 * @desc getDateYear Fn return the year of the given date.
 * @param date
 * @param locale
 */
export const getDateYear = (date: string | undefined) => date && format(new Date(date), "yyyy");

/**
 * @desc formatDistanceFromNow Fn return the distance between the given date and now in words.
 * @param date
 * @param locale
 */
export const formatDistanceFromNow = (date: string | undefined, locale: EdielLocale) =>
    date && formatDistanceToNow(new Date(date), { locale: localeMapper[locale], addSuffix: true });

/**
 * @desc formatDateWithHour Fn returns a formatted date that includes the hour & minutes.
 *       e.g. 31.12.2019 11:27
 * @param date
 * @param locale
 */
export const formatDateWithHour = (date: string | undefined, locale: EdielLocale) =>
    date && format(new Date(date), "d.M.yyy H:mm", { locale: localeMapper[locale] });

/**
 * @desc formatDate Fn returns a formatted date.
 *       e.g. 31.12.2019
 * @param date
 * @param locale
 */
export const formatDate = (date: string | undefined, locale: EdielLocale) =>
    date && format(new Date(date), "dd.MM.yyy", { locale: localeMapper[locale] });
