import * as React from "react";
import Select, { Option } from "../Select/Select";
import useId from "../../../../hooks/common/useId/useId";
import styled from "styled-components";

interface Props<T extends string> {
    options: readonly Option<T>[];

    // Empty string means no value is selected by default.
    // Once a value has been selected, the selection cannot be cleared.
    selectedValue: T | "";

    // Translated label
    label: string;

    required?: boolean;

    hideRequiredAsterisk?: boolean;

    onSelect: (value: T) => void;

    tabIndex?: number;

    disabled?: boolean;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;
    // If true, the selection can also be deselected
    allowDeselection?: boolean;

    // If true, visualize loading indicator for options
    isLoading?: boolean;

    selectClassName?: string;

    // Translated label for unknown (or empty) option
    unknownOption: string;

    name?: string;
}

const Container = styled.div`
    font-size: 18px;
`;

function LabeledSelect<T extends string>(
    props: Props<T>
): React.FunctionComponentElement<Props<T>> {
    const id = useId("select-");

    return (
        <Container>
            <label>
                <span id={id}>{props.label}</span>
                {props.required && !props.hideRequiredAsterisk ? "*" : ""}
                <Select
                    name={props.name}
                    className={props.selectClassName}
                    selectedValue={props.selectedValue}
                    options={props.options}
                    onSelect={props.onSelect}
                    tabIndex={props.tabIndex}
                    disabled={props.disabled}
                    required={props.required}
                    allowDeselection={props.allowDeselection}
                    isLoading={props.isLoading}
                    unknownOption={props.unknownOption}
                    invalid={props.invalid}
                    validateBeforeFocus={props.validateBeforeFocus}
                    ariaLabelledBy={id}
                />
            </label>
        </Container>
    );
}

export default LabeledSelect;
