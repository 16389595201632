import useLocale from "hooks/common/useLocale/useLocale";
import { LocalizedString } from "interfaces/common";
import { isExternalUrl } from "services/common/link/link";
import React, { PropsWithChildren, ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    localizedUrl: LocalizedString;
    openExternalLinkToNewTab: boolean;
    className?: string;
}

function Link({
    localizedUrl,
    openExternalLinkToNewTab,
    className,
    children,
}: PropsWithChildren<Props>): ReactElement {
    const locale = useLocale();
    const url = localizedUrl[locale] || "";

    return isExternalUrl(url) ? (
        <a
            className={className}
            target={openExternalLinkToNewTab ? "_blank" : undefined}
            href={url}
            rel="noopener"
        >
            {children}
        </a>
    ) : (
        <RouterLink className={className} to={`/${locale}/${url}`}>
            {children}
        </RouterLink>
    );
}

export default Link;
