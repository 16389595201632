import React, { ReactElement, useMemo } from "react";

import useLocale from "hooks/common/useLocale/useLocale";
import { DocumentFile as DocumentFileType } from "interfaces/content";
import { formatDate } from "services/common/dates/dates";
import { config } from "services/common/config/config";
import FileTags from "components/common/files/FileTags/FileTags";
import FileLink from "components/common/files/FileLink/FileLink";
import FileListItem from "components/common/files/FileListItem/FileListItem";
import { extractDocumentTags } from "components/content/Document/Document";
import DocumentDate from "../Document/DocumentDate";

export interface Props {
    document: DocumentFileType;
}

function DocumentFile({ document }: Props): ReactElement {
    const locale = useLocale();

    const formattedDate = useMemo(
        () => formatDate(document.publishingDate, locale),
        [document.publishingDate, locale]
    );

    const tags = useMemo(
        () => extractDocumentTags(document?.documentTags || [], locale),
        [document.documentTags, locale]
    );

    const filename = `${document.mFilesDocument.file.name}.${document.mFilesDocument.file.extension}`;
    const fileUrl = `${config.documents.endpoints.file}${document.mFilesDocument.file.id}`;

    return (
        <FileListItem>
            <FileLink
                data-testid="document-title"
                href={fileUrl}
                download={filename}
                extension={document.mFilesDocument.file.extension}
            >
                {document.title}
            </FileLink>
            <DocumentDate data-testid="document-date">{formattedDate}</DocumentDate>
            {!!tags?.length && <FileTags tags={tags} />}
        </FileListItem>
    );
}

export default DocumentFile;
