import React from "react";
import { NavHashLink } from "react-router-hash-link";

/**
 * To forward className and activeClassName
 */
const NavLinkAdapter = ({
    className,
    ...props
}: { className?: string } & React.ComponentProps<typeof NavHashLink>) => (
    <NavHashLink
        {...props}
        className={`${className}__className`}
        activeClassName={`${className}__activeClassName`}
    />
);
export default NavLinkAdapter;
