import React from "react";

import { QueryResult } from "interfaces/search";
import Pagination from "components/common/pagination/Pagination/Pagination";
import styles from "styles/styles";
import styled from "styled-components";
import { Col, Row } from "../../common/ui/FlexboxGrid";

interface Props {
    searchResults: QueryResult;
    onPaginationChange: (pageIndex: number) => void;
    currentPageNumber: string;
}

const PaginationRow = styled(Row)`
    margin: 0 auto;
    padding: ${styles.spacing[3]} 0;
`;

const SearchPagination = ({ searchResults, onPaginationChange, currentPageNumber }: Props) => (
    <PaginationRow>
        <Col mdOffset={4} md={8} xs={12}>
            {/* Only displays pagination when there are more pages to show */}
            {searchResults.totalResultsCount > searchResults.pageSize && (
                <Pagination
                    totalResultsCount={searchResults.totalResultsCount}
                    resultsCountPerPage={searchResults.pageSize}
                    onChange={onPaginationChange}
                    currentPageNumber={+currentPageNumber}
                />
            )}
        </Col>
    </PaginationRow>
);

export default SearchPagination;
