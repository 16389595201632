import React from "react";
import { useLocation } from "react-router-dom";

const useQuery = (params: string[]): Record<string, string | null> => {
    const { search } = useLocation();
    const urlParams = React.useMemo(() => new URLSearchParams(search), [search]);

    return params.reduce((acc, param) => ({ ...acc, [param]: urlParams.get(param) }), {});
};

export default useQuery;
