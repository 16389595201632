import { some } from "lodash";
import { AsyncActionCreators } from "typescript-fsa";
import { RootState } from "../../reducers";

/*
 * Create a function that returns if the latest request failed of atleast one given type
 */
export const failedSelectorFactory =
    (actions: AsyncActionCreators<any, any>[]) => (state: RootState) =>
        some(actions, (action) =>
            Boolean(state.common.failed[action.type] && state.common.failed[action.type].all)
        );

/*
 * Create a function that checks if the state has any ongoing fetches of the given type with given id.
 */
export const idFailedSelectorFactory =
    <Params, Result, Error>(action: AsyncActionCreators<Params, Result, Error>) =>
    (state: RootState, id: string) => {
        const actionState = state.common.failed[action.type];

        return Boolean(actionState && actionState.id[id]);
    };
