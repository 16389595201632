import useLocale from "hooks/common/useLocale/useLocale";
import { CtaBlock as CtaBlockType, LinkButton } from "interfaces/content";
import styles from "styles/styles";
import objectFitImages from "object-fit-images";
import React, { ReactElement } from "react";
import styled from "styled-components";
import RichText from "../RichText/RichText";
import CtaBlockLink from "./CtaBlockLink";
import { Col, Row } from "../../common/ui/FlexboxGrid";

export interface Props {
    ctaBlock: CtaBlockType;
}

const getLinkButtonGroups = (linkButtons: LinkButton[]) => {
    const a: LinkButton[] = [],
        b: LinkButton[] = [];

    linkButtons.forEach((linkButton, index) => {
        if (index % 2 === 0) {
            a.push(linkButton);
        } else {
            b.push(linkButton);
        }
    });

    return [a, b];
};

const LinkButtons = styled(Row)`
    margin: 0;
`;

const Container = styled.div<{ bgImage: any }>`
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${styles.colors.lightGrey};
    background-image: url(${(props) => props.bgImage});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;
    min-height: 400px;
`;

const SubtitleContainer = styled.div`
    max-width: 800px;
`;

const Title = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
    line-height: 2rem;
    max-width: 800px;
`;

function CtaBlock({ ctaBlock }: Props): ReactElement {
    const locale = useLocale();
    objectFitImages(null); // Needed to allow object-fit to work on IE11

    return (
        <Container bgImage={`${ctaBlock.media ? ctaBlock.media.file[locale]!.url : ""}`}>
            <Title>{ctaBlock.title && ctaBlock.title[locale]}</Title>
            <SubtitleContainer>
                {ctaBlock.subTitle && <RichText richText={ctaBlock.subTitle} />}
                {ctaBlock.linkButtons && (
                    <LinkButtons>
                        {getLinkButtonGroups(ctaBlock.linkButtons).map((group, index) => (
                            <Col xs={12} md={6} key={index}>
                                <div>
                                    {group.map((linkButton, buttonIndex) => (
                                        <CtaBlockLink
                                            key={`${linkButton.url}${buttonIndex}`}
                                            linkButton={linkButton}
                                            isEven={index % 2 === 0}
                                        />
                                    ))}
                                </div>
                            </Col>
                        ))}
                    </LinkButtons>
                )}
            </SubtitleContainer>
        </Container>
    );
}

export default CtaBlock;
