import * as React from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import facebook from "./facebook.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import twitter from "./twitter.svg";
import rss from "./rss.svg";
import styled from "styled-components";
import styles from "styles/styles";
import { Col, Row } from "../../ui/FlexboxGrid";

const LinkWrapper = styled.div`
    margin-bottom: ${styles.spacing[4]};
`;

const Footer = styled.div`
    max-width: ${styles.layout.contentMaxWidth};
    margin: ${styles.layout.contentMargin};
    padding: ${styles.spacing[6]} ${styles.spacing[5]};
`;

const LinkHeader = styled.div`
    color: ${styles.colors.white};
    font-size: ${styles.fontSize.medium};
    font-weight: bold;
    margin-top: ${styles.spacing[4]};
    margin-bottom: ${styles.spacing[6]};
`;

const SocialMediaLink = styled.a`
    margin-right: ${styles.spacing[4]};

    :hover {
        opacity: 0.8;
    }
`;

const AdditionalInfoSocialMedia = styled.div`
    margin-top: 75px;
`;

const Link = styled.a`
    color: ${styles.colors.white};
    text-decoration: none;
    font-size: ${styles.fontSize.normal};

    :hover {
        color: ${styles.colors.white};
        text-decoration: underline;
    }
`;

class TopFooter extends React.Component<WrappedComponentProps> {
    private renderLink(url: string, key: string) {
        return (
            <LinkWrapper>
                <Link href={url} target="_blank" rel="noopener">
                    <FormattedMessage id={key} />
                </Link>
            </LinkWrapper>
        );
    }

    public render() {
        return (
            <Footer>
                <Row>
                    <Col xs={12} md={3}>
                        <div>
                            <LinkHeader>Fingrid</LinkHeader>
                            <LinkWrapper>
                                <Link href="https://www.fingrid.fi" target="_blank" rel="noopener">
                                    Fingrid.fi
                                </Link>
                            </LinkWrapper>
                            {this.renderLink(
                                "https://www.fingrid.fi/sivut/yhtio/esittely/",
                                "footer.about"
                            )}
                            {this.renderLink(
                                "https://www.fingrid.fi/sivut/ajankohtaista/tiedotteet/?tag=3467",
                                "footer.news"
                            )}
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div>
                            <LinkHeader>
                                <FormattedMessage id="footer.header.extranets" />
                            </LinkHeader>
                            {this.renderLink("https://go.finextra.fi", "footer.guaranteesOfOrigin")}
                            {this.renderLink(
                                "https://ekstra.fingrid.fi",
                                "footer.gridCustomerServices"
                            )}
                            {this.renderLink(
                                "https://energiaselvitys.fingrid.fi",
                                "footer.invoicingAndEnergyBalance"
                            )}
                            {this.renderLink(
                                "https://webamr.rejlers.fi/laatutieto/app",
                                "footer.quality"
                            )}
                            {this.renderLink("https://ekstra.fingrid.fi", "footer.projects")}
                            {this.renderLink(
                                "https://turvailmo.fingrid.fi",
                                "footer.safetyDeclaration"
                            )}
                            {this.renderLink(
                                "https://portal.nordsafety.com/account/login",
                                "footer.safetyAudits"
                            )}
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div>
                            <LinkHeader>
                                <FormattedMessage id="footer.header.additionalInformation" />
                            </LinkHeader>
                            {this.renderLink(
                                "https://www.fingrid.fi/kantaverkko/kehittaminen",
                                "footer.gridDevelopment"
                            )}
                            {this.renderLink(
                                "https://www.fingrid.fi/tietosuojaselosteet",
                                "footer.informationPrivacy"
                            )}
                            {this.renderLink(
                                "https://www.fingrid.fi/sivut/ajankohtaista/tiedotteet/?tag=3464",
                                "footer.disturbances"
                            )}
                            {this.renderLink(
                                "https://www.fingrid.fi/kantaverkko/turvallisuus",
                                "footer.safetyInstructions"
                            )}
                            {this.renderLink(
                                "https://www.finlex.fi/fi/laki/ajantasa/2013/20130588",
                                "footer.electricityMarketAct"
                            )}
                            {this.renderLink(
                                "https://www.fingrid.fi/sahkomarkkinat/markkinoiden-yhtenaisyys/eurooppa-yhteistyo/eic-koodit",
                                "footer.eicCodes"
                            )}
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <AdditionalInfoSocialMedia>
                            {this.renderLink(
                                `/${this.props.intl.locale}/dokumentaatio/dokumentaatio-ja-materiaalit`,
                                "footer.helpAndInstructions"
                            )}
                            {this.renderLink(
                                `/${this.props.intl.locale}/ota-yhteytta/tukipalvelu`,
                                "footer.contacts"
                            )}
                            <div>
                                <SocialMediaLink
                                    title="Facebook"
                                    href="https://www.facebook.com/fingridfi"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img src={`${facebook}`} alt="Facebook" />
                                </SocialMediaLink>
                                <SocialMediaLink
                                    title="Twitter"
                                    href="https://twitter.com/fingrid_oyj"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img src={`${twitter}`} alt="Twitter" />
                                </SocialMediaLink>
                                <SocialMediaLink
                                    title="Instagram"
                                    href="https://www.instagram.com/fingridoyj"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img src={`${instagram}`} alt="Instagram" />
                                </SocialMediaLink>
                                <SocialMediaLink
                                    title="LinkedIn"
                                    href="https://www.linkedin.com/company/42235"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img src={`${linkedin}`} alt="Linkedin" />
                                </SocialMediaLink>
                                <SocialMediaLink
                                    title="RSS"
                                    href="https://www.fingrid.fi/api/rss/news"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img src={`${rss}`} alt="RSS" />
                                </SocialMediaLink>
                            </div>
                        </AdditionalInfoSocialMedia>
                    </Col>
                </Row>
            </Footer>
        );
    }
}

export default injectIntl(TopFooter);
