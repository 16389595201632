import React from "react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { login } from "services/auth/auth";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import Page from "components/common/layout/Page";
import ContentContainer from "components/common/layout/ContentContainer";
import Button from "../../../components/common/ui/buttons/Button/Button";

const ErrorMessage = styled.div`
    margin: 20px 0;
`;

const LoginFailed = () => {
    const locale = useLocale();
    const onLogin = useCallback(() => {
        login(`/${locale}`);
    }, [locale]);

    return (
        <ContentContainer>
            <Page>
                <ErrorMessage>
                    <FormattedMessage id="login.loginFailed" />
                </ErrorMessage>
                <Button onClick={onLogin}>
                    <FormattedMessage id="navigation.login" />
                </Button>
            </Page>
        </ContentContainer>
    );
};

export default LoginFailed;
