import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import styles from "../../../../styles/styles";

interface Props {
    className?: string;
    children?: React.ReactNode;
}

export const contentStyles = css`
    position: relative;
    background-color: ${styles.colors.white};
    padding: 20px;
`;

const StyledContent = styled.div`
    ${contentStyles}
`;

function Content({ children }: Props): ReactElement {
    return <StyledContent>{children}</StyledContent>;
}

export default Content;
