import React, { useRef } from "react";
import { MediaContentYoutubeItem } from "interfaces/content";
import useElementDimensions from "hooks/common/useElementDimensions/useElementDimensions";
import styled from "styled-components";

interface Props {
    item: MediaContentYoutubeItem;
}

const aspectRatio = 16 / 9;

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const MediaContentYoutube = ({ item }: Props) => {
    const ref = useRef(null);
    const { width } = useElementDimensions(ref);

    return (
        <Container ref={ref}>
            <iframe
                width={width}
                height={width / aspectRatio}
                src={item.youtubeUrl}
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </Container>
    );
};

export default MediaContentYoutube;
