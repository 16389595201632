import React from "react";
import styled from "styled-components";

interface Props<T> {
    name: string;
    options: { value: T; label: string; description?: string; key: string }[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: T) => void;
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    margin: 6px 0;
    cursor: pointer;
`;

const StyledInput = styled.input`
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 15px;
    accent-color: #3e5660;
    outline: 0;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
        accent-color: red;
    }
`;

const Description = styled.div`
    font-size: 0.8rem;
    margin-left: 50px;
    margin-bottom: 10px;
`;

function RadioButton<T>({ name, options, onChange }: Props<T>) {
    return (
        <StyledForm>
            {options.map((option) => (
                <>
                    <StyledLabel key={option.key} htmlFor={option.key}>
                        <StyledInput
                            type="radio"
                            id={option.key}
                            name={name}
                            value={option.key}
                            onChange={(event) => {
                                onChange(event, option.value);
                            }}
                        />
                        {option.label}
                    </StyledLabel>
                    {option.description && <Description>{option.description}</Description>}
                </>
            ))}
        </StyledForm>
    );
}

export default RadioButton;
