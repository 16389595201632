import * as React from "react";
import Button from "../Button/Button";
import { isBoolean } from "lodash";
import styled from "styled-components";
import styles from "../../../../../styles/styles";

export interface IconButtonProps {
    onClick: (event: React.MouseEvent) => void;
    label?: string;
    ariaLabel?: string;
    disabled?: boolean;
}

interface Props extends IconButtonProps {
    icon: string;
}

const StyledIconButton = styled(Button)<{ disabled: boolean }>`
    background: none;
    height: 30px;
    min-width: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 0;
    color: ${styles.colors.red};
    padding: 0;
    ${({ disabled }) => disabled && `opacity: 0.2;`}
`;

const StyledIcon = styled.img`
    padding-right: 5px;
`;

const IconButton = ({ icon, label, ...rest }: Props) => (
    <StyledIconButton disabled={isBoolean(rest.disabled) ? rest.disabled : false}>
        <StyledIcon src={icon} alt="" />
        {label ? label : null}
    </StyledIconButton>
);

export default IconButton;
