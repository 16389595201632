import { LocalizedMarkdown } from "interfaces/common";
import { NewsFeed, NewsFeedItem, NewsPageParameters } from "interfaces/content";
import actionCreatorFactory from "./actionCreatorFactory";

const actionCreator = actionCreatorFactory();

export const createNewsFeedItem = actionCreator.async<
    {
        newsFeedItem: {
            content: LocalizedMarkdown;
            tagIds?: string[];
            isActive?: boolean;
        };
        customerId: string;
    },
    NewsFeedItem
>("CREATE_NEWS_FEED_ITEM");

export const updateNewsFeedItem = actionCreator.async<
    {
        newsFeedItem: {
            content: LocalizedMarkdown;
            tagIds?: string[];
            isActive?: boolean;
        };
        customerId: string;
    },
    NewsFeedItem
>("UPDATE_NEWS_FEED_ITEM");

export const deleteNewsFeedItem = actionCreator.async<NewsFeedItem["id"], void>(
    "DELETE_NEWS_FEED_ITEM"
);

export const fetchNewsFeed = actionCreator.async<NewsPageParameters, NewsFeed>("FETCH_NEWS_FEED");
