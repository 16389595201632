import * as React from "react";
import ReactModal, { BaseModalBackground, ModalProvider } from "styled-react-modal";
import { css } from "styled-components";
import styles from "../../../../styles/styles";

interface Props {
    // If falsy, default modal styling is used
    css?: string;

    // Modal style class (default is "medium")
    // Note: only applied when className is not defined
    size?: "small" | "medium" | "large";
    disableModalScrolling?: boolean;

    // Overrides inline styles used by react-modal
    overlayClassName?: string;

    disableWindowScrolling?: boolean;
    isOpen: boolean;

    onRequestClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
    shouldCloseOnOverlayClick?: boolean;
}

interface State {
    overflowHidden: boolean;
}

const small = css`
    width: 100%;
    max-width: 500px;
    min-height: auto;
`;

const medium = css`
    width: 100%;
    max-width: 800px;
`;

const large = css`
    width: 95%;
    max-width: 1400px;
    height: 95%;
    max-height: 1300px;
`;

const scrollable = css`
    overflow-y: auto;
`;

const modalStyles = {
    large,
    medium,
    small,
};

const StyledModal = ReactModal.styled`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding: 40px;
    top: 50%;
    max-height: 80%;
    z-index: 30;
    opacity: 1;
    background-color: white;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 20%);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;

    transition: all 0.3s ease-in-out;

    &:focus {
        outline: none;
    }

    ${(props: Props) => modalStyles[props.size || "medium"]};

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 20px;
        max-height: 100%;
        overflow-y: auto;
    }
`;

class Modal extends React.Component<React.PropsWithChildren<Props>, State> {
    state = {
        overflowHidden: false,
    };

    // Prevent scrolling of the background if disableWindowScrolling is true
    onAfterOpen = () => {
        if (this.props.disableWindowScrolling) {
            this.setOverflowHidden();
        }
    };

    componentWillUnmount() {
        if (this.state.overflowHidden) {
            this.setOverflowVisible();
        }
    }

    componentDidUpdate() {
        if (this.state.overflowHidden && !this.props.isOpen) {
            this.setOverflowVisible();
        }
    }

    setOverflowHidden() {
        // Add a hidden style attribute, which will be removed once the modal is closed
        document.body.style.overflow = "hidden";
        this.setState({
            overflowHidden: true,
        });
    }

    setOverflowVisible() {
        document.body.style.overflow = "";

        // Only remove the style attribute if no other styles remain
        if (document.body.style.length === 0) {
            document.body.removeAttribute("style");
        }

        this.setState({
            overflowHidden: false,
        });
    }

    getModalStyle() {
        if (this.props.css) {
            return this.props.css;
        }

        return `${this.props.size ? modalStyles[this.props.size] : modalStyles.medium}${
            this.props.disableModalScrolling !== true ? ` ${scrollable}` : ""
        }`;
    }

    public render() {
        return (
            <ModalProvider backgroundComponent={BaseModalBackground}>
                <StyledModal
                    backgroundProps={{ opacity: 0.1 }}
                    isOpen={this.props.isOpen}
                    afterOpen={this.onAfterOpen}
                    onBackgroundClick={this.props.onRequestClose as any}
                    onEscapeKeydown={this.props.onRequestClose as any}
                >
                    {this.props.children}
                </StyledModal>
            </ModalProvider>
        );
    }
}

export default Modal;
