import { isArray } from "lodash";
import moment from "moment";

/**
 * An input is visualized as invalid if
 * a) the value is invalid, and the input is either not empty or has been focused, or
 * b) the input is required, and the input is empty and has been focused.
 */
export const shouldVisualizeInvalid = (
    value: unknown | unknown[],
    visited: boolean,
    invalid?: boolean | string,
    required?: boolean,
    forceValidation?: boolean
) => {
    // Note: we assume value is not of type number (as 0 is not an empty value)
    const isEmpty = isArray(value) ? value.length === 0 : !value;
    const shouldValidate = visited || forceValidation;

    return Boolean(
        (invalid && (!isEmpty || shouldValidate)) || (required && isEmpty && shouldValidate)
    );
};

/**
 * Set moment locale
 * @param language e.g. "en", "fi", etc.
 */
export const setMomentLocale = (language: string) => {
    moment.locale(language);
};
