import * as React from "react";
import plus from "../../../../images/plus-circle.svg";
import { buttonStyles } from "../buttons";
import styled, { css } from "styled-components";

interface Props {
    onClick?: () => void;

    // Translated label
    label: string;

    className?: string;
    disabled?: boolean;
    disabledClassName?: string;
}

const addNewButton = css`
    ${buttonStyles.transparent}
    padding: 0;
    cursor: pointer;
`;

const disabled = css`
    cursor: default;
    pointer-events: none;
`;

const contents = css`
    display: flex;
    font-size: 18px;
    align-items: center;

    > span {
        line-height: 24px;
    }
`;

const icon = css`
    height: 24px;
    width: 24px;
    margin-right: 5px;
    color: #fff;
`;

const StyledContents = styled.div`
    ${contents}
`;

const StyledIcon = styled.img`
    ${icon}
`;

const AddNewButton = styled.button`
    ${addNewButton}
    :disabled {
        ${disabled}
    }
`;

/**
 * Add new icon component
 */
class AddNew extends React.Component<Props> {
    private renderContent() {
        return (
            <StyledContents>
                <StyledIcon src={plus} alt="" />
                {this.props.label}
            </StyledContents>
        );
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    public render() {
        return (
            <AddNewButton onClick={this.onClick} disabled={this.props.disabled}>
                {this.renderContent()}
            </AddNewButton>
        );
    }
}

export default AddNew;
