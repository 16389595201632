import React, { ForwardedRef, forwardRef, MouseEvent, useCallback, useMemo, useState } from "react";
import calendarSvg from "../../../../images/calendar.svg";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { shouldVisualizeInvalid } from "../utilities";
import uniqueId from "lodash/uniqueId";
import { format } from "date-fns";
import styles from "../../../../styles/styles";
import styled from "styled-components";

export const DATE_STRING_FORMAT = "yyyy-MM-dd";

export interface DatePickerInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
    value: string | Date | null | undefined;
    // If truthy and the input has been focused, visualize the input as invalid. A string describes the error.
    invalid?: boolean | string;
    ariaLabel?: string;
    // visualize the input as invalid even if the input haven't had focus.
    validateBeforeFocus?: boolean;
    inputClassName?: string;
    hideIcon?: boolean;
}

const StyledTextInput = styled.input<{ isInvalid?: boolean }>`
    background-color: ${styles.colors.white};
    border: 1px solid ${styles.colors.grey4};
    box-sizing: border-box;
    color: ${styles.colors.grey7};
    font-family: "Lab Grotesque";
    font-size: 20px;
    padding: 11px 20px;
    margin: 0;
    outline: none;
    width: 100%;
    &:focus {
        outline: 2px solid ${styles.colors.focusBorderColor};
    }
    &:disabled {
        opacity: 0.5;
    }
    ${(props) =>
        props.isInvalid &&
        `
        border: 1px solid ${styles.colors.red};
    `};
`;

const StyledInputIcon = styled.img`
    width: 25px;
    height: 25px;
    margin-top: 4px;
`;

const DatePickerInput = forwardRef(
    (
        {
            inputIconAltText,
            onClick,
            className,
            invalid,
            value,
            required,
            validateBeforeFocus,
            inputClassName,
            onChange,
            onBlur,
            ariaLabel,
            id,
            disabled,
            hideIcon,
        }: DatePickerInputProps & { inputIconAltText?: string },
        ref: ForwardedRef<HTMLInputElement | null>
    ) => {
        const [visited, setVisited] = useState(false);

        const stringValue = useMemo(
            () =>
                !value ? "" : typeof value === "string" ? value : format(value, DATE_STRING_FORMAT),
            [value]
        );

        const isInvalid = useCallback(
            () =>
                shouldVisualizeInvalid(
                    stringValue,
                    visited,
                    invalid,
                    required,
                    validateBeforeFocus
                ),
            [invalid, required, stringValue, validateBeforeFocus, visited]
        );

        const errorElementId = useMemo(() => uniqueId("errorMessage_"), []);

        const onInputBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setVisited(true);

                if (onBlur) {
                    onBlur(event);
                }
            },
            [onBlur]
        );

        return (
            <div
                className={className}
                role="button"
                tabIndex={0}
                onClick={onClick}
                aria-label={ariaLabel + "-wrapper"}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && onClick && !disabled) {
                        onClick(event as unknown as MouseEvent<HTMLInputElement>);
                    }
                }}
                style={{ display: "flex", alignItems: "center" }}
            >
                <StyledTextInput
                    ref={ref}
                    type="text"
                    isInvalid={isInvalid()}
                    className={`${inputClassName}`}
                    value={stringValue}
                    onChange={onChange}
                    onBlur={onInputBlur}
                    aria-invalid={Boolean(isInvalid)}
                    aria-label={ariaLabel}
                    id={id}
                    aria-describedby={errorElementId}
                    disabled={disabled}
                />
                {isInvalid() && typeof invalid === "string" && (
                    <ErrorMessage id={errorElementId} message={invalid} />
                )}
                {!hideIcon && (
                    <StyledInputIcon
                        style={{ marginLeft: "0.5rem" }}
                        src={calendarSvg}
                        aria-label={ariaLabel + "-icon"}
                        alt={inputIconAltText}
                    />
                )}
            </div>
        );
    }
);

export default DatePickerInput;
