import React from "react";
import { FormattedMessage } from "react-intl";
import { IframeContent as IframeContentType } from "interfaces/content";
import styled from "styled-components";
import styles from "styles/styles";

interface Props {
    iframe: IframeContentType;
}

const StyledIframe = styled.iframe`
    width: 100%;
    padding: ${styles.spacing[2]} 0;
    border: none;
    min-height: 50vh;
`;

const IframeContent = ({ iframe }: Props) => (
    <StyledIframe src={iframe.url} sandbox="allow-scripts allow-same-origin">
        <FormattedMessage id="info.iframeNotSupported" />
    </StyledIframe>
);

export default IframeContent;
