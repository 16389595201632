import { RootState } from "state/reducers";
import { cleanUp, downloadBlob } from "services/common/download/download";
import * as queryString from "query-string";
import { Action } from "redux";
import { ofType, StateObservable } from "redux-observable";
import { fromEvent, Observable, of } from "rxjs";
import { ajax, AjaxError } from "rxjs/ajax";
import {
    catchError,
    concat,
    defaultIfEmpty,
    delay,
    filter,
    map,
    merge,
    mergeMap,
    takeUntil,
    tap,
    withLatestFrom,
} from "rxjs/operators";
import { Action as FSAction, AsyncActionCreators } from "typescript-fsa";

import { getIdToken } from "services/auth/auth";
import {
    deleteRequest,
    getAuthorizationHeader,
    getJson,
    request as ajaxRequest,
    RequestInfo,
} from "./ajax";
import { refreshToken } from "./common/failed/actions";
import { updateProgress } from "./common/progress/actions";

/**
 * Returns an Observable which resolves to user's access token if Authorization header is required.
 * Or if not, then resolves to null.
 */
const getTokenIfRequired = (state: RootState, requireAuthorizationHeader?: boolean) =>
    of(requireAuthorizationHeader ? getIdToken(state) : null);

/**
 * Fetch data as observable.
 * Cancels previous requests.
 * Note: This function uses mergeMap, because it must handle request cancellation.
 */

export const fetchSwitchMap =
    <Params, Result, Error>(
        asyncActionCreator: AsyncActionCreators<Params, Result, Error>,
        getRequestInfo: (action: FSAction<Params>) => RequestInfo
    ) =>
    (action$: Observable<Action>, state$: StateObservable<RootState>) =>
        action$.pipe(
            filter(asyncActionCreator.started.match),
            withLatestFrom(state$),
            mergeMap(([startedAction, state]) => {
                const requestInfo = getRequestInfo(startedAction);

                return getTokenIfRequired(state, requestInfo.requireAuthorizationHeader).pipe(
                    mergeMap((token) =>
                        getJson<Result>(requestInfo, token).pipe(
                            map((response) =>
                                createDoneAction(response, asyncActionCreator, startedAction)
                            ),
                            catchError((error: AjaxError) =>
                                of(createFailedAction(error, asyncActionCreator, startedAction))
                            )
                        )
                    ),
                    // If a new STARTED action is dispatched before the previous one is complete, cancel it
                    takeUntil(action$.pipe(ofType(asyncActionCreator.started))),
                    defaultIfEmpty(transformStartedActionToCancelled(startedAction)),
                    catchError((error) =>
                        of(createFailedAction(error, refreshToken, startedAction))
                    )
                );
            })
        );

/**
 * Creates done action with given payload and metadata
 */
const createDoneAction = <Params, Result, Error>(
    response: Result,
    asyncActionCreator: AsyncActionCreators<Params, Result, Error>,
    startedAction: FSAction<Params>
) =>
    asyncActionCreator.done(
        {
            params: startedAction.payload,
            result: response,
        },
        startedAction.meta
    );

const transformStartedActionToCancelled = (startedAction: FSAction<any>) => ({
    ...startedAction,
    type: (startedAction.type as string).replace(/_STARTED$/, "_CANCELLED"),
});

/**
 * Post, put or delete data as observable
 */
const methodMergeMap =
    (method: "post" | "put" | "delete") =>
    <Params, Result, Error>(
        asyncActionCreator: AsyncActionCreators<Params, Result, Error>,
        getRequestInfo: (action: FSAction<Params>) => RequestInfo & { body?: any }
    ) =>
    (action$: Observable<Action>, state$: StateObservable<RootState>) =>
        action$.pipe(
            filter(asyncActionCreator.started.match),
            withLatestFrom(state$),
            mergeMap(([startedAction, state]) => {
                const requestInfo = getRequestInfo(startedAction);

                return getTokenIfRequired(state, requestInfo.requireAuthorizationHeader).pipe(
                    mergeMap((token) =>
                        (method !== "delete"
                            ? ajaxRequest(method, token, requestInfo)
                            : deleteRequest(token, requestInfo)
                        ).pipe(
                            map((response) =>
                                createDoneAction(
                                    response.response as Result,
                                    asyncActionCreator,
                                    startedAction
                                )
                            ),
                            catchError((error: AjaxError) =>
                                of(createFailedAction(error, asyncActionCreator, startedAction))
                            )
                        )
                    ),
                    catchError((error) =>
                        of(createFailedAction(error, asyncActionCreator, startedAction))
                    )
                );
            })
        );

// TODO: Fix types
export const downloadMergeMap =
    <Params, Result, Error>(
        asyncActionCreator: AsyncActionCreators<Params, Result, Error>,
        getRequestInfo: (action: FSAction<Params>) => RequestInfo
    ) =>
    (action$: Observable<Action>, state$: StateObservable<RootState>) =>
        action$.pipe(
            filter(asyncActionCreator.started.match),
            withLatestFrom(state$),
            mergeMap(([startedAction, state]) => {
                const requestInfo = getRequestInfo(startedAction);

                return getTokenIfRequired(state, requestInfo.requireAuthorizationHeader).pipe(
                    mergeMap((token) => {
                        const request = new XMLHttpRequest();
                        const getRequest = () => request;
                        const progressId =
                            (startedAction.payload as any).fileId || asyncActionCreator.type;
                        const { url, queryParameters, headers } = requestInfo;

                        const authorizationHeader = token ? getAuthorizationHeader(token) : {};

                        // Emit on progress, and start with a dummy progress event
                        // in case progress events are not available.
                        const progressObservable = getProgressObservable(progressId, request);

                        const ajaxRequest = ajax({
                            url: queryParameters
                                ? `${url}?${queryString.stringify(queryParameters)}`
                                : url,
                            headers: {
                                "Content-type": "application/x-www-form-urlencoded",
                                ...headers,
                                ...authorizationHeader,
                            },
                            responseType: "blob",
                            createXHR: getRequest,
                        });

                        const requestObservable = ajaxRequest.pipe(
                            map((response) =>
                                downloadBlob(response, (startedAction.payload as any).fileName)
                            ),
                            // delay to make sure cleanUp doesn't remove data url before it's (programmatically) clicked
                            delay(100),
                            tap(cleanUp),
                            map(() => createDoneAction(null, asyncActionCreator, startedAction)),
                            catchError((error: AjaxError) =>
                                of(createFailedAction(error, asyncActionCreator, startedAction))
                            )
                        );

                        return progressObservable.pipe(
                            map((event: ProgressEvent) => {
                                const { loaded, total } = event;

                                return updateProgress({
                                    id: progressId,
                                    loaded,
                                    total,
                                });
                            }),
                            merge(requestObservable)
                        );
                    }),
                    catchError((error) =>
                        of(createFailedAction(error, refreshToken, startedAction))
                    )
                );
            })
        );

const getInitialProgressObservable = (progressId: string) =>
    of({
        id: progressId,
        loaded: 0,
        total: Infinity,
    });

const getProgressObservable = (
    progressId: string,
    eventTarget: XMLHttpRequest | XMLHttpRequestUpload
) => getInitialProgressObservable(progressId).pipe(concat(fromEvent(eventTarget, "progress")));

/**
 * Creates failed action with given payload and metadata
 */
const createFailedAction = <Params, Result, Error>(
    error: AjaxError | null,
    asyncActionCreator: AsyncActionCreators<Params, Result, Error>,
    startedAction: FSAction<Params>
) => {
    // If the XHR object is not available for some reason, then use
    // an object that doesn't have any response or status information.
    const xhr = (error && error.xhr) || { response: null, statusText: null };

    return asyncActionCreator.failed(
        {
            params: startedAction.payload,
            error: {
                ...xhr.response,
                statusCode: error && error.status,
                error: xhr.statusText,
            },
        },
        startedAction.meta
    );
};

export const postMergeMap = methodMergeMap("post");
export const putMergeMap = methodMergeMap("put");
export const deleteMergeMap = methodMergeMap("delete");
