import { AnyAction } from "redux";
import { isType } from "typescript-fsa";
import { fetchTestEnvironments } from "./actions";
import { TestEnvironment } from "interfaces/test-environments";

export interface EventsState {
    testEnvironments: TestEnvironment[];
}

const initialState: EventsState = {
    testEnvironments: [],
};

export default function reducer(state = initialState, action: AnyAction): EventsState {
    if (isType(action, fetchTestEnvironments.done)) {
        return {
            ...state,
            testEnvironments: action.payload.result,
        };
    }

    return state;
}
