import React, { ReactElement } from "react";
import { Column as ColumnType, ColumnRow as ColumnRowType } from "interfaces/content";
import Column from "components/content/Column/Column";
import styled from "styled-components";
import styles from "styles/styles";
import { Row } from "../../common/ui/FlexboxGrid";
import Col from "../../common/ui/FlexboxGrid/Col";

interface Props {
    columnRow: ColumnRowType;
    columnStyle?: string;
}

const ColumnRowContainer = styled.div`
    margin: ${styles.spacing[3]};
`;

function ColumnRow({ columnRow, columnStyle }: Props): ReactElement {
    return (
        <ColumnRowContainer>
            <Row>
                {columnRow.columns.map((column: ColumnType, index) => (
                    <Col key={index} xs={12} sm={6}>
                        <Column columnStyle={columnStyle} column={column} />
                    </Col>
                ))}
            </Row>
        </ColumnRowContainer>
    );
}

export default ColumnRow;
