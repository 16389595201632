import { useIntl } from "react-intl";
import { translate } from "components/utilities";

export type Translate = (messageId: string, values?: Record<string, unknown>) => string;

export const useTranslate = (): Translate => {
    const intl = useIntl();

    return (messageId: string, values?: Record<string, unknown>) =>
        translate(intl, messageId, values);
};

export default useTranslate;
