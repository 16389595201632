import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

interface Props {
    description: string;
}

const MetaDescription = ({ description }: Props): ReactElement => (
    <Helmet>
        <meta name="description" content={description} />
    </Helmet>
);

export default MetaDescription;
