import { AnyAction, Dispatch } from "redux";
import { v4 as uuid } from "uuid";

/**
 * A redux middleware which adds an unique identifier within async action metadata.
 */
export const asyncActionMiddleware = () => (next: Dispatch) => (action: AnyAction) => {
    const matches = /^(.*)_STARTED$/.exec(action.type);

    if (!matches) {
        return next(action);
    }

    if (!(action.meta && action.meta.id)) {
        action.meta = {
            ...(action.meta ? { ...action.meta } : {}),
            id: uuid(),
        };
    }

    return next(action);
};
