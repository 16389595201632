import React, { useState } from "react";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import DetailsView, { DetailsViewContent } from "components/common/ui/DetailsView/DetailsView";
import { Organization } from "interfaces/common";
import { ContactDetails, ContactInfo } from "interfaces/contact-info";
import ConfirmModal from "components/common/modals/ConfirmModal/ConfirmModal";
import { AuthService, AuthFeature, Authorization } from "interfaces/authorization";
import styled from "styled-components";
import styles from "styles/styles";
import Expandable from "../../common/ui/Expandable/Expandable";
import DeleteButton from "../../common/ui/buttons/DeleteButton/DeleteButton";
import EditButton from "../../common/ui/buttons/EditButton/EditButton";

interface Props {
    contactItems: {
        id: number;
        businessId: Organization["businessId"];
        header: React.ReactNode;
        detailsViewContent: DetailsViewContent;
    }[];
    isEditDisabled?: boolean;
    onEdit?: (id: number) => void;
    onDelete?: (id: ContactInfo["id"], businessId: ContactDetails["businessId"]) => void;
}

interface DeleteState {
    isDeleteConfirmOpen: boolean;

    // The ID of the contact info item that the user is confirming to delete
    deletedContact: {
        id: ContactInfo["id"];
        businessId: ContactDetails["businessId"];
    } | null;
}

const initialDeleteState: DeleteState = {
    isDeleteConfirmOpen: false,
    deletedContact: null,
};

const getEditRequirements = (
    businessId: Organization["businessId"]
): { required: Partial<Authorization>[] } => ({
    required: [
        {
            businessId,
            service: AuthService.DatahubServices,
            feature: AuthFeature.ContactInfo,
            accessLevel: "ReadWrite",
        },
    ],
});

const DeleteButtonContainer = styled.div`
    img {
        height: 24px;
    }
`;

const ContactItemsExpandable = styled(Expandable)`
    margin-bottom: ${styles.spacing[4]};

    :last-of-type {
        margin-bottom: 0;
    }
`;

function ContactList({ contactItems, onEdit, onDelete, isEditDisabled }: Props) {
    const translate = useTranslate();
    const [deleteState, setDeleteState] = useState(initialDeleteState);

    const onEditClick = (id: number) => () => {
        if (onEdit && !isEditDisabled) {
            onEdit(id);
        }
    };

    const onDeleteConfirm = () => {
        if (onDelete && deleteState.deletedContact) {
            onDelete(deleteState.deletedContact.id, deleteState.deletedContact.businessId);
        }

        setDeleteState(initialDeleteState);
    };

    const onDeleteCancel = () => {
        setDeleteState(initialDeleteState);
    };

    const getOnDeleteClick =
        (id: ContactInfo["id"], businessId: ContactDetails["businessId"]) => () => {
            // Prevent deleting while fetching/updating
            if (isEditDisabled) {
                return;
            }

            setDeleteState({
                deletedContact: { id, businessId },
                isDeleteConfirmOpen: true,
            });
        };

    return (
        <>
            {contactItems.map((contactItem) => (
                <ContactItemsExpandable
                    key={contactItem.id}
                    header={contactItem.header}
                    expandedAriaLabel={translate("common.details")}
                    collapsedAriaLabel={translate("common.details")}
                    actions={
                        <>
                            {onDelete && (
                                <AuthorizeContainer
                                    requirements={getEditRequirements(contactItem.businessId)}
                                >
                                    <DeleteButtonContainer>
                                        <DeleteButton
                                            onClick={getOnDeleteClick(
                                                contactItem.id,
                                                contactItem.businessId
                                            )}
                                            deleteIconAltText={translate("common.delete")}
                                        />
                                    </DeleteButtonContainer>
                                </AuthorizeContainer>
                            )}

                            {onEdit && (
                                <AuthorizeContainer
                                    requirements={getEditRequirements(contactItem.businessId)}
                                >
                                    <EditButton
                                        onClick={onEditClick(contactItem.id)}
                                        editIconAltText={translate("common.edit")}
                                    />
                                </AuthorizeContainer>
                            )}
                        </>
                    }
                >
                    <DetailsView content={contactItem.detailsViewContent} />
                </ContactItemsExpandable>
            ))}
            <ConfirmModal
                isOpen={deleteState.isDeleteConfirmOpen}
                onConfirm={onDeleteConfirm}
                onCancel={onDeleteCancel}
                descriptionKey="contactInfo.delete"
            />
        </>
    );
}

export default ContactList;
