import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import useTranslate from "hooks/common/useTranslate/useTranslate";
import { ContactType } from "interfaces/contact-info";
import AuthorizeContainer from "components/common/AuthorizeContainer/AuthorizeContainer";
import ContentSection from "components/common/ui/ContentSection/ContentSection";
import UnauthorizedPlaceholder from "components/common/UnauthorizePlaceholder/UnauthorizePlaceholder";
import AddNewContact from "../AddNewContact/AddNewContact";
import { addNewProps } from "../AddNewContact/utilities";
import Connecting from "../Connecting/Connecting";
import ExportContacts from "../ExportContacts/ExportContacts";
import InformationExchange from "../InfoExchange/InfoExchange";
import { AuthService, AuthFeature, Authorization } from "interfaces/authorization";
import styled from "styled-components";
import styles from "styles/styles";
import SearchInput from "../../common/ui/SearchInput/SearchInput";
import ToggleSwitchButtons, {
    ToggleSwitchButtonOption,
} from "../../common/ui/ToggleSwitchButtons/ToggleSwitchButtons";

interface State {
    selectedContactType: ContactType;
    filter: string;
}

const initialState: State = {
    selectedContactType: "informationExchange",
    filter: "",
};

const requirements: { required: Partial<Authorization>[] } = {
    required: [
        {
            service: AuthService.DatahubServices,
            feature: AuthFeature.ContactInfo,
            accessLevel: "Read",
        },
    ],
};

const Title = styled.h5`
    margin: 0;
    display: flex;
    flex-grow: 1;
    padding: ${styles.spacing[2]} 0;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${styles.spacing[3]};
    flex-wrap: wrap;

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        flex-direction: column;
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const TypeSelect = styled.div`
    display: flex;
    margin-left: 10px;
    padding: ${styles.spacing[2]} 0;
    flex-wrap: wrap;

    @media only screen and (max-width: 900px) {
        > button {
            min-width: 0;
        }

        margin-left: 0;
    }

    @media only screen and (${styles.breakpoints.mdGridBreakpoint}) {
        margin-left: 0;
        margin-top: 20px;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        width: 100%;

        > button {
            margin-bottom: 1px;
        }
    }
`;

function ContactInfo() {
    const translate = useTranslate();
    const [state, setState] = useState<State>(initialState);

    const onContactTypeSelect = useCallback(
        (contactType: ContactType) => (_event: React.MouseEvent) => {
            setState((state) => ({
                ...state,
                selectedContactType: contactType,
            }));
        },
        []
    );

    const onFilterChange = useCallback((filter: string) => {
        setState((state) => ({
            ...state,
            filter,
        }));
    }, []);

    const renderContactsComponent = useCallback(() => {
        switch (state.selectedContactType) {
            case "informationExchange":
                return <InformationExchange filter={state.filter} />;
            case "connecting":
                return <Connecting filter={state.filter} />;
            default:
                return null;
        }
    }, [state]);

    const contactTypeOptions: ToggleSwitchButtonOption<ContactType>[] = [
        {
            id: "informationExchange",
            content: <FormattedMessage id={`contactInfo.informationExchange`} />,
        },
        { id: "connecting", content: <FormattedMessage id={`contactInfo.connecting`} /> },
    ];

    return (
        <div>
            <Header>
                <Title>
                    <FormattedMessage id="contactInfo.title" />
                </Title>
                <TypeSelect>
                    <ToggleSwitchButtons<ContactType>
                        options={contactTypeOptions}
                        selectedOptionId={state.selectedContactType}
                        onSelectOption={onContactTypeSelect}
                    />
                </TypeSelect>
            </Header>
            <ContentSection
                header={{
                    headerKey: `contactInfo.${state.selectedContactType}`,
                    actions: (
                        <Actions>
                            <AddNewContact {...addNewProps[state.selectedContactType]} />
                            <AuthorizeContainer requirements={requirements}>
                                <ExportContacts contactType={state.selectedContactType} />
                            </AuthorizeContainer>
                            <SearchInput
                                value={state.filter}
                                onChange={onFilterChange}
                                ariaLabel={translate("common.search")}
                            />
                        </Actions>
                    ),
                }}
            >
                <AuthorizeContainer
                    requirements={requirements}
                    unauthorizedPlaceholder={<UnauthorizedPlaceholder />}
                >
                    {renderContactsComponent()}
                </AuthorizeContainer>
            </ContentSection>
        </div>
    );
}

export default ContactInfo;
