import { combineEpics } from "redux-observable";
import { fetchSwitchMap, putMergeMap } from "state/observable";
import { postMergeMap, deleteMergeMap } from "state/observable";
import { config } from "services/common/config/config";

import {
    createNewsFeedItem,
    deleteNewsFeedItem,
    fetchNewsFeed,
    updateNewsFeedItem,
} from "./actions";

export const createNewsFeedItemEpic = postMergeMap(createNewsFeedItem, (action) => ({
    url: config.news.create,
    body: {
        ...action.payload,
    },
    requireAuthorizationHeader: true,
}));

export const updateNewsFeedItemEpic = putMergeMap(updateNewsFeedItem, (action) => ({
    url: action.meta ? `${config.news.create}/${action.meta.id}` : "",
    body: {
        ...action.payload,
    },
    requireAuthorizationHeader: true,
}));

export const deleteNewsFeedItemEpic = deleteMergeMap(deleteNewsFeedItem, (action) => ({
    url: `${config.news.create}/${action.payload}`,
    requireAuthorizationHeader: true,
}));

export const fetchNewsFeedEpic = fetchSwitchMap(fetchNewsFeed, (action) => ({
    url: `${config.content.news}`,
    queryParameters: {
        ...action.payload,
    },
}));

export default combineEpics(
    createNewsFeedItemEpic,
    updateNewsFeedItemEpic,
    deleteNewsFeedItemEpic,
    fetchNewsFeedEpic
);
