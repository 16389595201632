import { successToast } from "components/common/notification/success-notification";
import {
    ConnectingContact,
    ConnectingContactDetails,
    ConnectingContactInfo,
} from "interfaces/contact-info";
import { getContactDetails } from "services/contact-info/connecting";
import {
    getContactById,
    getContactItemHeader,
    getContacts,
} from "services/contact-info/contact-info";
import { doneSelectorFactory } from "state/common/done";
import { loadingSelectorFactory } from "state/common/loading";
import {
    deleteConnectingContact,
    fetchConnectingContacts,
    fetchConnectingIfNeeded,
    updateConnectingContact,
} from "state/contact-info";
import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "hooks/common/usePrevious/usePrevious";
import ConnectingForm from "../ConnectingForm/ConnectingForm";
import ContactList from "../ContactList/ContactList";
import ContactListLoadingIndicator from "../ContactListLoadingIndicator/ContactListLoadingIndicator";
import EditModal from "../EditModal/EditModal";
import { getContactItems } from "./utilities";

const loadingSelector = loadingSelectorFactory([fetchConnectingContacts]);
const updateLoadingSelector = loadingSelectorFactory([updateConnectingContact]);
const updateDoneSelector = doneSelectorFactory(updateConnectingContact);
const deleteLoadingSelector = loadingSelectorFactory([deleteConnectingContact]);
const deleteDoneSelector = doneSelectorFactory(deleteConnectingContact);

const mapState = (state: RootState) => ({
    connectingContacts: state.contactInfo.connecting,
    isLoading: loadingSelector(state),
    isUpdateInProgress: updateLoadingSelector(state),
    isUpdateDone: updateDoneSelector(state),
    isDeleteInProgress: deleteLoadingSelector(state),
    isDeleteDone: deleteDoneSelector(state),
});

interface Props {
    filter: string;
}

interface State {
    isEditModalOpen: boolean;
    editedContact: ConnectingContactInfo | null;
}

const initialState: State = {
    isEditModalOpen: false,
    editedContact: null,
};

function Connecting({ filter }: Props) {
    const dispatch = useDispatch();
    const {
        isLoading,
        isUpdateInProgress,
        isUpdateDone,
        connectingContacts,
        isDeleteInProgress,
        isDeleteDone,
    } = useSelector(mapState, shallowEqual);
    const [state, setState] = useState<State>(initialState);
    const prevIsUpdateDone = usePrevious(isUpdateDone).current;
    const prevIsDeleteDone = usePrevious(isDeleteDone).current;

    useEffect(() => {
        dispatch(fetchConnectingIfNeeded());
    }, [dispatch]);

    const onEdit = useCallback(
        (contactId: ConnectingContactInfo["id"]) => {
            // Prevent editing while fetching/updating
            if (isLoading) {
                return;
            }

            const contactInfo = getContactById(connectingContacts, contactId);

            if (contactInfo) {
                setState((state) => ({
                    ...state,
                    isEditModalOpen: true,
                    editedContact: contactInfo,
                }));
            }
        },
        [connectingContacts, isLoading]
    );

    const closeEditForm = useCallback(() => {
        setState((state) => ({
            ...state,
            isEditModalOpen: false,
            editedContact: null,
        }));
    }, []);

    const onEditSave = useCallback(
        (contactDetails: ConnectingContactDetails, contactId: ConnectingContact["id"]) => {
            dispatch(updateConnectingContact.started({ contactDetails, contactId }));
        },
        [dispatch]
    );

    const onDelete = (
        contactId: ConnectingContactInfo["id"],
        businessId: ConnectingContactInfo["businessId"]
    ) => {
        dispatch(
            deleteConnectingContact.started({
                contactId,
                businessId,
            })
        );
    };

    useEffect(() => {
        if (prevIsUpdateDone === false && isUpdateDone === true) {
            closeEditForm();
            successToast("contactInfo.update.success");
        }
    }, [isUpdateDone, prevIsUpdateDone, closeEditForm]);

    useEffect(() => {
        if (prevIsDeleteDone === false && isDeleteDone === true) {
            successToast("contactInfo.delete.success");
        }
    }, [isDeleteDone, prevIsDeleteDone]);

    const filteredContacts = useMemo(
        () => (connectingContacts ? getContacts(connectingContacts, filter) : []),
        [connectingContacts, filter]
    );

    return (
        <>
            <ContactListLoadingIndicator isLoading={isLoading} />
            <ContactList
                contactItems={getContactItems(filteredContacts, getContactItemHeader)}
                onEdit={onEdit}
                onDelete={onDelete}
                isEditDisabled={isDeleteInProgress || isLoading || isUpdateInProgress}
            />
            <EditModal isOpen={state.isEditModalOpen} onRequestClose={closeEditForm}>
                {state.editedContact && (
                    <ConnectingForm
                        id={state.editedContact.id}
                        details={getContactDetails(state.editedContact)}
                        isSaveInProgress={isUpdateInProgress}
                        onSave={onEditSave}
                        onCancel={closeEditForm}
                    />
                )}
            </EditModal>
        </>
    );
}

export default Connecting;
