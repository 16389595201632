import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchResults from "components/search/SearchResults/SearchResults";
import SearchSection from "components/search/SearchSection/SearchSection";
import SearchPagination from "components/search/SearchPagination/SearchPagination";
import MetaDescription from "components/common/MetaDescription/MetaDescription";
import useTranslate from "hooks/common/useTranslate/useTranslate";
import useDocumentTitle from "hooks/common/navigation/useDocumentTitle";
import { loadingSelectorFactory } from "state/common/loading";
import { RootState } from "state/reducers";
import { textSearch, updateSearchTerm, updatePageNumber } from "state/search";
import { getCurrentSearchTerm, getCurrentPageNumber } from "state/search/selectors";
import { IndexDataType, ContentSearchParameters } from "interfaces/search";
import useLocale from "hooks/common/useLocale/useLocale";
import styled from "styled-components";
import Page from "components/common/layout/Page";

const loadingSelector = loadingSelectorFactory([textSearch]);

const mapState = (state: RootState) => ({
    searchTerm: getCurrentSearchTerm(state),
    pageNumber: getCurrentPageNumber(state),
    searchResults: state.search.searchResults,
    filters: state.search.filters,
    isLoading: loadingSelector(state),
});

const Container = styled.div`
    position: relative;
`;

const SearchPage = () => {
    const { searchTerm, filters, searchResults, isLoading, pageNumber } = useSelector(mapState);
    const dispatch = useDispatch();
    const locale = useLocale();
    const translate = useTranslate();

    useDocumentTitle(translate("search.title"));

    const onSearchTermChange = useCallback(
        (searchTerm: string) => {
            dispatch(updateSearchTerm(searchTerm));
        },
        [dispatch]
    );

    const onPaginationChange = useCallback(
        (pageNumber: number) => {
            dispatch(updatePageNumber(pageNumber));
        },
        [dispatch]
    );

    useEffect(() => {
        const filtersList = Object.keys(filters).filter(
            (key: IndexDataType) => filters[key]
        ) as IndexDataType[];

        if (searchTerm) {
            const searchParams: ContentSearchParameters = {
                searchTerm,
                locale,
                pageNumber,
            };

            if (filtersList && filtersList.length > 0) {
                searchParams.filters = filtersList;
            }

            dispatch(textSearch.started(searchParams));
        }
    }, [searchTerm, dispatch, filters, locale, pageNumber]);

    const searchPageDescription = translate("search.description");

    return (
        <>
            <MetaDescription description={searchPageDescription} />
            <Container>
                <SearchSection searchTerm={searchTerm} onSearchTermChange={onSearchTermChange} />
                <Page>
                    <SearchResults
                        searchTerm={searchTerm}
                        searchResults={searchResults}
                        isLoading={isLoading}
                    />
                    {searchResults && searchTerm && (
                        <SearchPagination
                            searchResults={searchResults}
                            onPaginationChange={onPaginationChange}
                            currentPageNumber={pageNumber}
                        />
                    )}
                </Page>
            </Container>
        </>
    );
};

export default SearchPage;
