import * as React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { RootState } from "state/reducers";
import { shallowEqual } from "state/utilities";
import { login } from "services/auth/auth";
import styled from "styled-components";
import styles from "styles/styles";
import Content from "../ui/Content/Content";

const mapState = (state: RootState) => ({
    user: state.oidc.user,
});

const onLogin = (event: React.MouseEvent) => {
    event.preventDefault();
    login();
};

const AuthorizationRequired = styled.div`
    padding: ${styles.spacing[4]};
    font-size: 24px;
    color: ${styles.colors.grey7};
    text-align: center;
`;

const LoginText = styled.a`
    color: ${styles.colors.red};
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;

    :hover {
        text-decoration: underline;
    }
`;

function UnauthorizedPlaceholder() {
    const { user } = useSelector(mapState, shallowEqual);

    return (
        <Content>
            <AuthorizationRequired>
                {user ? (
                    <FormattedMessage id="common.authorizationRequired" />
                ) : (
                    <FormattedMessage
                        id="common.loginRequired"
                        values={{
                            login: (
                                <LoginText href="" onClick={onLogin}>
                                    <FormattedMessage id="common.loginRequired.login" />
                                </LoginText>
                            ),
                        }}
                    />
                )}
            </AuthorizationRequired>
        </Content>
    );
}

export default UnauthorizedPlaceholder;
