import * as React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import infoIcon from "../../../../images/info.svg";
import "./react-tooltip.css";
import styled from "styled-components";
import useId from "../../../../hooks/common/useId/useId";

interface Props {
    infoText: string;

    infoIconAltText: string;

    id?: string;

    className?: string;
}

const InfoImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: help;
    padding-left: 7px;
`;

const Wrapper = styled.span`
    white-space: pre-line;
`;

/**
 * Component to display an Info button, which can be hovered to reveal more information
 *
 * Note that testing the tooltip functionality of this component requires a workaround.
 * See the tests of this component.
 */
const Info = ({ id, infoIconAltText, infoText }: Props) => {
    const randomId = useId();
    const tooltipId = id || randomId;

    return (
        <Wrapper>
            <span data-tooltip-id={tooltipId}>
                <InfoImg src={infoIcon} alt={infoIconAltText} />
            </span>
            <ReactTooltip id={tooltipId}>{infoText}</ReactTooltip>
        </Wrapper>
    );
};

export default Info;
